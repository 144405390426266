import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";

class CreateFaculty extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    // this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
    // this.state = { editorState: EditorState.createEmpty() };
    // this.oneditorChange = (editorState) => this.setState({ editorState });
  }

  state = {
    name: this.props.resp ? this.props.resp.data.name : "",
    designation: this.props.resp ? this.props.resp.data.designation : "",
    designation2: this.props.resp ? this.props.resp.data.designation2 : "",
    designation3: this.props.resp ? this.props.resp.data.designation3 : "",
    designation4: this.props.resp ? this.props.resp.data.designation4 : "",
    bio: this.props.resp ? this.props.resp.data.bio : "",
    image: this.props.resp ? this.props.resp.data.img : "",
    sortBy: "name:asc",
    limit: 1000,
    page: 1,
    faculty: [],
    search: "",
    showdesignation3: false,
    showdesignation4: false,
    create: 1,
  };

  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };
  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  designationHandleChange = (event) => {
    this.setState({ designation: event.target.value });
  };
  designation2HandleChange = (event) => {
    this.setState({ designation2: event.target.value });
  };
  designation3HandleChange = (event) => {
    this.setState({ designation3: event.target.value });
  };
  designation4HandleChange = (event) => {
    this.setState({ designation4: event.target.value });
  };
  imageHandleChange = (event) => {
    this.setState({ image: event.target.value });
  };
  bioHandleChange = (event) => {
    this.setState({ bio: event.target.value });
  };
  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");
      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "facultys/" + this.props.resp.data.id,
            {
              name: this.state.name,
              designation: this.state.designation,
              bio: this.state.bio,
              img: this.state.image,
              designation2: this.state.designation2,
              designation3: this.state.designation3,
              designation4: this.state.designation4,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Faculty Updated");
              this.setState({ create: 0 });
            } else {
              // this.setState({alert:res.data.msg});
              swal("User not found");
            }
          });
      } else {
        axios
          .post(
            Constants.api + "facultys",
            {
              name: this.state.name,
              designation: this.state.designation,
              bio: this.state.bio,
              img: this.state.image,
              designation2: this.state.designation2,
              designation3: this.state.designation3,
              designation4: this.state.designation4,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Faculty Added");

              this.setState({
                name: "",
                image: "",
                designation: "",
                bio: "",
                designation2: "",
                designation3: "",
                designation4: "",
              });
            } else {
              // this.setState({alert:res.data.msg});
              swal("User not found");
            }
          });
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };

  render() {
    if (this.state.create == 0) {
      window.location = "/faculty";
    }

    let search = this.state.search;

    return (
      <>
        <div class="faculty_section faculty_list">
          <div class="Faculty_text">
            <p>
              <span class="faculty_text">Faculty Listing </span>/
              <span>{this.props.resp ? "Edit" : "Create"} Faculty</span>
            </p>
          </div>
        </div>
        <div class="faculty_section faculty_list mar-20">
          <div class="faculty_text">
            <h3>
              {" "}
              <i
                class="fa fa-arrow-left"
                aria-hidden="true"
                onClick={() => this.setState({ create: 0 })}
              ></i>
              {this.props.resp ? "Edit" : "Create"} Faculty
            </h3>{" "}
          </div>
          <div class="btn_create">
            <button
              type="submit"
              onClick={this.handleFormSubmit}
              class="btn  btn-create btn-save_cretate"
            >
              Save
            </button>
            <button
              type="button"
              class="btn btn-save btn-save_close"
              onClick={() => this.setState({ create: 0 })}
            >
              Close
            </button>
          </div>
        </div>
        <div class="faculty_information">
          <div class="container">
            <div class="row">
              <div class="col-md-8 col-sm-12 main-scroll">
                <div class="faculty_info_sec">
                  <div class="faculty_info">
                    <div class="faculty_sections">
                      <div class="faculty_text">
                        <p>Faculty Name*</p>
                      </div>
                      <div class="number">
                        <p>{this.state.name.length}/100</p>
                      </div>
                    </div>
                  </div>

                  <div class="faculty_names">
                    {/* <div class="row"> */}
                    {/* <div class="col-md-11"> */}
                    <div class="input_section">
                      <div class="input_text">
                        <input
                          type="text"
                          name="name"
                          value={this.state.name}
                          onChange={this.nameHandleChange}
                          class="form-control"
                          id="exampleInputEmail1"
                          placeholder="Type here"
                          maxLength="100"
                        />{" "}
                      </div>
                    </div>
                    {this.validator.message(
                      "name",
                      this.state.name,
                      "required|string|max:250"
                    )}
                    {/* </div> */}
                    {/* <div class="col-md-1">
                          <div class="fa_arrow">
                            {" "}
                            <i class="fa fa-arrows-alt -alt" aria-hidden="true"></i>{" "}
                          </div>
                        </div> */}
                    {/* </div> */}
                  </div>
                  <div class="faculty_info">
                    <div class="faculty_sections">
                      <div class="faculty_text">
                        <p>Current Designation*</p>
                      </div>
                      <div class="number">
                        <p>{this.state.designation.length}/50</p>
                      </div>
                    </div>
                  </div>
                  <div class="faculty_names">
                    {/* <div class="row"> */}
                    {/* <div class="col-md-11"> */}
                    <div class="input_section">
                      <div class="input_text">
                        <input
                          type="text"
                          name="designation"
                          value={this.state.designation}
                          maxlength="100"
                          onChange={this.designationHandleChange}
                          class="form-control"
                          id="exampleInputEmail1"
                          placeholder="Type here"
                        />{" "}
                      </div>
                    </div>
                    {this.validator.message(
                      "designation",
                      this.state.name,
                      "required|string|max:250"
                    )}
                    {/* </div> */}
                    {/* <div class="col-md-1">
                          <div class="fa_arrow">
                            {" "}
                            <i class="fa fa-arrows-alt -alt" aria-hidden="true"></i>{" "}
                          </div>
                        </div> */}
                    {/* </div> */}
                  </div>
                  <div class="faculty_info">
                    <div class="faculty_sections">
                      <div class="faculty_text">
                        <p>Designation 2</p>
                      </div>
                      <div class="number">
                        <p>{this.state.designation2.length}/50</p>
                      </div>
                    </div>
                  </div>
                  <div class="faculty_names">
                    {/* <div class="row"> */}
                    {/* <div class="col-md-11"> */}
                    <div class="input_section">
                      <div class="input_text">
                        <input
                          type="text"
                          name="designation2"
                          onChange={this.designation2HandleChange}
                          class="form-control"
                          maxlength="100"
                          id="exampleInputEmail1"
                          placeholder="Type here"
                          value={this.state.designation2}
                        />{" "}
                      </div>
                    </div>
                    {/* </div> */}
                    {/* <div class="col-md-1">
                          <div class="fa_arrow">
                            {" "}
                            <i class="fa fa-arrows-alt -alt" aria-hidden="true"></i>{" "}
                          </div>
                        </div> */}
                    {/* </div> */}
                  </div>
                  {this.state.showdesignation3 == true ? (
                    <>
                      <div class="faculty_info">
                        <div class="faculty_sections des-padding">
                          <div class="faculty_text">
                            <p>Designation 3</p>
                          </div>
                          <div class="number">
                            <p>{this.state.designation3.length}/50</p>
                          </div>
                        </div>
                      </div>
                      <div class="faculty_names">
                        <div class="row-full">
                          <div class="col-md-12">
                            <div class="input_section">
                              <div class="input_text">
                                <input
                                  type="text"
                                  name="designation3"
                                  onChange={this.designation3HandleChange}
                                  class="form-control"
                                  maxlength="100"
                                  value={this.state.designation3}
                                  id="exampleInputEmail1"
                                  placeholder="Type here"
                                />{" "}
                              </div>
                            </div>
                          </div>
                          {/* <div class="col-md-1">
                              <div class="fa_arrow">
                                {" "}
                                <i
                                  class="fa fa-arrows-alt -alt"
                                  aria-hidden="true"
                                ></i>{" "}
                              </div>
                            </div> */}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.showdesignation4 == true ? (
                    <>
                      <div class="faculty_info">
                        <div class="faculty_sections des-padding">
                          <div class="faculty_text">
                            <p>Designation 4</p>
                          </div>
                          <div class="number">
                            <p>{this.state.designation4.length}/50</p>
                          </div>
                        </div>
                      </div>
                      <div class="faculty_names">
                        <div class="row-full">
                          <div class="col-md-12">
                            <div class="input_section">
                              <div class="input_text">
                                <input
                                  type="text"
                                  name="designation4"
                                  maxlength="100"
                                  onChange={this.designation4HandleChange}
                                  class="form-control"
                                  id="exampleInputEmail1"
                                  value={this.state.designation4}
                                  placeholder="Type here"
                                />{" "}
                              </div>
                            </div>
                          </div>
                          {/* <div class="col-md-1">
                              <div class="fa_arrow">
                                {" "}
                                <i
                                  class="fa fa-arrows-alt -alt"
                                  aria-hidden="true"
                                ></i>{" "}
                              </div>
                            </div> */}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {!this.state.showdesignation3 ? (
                    <div class="add_section">
                      {" "}
                      <span
                        class="add-more-cursor"
                        onClick={() =>
                          this.setState({ showdesignation3: true })
                        }
                      >
                        <i class="fa fa-plus-circle" aria-hidden="true"></i> Add
                        More
                      </span>{" "}
                    </div>
                  ) : (
                    ""
                  )}
                  {!this.state.showdesignation4 &&
                  this.state.showdesignation3 ? (
                    <div class="add_section">
                      {" "}
                      <span
                        class="add-more-cursor"
                        onClick={() =>
                          this.setState({ showdesignation4: true })
                        }
                      >
                        <i class="fa fa-plus-circle" aria-hidden="true"></i> Add
                        More
                      </span>{" "}
                    </div>
                  ) : (
                    ""
                  )}
                  <div class="faculty_info faculty_upload">
                    <p> Enter Image URL*</p>
                    {/* <div class="row">
                        <div class="col-md-12"> */}
                    <div class="input_section">
                      <div class="input_text">
                        <input
                          type="text"
                          name="image"
                          value={this.state.image}
                          onChange={this.imageHandleChange}
                          class="form-control upload-text"
                          id="exampleInputEmail1"
                          placeholder="Uploaded file name to be shown here"
                        />{" "}
                      </div>
                    </div>
                    {this.validator.message(
                      "image",
                      this.state.image,
                      "required|string"
                    )}
                    {/* </div> */}
                    {/* <div class="col-md-1">
                          <div class="fa_arrow">
                            {" "}
                            <i class="fa fa-arrows-alt -alt" aria-hidden="true"></i>{" "}
                          </div>
                        </div> */}
                    {/* </div> */}
                  </div>
                  <div class="faculty_info faculty_upload">
                    <div class="faculty_text_section ">
                      <div class="faculty_info">
                        <div class="faculty_sections Upload_info faculty_bio">
                          <div class="faculty_text">
                            <p>Faculty Bio*</p>
                          </div>
                          <div class="number">
                            <p>{this.state.bio.length}/280</p>
                          </div>
                        </div>
                        {/* <div class="row">
                            <div class="col-md-12"> */}
                        <div class="Upload_sections">
                          <textarea
                            name="bio"
                            onChange={this.bioHandleChange}
                            value={this.state.bio}
                            className="form-control"
                            row="3"
                            class="upload-text"
                            maxLength="280"
                          />
                        </div>
                        {this.validator.message(
                          "bio",
                          this.state.name,
                          "required|string|max:280"
                        )}
                        {/* </div> */}
                        {/* </div> */}
                      </div>
                    </div>
                    <br />
                    <br />{" "}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div class="available_img_section">
                  {this.state.name != "" ? (
                    <div class="img_info">
                      <div class="available_img">
                        <img src={this.state.image} />

                        <h5 class="heading_name"> {this.state.name}</h5>
                        <h5>{this.state.designation}</h5>
                        <div class="delte_img"></div>
                      </div>
                    </div>
                  ) : (
                    <div class="na-container">Not Available</div>
                  )}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CreateFaculty;
