import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
class CreateAccessRoles extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      name: this.props.resp ? this.props.resp.data.name : "",
      role: this.props.resp ? this.props.resp.data.role : "",
      email: this.props.resp ? this.props.resp.data.email : "",
    };
  }

  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  roleHandleChange = (event) => {
    this.setState({ role: event.target.value });
  };
  emailHandleChange = (event) => {
    this.setState({ email: event.target.value });
  };
  passwordHandleChange = (event) => {
    this.setState({ password: event.target.value });
  };
  
  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");

      let obj = {
        name: this.state.name,
        email: this.state.email,
        role: this.state.role,
        password: this.state.password,
      };
      if (this.props.resp) {
        axios
          .patch(Constants.api + "users/" + this.props.resp.data.id, obj, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.status == 200) {
              swal("User Updated");
              this.setState({ create: 0 });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      } else {
        axios
          .post(Constants.api + "users", obj, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.status == 201) {
              swal("User Added");
              window.location = "/access-code";

              this.setState({
                name: "",
                email: "",
                role: "",
                password: "",
              });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };

  render() {
    if (this.state.create == 0) {
      window.location = "/access-code";
    }

    return (
      <>
        <div class="main-head pathway-head pad_30">
          <h3>
            <i
              class="fa fa-arrow-left"
              onClick={() => this.setState({ create: 0 })}
              aria-hidden="true"
            ></i>{" "}
            Roles & Access
          </h3>
          <div class="btn-right">
            {/* <button type="button" class="btn btn-edit">
                <i class="fa fa-eye" aria-hidden="true"></i>
              </button> */}
            <button
              type="button"
              onClick={this.handleFormSubmit}
              class="btn  btn-create btn-save_cretate pathway-add-save-btn"
            >
              Save
            </button>
            <button
              type="button"
              onClick={() => this.setState({ create: 0 })}
              class="btn  btn-create btn-save btn-save_close pathway-add-next-btn"
            >
              Close
            </button>
          </div>
        </div>
        <div class="roles_access">
          <div class="container roles_table">
            <div class="row">
              <div class="col-sm-12"></div>
            </div>
          </div>
          <div class="email_section ">
            <div class="from_section">
              <div class="from_group">
                <form>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-11">
                        <div class="from_email_user">
                          <label for="exampleInputEmail1">Full Name*</label>
                          <input
                            type="email"
                            class="form-control"
                            id=""
                            name="name"
                            value={this.state.name}
                            onChange={this.nameHandleChange}
                            aria-describedby="emailHelp"
                            placeholder="Type here"
                          />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-11">
                        <div class="from_email_user">
                          <label for="exampleInputEmail1">Email*</label>
                          <input
                            type="email"
                            class="form-control"
                            id=""
                            name="email"
                            value={this.state.email}
                            onChange={this.emailHandleChange}
                            aria-describedby="emailHelp"
                            placeholder="Type here"
                          />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div class="form-group">
                    <div class="row">
                      <div class="col-md-11">
                        <div class="from_email_user">
                          <label for="exampleInputEmail1">First Name**</label>
                          <input
                            type="email"
                            class="form-control"
                            id=""
                            aria-describedby="emailHelp"
                            placeholder="Type here"
                          />{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-11">
                        <div class="from_email_user">
                          <label for="exampleInputEmail1">Last Name*</label>
                          <input
                            type="email"
                            class="form-control"
                            id=""
                            aria-describedby="emailHelp"
                            placeholder="Type here"
                          />{" "}
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div class="form-group from_passsword">
                    <div class="row">
                      <>
                        <div class="col-md-3 p_r_0">
                          <label for="exampleInputEmail1">
                            Passsword{" "}
                            {/* <img
                            src="img/Icon ionic-ios-information-circle-outline@2x.png"
                            alt="img_circle"
                          /> */}
                          </label>
                          <button
                            type="button"
                            class="btn btn-success Add_more_option "
                            onClick={() =>
                              this.setState({
                                password: Math.random().toString(36).slice(2),
                              })
                            }
                          >
                            Generate Password
                          </button>
                        </div>

                        <div class="col-md-8 gen-password-contain">
                          <div class="from_email_user m_t_31">
                            <input
                              type="text"
                              class="form-control"
                              id=""
                              aria-describedby="emailHelp"
                              name="password"
                              value={this.state.password}
                              onChange={this.passwordHandleChange}
                              placeholder="Generated password will be shown here"
                            />{" "}
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                  <div class="form-group from_passsword">
                    <div class="row">
                      <div class="col-md-11 access-chose">
                        <div class="input-group ">
                          <select
                            class="custom-select"
                            name="role"
                            value={this.state.role}
                            onChange={this.roleHandleChange}
                            id="inputGroupSelect01"
                          >
                            <option value="">Choose...</option>
                            <option value="admin">Admin</option>
                            <option value="Product">Product</option>
                            <option value="Support">Support</option>
                            <option value="CommercialAdmin">
                              Commercial Admin
                            </option>
                            <option value="CommercialAuthor">
                              Commercial Author
                            </option>
                            <option value="ContentOpsAdmin">
                              ContentOps Admin
                            </option>
                            <option value="ContentOpsAuthor">
                              ContentOps Author
                            </option>
                            <option value="ConsumerDailyAdmin">
                              Consumer Daily Admin
                            </option>
                            <option value="ConsumerDailyAuthor">
                              Consumer Daily Author
                            </option>
                            <option value="ConsumerMerchAdmin">
                              Consumer Merch Admin
                            </option>
                            <option value="ConsumerMerchAuthor">
                              Consumer Merch Author
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  addContent(content) {
    if (
      this.state.addedContent.some((val) => content.name === val.name) === false
    )
      this.state.addedContent.push(content);
    this.state.contents.splice(this.state.contents.indexOf(content), 1);

    this.state.contents = [];
    this.state.searchTerm = "";
  }
  removeContent(content) {
    this.state.addedContent.splice(this.state.addedContent.indexOf(content), 1);
  }

  searchUpdated(term) {
    const token = localStorage.getItem("token");
    if (term.length > 0) {
      axios
        .get(
          Constants.api +
            "pathways?name=" +
            term +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=60&page=" +
            this.state.page,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            if (this.state.addedContent.length != 0) {
              this.state.addedContent.forEach((faculty) => {
                let index = res.data.output.results.indexOf(faculty);
                const newFaculty = res.data.output.results;
                // newFaculty.splice(index, 1);
                this.setState({ contents: newFaculty });
              });
            } else {
              this.setState({ contents: res.data.output.results });
            }
          }
        });
      this.setState({ searchTerm: term });
    } else {
      this.setState({
        contents: [],
      });
    }
  }
}

export default CreateAccessRoles;
