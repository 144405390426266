import React from "react";
import { Route, Switch } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Login from "./components/login/login.component";
import Faculty from "./components/faculty/faculty.component";
import Dashboard from "./components/dashboard/dashboard.component";
import Navbar from "./components/navbar/navbar.component";
import ThriveSKill from "./components/thriveskill/thriveskill.component";
import Categories from "./components/categories/categories.component";
import Content from "./components/content/content.component";
import CreateVideo from "./components/content/createvideo.component";
import CreateImage from "./components/content/createimage.component";
import CreateMcq from "./components/content/createmcq.component";
import CreatePoll from "./components/content/createpoll.component";
import Graded from "./components/gradedassesment/graded.component";
import Lessons from "./components/lesson/lesson.component";
import Pathway from "./components/pathway/pathway.component";
import PersonalAssessment from "./components/personal/personal.component";
import Assessment from "./components/assessment/assessment.component";
import LessonCreate from "./components/lesson/lessonCreate.component";
import LessonSort from "./components/lesson/lessonSort.component";
import Pricing from "./components/pricing/pricing.component";
import Question from "./components/gradedassesment/question.component";
import PathwayMerch from "./components/pathwaymerchandising/pathwaymerchandising.component";
import TodaySection from "./components/today/todaysection.component";
import DailyChallenge from "./components/dailyChallenge/dailyChallenge.component";
import AccessRoles from "./components/accessRoles/accessRoles.component";
import Coupon from "./components/coupon/coupon.component";
import Testimonial from "./components/testimonial/testimonial.component";
import TextEditor from "./TextEditor";
import Templates from "./components/templates/templates.component";
import TodayContents from "./components/templates/todaycontents.component";
import Tags from "./components/templates/tags.component";

function App() {
  return (
    <>
      {localStorage.getItem("userid") ? (
        <>
          <nav class="navbar navbar-expand-lg" sticky="top">
            <a class="navbar-brand" href="#">
              <img src={require("./img/logo.svg").default} />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ml-auto">
                <li class="dropdown user user-menu">
                  <a href="#" class="">
                    <img
                      src={require("./img/admin.png").default}
                      class="user-image"
                      alt="User Image"
                    />
                    {localStorage.getItem("username")}
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          <div class="container-fluid  container_text">
            <div class="row no-gutters">
              <div class="col-md-2">
                <Navbar />
              </div>
              <div class="col-md-10">
                <Switch>
                  <Route exact path="/faculty" component={Faculty} />
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route exact path="/thrive-skill" component={ThriveSKill} />
                  <Route exact path="/categories" component={Categories} />
                  <Route exact path="/content" component={Content} />
                  <Route
                    exact
                    path="/content/createvideo"
                    component={CreateVideo}
                  />
                  <Route
                    exact
                    path="/content/createimage"
                    component={CreateImage}
                  />
                  <Route
                    exact
                    path="/content/createpoll"
                    component={CreatePoll}
                  />
                  <Route
                    exact
                    path="/content/createmcq"
                    component={CreateMcq}
                  />
                  <Route exact path="/graded-assesment" component={Graded} />
                  <Route exact path="/lesson" component={Lessons} />
                  <Route exact path="/lessonsort" component={LessonSort} />
                  <Route exact path="/pathway" component={Pathway} />
                  <Route exact path="/question" component={Question} />
                  <Route
                    exact
                    path="/personal-assessment"
                    component={PersonalAssessment}
                  />
                  <Route
                    exact
                    path="/pathway-merchandising"
                    component={PathwayMerch}
                  />
                  <Route exact path="/today-section" component={TodaySection} />
                  <Route
                    exact
                    path="/daily-challenge"
                    component={DailyChallenge}
                  />
                  <Route exact path="/assessment" component={Assessment} />
                  <Route exact path="/pricing" component={Pricing} />
                  <Route exact path="/access-code" component={AccessRoles} />
                  <Route exact path="/coupon-code" component={Coupon} />
                  <Route exact path="/testimonial" component={Testimonial} />
                  <Route exact path="/texteditor" component={TextEditor} />
                  <Route exact path="/today-template" component={Templates} />
                  <Route exact path="/tags" component={Tags} />

                  <Route
                    exact
                    path="/today-content"
                    component={TodayContents}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Route exact path="/" component={Login} />
      )}
    </>
  );
}

export default App;
