import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import Switch from "react-switch";

class CreateTag extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.handleTagDrag = this.handleTagDrag.bind(this);
    this.state = {
      create: 1,
      superCategory: this.props.resp ? this.props.resp.data.superCategory : "",
      category: this.props.resp
        ? this.props.resp.data.category
        : [
          {
            name: "",
            tags: [],
          },
        ],
      tags: [],
      currentTag: "",
    };
  }
  categoryHandleChange = (event) => {
    this.setState({ category: event.target.value });
  };
  superCategoryHandleChange = (event) => {
    this.setState({ superCategory: event.target.value });
  };
  tagHandleChange = (event) => {
    this.setState({ currentTag: event.target.value });
  };

  handleTagAddition(event) {
    if (event.charCode == "13") {
      this.setState((state) => ({ tags: [...state.tags, event.target.value] }));
      this.setState({ currentTag: "" });
    }
  }

  handleTagDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  }

  addFormFields = () => {
    this.setState({
      category: [...this.state.category, { name: "", tags: [] }],
    });
  };

  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      this.validator.hideMessages();

      const token = localStorage.getItem("token");

      let obj = {};
      obj = {
        category: this.state.category,
        superCategory: this.state.superCategory,
      };
      if (this.props.resp) {
        console.log(this.props.resp);
        axios
          .patch(Constants.api + "tags/" + this.props.resp.data.id, obj, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.status == 200) {
              swal("Tags Updated");
              this.setState({ create: 0 });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      } else {
        axios
          .post(
            Constants.api + "tags",

            obj,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Tags Added");
              window.location = "/tags";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };
  render() {
    let removeFormFields = (i) => {
      let category = this.state.category;
      category.splice(i, 1);
      this.setState({ category });
    };

    let handleOptionChange = (i, e) => {
      let category = this.state.category;
      category[i][e.target.name] = e.target.value;
      this.setState({ category });
      console.log(this.state.category);
    };
    let handleTagChange = (i, e) => {
      let category = this.state.category;

      let tags = category[i].tags;

      if (e.charCode == "13") {
        tags.push(e.target.value);

        category[i].tags = tags;
        this.setState({ category });
        console.log(this.state.category);
      }
    };
    let handleTagDelete = (i, index) => {
      let { category } = this.state;
      console.log(category[i].tags);

      let tag = category[i].tags.splice(index, 1);
      console.log(tag);

      category[i].tags = tag;
    };
    if (this.state.create == 0) {
      window.location = "/tags";
    }
    let type = this.state.type;
    let options = [
      { value: "IMAGE_TEXT_SEPERATED", label: "Image & Text Seperate" },
      { value: "VIDEO_TEXT_SEPERATED", label: "Video & Text Seperate" },
      { value: "IMAGE_TEXT_OVERLAY", label: "Image with text overlay" },
      { value: "TEXT_ONLY", label: " Text Only" },
    ];

    return (
      <>
        {" "}
        <div class="add_video_section add_video">
          {/* Bread crumbs */}
          {/* <div class="faculty_sections">
            <div class="Faculty_text">
              <p>
                <span>Create Tags</span>
              </p>
            </div>
          </div> */}
          {/* Second Section */}
          <div class="faculty_section">
            <div class="faculty_text">
              <h3>
                <i
                  class="fa fa-arrow-left"
                  aria-hidden="true"
                  onClick={() => this.setState({ create: 0 })}
                ></i>{" "}
                {this.props.resp ? "Edit" : "Create"}
                Tags
              </h3>{" "}
            </div>
            <div class="btn_create">
              <button
                type="submit"
                onClick={this.handleFormSubmit}
                class="btn btn-primary btn_category btn_vidios btn-save-1"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                {this.props.resp ? "Update" : "Save"}

              </button>
              <button
                onClick={() => this.setState({ create: 0 })}
                type="button"
                class="btn btn-save btn-save_close"
              >
                Close
              </button>
            </div>
          </div>

          {/* Main Content */}
          <div class="mcq_section ">
            <div class="container video_sections">
              <div class="row">
                {/* Content */}
                <div class="col-md-8 main-scroll">
                  {/* Headin Section */}
                  <div class="template_vidio_name">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="template_name image-template-name">
                          <label for="exampleFormControlInput1">
                            Super Category
                          </label>

                          <div class="input_section">
                            <div class="input_text">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Super Category Name"
                                name="superCategory"
                                value={this.state.superCategory}
                                onChange={this.superCategoryHandleChange}
                                maxLength="50"
                                id=""
                              />{" "}
                              {this.validator.message(
                                "Super Category",
                                this.state.superCategory,
                                "required"
                              )}
                            </div>
                          </div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                    {this.state.category.map(function (item, i) {
                      return (
                        <>
                          <div class="row">
                            <div class="col-md-12">
                              <div class="template_name image-template-name">
                                <label for="exampleFormControlInput1">
                                  Category{"  "}
                                  {i > 0 ? (
                                    <i
                                      class="fa fa-close btn btn-danger btn-sm"
                                      onClick={() => removeFormFields(i)}
                                    ></i>
                                  ) : (
                                    ""
                                  )}
                                </label>

                                <div class="input_section">
                                  <div class="input_text">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Category Name"
                                      name="name"
                                      value={item.name || ""}
                                      maxLength="50"
                                      id=""
                                      onChange={(e) => handleOptionChange(i, e)}
                                    />{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="input_section">
                            <div class="input_text">
                              <div class="template_name image-template-name">
                                <label for="exampleFormControlInput1">
                                  Tags{" "}
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="tags"
                                  name="tags"
                                  onKeyPress={(e) => handleTagChange(i, e)}
                                  placeholder="Press enter to add tags"
                                />

                                {item.tags.map((tag, index) => (
                                  <>
                                    <span style={{ background: "#cecece" }}>
                                      {tag}
                                      <i
                                        className="material-icons "
                                        onClick={() =>
                                          handleTagDelete(i, index)
                                        }
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                      >
                                        &nbsp; x
                                      </i>
                                    </span>
                                    &nbsp;
                                  </>
                                ))}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                    <div class="template_name">
                      <div class="input_text">
                        <button
                          onClick={this.addFormFields}
                          class="btn btn-primary"
                        >
                          Add more categories
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CreateTag;
