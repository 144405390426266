import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import Switch from "react-switch";
import DatePicker from "react-datetime";
import SearchInput from "react-search-input";
import ReactHlsPlayer from "react-hls-player";
import { Editor } from "@tinymce/tinymce-react";
import ReactDragListView from "react-drag-listview";
import ReactDragListView2 from "react-drag-listview";
import ReactDragListView3 from "react-drag-listview";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import parse from "html-react-parser";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
class CreateTodayContent extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
    this.pathwaySearchUpdated = this.pathwaySearchUpdated.bind(this);
    this.feedbackHandleChange = this.feedbackHandleChange.bind(this);

    this.state = {
      create: 1,
      template: [],
      name:
        this.props.resp && this.props.resp.data
          ? this.props.resp.data.name
          : "",
      duration:
        this.props.resp && this.props.resp.data
          ? this.props.resp.data.duration
          : "",
      type:
        this.props.resp && this.props.resp.data
          ? this.props.resp.data.template.templateType
          : "",
      descriptionPresent:
        this.props.resp && this.props.resp.data.ttDescriptor
          ? this.props.resp.data.ttDescriptor.present
          : false,
      imageAlign:
        this.props.resp &&
          this.props.resp.data.ttImage &&
          this.props.resp.data.ttImage.alignTop
          ? this.props.resp.data.ttImage.alignTop
          : false,
      videoAlign:
        this.props.resp &&
          this.props.resp.data.ttVideo &&
          this.props.resp.data.ttVideo.alignTop
          ? this.props.resp.data.ttVideo.alignTop
          : false,
      headingAlign:
        this.props.resp &&
          this.props.resp.data.ttHeading &&
          this.props.resp.data.ttHeading.alignTop
          ? this.props.resp.data.ttHeading.alignTop
          : false,
      imageHeight:
        this.props.resp && this.props.resp.data.ttImage
          ? this.props.resp.data.ttImage.imageHeight
          : 0,
      imageUrl:
        this.props.resp && this.props.resp.data.template.image
          ? this.props.resp.data.template.image.imageUrl
          : "",
      videoHeight:
        this.props.resp && this.props.resp.data.ttVideo
          ? this.props.resp.data.ttVideo.videoHeight
          : 0,
      videoWidth:
        this.props.resp && this.props.resp.data.ttVideo
          ? this.props.resp.data.ttVideo.videoWidth
          : 0,
      headingLimit:
        this.props.resp && this.props.resp.data.template
          ? this.props.resp.data.template.heading.headingLimit
          : 0,
      minFont:
        this.props.resp && this.props.resp.data.template
          ? this.props.resp.data.template.heading.fontSizeMax
          : 20,
      maxFont:
        this.props.resp && this.props.resp.data.template
          ? this.props.resp.data.template.heading.fontSizeMax
          : 30,
      selectedType:
        this.props.resp && this.props.resp.data.template
          ? {
            value: this.props.resp.data.template.templateType,
            label: this.props.resp.data.template.templateType,
          }
          : null,
      selectedTemplate:
        this.props.resp && this.props.resp.data.template
          ? this.props.resp.data.template
          : {},
      selectednuggetType:
        this.props.resp && this.props.resp.data.content
          ? {
            value: this.props.resp.data.content.nuggetType,
            label: this.props.resp.data.content.nuggetType,
          }
          : {},
      heading:
        this.props.resp &&
          this.props.resp.data.template &&
          this.props.resp.data.template.heading
          ? this.props.resp.data.template.heading.headingText
          : "",
      description:
        this.props.resp &&
          this.props.resp.data.template &&
          this.props.resp.data.template.descriptor
          ? this.props.resp.data.template.descriptor.descriptorText
          : "",
      startDate: this.props.resp ? this.props.resp.data.startDate : "",
      endDate: this.props.resp ? this.props.resp.data.endDate : "",
      pathways: [],
      selectedPathway: [],

      fontSize: 20,
      fontrange: [],
      backColor: "FFBE00",
      fontColor: "000000",
      contentfontrange: [],
      contentfontSize: 20,
      contentbackColor:
        this.props.resp && this.props.resp.data.content.heading
          ? this.props.resp.data.content.heading.backColor
          : "FFBE00",
      contentfontColor:
        this.props.resp && this.props.resp.data.content.heading
          ? this.props.resp.data.content.heading.fontColor
          : "000000",
      contentHeading:
        this.props.resp && this.props.resp.data.content.heading
          ? this.props.resp.data.content.heading.headingText
          : "",
      tags:
        this.props.resp && this.props.resp.data.tags
          ? this.props.resp.data.tags
          : [],
      taglist: [],
      nuggetType:
        this.props.resp && this.props.resp.data.content
          ? this.props.resp.data.content.nuggetType
          : "",
      next: 0,
      subtitle:
        this.props.resp &&
          this.props.resp.data.content &&
          this.props.resp.data.content.video
          ? this.props.resp.data.content.video.subtitle
          : "",
      contentimageUrl:
        this.props.resp &&
          this.props.resp.data.content &&
          (this.props.resp.data.content.video ||
            this.props.resp.data.content.image)
          ? this.props.resp.data.content.video
            ? this.props.resp.data.content.video.url
            : this.props.resp.data.content.image.url
          : "",
      article:
        this.props.resp &&
          this.props.resp.data.content &&
          this.props.resp.data.content.article
          ? this.props.resp.data.content.article.contentText
          : "",
      spotlight:
        this.props.resp &&
          this.props.resp.data.content &&
          this.props.resp.data.content.spotlight
          ? this.props.resp.data.content.spotlight.contentText
          : "",
      snippet:
        this.props.resp &&
          this.props.resp.data.content &&
          this.props.resp.data.content.snippet
          ? this.props.resp.data.content.snippet.contentText
          : "",

      storycontentText:
        this.props.resp &&
          this.props.resp.data.content &&
          this.props.resp.data.content.story
          ? this.props.resp.data.content.story.contentText
          : "",
      quizcontentText:
        this.props.resp &&
          this.props.resp.data.content &&
          this.props.resp.data.content.quiz
          ? this.props.resp.data.content.quiz.contentText
          : "",
      quiznNScontentText:
        this.props.resp &&
          this.props.resp.data.content &&
          this.props.resp.data.content.quizNS
          ? this.props.resp.data.content.quizNS.contentText
          : "",
      stories:
        this.props.resp &&
          this.props.resp.data.content &&
          this.props.resp.data.content.stories
          ? this.props.resp.data.content.story.stories
          : [
            {
              imageUrl: "",
              contentText: "",
            },
          ],

      question:
        this.props.resp &&
          this.props.resp.data.content &&
          this.props.resp.data.content.poll
          ? this.props.resp.data.content.poll.question
          : "",

      mcqquestion:
        this.props.resp &&
          this.props.resp.data.content &&
          this.props.resp.data.content.mcq
          ? this.props.resp.data.content.mcq.question
          : "",

      options:
        this.props.resp &&
          this.props.resp.data.content &&
          this.props.resp.data.content.mcq
          ? this.props.resp.data.content.mcq.options
          : [{ option: "", correct: false, feedback: "" }],
      polloptions: this.props.resp
        ? this.props.resp.data.content.poll.options
        : [{ option: "" }],
      feedback:
        this.props.resp &&
          this.props.resp.data.content &&
          this.props.resp.data.content.mcq
          ? this.props.resp.data.content.mcq.feedbackType
          : "",
      feedbackCorrect:
        this.props.resp &&
          this.props.resp.data.content &&
          this.props.resp.data.content.mcq
          ? this.props.resp.data.content.mcq.feedbackCorrect
          : "",
      feedbackInCorrect:
        this.props.resp &&
          this.props.resp.data.content &&
          this.props.resp.data.content.mcq
          ? this.props.resp.data.content.mcq.feedbackInCorrect
          : "",
      feedbackText:
        this.props.resp &&
          this.props.resp.data.content &&
          this.props.resp.data.content.mcq
          ? this.props.resp.data.content.mcq.feedback
          : "",
      quizNSresults:
        this.props.resp &&
          this.props.resp.data.content &&
          this.props.resp.data.content.quizNS
          ? this.props.resp.data.content.quizNS.results
          : [
            {
              resultText: "",
              resultKey: 0,
            },
          ],
      quizresults:
        this.props.resp &&
          this.props.resp.data.content &&
          this.props.resp.data.content.quiz
          ? this.props.resp.data.content.quiz.results
          : [
            {
              resultText: "",
              scoreResult: 0,
            },
          ],
      quizquestion:
        this.props.resp &&
          this.props.resp.data.content &&
          this.props.resp.data.content.quiz
          ? this.props.resp.data.content.quiz.questions
          : [
            {
              question: "",
              options: [
                {
                  option: "",
                  correct: false,
                  score: 0,
                },
              ],
            },
          ],
      quizNSquestion:
        this.props.resp &&
          this.props.resp.data.content &&
          this.props.resp.data.content.quizNS
          ? this.props.resp.data.content.quizNS.questions
          : [
            {
              question: "",
              options: [
                {
                  option: "",
                  resultKey: 0
                },
              ],
            },
          ],
    };
  }
  componentDidMount() {
    //console.log(this.state.quizresults);
    if (this.props.resp && this.props.resp.data.pathway)
      this.state.selectedPathway.push(this.props.resp.data.pathway);
    if (this.props.resp && this.props.resp.data.moreTxt)
      this.state.moreTxt = this.props.resp.data.moreTxt;
    console.log("selected template");
    console.log(this.state.selectedTemplate);
    if (this.props.resp) {
      if (this.state.selectedTemplate.heading)
        this.setfontrange(
          this.state.selectedTemplate.heading.fontSizeMin,
          this.state.selectedTemplate.heading.fontSizeMax
        );
    }

    // if (this.props.resp && this.props.resp.data.content && this.props.resp.data.content.stories)
    //   this.state.stories.push(this.props.resp.content.stories)
    const tsRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        //console.log(token);
        let url = "todayTemplates?sortBy=name:asc&limit=100&page=1";

        const resp = await axios
          .get(Constants.api + url, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              console.log(resp.data.results);
              this.setState({ template: resp.data.results });
              console.log(this.state.template);
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    tsRequest();
    const tag = async () => {
      try {
        const token = localStorage.getItem("token");
        //console.log(token);
        let url = "tags?sortBy=name:asc&limit=100&page=1";

        const resp = await axios
          .get(Constants.api + url, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              console.log(resp.data.results);
              this.setState({ taglist: resp.data.results });
              console.log(this.state.taglist);
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    tag();
  }
  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  durationHandleChange = (event) => {
    this.setState({ duration: event.target.value });
  };
  articleHandleChange = (content, editor) => {
    this.setState({ article: content });
  };
  snippetHandleChange = (content, editor) => {
    this.setState({ snippet: content });
  };
  spotlightHandleChange = (content, editor) => {
    this.setState({ spotlight: content });
  };
  storycontentTextHandleChange = (content, editor) => {
    this.setState({ storycontentText: content });
  };
  quizcontentTextHandleChange = (content, editor) => {
    this.setState({ quizcontentText: content });
  };
  quizNScontentTextHandleChange = (content, editor) => {
    this.setState({ quizNScontentText: content });
  };
  fontSizeHandleChange = (event) => {
    this.setState({ fontSize: event.target.value });
  };
  headingHandleChange = (event) => {
    this.setState({ heading: event.target.value });
  };

  contentHeadingHandleChange = (event) => {
    this.setState({ contentHeading: event.target.value });
  };
  contentFontSizeHandleChange = (event) => {
    this.setState({ contentfontSize: event.target.value });
  };
  contentFontColorChange = (event) => {
    this.setState({ contentfontColor: event.target.value });
  };
  contentBackColorChange = (event) => {
    this.setState({ contentbackColor: event.target.value });
  };
  descriptionHandleChange = (event) => {
    this.setState({ description: event.target.value });
  };
  typeHandleChange = (type) => {
    console.log(type);
    this.setState(
      {
        selectedTemplate: type.value,
        type: type.label,
        selectedType: type,
      },
      this.setfontrange(
        type.value.ttHeading.fontSizeMin,
        type.value.ttHeading.fontSizeMax
      )
    );

    console.log(this.state.selectedTemplate);
  };
  handleStoryChange(i, e) {
    let stories = this.state.stories;
    stories[i][e.target.name] = e.target.value;
    this.setState({ stories });
    console.log(this.state.stories);
  }
  addMoreStories() {
    this.setState({
      stories: [
        ...this.state.stories,
        { imageUrl: "", contentText: "", editorid: "" },
      ],
    });
  }
  removeStories(i) {
    let stories = this.state.stories;
    stories.splice(i, 1);
    this.setState({ stories });
  }
  handleStoryEditorChange = (content, editor) => {
    //alert(e.target.getContent);
    console.log(editor.id);
    let stories = this.state.stories;
    let l = stories.length;
    console.log(stories);
    stories[l - 1].contentText = content;
    stories[l - 1].editorid = editor.id;
    this.setState({ stories });
  };
  handleQuizResultEditorChange = (content, editor) => {
    let quizresults = this.state.quizresults;
    let l = quizresults.length;
    quizresults[l - 1].resultText = content;
    quizresults[l - 1].editorid = editor.id;
    console.log(quizresults)
    this.setState({ quizresults });
    console.log(this.state.quizresults);
  }
  handleQuizNSResultEditorChange = (content, editor) => {
    let quizNSresults = this.state.quizNSresults;
    let l = quizNSresults.length;
    quizNSresults[l - 1].resultText = content;
    quizNSresults[l - 1].resultKey = l;

    quizNSresults[l - 1].editorid = editor.id;
    console.log(quizNSresults)
    this.setState({ quizNSresults });
    console.log(this.state.quizNSresults);
  }
  handleQuizQuestionChange = (content, editor) => {
    //alert(e.target.getContent);
    console.log(editor.id);
    let quizquestion = this.state.quizquestion;
    let l = quizquestion.length;
    console.log(quizquestion);
    quizquestion[l - 1].question = content;
    quizquestion[l - 1].editorid = editor.id;
    this.setState({ quizquestion });
  };

  handleQuizNSQuestionChange = (content, editor) => {
    //alert(e.target.getContent);
    console.log(editor.id);
    let quizNSquestion = this.state.quizNSquestion;
    let l = quizNSquestion.length;
    console.log(quizNSquestion);
    quizNSquestion[l - 1].question = content;
    quizNSquestion[l - 1].editorid = editor.id;
    this.setState({ quizNSquestion });
  };

  storycontentTextHandleChange = (content, editor) => {
    this.setState({ storycontentText: content });
  };

  questionHandleChange = (content, editor) => {
    this.setState({ question: content });
  };
  mcqquestionHandleChange = (content, editor) => {
    this.setState({ mcqquestion: content });
  };

  descriptionHandleChange = (event) => {
    this.setState({ description: event.target.value });
  };
  handleOptionChange(i, e) {
    let options = this.state.options;
    options[i][e.target.name] = e.target.value;
    this.setState({ options });
    console.log(this.state.options);
  }

  handleCorrectChange(i) {
    let options = this.state.options;
    options[i]["correct"] = !options[i]["correct"];
    this.setState({ options });
  }
  addFormFields() {
    this.setState({
      options: [
        ...this.state.options,
        { option: "", correct: false, feedback: "" },
      ],
    });
  }

  removeFormFields(i) {
    let options = this.state.options;
    options.splice(i, 1);
    this.setState({ options });
  }
  feedbackHandleChange = (event) => {
    this.setState({ feedback: event.target.value });
  };
  feedbackTextHandleChange = (event) => {
    this.setState({ feedbackText: event.target.value });
  };
  feedbackInCorrectHandleChange = (event) => {
    this.setState({ feedbackInCorrect: event.target.value });
  };
  feedbackCorrectHandleChange = (event) => {
    this.setState({ feedbackCorrect: event.target.value });
  };
  handlePollOptionChange(i, e) {
    let polloptions = this.state.polloptions;
    polloptions[i][e.target.name] = e.target.value;
    this.setState({ polloptions });
    console.log(this.state.polloptions);
  }
  addPollFields() {
    this.setState({
      polloptions: [...this.state.polloptions, { option: "" }],
    });
  }
  removePollFields(i) {
    let polloptions = this.state.polloptions;
    polloptions.splice(i, 1);
    this.setState({ polloptions });
  }

  addQuizFields() {
    this.setState({
      quizquestion: [
        ...this.state.quizquestion,
        {
          question: "",
          options: [
            {
              option: "",
              correct: false,
              score: 0,
            },
          ],
        },
      ],
    });
  }
  addQuizNSFields() {
    this.setState({
      quizNSquestion: [
        ...this.state.quizNSquestion,
        {
          question: "",
          options: [
            {
              option: "",
              resultKey: 0,
            },
          ],
        },
      ],
    });
  }
  addQuizResultFields() {
    this.setState({
      quizresults: [
        ...this.state.quizresults,

        {
          resultText: "",
          scoreResult: 0,
        },
      ],
    });
  }
  addQuizNSResultFields() {
    this.setState({
      quizNSresults: [
        ...this.state.quizNSresults,

        {
          resultText: "",
          resultKey: 0,
        },
      ],
    });
  }
  handleQuizResultChange(i, e) {
    let quizresults = this.state.quizresults;
    quizresults[i][e.target.name] = e.target.value;
    this.setState({ quizresults });
    console.log(this.state.quizresults);
  }
  handleQuizNSResultChange(i, e) {
    let quizNSresults = this.state.quizNSresults;
    quizNSresults[i][e.target.name] = e.target.value;
    this.setState({ quizNSresults });
    console.log(this.state.quizNSresults);
  }

  addQuizOptions(index) {
    let quizquestion = this.state.quizquestion;

    quizquestion[index].options = [
      ...quizquestion[index].options, {
        correct: false,
        option: "",
        score: 0,
      }


    ];
    this.setState({
      quizquestion

    });
    console.log(this.state.quizquestion)

  }


  addQuizNSOptions(index) {
    let quizNSquestion = this.state.quizNSquestion;

    quizNSquestion[index].options = [
      ...quizNSquestion[index].options, {
        correct: false,
        option: "",
        score: 0,
      }


    ];
    this.setState({
      quizNSquestion

    });
    console.log(this.state.quizNSquestion)

  }

  nuggetTypeHandleChange = (type) => {
    // this.setState({ type });
    this.setState({
      selectednuggetType: type,
      nuggetType: type.label,
    });
  };
  setfontrange = (fontSizeMin, fontSizeMax) => {
    let fontrange = [];
    for (let i = fontSizeMin; i <= fontSizeMax; i++) {
      fontrange.push(i);
    }
    this.setState({ fontrange });
    let contentfontrange = [];
    for (let i = 20; i <= 45; i++) {
      contentfontrange.push(i);
    }
    this.setState({ fontrange });
    this.setState({ contentfontrange });

    console.log(fontrange);
  };
  imageAlignChange = (event) => {
    this.setState({ imageAlign: !this.state.imageAlign });
  };
  videoAlignChange = (event) => {
    this.setState({ videoAlign: !this.state.imageAlign });
  };
  headingAlignChange = (event) => {
    this.setState({ headingAlign: !this.state.headingAlign });
  };
  descriptionPresentChange = (event) => {
    this.setState({ descriptionPresent: !this.state.descriptionPresent });
  };
  headingLimitHandleChange = (event) => {
    this.setState({ headingLimit: event.target.value });
  };
  imageUrlHandleChange = (event) => {
    this.setState({ imageUrl: event.target.value });
  };
  contentimageUrlHandleChange = (event) => {
    this.setState({ contentimageUrl: event.target.value });
  };
  subtitleHandleChange = (event) => {
    this.setState({ subtitle: event.target.value });
  };
  imageHeightHandleChange = (event) => {
    this.setState({ imageHeight: event.target.value });
  };
  minFontHandleChange = (event) => {
    this.setState({ minFont: event.target.value });
  };
  maxFontHandleChange = (event) => {
    this.setState({ maxFont: event.target.value });
  };
  videoWidthHandleChange = (event) => {
    this.setState({ videoWidth: event.target.value });
  };
  videoHeightHandleChange = (event) => {
    this.setState({ videoHeight: event.target.value });
  };
  setStartDate = (date) => {
    this.setState({
      startDate: date,
    });
  };
  setEndDate = (date) => {
    this.setState({
      endDate: date,
    });
  };

  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      this.validator.hideMessages();

      const token = localStorage.getItem("token");

      let obj = {};

      obj = {
        name: this.state.name,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        duration: this.state.duration,
        status: "PUBLISHED",
        tags: this.state.tags,

        template: {
          name: this.state.selectedTemplate.name,
          templateType: this.state.selectedTemplate.templateType,
          image: {
            alignTop: this.state.selectedTemplate.ttImage
              ? this.state.selectedTemplate.ttImage.alignTop
              : false,
            imageWidth: this.state.selectedTemplate.ttImage
              ? this.state.selectedTemplate.ttImage.imageWidth
              : 0,
            imageHeight: this.state.selectedTemplate.ttImage
              ? this.state.selectedTemplate.ttImage.imageHeight
              : 0,
            imageUrl: this.state.imageUrl,
          },
          video: {
            alignTop: this.state.selectedTemplate.ttVideo
              ? this.state.selectedTemplate.ttVideo.alignTop
              : false,
            videoWidth: this.state.selectedTemplate.ttImage
              ? this.state.selectedTemplate.ttImage.videoWidth
              : 0,
            videoHeight: this.state.selectedTemplate.ttImage
              ? this.state.selectedTemplate.ttImage.videoHeight
              : 0,
            videoUrl: this.state.videoUrl,
          },
          colorBack: {
            backColor: this.state.backColor,
            fontColor: this.state.fontColor,
          },
          heading: {
            headingText: this.state.heading,
            alignTop: this.state.selectedTemplate.ttHeading
              ? this.state.selectedTemplate.ttHeading.alignTop
              : false,
            font: "JOST_BOLD",
            fontSize: this.state.fontSize,
          },
          descriptor: {
            descriptorText: this.state.selectedTemplate.ttDescriptor
              ? this.state.selectedTemplate.ttDescriptor.descriptorText
              : "",
            present: this.state.selectedTemplate.ttDescriptor
              ? this.state.selectedTemplate.ttDescriptor.present
              : false,
            font: "Poppins_Light",
            fontSize: this.state.selectedTemplate.ttDescriptor
              ? this.state.selectedTemplate.ttDescriptor.fontSize
              : 14,
          },
        },
        content: {
          nuggetType: this.state.nuggetType,
          heading: {
            headingText: this.state.contentHeading,
            font: "JOST_BOLD",
            fontSize: this.state.contentfontSize,
            fontColor: this.state.contentfontColor,
            backColor: this.state.contentbackColor,
          },
        },
      };
      if (this.state.selectedPathway.length > 0) {
        obj.pathway = this.state.selectedPathway[0].id;
        obj.moreTxt = this.state.moreTxt
      }
      if (this.state.nuggetType == "VIDEO")
        obj.content.video = {
          url: this.state.contentimageUrl,
          subtitle: this.state.subtitle,
        };
      if (this.state.nuggetType == "ARTICLE")
        obj.content.article = {
          contentText: this.state.article,
        };
      if (this.state.nuggetType == "SNIPPET")
        obj.content.snippet = {
          contentText: this.state.snippet,
        };
      if (this.state.nuggetType == "SPOTLIGHT")
        obj.content.spotlight = {
          contentText: this.state.spotlight,
        };
      if (this.state.nuggetType == "STORY")
        obj.content.story = {
          stories: this.state.stories,
        };

      if (this.state.nuggetType == "MCQ")
        obj.content.mcq = {
          question: this.state.mcqquestion,
          feedback: this.state.feedbackText,
          feedbackType: this.state.feedback,
          feedbackCorrect: this.state.feedbackCorrect,
          feedbackInCorrect: this.state.feedbackInCorrect,
          options: this.state.options,
        };

      if (this.state.nuggetType == "POLL")
        obj.content.poll = {
          question: this.state.question,
          options: this.state.polloptions,
        };
      if (this.state.nuggetType == "QUIZ")
        obj.content.quiz = {
          contentText: this.state.quizcontentText,
          questions: this.state.quizquestion,
          results: this.state.quizresults,
        };
      if (this.state.nuggetType == "QUIZNS")
        obj.content.quizNS = {
          contentText: this.state.quizNScontentText,
          questions: this.state.quizNSquestion,
          results: this.state.quizNSresults,
        };
      console.log(obj);

      if (this.props.resp) {
        console.log(this.props.resp);
        axios
          .patch(
            Constants.api + "todayContents/" + this.props.resp.data.id,
            obj,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.code) {
              swal(res.message);
            }
            else {
              if (res.status == 200) {
                swal("Today Content Updated");
                this.setState({ create: 0 });
              } else {
                // this.setState({alert:res.data.msg});
                swal("Something went wrong!");
              }
            }

          });
      } else {
        axios
          .post(
            Constants.api + "todayContents",

            obj,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Today  Content Added");
              window.location = "/today-content";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };
  render() {
    let removeQuizOptions = (i, i1) => {
      let quizquestion = this.state.quizquestion;
      let options = quizquestion[i1].options;
      options.splice(i, 1);
      quizquestion[i1].options = options;
      this.setState({ quizquestion });
    };
    let removeQuizNSOptions = (i, i1) => {
      let quizNSquestion = this.state.quizNSquestion;
      let options = quizNSquestion[i1].options;
      options.splice(i, 1);
      quizNSquestion[i1].options = options;
      this.setState({ quizNSquestion });
    };
    let removeQuizQuestion = (i) => {
      let quizquestion = this.state.quizquestion;
      quizquestion.splice(i, 1);
      this.setState({ quizquestion });
    };
    let removeQuizNSQuestion = (i) => {
      let quizNSquestion = this.state.quizNSquestion;
      quizNSquestion.splice(i, 1);
      this.setState({ quizNSquestion });
    };
    let removeQuizResult = (i) => {
      let quizresults = this.state.quizresults;
      quizresults.splice(i, 1);
      this.setState({ quizresults });
    };
    let removeQuizNSResult = (i) => {
      let quizNSresults = this.state.quizNSresults;
      quizNSresults.splice(i, 1);
      this.setState({ quizNSresults });
    };
    let handleQuizOptionChange = (i, e, i1) => {
      let quizquestion = this.state.quizquestion;
      let options = this.state.quizquestion[i1].options;
      options[i][e.target.name] = e.target.value;
      quizquestion[i1].options = options;
      this.setState({ quizquestion });
      console.log(this.state.quizquestion);
    };
    let handleQuizNSOptionChange = (i, e, i1) => {
      let quizNSquestion = this.state.quizNSquestion;
      let options = this.state.quizNSquestion[i1].options;
      options[i][e.target.name] = e.target.value;
      quizNSquestion[i1].options = options;
      this.setState({ quizNSquestion });
      console.log(this.state.quizNSquestion);
    };
    let handleQuizCorrectChange = (i, i1) => {
      let quizquestion = this.state.quizquestion;

      let options = quizquestion[i1].options;
      options[i]["correct"] = !options[i]["correct"];
      quizquestion[i1].options = options;
      this.setState({ quizquestion });
    };

    let quizquestion = this.state.quizquestion;
    let tags = this.state.tags;
    const handleTagAddition = (tag) => {
      let tags = this.state.tags;
      if (tags.indexOf(tag) <= -1) {
        tags.push(tag);
        this.setState({ tags });
      } else {
        tags.splice(tags.indexOf(tag), 1);
        this.setState({ tags });
      }
      console.log(this.state.tags);
    };
    //Dragging

    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        console.log(fromIndex);
        const options = [...that.state.options];
        const item = options.splice(fromIndex, 1)[0];
        options.splice(toIndex, 0, item);

        that.setState({ options });
      },
      nodeSelector: "li",
      handleSelector: ".mcq_arrow",
    };
    const dragProps2 = {
      onDragEnd(fromIndex, toIndex) {
        console.log(fromIndex);
        const stories = [...that.state.stories];
        const item = stories.splice(fromIndex, 1)[0];
        stories.splice(toIndex, 0, item);

        that.setState({ stories });
      },
      nodeSelector: "li",
      handleSelector: ".mcq_arrow",
    };
    const dragProps3 = {
      onDragEnd(fromIndex, toIndex) {
        console.log(fromIndex);
        const polloptions = [...that.state.polloptions];
        const item = polloptions.splice(fromIndex, 1)[0];
        polloptions.splice(toIndex, 0, item);

        that.setState({ polloptions });
      },
      nodeSelector: "li",
      handleSelector: ".mcq_arrow",
    };

    if (this.state.create == 0) {
      window.location = "/today-content";
    }
    let type = this.state.type;
    let fonts = "";

    let options = [];
    this.state.template.map(function (item, i) {
      options.push({
        value: item,
        label: item.name + " (" + item.templateType + ")",
      });
    });
    let nuggetType = [];
    nuggetType = [
      { value: "MCQ", label: "MCQ" },
      { value: "POLL", label: "POLL" },
      { value: "VIDEO", label: "VIDEO" },
      { value: "ARTICLE", label: "ARTICLE" },
      { value: "SNIPPET", label: "SNIPPET" },
      { value: "SPOTLIGHT", label: "SPOTLIGHT" },
      { value: "STORY", label: "STORY" },
      { value: "QUIZ", label: "QUIZ" },
      { value: "QUIZNS", label: "QUIZNS" },
    ];
    return (
      <>
        {" "}
        <div class="add_video_section add_video">
          {/* Bread crumbs */}
          {/* <div class="faculty_sections">
            <div class="Faculty_text">
              <p>
                <span>Create Content</span>
              </p>
            </div>
          </div> */}
          {/* Second Section */}
          <div class="faculty_section">
            <div class="faculty_text">
              <h3>
                <i
                  class="fa fa-arrow-left"
                  aria-hidden="true"
                  onClick={() => this.setState({ create: 0 })}
                ></i>{" "}
                {this.props.resp ? "Edit" : "Create"} Today's Content
              </h3>{" "}
            </div>
            <div class="btn_create">
              <button
                type="submit"
                onClick={this.handleFormSubmit}
                class="btn btn-primary btn_category btn_vidios btn-save-1"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                {
                  this.state.props
                    ? "Update" : "Save"}
              </button>
              <button
                onClick={() => this.setState({ create: 0 })}
                type="button"
                class="btn btn-save btn-save_close"
              >
                Close
              </button>
            </div>
          </div>
          {/* Progress */}
          <ol class="track-progress">
            <li class="done" onClick={() => this.setState({ next: 0 })}>
              <div class="blue-dot"></div> <span>Outer Card</span>{" "}
            </li>
            <li
              class={`${this.state.next == 1 ? "done" : "todo"}`}
              onClick={() => this.setState({ next: 1 })}
            >
              <div class="blue-dot"></div> <span>Inner Card</span>{" "}
            </li>
          </ol>

          {this.state.next == 0 ? (
            <>
              {" "}
              {/* ABout Content */}
              <div class="mcq_section ">
                <div class="container video_sections">
                  <div class="row">
                    {/* Preview */}
                    {this.state.type &&
                      this.state.type.includes("TEXT_ONLY") ? (
                      <>
                        <div
                          class="rows col-md-3  text-center  main-scroll"
                          style={{
                            backgroundColor: "#" + this.state.backColor,
                          }}
                        >
                          <span
                            style={{
                              fontSize: this.state.fontSize + " !important",
                              width: "100%",
                              height: "100%",
                              color: "#" + this.state.fontColor,
                            }}
                          >
                            {this.state.heading != ""
                              ? this.state.heading
                              : "Heading"}
                          </span>
                          <p
                            style={{
                              fontSize: "14px",
                              color: this.state.fontColor,
                            }}
                          >
                            {" "}
                            {this.state.description != ""
                              ? this.state.description
                              : "Description"}
                          </p>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {this.state.selectedTemplate.ttImage &&
                      this.state.selectedTemplate.templateType &&
                      this.state.selectedTemplate.templateType.includes(
                        "IMAGE"
                      ) ? (
                      <div class="rows col-md-3  text-center  main-scroll">
                        <div
                          align={
                            this.state.selectedTemplate.ttImage.alignTop
                              ? "top"
                              : "bottom"
                          }
                          class=" text-center  main-scroll"
                          style={{
                            background:
                              this.state.imageUrl != "" ? "" : "black",
                            backgroundImage: `url(${this.state.imageUrl})`,
                            height:
                              this.state.selectedTemplate.templateType.includes(
                                "OVERLAY"
                              )
                                ? "100%"
                                : this.state.selectedTemplate.ttImage
                                  .imageHeight,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: this.state.selectedTemplate.ttImage
                              .imageAlign
                              ? "flex-start"
                              : "flex-end",
                          }}
                        >
                          {this.state.type &&
                            this.state.type.includes("OVERLAY") ? (
                            <>
                              <span
                                style={{
                                  backgroundImage: `url(${this.state.imageUrl})`,
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: this.state.fontSize,
                                    background: this.state.backColor,
                                    color: "#" + this.state.fontColor,
                                  }}
                                >
                                  {" "}
                                  {this.state.heading != ""
                                    ? this.state.heading
                                    : "Heading"}
                                </p>

                                {this.state.selectedTemplate.ttDescriptor
                                  .present ? (
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      color: "#" + this.state.fontColor,
                                      background: this.state.backColor,
                                    }}
                                  >
                                    {" "}
                                    {this.state.description != ""
                                      ? this.state.description
                                      : "Description"}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </span>
                            </>
                          ) : (
                            ""
                          )}

                        </div>
                        {this.state.type &&
                          this.state.type.includes("SEPERATE") ? (
                          <>
                            <span
                              style={{
                                fontSize: this.state.fontSize,
                                backgroundColor: "#" + this.state.backColor,
                                width: "100%",
                                height: "100%",
                                color: "#" + this.state.fontColor,

                                textAlign: this.state.selectedTemplate.ttHeading
                                  .alignTop
                                  ? "top"
                                  : "bottom",
                              }}
                            >
                              {this.state.heading != ""
                                ? this.state.heading
                                : "Heading"}
                            </span>

                            <p
                              style={{
                                fontSize: "14px",
                                color: "#" + this.state.fontColor,
                              }}
                            >
                              {" "}
                              {this.state.selectedTemplate.ttDescriptor
                                .descriptorText != ""
                                ? this.state.selectedTemplate.ttDescriptor
                                  .descriptorText
                                : ""}
                            </p>

                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.selectedTemplate.image &&
                      this.state.selectedTemplate.templateType &&
                      this.state.selectedTemplate.templateType.includes(
                        "IMAGE"
                      ) ? (

                      < div class="rows col-md-3  text-center  main-scroll">
                        <div
                          align={
                            this.state.selectedTemplate.image.alignTop
                              ? "top"
                              : "bottom"
                          }
                          class=" text-center  main-scroll"
                          style={{
                            background:
                              this.state.imageUrl != "" ? "" : "black",
                            backgroundImage: `url(${this.state.selectedTemplate.image.imageUrl})`,
                            height:
                              this.state.selectedTemplate.templateType.includes(
                                "OVERLAY"
                              )
                                ? "100%"
                                : "250px",

                            display: "flex",
                            flexDirection: "column",
                            justifyContent: this.state.selectedTemplate.image
                              .imageAlign
                              ? "flex-start"
                              : "flex-end",
                          }}
                        >
                          {this.state.type &&
                            this.state.type.includes("OVERLAY") ? (
                            <>
                              <span
                                style={{
                                  backgroundImage: `url(${this.state.imageUrl})`,
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: this.state.fontSize,
                                    background: this.state.backColor,
                                    color: this.state.fontColor,
                                  }}
                                >
                                  {" "}
                                  {this.state.heading != ""
                                    ? this.state.heading
                                    : "Heading"}
                                </p>

                                {this.state.selectedTemplate.descriptor
                                  .present ? (
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      color: this.state.fontColor,
                                      background: this.state.backColor,
                                    }}
                                  >
                                    {" "}
                                    {this.state.description != ""
                                      ? this.state.description
                                      : "Description"}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </span>
                            </>
                          ) : (
                            ""
                          )}

                        </div>
                        {this.state.type &&
                          this.state.type.includes("SEPERATE") ? (
                          <>
                            <span
                              style={{
                                fontSize: this.state.fontSize,
                                backgroundColor: "#" + this.state.backColor,
                                width: "100%",
                                height: "100%",
                                color: this.state.fontColor,


                              }}
                            >
                              {this.state.heading != ""
                                ? this.state.heading
                                : "Heading"}
                            </span>
                            {this.props.resp ?

                              <p
                                style={{
                                  fontSize: "14px",
                                  color: this.state.fontColor,
                                }}
                              >
                                {" "}
                                {this.state.selectedTemplate.descriptor
                                  .descriptorText != ""
                                  ? this.state.selectedTemplate.descriptor
                                    .descriptorText
                                  : ""}
                              </p>
                              :
                              <p
                                style={{
                                  fontSize: "14px",
                                  color: this.state.fontColor,
                                }}
                              >
                                {" "}
                                {this.state.selectedTemplate.ttDescriptor
                                  .descriptorText != ""
                                  ? this.state.selectedTemplate.ttDescriptor
                                    .descriptorText
                                  : ""}
                              </p>
                            }
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.selectedTemplate.templateType &&
                      this.state.selectedTemplate.templateType.includes(
                        "VIDEO"
                      ) ? (
                      <div
                        class="rows col-md-3  text-center 
                     main-scroll"
                      >
                        <div>
                          {this.state.imageUrl != "" ? (
                            <ReactHlsPlayer
                              src={this.state.imageUrl}
                              autoPlay={false}
                              controls={true}
                              width="100%"
                              height="auto"
                              id="myvid"
                            />
                          ) : (
                            "Video"
                          )}
                          {this.state.type &&
                            this.state.type.includes("OVERLAY") ? (
                            <>
                              <span
                                style={{
                                  fontSize:
                                    this.state.selectedTemplate.ttHeading
                                      .maxFont,
                                  textAlign: this.state.selectedTemplate
                                    .ttHeading.alignTop
                                    ? "top"
                                    : "bottom",
                                }}
                              >
                                {this.state.heading != ""
                                  ? this.state.heading
                                  : "Heading"}{" "}
                              </span>
                              {this.state.selectedTemplate.ttDescriptor
                                .descriptionPresent ? (
                                <p
                                  style={{
                                    fontSize: "14px",
                                    color: this.state.fontColor,
                                  }}
                                >
                                  {" "}
                                  {this.state.description != ""
                                    ? this.state.description
                                    : ""}
                                </p>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                        {this.state.selectedTemplate.templateType &&
                          this.state.selectedTemplate.templateType.includes(
                            "SEPERATE"
                          ) ? (
                          <>
                            <span
                              style={{
                                fontSize: this.state.fontSize,
                                backgroundColor: "#" + this.state.backColor,
                                width: "100%",
                                height: "100%",
                                color: this.state.fontColor,


                              }}
                            >
                              {this.state.heading != ""
                                ? this.state.heading
                                : "Heading"}
                            </span>
                            <p
                              style={{
                                fontSize: "14px",
                                color: this.state.fontColor,
                              }}
                            >
                              {" "}
                              {this.props.resp ?
                                this.state.selectedTemplate.descriptor
                                  .descriptorText
                                  ? this.state.selectedTemplate.descriptor
                                    .descriptorText
                                  : "" : this.state.selectedTemplate.ttDescriptor
                                    .descriptorText
                                  ? this.state.selectedTemplate.ttDescriptor
                                    .descriptorText : ''
                              }

                            </p>

                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Content */}
                    <div class="col-md-8 main-scroll">
                      {/* Headin Section */}
                      <div class="template_vidio_name">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="template_name image-template-name">
                              {!this.props.resp ? (
                                <>
                                  <label for="exampleFormControlInput1">
                                    Choose the Template
                                  </label>
                                  <Select
                                    value={this.state.selectedType}
                                    onChange={this.typeHandleChange}
                                    options={options}
                                  />
                                </>
                              ) : (
                                ""
                              )}
                              <div>
                                <div class="template_sections">
                                  <div class="template_text">
                                    <p class="info-template">Content Name</p>
                                  </div>
                                  <div class="number-range">
                                    <p>{this.state.name.length}/50</p>
                                  </div>
                                </div>
                                <div class="input_section">
                                  <div class="input_text">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Content Name"
                                      name="name"
                                      value={this.state.name}
                                      onChange={this.nameHandleChange}
                                      maxLength="50"
                                      id=""
                                    />{" "}
                                    {this.validator.message(
                                      "name",
                                      this.state.name,
                                      "required"
                                    )}
                                  </div>
                                </div>
                                <div class="template_sections">
                                  <div class="template_text">
                                    <p class="info-template">Start Date</p>
                                  </div>
                                </div>
                                <div class="input_section">
                                  <DatePicker
                                    selected={this.state.startDate}
                                    value={this.state.startDate}
                                    onChange={this.setStartDate}
                                    showTimeSelect={false}
                                    closeOnSelect={true}
                                    closeOnClickOutside={true}
                                    timeFormat={false}
                                    dateFormat="YYYY-MM-DD"
                                  />
                                  {this.validator.message(
                                    "Start Date",
                                    this.state.startDate,
                                    "required"
                                  )}
                                </div>
                                <div class="template_sections">
                                  <div class="template_text">
                                    <p class="info-template">End Date</p>
                                  </div>
                                </div>
                                <div class="input_section">
                                  <div class="input_text">
                                    <DatePicker
                                      selected={this.state.endDate}
                                      value={this.state.endDate}
                                      onChange={this.setEndDate}
                                      showTimeSelect={false}
                                      closeOnSelect={true}
                                      closeOnClickOutside={true}
                                      timeFormat={false}
                                      dateFormat="YYYY-MM-DD"
                                    />
                                    {this.validator.message(
                                      "End Date",
                                      this.state.endDate,
                                      "required"
                                    )}
                                  </div>
                                </div>
                                <div class="template_sections">
                                  <div class="template_text">
                                    <p class="info-template">Duration (in Seconds)</p>
                                  </div>
                                </div>
                                <div class="input_section">
                                  <div class="input_text">
                                    <input
                                      type="number"
                                      class="form-control"
                                      placeholder="Duration (in secs)"
                                      name="duration"
                                      value={this.state.duration}
                                      onChange={this.durationHandleChange}
                                      id=""
                                    />{" "}
                                    {this.validator.message(
                                      "duration",
                                      this.state.duration,
                                      "required"
                                    )}
                                  </div>
                                </div>
                                <div class="form-group">
                                  <label class="mar-20 bold-label">
                                    Pathway*
                                  </label>
                                  <div class="inline-form-full">
                                    {this.state.selectedPathway.length == 0 ? (
                                      <SearchInput
                                        class="form-control collapse-search"
                                        id="inputAddress"
                                        autoComplete="off"
                                        onChange={this.pathwaySearchUpdated}
                                        placeholder="Type to add Pathway"
                                      />
                                    ) : (
                                      this.state.selectedPathway.map(
                                        (pathway) => {
                                          return (
                                            <>
                                              {pathway.name}{" "}
                                              <a
                                                class="btn btn-trash"
                                                onClick={() =>
                                                  this.removePathway(pathway)
                                                }
                                              >
                                                x
                                              </a>
                                            </>
                                          );
                                        }
                                      )
                                    )}
                                    {this.state.pathways.map((pathway) => {
                                      return (
                                        <>
                                          <div>
                                            <div class="card card-body">
                                              <a
                                                href="#"
                                                class="search-body-view"
                                              >
                                                <div class="search-body-view-part">
                                                  <span class="search-body-view-part-name">
                                                    {" "}
                                                    {pathway.name}
                                                  </span>
                                                </div>
                                                <div class="search-body-view-part"></div>
                                                <div class="search-body-view-last">
                                                  <div
                                                    class="box-c box-blue"
                                                    onClick={() =>
                                                      this.addPathway(pathway)
                                                    }
                                                  >
                                                    <i
                                                      class="fa fa-plus"
                                                      aria-hidden="true"
                                                    ></i>
                                                  </div>
                                                </div>
                                              </a>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })}

                                  </div>
                                  {this.state.selectedPathway.length > 0 ?
                                    <div class="form-group">
                                      <label class="mar-20 bold-label">
                                        Learn more about this
                                      </label>
                                      <div class="input_section">
                                        <div class="input_text">
                                          <input
                                            type="text"
                                            class="form-control"
                                            placeholder=" Learn more about this text"
                                            name="moreTxt"
                                            value={this.state.moreTxt}
                                            onChange={this.moreTxtHandleChange}
                                            id=""
                                          />{" "}

                                        </div>
                                      </div>
                                    </div> : ''}
                                  <div class="form-group">
                                    <label class="mar-20 bold-label">
                                      Tags
                                    </label>
                                    <div class="inline-form-full">
                                      {this.state.taglist.map(function (
                                        item,
                                        index
                                      ) {
                                        return (
                                          <>
                                            <b>
                                              <u>{item.superCategory}</u>
                                            </b>
                                            <br />
                                            {item.category.map(function (
                                              cat,
                                              ci
                                            ) {
                                              return (
                                                <>
                                                  <b> {cat.name}</b>
                                                  <br />
                                                  {cat.tags.map(function (
                                                    tg,
                                                    ti
                                                  ) {
                                                    let cc = "";
                                                    if (tags.indexOf(tg) > -1)
                                                      cc = "btn-primary";
                                                    else cc = "btn-default";
                                                    return (
                                                      <>
                                                        <button
                                                          class={`btn btn-sm ${cc}`}
                                                          onClick={() =>
                                                            handleTagAddition(
                                                              tg
                                                            )
                                                          }
                                                        >
                                                          {tg}
                                                        </button>{" "}
                                                      </>
                                                    );
                                                  })}
                                                  <br />
                                                </>
                                              );
                                            })}
                                          </>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Content Ended */}
                      {this.state.type &&
                        this.state.type.includes("IMAGE") == true ? (
                        <>
                          {" "}
                          {/* Image start */}
                          <div class="template_vidio_name">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="template_name image-template-name">
                                  <label for="exampleFormControlInput1">
                                    Image
                                  </label>
                                  <div>
                                    <div class="template_sections">
                                      <div class="template_text">
                                        <p class="info-template">Image URL</p>
                                      </div>
                                      <div class="number-range"></div>
                                    </div>
                                    <div class="input_section">
                                      <div class="input_text">
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="Image URL"
                                          name="imageUrl"
                                          value={this.state.imageUrl}
                                          onChange={this.imageUrlHandleChange}
                                          id=""
                                        />{" "}
                                        {this.validator.message(
                                          "Image URL",
                                          this.state.imageUrl,
                                          "required"
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* image Ended */}
                        </>
                      ) : (
                        ""
                      )}

                      {this.state.type &&
                        this.state.type.includes("VIDEO") == true ? (
                        <>
                          {" "}
                          {/* Image start */}
                          <div class="template_vidio_name">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="template_name image-template-name">
                                  <label for="exampleFormControlInput1">
                                    Video
                                  </label>

                                  <div>
                                    <div class="template_sections">
                                      <div class="template_text">
                                        <p class="info-template">Video Url</p>
                                      </div>
                                    </div>
                                    <div class="input_section">
                                      <div class="input_text">
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="Video Url"
                                          name="videoUrl"
                                          value={this.state.imageUrl}
                                          onChange={this.imageUrlHandleChange}
                                          id=""
                                        />{" "}
                                        {this.validator.message(
                                          "Video URL",
                                          this.state.imageUrl,
                                          "required"
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* image Ended */}
                        </>
                      ) : (
                        ""
                      )}
                      {this.state.type &&
                        this.state.type.includes("TEXT") == true ? (
                        <>
                          {" "}
                          {/* Image start */}
                          <div class="template_vidio_name">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="template_name image-template-name">
                                  <label for="exampleFormControlInput1">
                                    Font size
                                  </label>
                                  <div class="inline-form">
                                    {" "}
                                    <select
                                      class="form-control"
                                      onChange={this.fontSizeHandleChange}
                                    >
                                      {this.state.fontrange.map((font) => {
                                        return (
                                          <option value={font}>{font}</option>
                                        );
                                      })}
                                    </select>{" "}
                                  </div>
                                </div>

                                <div class="template_name image-template-name">
                                  <label for="exampleFormControlInput1">
                                    Heading{" "}
                                  </label>
                                  <div class="input_section">
                                    <div class="input_text">
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Heading"
                                        name="heading"
                                        value={this.state.heading}
                                        onChange={this.headingHandleChange}
                                        maxLength={
                                          this.state.selectedTemplate.ttHeading
                                            ? this.state.selectedTemplate
                                              .ttHeading.headingLimit
                                            : "100"
                                        }
                                        id=""
                                      />{" "}
                                      {this.validator.message(
                                        "Heading",
                                        this.state.heading,
                                        "required"
                                      )}
                                    </div>
                                  </div>
                                  {this.state.selectedTemplate.ttDescriptor &&
                                    this.state.selectedTemplate.ttDescriptor
                                      .present ? (
                                    <>
                                      <div class="template_sections">
                                        <div class="template_text">
                                          <p class="info-template">
                                            Description
                                          </p>
                                        </div>
                                        <div class="number-range"></div>
                                      </div>
                                      <div class="input_section">
                                        <div class="input_text">
                                          <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Description"
                                            name="description"
                                            value={this.state.description}
                                            onChange={
                                              this.descriptionHandleChange
                                            }
                                            id=""
                                          />{" "}
                                          {this.validator.message(
                                            "Description",
                                            this.state.description,
                                            "required"
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <div class="template_name image-template-name">
                                    <label for="exampleFormControlInput1">
                                      Outer-card Background Color
                                    </label>
                                  </div>
                                  <div class="input_section">
                                    <div class="input_text">
                                      <span
                                        style={{
                                          background: "#FFBE00",
                                          padding: "10px",
                                          margin: "2px",
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            backColor: "FFBE00",
                                          })
                                        }
                                      >
                                        #FFBE00
                                      </span>
                                      <span
                                        style={{
                                          background: "#0A5EA9",
                                          padding: "10px",
                                          margin: "2px",
                                          color: "white",
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            backColor: "0A5EA9",
                                          })
                                        }
                                      >
                                        #0A5EA9
                                      </span>
                                      <span
                                        style={{
                                          background: "#4CB963",
                                          padding: "10px",
                                          margin: "2px",
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            backColor: "4CB963",
                                          })
                                        }
                                      >
                                        #4CB963
                                      </span>
                                      <span
                                        style={{
                                          background: "#FFFFFF",
                                          padding: "10px",
                                          margin: "2px",
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            backColor: "FFFFFF",
                                          })
                                        }
                                      >
                                        #FFFFFF
                                      </span>
                                      <span
                                        style={{
                                          background: "#000000",
                                          padding: "10px",
                                          margin: "2px",
                                          color: "white",
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            backColor: "000000",
                                          })
                                        }
                                      >
                                        #000000
                                      </span>
                                    </div>
                                  </div>{" "}
                                  <div class="template_name image-template-name">
                                    <label for="exampleFormControlInput1">
                                      Outer-card Header Font Color
                                    </label>
                                  </div>
                                  <div class="input_section">
                                    <div class="input_text">
                                      <span
                                        style={{
                                          background: "#ffffff",
                                          padding: "10px",
                                          margin: "2px",
                                          color: "black",

                                        }}
                                        onClick={() =>
                                          this.setState({
                                            fontColor: "FFFFFF",
                                          })
                                        }
                                      >
                                        #FFFFFF
                                      </span>
                                      <span
                                        style={{
                                          background: "#4A4A4A",
                                          padding: "10px",
                                          margin: "2px",
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            fontColor: "4A4A4A",
                                          })
                                        }
                                      >
                                        #4A4A4A
                                      </span>

                                    </div>
                                  </div>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* image Ended */}
                        </>
                      ) : (
                        ""
                      )}
                      <i
                        class="fa fa-arrow-right float-right btn btn-primary"
                        onClick={() => this.setState({ next: 1 })}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              {/* End ABout Content */}
            </>
          ) : (
            <>
              {/* Add Content */}

              <div class="mcq_section ">
                <i
                  class="fa fa-arrow-left float-right btn btn-primary"
                  onClick={() => this.setState({ next: 0 })}
                  aria-hidden="true"
                ></i>
                <div class="container video_sections">
                  <div class="row">
                    {/* Preview */}{" "}
                    {this.state.contentHeading != "" ? (
                      <>
                        <div
                          class="rows col-md-3  text-center  main-scroll"
                          style={{
                            backgroundColor: "#" + this.state.contentbackColor,
                          }}
                        >
                          <span
                            style={{
                              fontSize: this.state.contentfontSize,
                              width: "100%",
                              height: "100%",
                              color: "#" + this.state.contentfontColor,
                            }}
                          >
                            {this.state.contentHeading != ""
                              ? this.state.contentHeading
                              : "Heading"}
                          </span>
                          {this.state.nuggetType == "VIDEO" ? (
                            <ReactHlsPlayer
                              src={this.state.contentimageUrl}
                              width="100%"
                              autoPlay={false}
                              controls={true}
                            />
                          ) : (
                            ""
                          )}
                          {this.state.nuggetType == "MCQ" ? (
                            <>
                              {typeof this.state.mcqquestion == "string"
                                ? parse(this.state.mcqquestion)
                                : ""}
                              <div class="info_mcq">
                                {this.state.options.map(function (item, i) {
                                  return (
                                    <>
                                      <label class="container ">
                                        {item.option}
                                        {/* <input type="radio" name="radio" />{" "} */}
                                        <span class="checkmark"></span>{" "}
                                      </label>
                                    </>
                                  );
                                })}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.nuggetType == "POLL" ? (
                            <>
                              {typeof this.state.question == "string"
                                ? parse(this.state.question)
                                : ""}{" "}
                              <div class="info_mcq">
                                {this.state.polloptions.map(function (item, i) {
                                  return (
                                    <>
                                      <label class="container ">
                                        {item.option}
                                        {/* <input type="radio" name="radio" />{" "} */}
                                        <span class="checkmark"></span>{" "}
                                      </label>
                                    </>
                                  );
                                })}
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.nuggetType == "ARTICLE" ? (
                            <>{parse(this.state.article)}</>
                          ) : (
                            ""
                          )}

                          {this.state.nuggetType == "SNIPPET" ? (
                            <>{parse(this.state.snippet)}</>
                          ) : (
                            ""
                          )}
                          {this.state.nuggetType == "SPOTLIGHT" ? (
                            <>{parse(this.state.spotlight)}</>
                          ) : (
                            ""
                          )}
                          {this.state.nuggetType == "STORY" ? (
                            <>
                              {this.state.stories.map(function (item, i) {
                                return (
                                  <>
                                    <div class="info_mcq">
                                      <label class="container ">
                                        <img src={item.imageUrl} />
                                        {parse(item.contentText)}
                                        {/* <input type="radio" name="radio" />{" "} */}
                                      </label>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.nuggetType == "QUIZ" ? (
                            <>
                              {typeof this.state.quizcontentText == "string" ? parse(this.state.quizcontentText) : ''}
                              {this.state.quizquestion.map(function (item, i) {
                                return (
                                  <>
                                    {parse(item.question)}

                                    <div class="info_mcq">
                                      <label class="container ">
                                        {item.options.map(function (item, i) {
                                          return <><li>{item.option}</li></>;
                                        })}
                                        {/* <input type="radio" name="radio" />{" "} */}
                                      </label>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.nuggetType == "QUIZNS" ? (
                            <>
                              {typeof this.state.quizNScontentText == "string" ? parse(this.state.quizNScontentText) : ''}
                              {this.state.quizNSquestion.map(function (item, i) {
                                return (
                                  <>
                                    {parse(item.question)}

                                    <div class="info_mcq">
                                      <label class="container ">
                                        {item.options.map(function (item, i) {
                                          return <><li>{item.option}</li></>;
                                        })}
                                        {/* <input type="radio" name="radio" />{" "} */}
                                      </label>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {/* Content */}
                    <div class="col-md-8 main-scroll">
                      {/* Headin Section */}
                      <div class="template_vidio_name">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="template_name image-template-name">
                              <label for="exampleFormControlInput1">
                                Choose Content Type
                              </label>
                              <Select
                                value={this.state.selectednuggetType}
                                onChange={this.nuggetTypeHandleChange}
                                options={nuggetType}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="template_vidio_name">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="template_name image-template-name">
                              <label for="exampleFormControlInput1">
                                Heading
                              </label>

                              <div class="input_section">
                                <div class="input_text">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="Heading"
                                    name="contentHeading"
                                    value={this.state.contentHeading}
                                    onChange={this.contentHeadingHandleChange}
                                    id=""
                                  />{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-12">
                            <div class="template_name image-template-name">
                              <label for="exampleFormControlInput1">
                                Font Size
                              </label>

                              <div class="input_section">
                                <div class="input_text">
                                  <select
                                    class="form-control"
                                    onChange={this.contentFontSizeHandleChange}
                                  >
                                    {this.state.contentfontrange.map((font) => {
                                      return (
                                        <option value={font}>{font}</option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="template_name image-template-name">
                              <label for="exampleFormControlInput1">
                                Background color{" "}
                              </label>

                              <div class="input_section">
                                <div class="input_text">
                                  <span
                                    style={{
                                      background: "#FFBE00",
                                      padding: "10px",
                                      margin: "2px",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        contentbackColor: "FFBE00",
                                        contentfontColor: "000000",
                                      })
                                    }
                                  >
                                    #FFBE00
                                  </span>
                                  <span
                                    style={{
                                      background: "#0A5EA9",
                                      padding: "10px",
                                      margin: "2px",
                                      color: "white !important",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        contentbackColor: "0A5EA9",
                                        contentfontColor: "FFFFFF",
                                      })
                                    }
                                  >
                                    #0A5EA9
                                  </span>
                                  <span
                                    style={{
                                      background: "#4CB963",
                                      padding: "10px",
                                      margin: "2px",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        contentbackColor: "4CB963",
                                        contentfontColor: "000000",
                                      })
                                    }
                                  >
                                    #4CB963
                                  </span>
                                  <span
                                    style={{
                                      background: "#FFFFFF",
                                      padding: "10px",
                                      margin: "2px",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        contentbackColor: "FFFFFF",
                                        contentfontColor: "000000",
                                      })
                                    }
                                  >
                                    #FFFFFF
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {this.state.nuggetType == "MCQ" ? (
                        <>
                          {/* MCQ Type*/}

                          <div class="template_vidio_name">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="template_name image-template-name">
                                  <div class="temp-bot">
                                    <div class="template_sections">
                                      <div class="template_text">
                                        <p class="info-template">MCQ</p>
                                        <p class="info-template">Question</p>
                                      </div>
                                    </div>
                                    <div class="input_section">
                                      <div class="input_text">
                                        <Editor
                                          apiKey="ymkv90kzpjgv93gg805nbd7tgjvf438bzldjz4fqlc2w6d6w"
                                          value={this.state.mcqquestion}
                                          init={{
                                            height: 300,
                                            menubar: true,
                                            font_formats:
                                              "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                                          }}
                                          onEditorChange={
                                            this.mcqquestionHandleChange
                                          }
                                        />
                                      </div>
                                      {this.validator.message(
                                        "Question",
                                        this.state.mcqquestion,
                                        "required"
                                      )}
                                    </div>
                                  </div>

                                  <div class="temp-bot">
                                    <ReactDragListView {...dragProps}>
                                      <ol class="presentation-list">
                                        {this.state.options.map(
                                          (element, index) => (
                                            <>
                                              <li key={index}>
                                                <div class="row">
                                                  <div class=" p_l_26">
                                                    <div class="faculty_sections Upload_info add_option">
                                                      <div class="faculty_text">
                                                        <p>
                                                          Add Option {index + 1}
                                                          *
                                                        </p>
                                                      </div>
                                                      <div class="number summernote_number">
                                                        <p>
                                                          {
                                                            element.option
                                                              .length
                                                          }
                                                          /124
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div class="form-group col-md-8">
                                                      <textarea
                                                        class="form-control"
                                                        id=""
                                                        rows="3"
                                                        name="option"
                                                        value={
                                                          element.option || ""
                                                        }
                                                        onChange={(e) =>
                                                          this.handleOptionChange(
                                                            index,
                                                            e
                                                          )
                                                        }
                                                        maxLength="124"
                                                      ></textarea>
                                                    </div>
                                                  </div>

                                                  <div class="col-md-6 row-flex">
                                                    <div class="col-md-4">
                                                      <p>Correct</p>
                                                      {element.correct ? (
                                                        <div
                                                          class="btn btn_mcq  btn_mcq_active
                                                    
                                                    "
                                                          style={{
                                                            backgroundColor:
                                                              "#5ac06c26",
                                                          }}
                                                          onClick={() =>
                                                            this.handleCorrectChange(
                                                              index
                                                            )
                                                          }
                                                        >
                                                          {" "}
                                                          <i
                                                            class="fa fa-check"
                                                            aria-hidden="true"
                                                          ></i>{" "}
                                                        </div>
                                                      ) : (
                                                        <div
                                                          class="btn btn_mcq  "
                                                          onClick={() =>
                                                            this.handleCorrectChange(
                                                              index
                                                            )
                                                          }
                                                        >
                                                          {" "}
                                                          <i
                                                            class="fa fa-check"
                                                            aria-hidden="true"
                                                          ></i>{" "}
                                                        </div>
                                                      )}
                                                    </div>
                                                    <div
                                                      class="col-md-4"
                                                      onClick={() =>
                                                        this.removeFormFields(
                                                          index
                                                        )
                                                      }
                                                    >
                                                      <p>Remove</p>
                                                      <div class="btn btn_mcq">
                                                        <i
                                                          class="fa fa-times"
                                                          aria-hidden="true"
                                                        ></i>{" "}
                                                      </div>
                                                    </div>
                                                    <div class="col-md-4 move-btn">
                                                      {" "}
                                                      <i
                                                        class="fa fa-arrows-alt  mcq_arrow"
                                                        aria-hidden="true"
                                                      ></i>{" "}
                                                    </div>
                                                  </div>
                                                </div>
                                                {this.state.feedback ==
                                                  "OPTIONWISE" ? (
                                                  <div class="row">
                                                    <div class="col-md-6 p_l_26">
                                                      <div class="faculty_sections Upload_info add_option">
                                                        <div class="faculty_text">
                                                          <p>
                                                            Add Feedback{" "}
                                                            {index + 1}*
                                                          </p>
                                                        </div>
                                                        <div class="number summernote_number">
                                                          <p>
                                                            {
                                                              element.feedback
                                                                .length
                                                            }
                                                            /230
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div class="form-group">
                                                        <textarea
                                                          class="form-control"
                                                          id=""
                                                          rows="3"
                                                          name="feedback"
                                                          value={
                                                            element.feedback ||
                                                            ""
                                                          }
                                                          onChange={(e) =>
                                                            this.handleOptionChange(
                                                              index,
                                                              e
                                                            )
                                                          }
                                                          maxLength="230"
                                                        ></textarea>
                                                        {this.validator.message(
                                                          "Feedback",
                                                          element.feedbak,
                                                          "maxlength:230"
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </li>
                                            </>
                                          )
                                        )}
                                      </ol>
                                    </ReactDragListView>
                                  </div>

                                  <div>
                                    <div class="template_sections">
                                      <div class="template_text">
                                        <div class="input_section">
                                          <div class="input_text">
                                            <button
                                              type="button"
                                              class="btn btn-primary btn_add_mcq "
                                              onClick={() =>
                                                this.addFormFields()
                                              }
                                            >
                                              Add More Options
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Feedback */}
                          <div class="template_vidio_name">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="template_name image-template-name">
                                  <p>Feedback Type*</p>
                                  <div class="mcq_text">
                                    <div class="select_from">
                                      <select
                                        class="js-example-basic-multipleones form-control "
                                        name="feedback"
                                        onChange={this.feedbackHandleChange}
                                        value={this.state.feedback}
                                        placeholder="Type here"
                                      >
                                        {" "}
                                        <i
                                          class="fa fa-plus-square-o"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        <option value="">Select</option>
                                        <option value="GENERIC">GENERIC</option>
                                        <option value="OPTIONWISE">
                                          Option Specific
                                        </option>
                                        <option value="CORRECT-INCORRECT">
                                          Correct
                                        </option>
                                      </select>
                                      {this.validator.message(
                                        "feedback",
                                        this.state.feedback,
                                        "required|string|max:250"
                                      )}
                                      <ion-icon name="caret-up-outline"></ion-icon>
                                      <ion-icon name="caret-down-outline"></ion-icon>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {this.state.feedback == "GENERIC" ? (
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="template_name image-template-name">
                                    <div>
                                      <div class="template_sections">
                                        <div class="template_text">
                                          <p class="info-template">Feedback</p>
                                        </div>
                                      </div>
                                      <div class="input_section">
                                        <div class="input_text">
                                          <textarea
                                            class="form-control summernote_from"
                                            id=""
                                            rows="3"
                                            maxLength="300"
                                            name="feedbackText"
                                            value={this.state.feedbackText}
                                            onChange={
                                              this.feedbackTextHandleChange
                                            }
                                          ></textarea>{" "}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.feedback == "CORRECT-INCORRECT" ? (
                              <>
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="template_name image-template-name">
                                      <div>
                                        <div class="template_sections">
                                          <div class="template_text">
                                            <p class="info-template">
                                              Feedback of Correct
                                            </p>
                                          </div>
                                          <div class="number-range">
                                            <p>
                                              {
                                                this.state.feedbackCorrect
                                                  .length
                                              }
                                              /230
                                            </p>
                                          </div>
                                        </div>
                                        <div class="input_section">
                                          <div class="input_text">
                                            <textarea
                                              class="form-control summernote_from"
                                              id=""
                                              rows="3"
                                              name="feedbackCorrect"
                                              value={this.state.feedbackCorrect}
                                              maxLength="230"
                                              onChange={
                                                this.feedbackCorrectHandleChange
                                              }
                                            ></textarea>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>{" "}
                                </div>
                                <div class="row">
                                  <div class="col-md-12">
                                    <div class="template_name image-template-name">
                                      <div>
                                        <div class="template_sections">
                                          <div class="template_text">
                                            <p class="info-template">
                                              Feedback of InCorrect
                                              {/* <img
                                    src={
                                      require("../../img/ionic-ios-information-circle-outline@2x.png")
                                        .default
                                    }
                                    alt="img_circle"
                                    class="img_line"
                                  /> */}
                                            </p>
                                          </div>
                                          <div class="number-range">
                                            <p>
                                              {
                                                this.state.feedbackInCorrect
                                                  .length
                                              }
                                              /230
                                            </p>
                                          </div>
                                        </div>
                                        <div class="input_section">
                                          <div class="input_text">
                                            <textarea
                                              class="form-control summernote_from"
                                              id=""
                                              rows="3"
                                              name="feedbackInCorrect"
                                              value={
                                                this.state.feedbackInCorrect
                                              }
                                              maxLength="230"
                                              onChange={
                                                this
                                                  .feedbackInCorrectHandleChange
                                              }
                                            ></textarea>{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                          {/*  End MCQ Type*/}
                        </>
                      ) : (
                        ""
                      )}
                      {this.state.nuggetType == "POLL" ? (
                        <>
                          {/* POLL Type*/}

                          <div class="template_vidio_name">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="template_name image-template-name">
                                  <div class="temp-bot">
                                    <div class="template_sections">
                                      <div class="template_text">
                                        <p class="info-template">Poll</p>
                                        <p class="info-template">Question</p>
                                      </div>
                                    </div>
                                    <div class="input_section">
                                      <div class="input_text">
                                        <Editor
                                          apiKey="ymkv90kzpjgv93gg805nbd7tgjvf438bzldjz4fqlc2w6d6w"
                                          value={this.state.question}
                                          init={{
                                            height: 300,
                                            menubar: true,
                                            font_formats:
                                              "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                                          }}
                                          onEditorChange={
                                            this.questionHandleChange
                                          }
                                        />
                                      </div>
                                      {this.validator.message(
                                        "Question",
                                        this.state.question,
                                        "required"
                                      )}
                                    </div>
                                  </div>

                                  <div class="temp-bot">
                                    <ReactDragListView3 {...dragProps3}>
                                      <ol class="presentation-list">
                                        {this.state.polloptions.map(
                                          (element, index) => (
                                            <>
                                              <li key={index}>
                                                <div class="row">
                                                  <div class="col-md-6 p_l_26">
                                                    <div class="faculty_sections Upload_info add_option">
                                                      <div class="faculty_text">
                                                        <p>
                                                          Add Option {index + 1}
                                                          *
                                                        </p>
                                                      </div>
                                                      <div class="number summernote_number">
                                                        <p>
                                                          {
                                                            element.option
                                                              .length
                                                          }
                                                          /124
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div class="form-group">
                                                      <textarea
                                                        class="form-control"
                                                        id=""
                                                        rows="3"
                                                        name="option"
                                                        value={
                                                          element.option || ""
                                                        }
                                                        onChange={(e) =>
                                                          this.handlePollOptionChange(
                                                            index,
                                                            e
                                                          )
                                                        }
                                                        maxLength="124"
                                                      ></textarea>
                                                    </div>
                                                  </div>

                                                  <div class="col-md-6 row-flex">
                                                    <div
                                                      class="col-md-4"
                                                      onClick={() =>
                                                        this.removePollFields(
                                                          index
                                                        )
                                                      }
                                                    >
                                                      <p>Remove</p>
                                                      <div class="btn btn_mcq">
                                                        <i
                                                          class="fa fa-times"
                                                          aria-hidden="true"
                                                        ></i>{" "}
                                                      </div>
                                                    </div>
                                                    <div class="col-md-4 move-btn">
                                                      {" "}
                                                      <i
                                                        class="fa fa-arrows-alt  mcq_arrow"
                                                        aria-hidden="true"
                                                      ></i>{" "}
                                                    </div>
                                                  </div>
                                                </div>
                                              </li>
                                            </>
                                          )
                                        )}
                                      </ol>
                                    </ReactDragListView3>
                                  </div>

                                  <div>
                                    <div class="template_sections">
                                      <div class="template_text">
                                        <div class="input_section">
                                          <div class="input_text">
                                            <button
                                              type="button"
                                              class="btn btn-primary btn_add_mcq "
                                              onClick={() =>
                                                this.addPollFields()
                                              }
                                            >
                                              Add More Options
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/*  End POLL Type*/}
                        </>
                      ) : (
                        ""
                      )}

                      {this.state.nuggetType == "VIDEO" ? (
                        <>
                          {/* Video Type*/}
                          <div class="template_vidio_name">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="template_name image-template-name">
                                  <label for="exampleFormControlInput1">
                                    Video Url{" "}
                                  </label>

                                  <div class="input_section">
                                    <div class="input_text">
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Video Url"
                                        name="contentimageUrl"
                                        value={this.state.contentimageUrl}
                                        onChange={
                                          this.contentimageUrlHandleChange
                                        }
                                        id=""
                                      />{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="template_vidio_name">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="template_name image-template-name">
                                  <label for="exampleFormControlInput1">
                                    Subtitle Url{" "}
                                  </label>

                                  <div class="input_section">
                                    <div class="input_text">
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Subtitle Url"
                                        name="subtitle"
                                        value={this.state.subtitle}
                                        onChange={this.subtitleHandleChange}
                                        id=""
                                      />{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*  End Video Type*/}
                        </>
                      ) : (
                        ""
                      )}

                      {/* Content Ended */}
                      {this.state.nuggetType == "ARTICLE" ? (
                        <>
                          {/* Article Type*/}
                          <div class="template_vidio_name">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="template_name image-template-name">
                                  <label for="exampleFormControlInput1">
                                    Article
                                  </label>

                                  <div class="input_section">
                                    <div class="input_text">
                                      <Editor
                                        apiKey="ymkv90kzpjgv93gg805nbd7tgjvf438bzldjz4fqlc2w6d6w"
                                        value={this.state.article}
                                        init={{
                                          height: 500,
                                          menubar: true,
                                          font_formats:
                                            "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
                                        }}
                                        onEditorChange={
                                          this.articleHandleChange
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/*  End Article Type*/}
                        </>
                      ) : (
                        ""
                      )}
                      {this.state.nuggetType == "SNIPPET" ? (
                        <>
                          {/* Snippet Type*/}
                          <div class="template_vidio_name">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="template_name image-template-name">
                                  <label for="exampleFormControlInput1">
                                    Snippet
                                  </label>

                                  <div class="input_section">
                                    <div class="input_text">
                                      <Editor
                                        apiKey="ymkv90kzpjgv93gg805nbd7tgjvf438bzldjz4fqlc2w6d6w"
                                        value={this.state.snippet}
                                        init={{
                                          height: 500,
                                          menubar: true,
                                        }}
                                        onEditorChange={
                                          this.snippetHandleChange
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/*  End Snippet Type*/}
                        </>
                      ) : (
                        ""
                      )}
                      {this.state.nuggetType == "SPOTLIGHT" ? (
                        <>
                          {/* spotlight Type*/}
                          <div class="template_vidio_name">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="template_name image-template-name">
                                  <label for="exampleFormControlInput1">
                                    Spotlight
                                  </label>

                                  <div class="input_section">
                                    <div class="input_text">
                                      <Editor
                                        apiKey="ymkv90kzpjgv93gg805nbd7tgjvf438bzldjz4fqlc2w6d6w"
                                        value={this.state.spotlight}
                                        init={{
                                          height: 500,
                                          menubar: true,
                                        }}
                                        onEditorChange={
                                          this.spotlightHandleChange
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/*  End spotlight Type*/}
                        </>
                      ) : (
                        ""
                      )}
                      {this.state.nuggetType == "STORY" ? (
                        <>
                          {/* story Type*/}
                          <div class="template_vidio_name">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="template_name image-template-name">
                                  <label for="exampleFormControlInput1">
                                    Story
                                  </label>

                                  <ReactDragListView2 {...dragProps2}>
                                    <ol class="presentation-list">
                                      {this.state.stories.map(
                                        (element, index) => (
                                          <>
                                            <li key={index}>
                                              <div class="row">
                                                <div class="col-md-6 p_l_26">
                                                  <div class="faculty_sections Upload_info add_option">
                                                    <div class="faculty_text">
                                                      <p>
                                                        Add Story {index + 1}*
                                                        {index > 0 ? (
                                                          <i
                                                            onClick={() =>
                                                              this.removeStories(
                                                                index
                                                              )
                                                            }
                                                            class="fa fa-times btn btn_mcq"
                                                            aria-hidden="true"
                                                          ></i>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </p>
                                                    </div>
                                                  </div>

                                                  <div class="form-group">
                                                    <label>Image Url</label>
                                                    <input
                                                      class="form-control"
                                                      id=""
                                                      rows="3"
                                                      name="imageUrl"
                                                      value={
                                                        element.imageUrl || ""
                                                      }
                                                      onChange={(e) =>
                                                        this.handleStoryChange(
                                                          index,
                                                          e
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <div class="form-group">
                                                    <Editor
                                                      apiKey="ymkv90kzpjgv93gg805nbd7tgjvf438bzldjz4fqlc2w6d6w"
                                                      value={
                                                        element.contentText ||
                                                        ""
                                                      }
                                                      name="contentText"
                                                      init={{
                                                        height: 200,
                                                        menubar: true,
                                                      }}
                                                      onEditorChange={
                                                        this
                                                          .handleStoryEditorChange
                                                      }
                                                    />
                                                  </div>
                                                </div>

                                                <div class="col-md-4 move-btn">
                                                  {" "}
                                                  <i
                                                    class="fa fa-arrows-alt  mcq_arrow"
                                                    aria-hidden="true"
                                                  ></i>{" "}
                                                </div>
                                              </div>
                                            </li>
                                          </>
                                        )
                                      )}
                                    </ol>
                                  </ReactDragListView2>
                                  <div class="template_sections">
                                    <div class="template_text">
                                      <div class="input_section">
                                        <div class="input_text">
                                          <button
                                            type="button"
                                            class="btn btn-primary btn_add_mcq "
                                            onClick={() =>
                                              this.addMoreStories()
                                            }
                                          >
                                            Add More Stories
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/*  End story Type*/}
                        </>
                      ) : (
                        ""
                      )}
                      {this.state.nuggetType == "QUIZ" ? (
                        <>
                          {/* story Type*/}
                          <div class="template_vidio_name">
                            <div class="row">
                              <div class="col-.////:;';pmd-12">
                                <div class="template_name image-template-name">
                                  <label for="exampleFormControlInput1">
                                    Quiz
                                  </label>

                                  <div class="input_section">
                                    <p>Content Text</p>
                                    <div class="input_text">
                                      <Editor
                                        apiKey="ymkv90kzpjgv93gg805nbd7tgjvf438bzldjz4fqlc2w6d6w"
                                        value={this.state.quizcontentText}
                                        init={{
                                          height: 200,
                                          menubar: true,
                                        }}
                                        onEditorChange={
                                          this.quizcontentTextHandleChange
                                        }
                                      />
                                    </div>
                                  </div>
                                  <ol class="presentation-list">
                                    {this.state.quizquestion.map(
                                      (elements, index1) => (
                                        <>
                                          <li key={index1}>
                                            <div class="row">
                                              <div class="col-md-6 p_l_26">
                                                <div class="faculty_sections Upload_info add_option">
                                                  <div class="faculty_text">
                                                    <p>
                                                      Add Question {index1 + 1}*
                                                      {index1 > 0 ? (
                                                        <i
                                                          onClick={() =>
                                                            removeQuizQuestion(
                                                              index1
                                                            )
                                                          }
                                                          class="fa fa-times btn btn_mcq"
                                                          aria-hidden="true"
                                                        ></i>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="form-group ">
                                                <label>Question</label>
                                                <Editor
                                                  apiKey="ymkv90kzpjgv93gg805nbd7tgjvf438bzldjz4fqlc2w6d6w"
                                                  value={
                                                    elements.question || ""
                                                  }
                                                  init={{
                                                    height: 200,
                                                    menubar: true,
                                                  }}
                                                  onEditorChange={
                                                    this
                                                      .handleQuizQuestionChange
                                                  }
                                                />
                                              </div>
                                              <div class="form-group col-md-8">
                                                <ol class="presentation-list">
                                                  {elements.options.map(
                                                    (element, index) => (
                                                      <>
                                                        <li key={index}>
                                                          Add Option{" "}{index + 1}*
                                                          <div class="row">

                                                            <div class="col-md-6 p_l_26">
                                                              <div class="faculty_sections Upload_info add_option">
                                                                <div class="faculty_text">
                                                                  <p>


                                                                  </p>
                                                                </div>
                                                              </div>
                                                              <textarea
                                                                class="form-control col-sm-12"
                                                                id=""
                                                                rows="3"
                                                                name="option"
                                                                value={
                                                                  element.option ||
                                                                  ""
                                                                }
                                                                onChange={(e) =>
                                                                  handleQuizOptionChange(
                                                                    index,
                                                                    e,
                                                                    index1
                                                                  )
                                                                }
                                                                maxLength="124"
                                                              ></textarea>
                                                              <input
                                                                class="form-control col-md-3"
                                                                id=""
                                                                placeholder="score"
                                                                type="number"
                                                                name="score"
                                                                value={
                                                                  element.score ||
                                                                  ""
                                                                }
                                                                onChange={(e) =>
                                                                  handleQuizOptionChange(
                                                                    index,
                                                                    e,
                                                                    index1
                                                                  )
                                                                }
                                                                maxLength="124"
                                                              />
                                                            </div>

                                                            <div class="col-md-5 row-flex">
                                                              <div class="col-sm-3  ">
                                                                <p>Correct</p>
                                                                {element.correct ? (
                                                                  <div
                                                                    class="btn btn_mcq  btn_mcq_active
                                                    
                                                    "
                                                                    style={{
                                                                      backgroundColor:
                                                                        "#5ac06c26",
                                                                    }}
                                                                    onClick={() =>
                                                                      handleQuizCorrectChange(
                                                                        index,
                                                                        index1
                                                                      )
                                                                    }
                                                                  >
                                                                    {" "}
                                                                    <i
                                                                      class="fa fa-check"
                                                                      aria-hidden="true"
                                                                    ></i>{" "}
                                                                  </div>
                                                                ) : (
                                                                  <div
                                                                    class="btn btn_mcq  "
                                                                    onClick={() =>
                                                                      handleQuizCorrectChange(
                                                                        index,
                                                                        index1
                                                                      )
                                                                    }
                                                                  >
                                                                    {" "}
                                                                    <i
                                                                      class="fa fa-check"
                                                                      aria-hidden="true"
                                                                    ></i>{" "}
                                                                  </div>
                                                                )}
                                                              </div>
                                                              <div
                                                                class="col-md-2"
                                                                onClick={() =>
                                                                  removeQuizOptions(
                                                                    index,
                                                                    index1
                                                                  )
                                                                }
                                                              >
                                                                <p>Remove</p>
                                                                <div class="btn btn_mcq">
                                                                  <i
                                                                    class="fa fa-times"
                                                                    aria-hidden="true"
                                                                  ></i>{" "}
                                                                </div>
                                                              </div>
                                                            </div>
                                                            {/* 
                                                            <div class="col-md-4 move-btn">
                                                              {" "}
                                                              <i
                                                                class="fa fa-arrows-alt  mcq_arrow"
                                                                aria-hidden="true"
                                                              ></i>{" "}
                                                            </div> */}
                                                          </div>
                                                        </li>
                                                      </>
                                                    )
                                                  )}
                                                </ol>
                                                <br />
                                                <div class="template_sections">
                                                  <div class="template_text">
                                                    <div class="input_section">
                                                      <div class="input_text">
                                                        <button
                                                          type="button"
                                                          class="btn  btn-sm btn-primary btn_add_mcq "
                                                          onClick={() =>
                                                            this.addQuizOptions(
                                                              index1
                                                            )
                                                          }
                                                        >
                                                          Add More Options
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            {/* <div class="col-md-4 move-btn">
                                              {" "}
                                              <i
                                                class="fa fa-arrows-alt  mcq_arrow"
                                                aria-hidden="true"
                                              ></i>{" "}
                                            </div> */}
                                          </li>
                                        </>
                                      )
                                    )}
                                  </ol>
                                  <div class="template_sections">
                                    <div class="template_text">
                                      <div class="input_section">
                                        <div class="input_text">
                                          <button
                                            type="button"
                                            class="btn btn-primary btn_add_mcq "
                                            onClick={() => this.addQuizFields()}
                                          >
                                            Add More Questions
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <ol class="presentation-list">
                                    {this.state.quizresults.map(
                                      (element, index) => (
                                        <>
                                          <li key={index}>
                                            <div class="row">
                                              <div class="col-md-6 p_l_26">
                                                <div class="faculty_sections Upload_info add_option">
                                                  <div class="faculty_text">
                                                    <p>
                                                      Add Result {index + 1}*
                                                    </p>
                                                  </div>
                                                  <div class="number summernote_number">
                                                    <p>
                                                      {
                                                        element.resultText
                                                          .length
                                                      }
                                                      /124
                                                    </p>
                                                  </div>
                                                </div>
                                                <div class="form-group">
                                                  <Editor
                                                    apiKey="ymkv90kzpjgv93gg805nbd7tgjvf438bzldjz4fqlc2w6d6w"
                                                    value={
                                                      element.resultText ||
                                                      ""
                                                    }
                                                    name="resultText"
                                                    init={{
                                                      height: 200,
                                                      menubar: true,
                                                    }}
                                                    onEditorChange={
                                                      this
                                                        .handleQuizResultEditorChange
                                                    }
                                                  />
                                                </div>
                                                <div class="form-group">
                                                  <input
                                                    class="form-control"
                                                    id=""
                                                    rows="3"
                                                    type="number"
                                                    placeholder="score"
                                                    name="scoreResult"
                                                    value={
                                                      element.scoreResult || ""
                                                    }
                                                    onChange={(e) =>
                                                      this.handleQuizResultChange(
                                                        index,
                                                        e
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              {index > 0 ? (
                                                <div class="col-md-6 row-flex">
                                                  <div
                                                    class="col-md-4"
                                                    onClick={() =>
                                                      removeQuizResult(index)
                                                    }
                                                  >
                                                    <p>Remove</p>
                                                    <div class="btn btn_mcq">
                                                      <i
                                                        class="fa fa-times"
                                                        aria-hidden="true"
                                                      ></i>{" "}
                                                    </div>
                                                  </div>
                                                  {/* <div class="col-md-4 move-btn">
                                                    {" "}
                                                    <i
                                                      class="fa fa-arrows-alt  mcq_arrow"
                                                      aria-hidden="true"
                                                    ></i>{" "}
                                                  </div> */}
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </li>
                                        </>
                                      )
                                    )}
                                  </ol>
                                  <div class="template_sections">
                                    <div class="template_text">
                                      <div class="input_section">
                                        <div class="input_text">
                                          <button
                                            type="button"
                                            class="btn btn-primary btn_add_mcq "
                                            onClick={() =>
                                              this.addQuizResultFields()
                                            }
                                          >
                                            Add More Results
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/*  End story Type*/}
                        </>
                      ) : (
                        ""
                      )}
                      {this.state.nuggetType == "QUIZNS" ? (
                        <>
                          {/* story Type*/}
                          <div class="template_vidio_name">
                            <div class="row">
                              <div class="col-.////:;';pmd-12">
                                <div class="template_name image-template-name">
                                  <label for="exampleFormControlInput1">
                                    Non Scored Quiz
                                  </label>

                                  <div class="input_section">
                                    <p>Content Text</p>
                                    <div class="input_text">
                                      <Editor
                                        apiKey="ymkv90kzpjgv93gg805nbd7tgjvf438bzldjz4fqlc2w6d6w"
                                        value={this.state.quizNScontentText}
                                        init={{
                                          height: 200,
                                          menubar: true,
                                        }}
                                        onEditorChange={
                                          this.quizNScontentTextHandleChange
                                        }
                                      />
                                    </div>
                                  </div>
                                  {this.state.quizNSresults.map(
                                    (element, index) => (
                                      <>
                                        <div class="row">
                                          <div class="col-md-12 p_l_26">
                                            <div class="faculty_sections Upload_info add_option">
                                              <div class="faculty_text">
                                                <p>
                                                  Add Result {index + 1}*
                                                </p>
                                              </div>
                                              <div class="number summernote_number">
                                                <p>
                                                  {
                                                    element.resultText
                                                      .length
                                                  }
                                                  /124
                                                </p>
                                              </div>
                                            </div>
                                            <div class="row  mx-2">
                                              <div class="form-group col-md-10">
                                                <Editor
                                                  apiKey="ymkv90kzpjgv93gg805nbd7tgjvf438bzldjz4fqlc2w6d6w"
                                                  value={
                                                    element.resultText ||
                                                    ""
                                                  }
                                                  name="resultText"
                                                  init={{
                                                    height: 200,
                                                    menubar: true,
                                                  }}
                                                  onEditorChange={
                                                    this
                                                      .handleQuizNSResultEditorChange
                                                  }
                                                />
                                              </div>
                                              <div class="form-group col-md-2">
                                                <input
                                                  class="form-control"
                                                  id=""
                                                  rows="3"
                                                  type="number"
                                                  placeholder="resultKey"
                                                  name="resultKey"
                                                  readOnly={true}
                                                  value={
                                                    element.resultKey
                                                  }

                                                />
                                              </div>
                                            </div>
                                          </div>
                                          {/* {index > 0 ? (
                                                <div class="col-md-6 row-flex">
                                                  <div
                                                    class="col-md-4"
                                                    onClick={() =>
                                                      removeQuizNSResult(index)
                                                    }
                                                  >
                                                    <p>Remove</p>
                                                    <div class="btn btn_mcq">
                                                      <i
                                                        class="fa fa-times"
                                                        aria-hidden="true"
                                                      ></i>{" "}
                                                    </div>
                                                  </div>
                                                  
                                            </div>
                                            ) : (
                                            ""
                                              )} */}
                                        </div>
                                      </>
                                    )
                                  )}
                                  <div class="template_sections">
                                    <div class="template_text">
                                      <div class="input_section">
                                        <div class="input_text" onClick={() =>
                                          this.addQuizNSResultFields()
                                        }>
                                          <i
                                            class="fa fa-plus "

                                          >
                                          </i>
                                          Add More Results
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <ol class="presentation-list">
                                    {this.state.quizNSquestion.map(
                                      (elements, index1) => (
                                        <>
                                          <li key={index1}>
                                            <div class="row">
                                              <div class="col-md-12 p_l_26">
                                                <div class="faculty_sections Upload_info add_option">
                                                  <div class="faculty_text">
                                                    <p>
                                                      Add Question {index1 + 1}*
                                                      {index1 > 0 ? (
                                                        <i
                                                          onClick={() =>
                                                            removeQuizNSQuestion(
                                                              index1
                                                            )
                                                          }
                                                          class="fa fa-times btn btn_mcq"
                                                          aria-hidden="true"
                                                        ></i>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="row mx-2">
                                              <div class="form-group ">
                                                <label>Question</label>
                                                <Editor
                                                  apiKey="ymkv90kzpjgv93gg805nbd7tgjvf438bzldjz4fqlc2w6d6w"
                                                  value={
                                                    elements.question || ""
                                                  }
                                                  init={{
                                                    height: 200,
                                                    menubar: true,
                                                  }}
                                                  onEditorChange={
                                                    this
                                                      .handleQuizNSQuestionChange
                                                  }
                                                />
                                              </div>
                                            </div>

                                            {elements.options.map(
                                              (element, index) => (
                                                <>
                                                  Add Option{" "}{index + 1}*
                                                  {index > 0 ?


                                                    <i
                                                      onClick={() =>
                                                        removeQuizNSOptions(
                                                          index,
                                                          index1
                                                        )
                                                      }
                                                      class="fa btn btn-danger fa-times"
                                                      aria-hidden="true"
                                                    ></i>


                                                    : ''}
                                                  <div class="row mx-2">

                                                    <div class="form-group col-sm-8">
                                                      <textarea
                                                        class="form-control "
                                                        id=""
                                                        rows="3"
                                                        name="option"
                                                        value={
                                                          element.option ||
                                                          ""
                                                        }
                                                        onChange={(e) =>
                                                          handleQuizNSOptionChange(
                                                            index,
                                                            e,
                                                            index1
                                                          )
                                                        }
                                                        maxLength="124"
                                                      ></textarea>
                                                    </div>
                                                    <div class="form-group col-sm-4">
                                                      <label>Choose Result Key</label>
                                                      <select class="form-control " name="resultkey" onChange={(e) =>
                                                        handleQuizNSOptionChange(
                                                          index,
                                                          e,
                                                          index1
                                                        )}   >
                                                        {
                                                          this.state.quizNSresults.map(function (item, i) {
                                                            return (<option value={i + 1}>
                                                              {i + 1}
                                                            </option>)
                                                          }
                                                          )
                                                        }
                                                      </select>
                                                    </div>
                                                  </div>

                                                </>
                                              )
                                            )}
                                            <br />
                                            <div class="template_sections">
                                              <div class="template_text">
                                                <div class="input_section">
                                                  <div class="input_text" onClick={() =>
                                                    this.addQuizNSOptions(
                                                      index1
                                                    )
                                                  }>
                                                    <i
                                                      type="button"
                                                      class="fa fa-plus"

                                                    >  </i>
                                                    Add More Options

                                                  </div>
                                                </div>
                                              </div>
                                            </div>


                                            {/* <div class="col-md-4 move-btn">
                                              {" "}
                                              <i
                                                class="fa fa-arrows-alt  mcq_arrow"
                                                aria-hidden="true"
                                              ></i>{" "}
                                            </div> */}
                                          </li>
                                        </>
                                      )
                                    )}
                                  </ol>
                                  <div class="template_sections">
                                    <div class="template_text">
                                      <div class="input_section">
                                        <div class="input_text">
                                          <button
                                            type="button"
                                            class="btn btn-primary btn_add_mcq "
                                            onClick={() => this.addQuizNSFields()}
                                          >
                                            Add More Questions
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>

                          {/*  End story Type*/}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div >
              {/* end Add Content */}
            </>
          )
          }
        </div>
      </>
    );
  }
  moreTxtHandleChange = (event) => {
    this.setState({ moreTxt: event.target.value });
  };
  addPathway(pathway) {
    if (this.state.selectedPathway.indexOf(pathway) == -1) {
      this.state.selectedPathway.push(pathway);
      //  this.state.pathways.splice(this.state.pathways.indexOf(pathway), 1);
    }
    this.state.pathways = [];
  }
  removePathway(pathway) {
    console.log(pathway);
    // alert(this.state.selectedPathway.indexOf(pathway));
    let selectedPathway = this.state.selectedPathway;
    selectedPathway.splice(this.state.selectedPathway.indexOf(pathway), 1);
    this.setState({ selectedPathway });
  }
  pathwaySearchUpdated(term) {
    const token = localStorage.getItem("token");
    if (term.length > 0) {
      axios
        .get(
          Constants.api +
          "pathways?name=" +
          term +
          "&sortBy=name:asc&limit=10&page=1",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.setState({ pathways: res.data.output.results });
            console.log(this.state.pathways);
          }
        });
      this.setState({ searchTerm: term });
    } else {
      this.setState({
        pathways: [],
      });
    }
  }
}

export default CreateTodayContent;
