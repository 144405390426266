import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import { WithContext as ReactTags } from "react-tag-input";
import ReactDragListView from "react-drag-listview";
import ReactDragQuestionView from "react-drag-listview";
import SearchInput from "react-search-input";
import SelectSearch from "react-select-search";

class CreateGraded extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      name: this.props.resp ? this.props.resp.data.name : "",
      description: this.props.resp ? this.props.resp.data.description : "",

      title: this.props.resp ? this.props.resp.data.title : "",
      tags: [],
      sortBy: "name:asc",
      limit: 1000,
      page: 1,
      tag: [],

      create: 1,

      addedFaculty: this.props.resp ? this.props.resp.data.facultys : [],
      faculties: [],

      addedThriveSkill: this.props.resp
        ? this.props.resp.data.thriveSkills
        : [],
      thriveSkills: [],
      thriveskillSearchTerm: "",

      addedQuestion: this.props.resp ? this.props.resp.data.questions : [],
      questions: [],
      questionSearchTerm: "",

      preview: 0,
    };
    this.handleTagDelete = this.handleTagDelete.bind(this);
    this.handleTagAddition = this.handleTagAddition.bind(this);
    this.handleTagDrag = this.handleTagDrag.bind(this);
    this.facultyUpdated = this.facultyUpdated.bind(this);
    this.thriveSkillUpdated = this.thriveSkillUpdated.bind(this);
    this.questionUpdated = this.questionUpdated.bind(this);
  }

  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  titleHandleChange = (event) => {
    this.setState({ title: event.target.value });
  };
  instructionTxtHandleChange = (event) => {
    this.setState({ instructionTxt: event.target.value });
  };
  imageHandleChange = (event) => {
    this.setState({ image: event.target.value });
  };
  descriptionHandleChange = (event) => {
    this.setState({ description: event.target.value });
  };

  xpPointsHandleChange = (event) => {
    this.setState({ xpPoints: event.target.value });
  };
  durationHandleChange = (event) => {
    this.setState({ duration: event.target.value });
  };

  feedbackHandleChange = (event) => {
    this.setState({ feedback: event.target.value });
  };
  componentDidMount() {}
  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      let thriveskill = [];
      let tsk = this.state.addedThriveSkill;
      tsk.map(function (item, i) {
        thriveskill.push(item.id);
        //Do something
      });
      let tags = [];
      // let tag = this.state.tags;
      // tag.foreach((tag) => {
      //   tags.push(tag.text);
      //   //Do something
      //   //return true;
      // });

      let facultys = [];
      let options = this.state.addedFaculty;
      options.map(function (item, i) {
        facultys.push(item.id);
        //Do something
      });

      let ques = [];
      this.state.addedQuestion.map(function (item, i) {
        ques.push(item.id);
        //Do something
      });

      const token = localStorage.getItem("token");
      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "gradedAssesss/" + this.props.resp.data.id,
            {
              name: this.state.name,
              title: this.state.title,
              description: this.state.description,
              xpPoints: this.state.xpPoints,
              duration: this.state.duration,
              facultys: facultys,
              thriveSkills: thriveskill,
              tags: tags,
              questions: ques,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Graded Assessment Updated");
              this.setState({ create: 0 });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      } else {
        axios
          .post(
            Constants.api + "gradedAssesss",
            {
              name: this.state.name,
              title: this.state.title,
              description: this.state.description,
              xpPoints: this.state.xpPoints,
              duration: this.state.duration,
              facultys: facultys,
              thriveSkills: thriveskill,
              tags: tags,
              questions: ques,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Graded Assessment Added");

              this.setState({
                name: "",
                description: "",
                addedFaculty: [],
                addedThriveSkill: [],
                addedQuestion: [],
                image: "",
                contextual: "",
                question: "",
                instructionTxt: "",
                options: [{ option: "", correct: false }],
                heading: "",
                feedbackText: "",
                xpPoints: 0,
                duration: 0,
              });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };
  handleTagAddition(tag) {
    this.setState((state) => ({ tags: [...state.tags, tag] }));
  }
  handleTagDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  }
  handleTagDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  }

  render() {
    if (this.state.create == 0) {
      window.location = "/graded-assesment";
    }
    //Dragging
    const that = this;
    const qdragProps = {
      onDragEnd(fromIndex, toIndex) {
        console.log(fromIndex);
        const addedQuestion = [...that.state.addedQuestion];
        const item = addedQuestion.splice(fromIndex, 1)[0];
        addedQuestion.splice(toIndex, 0, item);

        that.setState({ addedQuestion });
      },
      nodeSelector: "li",
      handleSelector: ".mcq_arrow",
    };

    return (
      <>
        <div class="add_video_section add_video">
          {/* Bread crumbs */}
          <div class="faculty_sections">
            <div class="Faculty_text">
              <p>
                Content Listing /<span>Graded Assessment</span>
              </p>
            </div>
          </div>
          {/* Second Section */}
          <div class="faculty_section">
            <div class="faculty_text">
              <h3>
                <i
                  class="fa fa-arrow-left"
                  onClick={() => this.setState({ create: 0 })}
                  aria-hidden="true"
                ></i>{" "}
                Graded Assessment
              </h3>{" "}
            </div>
            <div class="btn_create">
              <button
                type="submit"
                onClick={this.handleFormSubmit}
                class="btn btn-primary btn_category btn_vidios btn-save-1"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                Save
              </button>
              <button
                onClick={() => this.setState({ create: 0 })}
                type="button"
                class="btn btn-save btn-save_close"
              >
                Close
              </button>
            </div>
          </div>
          {/* Progress */}
          <ol class="track-progress">
            <li class="done">
              <div class="blue-dot"></div> <span>Create</span>{" "}
            </li>
            <li class="todo">
              <div class="blue-dot"></div> <span>Preview</span>{" "}
            </li>
          </ol>
          {/* Main Content */}
          <div class="mcq_section ">
            <div class="container video_sections">
              <div class="row">
                {/* Preview */}
                <div class="col-md-4">
                  <div
                    class="available_info text-center mcq_texts main-scroll-table"
                    // style={bg}
                  >
                    <h5>{this.state.question}</h5>
                    <p>{this.state.instructionTxt}</p>
                    {/* <div class="info_mcq">
                        {this.state.options.map(function (item, i) {
                          return (
                            <>
                              <label class="container mcq_choose_text">
                                {item.option}
                                <input type="radio" name="radio" />{" "}
                                <span class="checkmark"></span>{" "}
                              </label>
                            </>
                          );
                        })}
                      </div> */}
                  </div>
                </div>
                {/* Content */}
                <div class="col-md-8 main-scroll-table">
                  {/* Additional details */}
                  <div class="template_vidio_name">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="template_name image-template-name">
                          <div>
                            <div class="template_sections">
                              <div class="template_text">
                                <p class="info-template">
                                  Template Name*
                                  <img
                                    src={
                                      require("../../img/ionic-ios-information-circle-outline@2x.png")
                                        .default
                                    }
                                    alt="img_circle"
                                    class="img_line"
                                  />
                                </p>
                              </div>
                              <div class="number-range">
                                <p>{this.state.name.length}/100</p>
                              </div>
                            </div>
                            <div class="input_section">
                              <div class="input_text">
                                <input
                                  type="text"
                                  name="name"
                                  value={this.state.name}
                                  onChange={this.nameHandleChange}
                                  class="form-control"
                                  placeholder="Template Name"
                                  id=""
                                  maxLength="100"
                                />{" "}
                              </div>
                              {this.validator.message(
                                "Name",
                                this.state.name,
                                "required"
                              )}
                            </div>
                          </div>

                          <div>
                            <div class="template_sections">
                              <div class="template_text">
                                <p class="info-template">Title</p>
                              </div>
                              <div class="number-range">
                                <p>{this.state.title.length}/40</p>
                              </div>
                            </div>
                            <div class="input_section">
                              <div class="input_text">
                                <input
                                  type="text"
                                  class="form-control full-width"
                                  name="title"
                                  value={this.state.title}
                                  onChange={this.titleHandleChange}
                                  id="exampleInputEmail1"
                                  placeholder="Title"
                                  maxLength="100"
                                />{" "}
                              </div>
                            </div>
                          </div>

                          <div class="template_sections">
                            <div class="template_text">
                              <p class="info-template">Description</p>
                            </div>
                            <div class="number-range">
                              <p>{this.state.description.length}/280</p>
                            </div>
                          </div>
                          <div class="input_section">
                            <div class="input_text">
                              <div class="form-group">
                                <textarea
                                  class="form-control"
                                  id=""
                                  rows="3"
                                  name="description"
                                  value={this.state.description}
                                  onChange={this.descriptionHandleChange}
                                  placeholder="Description"
                                  maxLength="280"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="image-template-name image-template-name-2">
                          {/* Add Faculty */}
                          <div class="template_sections">
                            <div class="template_text">
                              <p>Enter Faculty Name</p>
                            </div>
                          </div>

                          <div class="input_section">
                            <div class="input_text">
                              <SearchInput
                                class="form-control collapse-search"
                                id="facultyInput"
                                onChange={this.facultyUpdated}
                                placeholder="Type to add Faculty"
                                value={this.state.facultySearchTerm}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          {this.state.faculties.map((item) => {
                            return (
                              <>
                                <div class="dropdown_img">
                                  <a
                                    class="dropdown-item"
                                    onClick={() => this.addFaculty(item)}
                                    href="#"
                                  >
                                    <img src={item.img} />
                                    <span class="text_vidio_name">
                                      {item.name}
                                    </span>{" "}
                                    {/* <span aria-hidden="true">×</span> */}
                                  </a>
                                </div>
                              </>
                            );
                          })}
                          <div class="">
                            {this.state.addedFaculty.map((item, index) => (
                              <>
                                <a class="dropdown-item drop-img" href="#">
                                  <img src={item.img} width="10px" />
                                  <span class="text_vidio_name">
                                    {item.name}
                                  </span>{" "}
                                  <span
                                    aria-hidden="true"
                                    onClick={() => this.removeFaculty(item)}
                                  >
                                    <i
                                      class="fa fa-times"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </a>
                              </>
                            ))}
                          </div>

                          {/* Add Thrive Skills */}
                          <div class="template_sections">
                            <div class="template_text">
                              <p>Enter Thrive Skills Tag</p>
                            </div>
                          </div>
                          <div class="input_section">
                            <div class="input_text">
                              <SearchInput
                                class="form-control collapse-search"
                                id="thriveskillInput"
                                onChange={this.thriveSkillUpdated}
                                placeholder="Type to add Thrive Skill"
                                value={this.state.thriveskillSearchTerm}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          {this.state.thriveSkills.map((item) => {
                            return (
                              <>
                                <div class="dropdown_img">
                                  <a
                                    class="dropdown-item"
                                    onClick={() => this.addThriveSkill(item)}
                                    href="#"
                                  >
                                    <img src={item.icon} />
                                    <span class="text_vidio_name">
                                      {item.name}
                                    </span>{" "}
                                    {/* <span aria-hidden="true">×</span> */}
                                  </a>
                                </div>
                              </>
                            );
                          })}
                          <div class="">
                            {this.state.addedThriveSkill.map((item, index) => (
                              <>
                                <a class="dropdown-item drop-img" href="#">
                                  <img src={item.icon} width="10px" />
                                  <span class="text_vidio_name">
                                    {item.name}
                                  </span>{" "}
                                  <span
                                    aria-hidden="true"
                                    onClick={() => this.removeThriveSkill(item)}
                                  >
                                    <i
                                      class="fa fa-times"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </a>
                              </>
                            ))}
                          </div>

                          <div class="template_sections">
                            <div class="row-xp">
                              <div class="col-md-6 switch_btn">
                                <div class="template_name enter_xp">
                                  <p>Enter XP points</p>
                                  <div class="input_section from_daily">
                                    <div class="input_text">
                                      <input
                                        class="form-control enter_xp_text"
                                        id=""
                                        name="xpPoints"
                                        value={this.state.xpPoints}
                                        onChange={this.xpPointsHandleChange}
                                        placeholder=""
                                      ></input>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6 switch_btn">
                                <div class="template_name enter_xp duration_xp">
                                  <p>Enter Duration</p>
                                  <div class="input_section from_daily">
                                    <div class="input_text">
                                      <input
                                        class="form-control enter_xp_text"
                                        id=""
                                        name="duration"
                                        value={this.state.duration}
                                        onChange={this.durationHandleChange}
                                        placeholder=""
                                      ></input>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="switch-row">
                            <div class="col-md-12 switch_btn">
                              {/* <div class="switch-section-image">
                                  <label class="switch">
                                    <input
                                      type="checkbox"
                                      checked={this.state.dailychallenge}
                                      onChange={this.dcChange}
                                    />{" "}
                                    <span class="slider round"></span>{" "}
                                  </label>
                                  <p>Daily Challenges</p>
                                </div> */}
                              <div class="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  checked={this.state.dailychallenge}
                                  onChange={this.dcChange}
                                  class="custom-control-input"
                                  id="customSwitches2"
                                />
                                <label
                                  class="custom-control-label"
                                  for="customSwitches2"
                                >
                                  Daily Challenges
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* POLL */}

                  <div class="template_vidio_name">
                    <div class="row-full add-content-container">
                      <div class="inline-form-full">
                        <label for="exampleFormControlInput1">
                          Add Questions{" "}
                        </label>
                        <SearchInput
                          class="form-control collapse-search"
                          id="inputAddress"
                          onChange={this.questionUpdated}
                          value={this.state.questionSearchTerm}
                          placeholder="Type to add Question"
                          autoComplete="off"
                        />
                        {this.state.questions.map((question) => {
                          return (
                            <>
                              <div>
                                <div class="card card-body">
                                  <a href="#" class="search-body-view">
                                    <div class="search-body-view-part">
                                      <span class="search-body-view-part-name">
                                        {" "}
                                        {question.name}
                                      </span>
                                    </div>
                                    <div class="search-body-view-last">
                                      <div
                                        class="box-c box-blue"
                                        onClick={() =>
                                          this.addQuestion(question)
                                        }
                                      >
                                        <i
                                          class="fa fa-plus"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </>
                          );
                        })}
                        <div>
                          {/* Listing of Added Content */}
                          {this.state.addedQuestion.length > 0 ? (
                            <div class="added-content-container">
                              <label>Added Question</label>
                              <ReactDragListView {...qdragProps}>
                                <ol>
                                  {this.state.addedQuestion.map(
                                    (question, index) => (
                                      <li key={index}>
                                        <div class="search-body-view search-body-view-box">
                                          <div class="search-body-view-part">
                                            <span class="search-body-view-part-name">
                                              {question.name}
                                            </span>
                                          </div>
                                          <div class="search-body-view-last">
                                            <a
                                              href="#"
                                              onClick={() =>
                                                this.removeQuestion(question)
                                              }
                                            >
                                              Remove
                                            </a>
                                            {/* <ion-icon name="checkmark-outline">drag</ion-icon> */}
                                          </div>
                                          <div class="col-md-1">
                                            {" "}
                                            <i
                                              class="fa fa-arrows-alt  mcq_arrow"
                                              aria-hidden="true"
                                            ></i>{" "}
                                          </div>
                                        </div>
                                      </li>
                                    )
                                  )}
                                </ol>
                              </ReactDragListView>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Content Ended */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  addFaculty(item) {
    if (this.state.addedFaculty.some((val) => item.name === val.name) === false)
      this.state.addedFaculty.push(item);
    this.state.faculties = [];
    this.state.facultySearchTerm = "";
  }
  removeFaculty(item) {
    this.state.addedFaculty.splice(this.state.addedFaculty.indexOf(item), 1);
  }

  facultyUpdated(term) {
    const token = localStorage.getItem("token");
    if (term.length > 0) {
      axios
        .get(
          Constants.api +
            "facultys?name=" +
            term +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=10&page=" +
            this.state.page,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.setState({ faculties: res.data.results });
          }
        });
      this.setState({ facultySearchTerm: term });
    } else {
      this.setState({
        faculties: [],
      });
    }
  }

  addThriveSkill(item) {
    if (
      this.state.addedThriveSkill.some((val) => item.name === val.name) ===
      false
    )
      this.state.addedThriveSkill.push(item);
    this.state.thriveSkills = [];
    this.state.thriveskillSearchTerm = "";
  }
  removeThriveSkill(item) {
    this.state.addedThriveSkill.splice(
      this.state.addedThriveSkill.indexOf(item),
      1
    );
  }

  thriveSkillUpdated(term) {
    const token = localStorage.getItem("token");
    if (term.length > 0) {
      axios
        .get(
          Constants.api +
            "thriveSkills?name=" +
            term +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=10&page=" +
            this.state.page,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.setState({ thriveSkills: res.data.results });
          }
        });
      this.setState({ thriveskillSearchTerm: term });
    } else {
      this.setState({
        thriveSkills: [],
      });
    }
  }

  addQuestion(content) {
    if (
      this.state.addedQuestion.some((val) => content.name === val.name) ===
      false
    )
      this.state.addedQuestion.push(content);
    this.state.questions = [];
    this.state.questionSearchTerm = "";
  }
  removeQuestion(content) {
    this.state.addedQuestion.splice(
      this.state.addedQuestion.indexOf(content),
      1
    );
  }

  questionUpdated(term) {
    const token = localStorage.getItem("token");
    if (term.length > 0) {
      axios
        .get(
          Constants.api +
            "gaQuestions?name=" +
            term +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=10&page=" +
            this.state.page,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.setState({
              questions: res.data.results,
            });
          }
        });
      this.setState({ questionSearchTerm: term });
    } else {
      this.setState({
        questions: [],
      });
    }
  }
}

export default CreateGraded;
