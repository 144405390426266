import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import ReactDragListView from "react-drag-listview";
import SearchInput from "react-search-input";

class CreatePoll extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      options: this.props.resp
        ? this.props.resp.data.nuggetPOLL.poll.options
        : [{ option: "" }],
      tag: [],
      name: this.props.resp ? this.props.resp.data.name : "",
      description: this.props.resp ? this.props.resp.data.description : "",
      heading: this.props.resp ? this.props.resp.data.nuggetPOLL.heading : "",

      title: this.props.resp ? this.props.resp.data.title : "",
      instructionTxt: this.props.resp
        ? this.props.resp.data.nuggetPOLL.poll.instruction
        : "",
      contextual: this.props.resp
        ? this.props.resp.data.nuggetPOLL.contextual
        : "",
      question: this.props.resp
        ? this.props.resp.data.nuggetPOLL.poll.question
        : "",
      image: this.props.resp ? this.props.resp.data.nuggetPOLL.url : "",
      type: "POLL",
      tags: [],
      sortBy: "name:asc",
      limit: 1000,
      page: 1,
      feedback: this.props.resp
        ? this.props.resp.data.nuggetPOLL.feedbackType
        : "Generic",
      feedbackText: this.props.resp
        ? this.props.resp.data.nuggetPOLL.poll.feedback
        : "",
      search: "",
      create: 1,
      xpPoints: this.props.resp ? this.props.resp.data.xpPoints : 0,
      duration: this.props.resp ? this.props.resp.data.duration : 30,
      dailychallenge: this.props.resp
        ? this.props.resp.data.dailyChallenge
        : false,

      ifimage: true,

      addedFaculty: this.props.resp ? this.props.resp.data.facultys : [],
      faculties: [],
      facultySearchTerm: "",

      addedThriveSkill: this.props.resp
        ? this.props.resp.data.thriveSkills
        : [],
      thriveSkills: [],
      thriveskillSearchTerm: "",

      preview: 0,
    };
    this.feedbackHandleChange = this.feedbackHandleChange.bind(this);
    this.handleTagDelete = this.handleTagDelete.bind(this);
    this.handleTagAddition = this.handleTagAddition.bind(this);
    this.handleTagDrag = this.handleTagDrag.bind(this);
    this.facultyUpdated = this.facultyUpdated.bind(this);
    this.thriveSkillUpdated = this.thriveSkillUpdated.bind(this);
  }

  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };
  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  titleHandleChange = (event) => {
    this.setState({ title: event.target.value });
  };
  instructionTxtHandleChange = (event) => {
    this.setState({ instructionTxt: event.target.value });
  };
  imageHandleChange = (event) => {
    this.setState({ image: event.target.value });
  };
  descriptionHandleChange = (event) => {
    this.setState({ description: event.target.value });
  };
  videoHandleChange = (event) => {
    this.setState({ video: event.target.value });
  };
  headingHandleChange = (event) => {
    this.setState({ heading: event.target.value });
  };
  xpPointsHandleChange = (event) => {
    this.setState({ xpPoints: event.target.value });
  };
  durationHandleChange = (event) => {
    this.setState({ duration: event.target.value });
  };
  ifimage() {
    this.setState({ ifimage: !this.state.ifimage });
  }
  contextualHandleChange = (event) => {
    this.setState({ contextual: event.target.value });
  };
  questionHandleChange = (event) => {
    this.setState({ question: event.target.value });
  };
  feedbackHandleChange = (event) => {
    this.setState({ feedback: event.target.value });
  };
  feedbackTextHandleChange = (event) => {
    this.setState({ feedbackText: event.target.value });
  };

  componentDidMount() {}

  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      let thriveskill = [];
      let tsk = this.state.addedThriveSkill;
      tsk.map(function (item, i) {
        thriveskill.push(item.id);
        //Do something
      });
      let tags = [];
      // let tag = this.state.tags;
      // tag.foreach((tag) => {
      //   tags.push(tag.text);
      //   //Do something
      //   // return true;
      // });

      let facultys = [];
      let options = this.state.addedFaculty;
      options.map(function (item, i) {
        facultys.push(item.id);
        //Do something
      });

      const token = localStorage.getItem("token");
      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "contentUnits/" + this.props.resp.data.id,
            {
              name: this.state.name,
              title: this.state.title,
              description: this.state.description,
              nuggetType: this.state.type,
              xpPoints: this.state.xpPoints,
              duration: this.state.duration,
              dailyChallenge: this.state.dailychallenge,
              facultys: facultys,
              thriveSkills: thriveskill,
              tags: tags,
              nuggetPOLL: {
                heading: this.state.title,
                url: this.state.image,
                urlType: this.state.ifimage ? "IMAGE" : "VIDEO",
                contextual: this.state.contextual,
                poll: {
                  question: this.state.question,
                  instruction: this.state.instructionTxt,
                  feedback: this.state.feedbackText,
                  options: this.state.options,
                },
              },
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Content Updated");
              this.setState({ create: 0 });
              this.validator.hideMessages();
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      } else {
        axios
          .post(
            Constants.api + "contentUnits",
            {
              name: this.state.name,
              title: this.state.title,
              description: this.state.description,
              nuggetType: this.state.type,
              xpPoints: this.state.xpPoints,
              duration: this.state.duration,
              dailyChallenge: this.state.dailychallenge,
              facultys: facultys,
              thriveSkills: thriveskill,
              tags: tags,
              nuggetPOLL: {
                heading: this.state.title,
                url: this.state.image,
                urlType: this.state.ifimage ? "IMAGE" : "VIDEO",
                contextual: this.state.contextual,
                poll: {
                  question: this.state.question,
                  instruction: this.state.instructionTxt,
                  feedback: this.state.feedbackText,
                  options: this.state.options,
                },
              },
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Content Added");

              this.setState({
                name: "",
                description: "",
                addedFaculty: [],
                addedThriveSkill: [],
                image: "",
                contextual: "",
                question: "",
                instructionTxt: "",
                options: [{ option: "" }],
                heading: "",
                feedbackText: "",
                xpPoints: 0,
                duration: 0,
              });
              this.validator.hideMessages();
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };
  handleTagAddition(tag) {
    this.setState((state) => ({ tags: [...state.tags, tag] }));
  }
  handleTagDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  }
  handleTagDrag(tag, currPos, newPos) {
    const tags = [...this.state.tags];
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags: newTags });
  }
  handleOptionChange(i, e) {
    let options = this.state.options;
    options[i][e.target.name] = e.target.value;
    this.setState({ options });
  }
  handleCorrectChange(i) {
    let options = this.state.options;
    options[i]["correct"] = !options[i]["correct"];
    this.setState({ options });
  }
  addFormFields() {
    this.setState({
      options: [...this.state.options, { option: "", correct: false }],
    });
  }

  removeFormFields(i) {
    let options = this.state.options;
    options.splice(i, 1);
    this.setState({ options });
  }

  render() {
    const { tags, suggestions } = this.state;
    const KeyCodes = {
      comma: 188,
      enter: [10, 13],
    };

    const delimiters = [...KeyCodes.enter, KeyCodes.comma];
    let imgUrl = this.state.image ? this.state.image : "";
    let bg = {
      backgroundImage: "url(" + imgUrl + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };
    if (this.state.create == 0) {
      window.location = "/content";
    }

    //Dragging
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const options = [...that.state.options];
        const item = options.splice(fromIndex, 1)[0];
        options.splice(toIndex, 0, item);

        that.setState({ options });
      },
      nodeSelector: "li",
      handleSelector: ".mcq_arrow",
    };

    // Thriskill Render
    // const thriveSkillRender = (() => {
    //   return (
    //     <>
    //       hello
    //     </>
    //   )
    // });

    return (
      <>
        <div class="add_video_section add_video">
          {/* Bread crumbs */}
          <div class="faculty_sections">
            <div class="Faculty_text">
              <p>
                Content Listing /<span>Create POLL</span>
              </p>
            </div>
          </div>
          {/* Second Section */}
          <div class="faculty_section">
            <div class="faculty_text">
              <h3>
                <i
                  class="fa fa-arrow-left"
                  onClick={() => this.setState({ create: 0 })}
                  aria-hidden="true"
                ></i>{" "}
                Create POLL
              </h3>{" "}
            </div>
            <div class="btn_create">
              <button
                type="submit"
                onClick={this.handleFormSubmit}
                class="btn btn-primary btn_category btn_vidios btn-save-1"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                Save
              </button>
              <button
                onClick={() => this.setState({ create: 0 })}
                type="button"
                class="btn btn-save btn-save_close"
              >
                Close
              </button>
            </div>
          </div>
          {/* Progress */}
          <ol class="track-progress">
            <li class="done">
              <div class="blue-dot"></div> <span>Create</span>{" "}
            </li>
            <li class="todo">
              <div class="blue-dot"></div> <span>Preview</span>{" "}
            </li>
          </ol>

          {/* Main Content */}
          <div class="mcq_section ">
            <div class="container video_sections">
              <div class="row">
                {/* Preview */}
                <div class="col-md-4">
                  <div
                    class="available_info text-center mcq_texts main-scroll"
                    // style={bg}
                  >
                    <h5>{this.state.question}</h5>
                    <p>{this.state.instructionTxt}</p>
                    <div class="info_mcq">
                      {this.state.options.map(function (item, i) {
                        return (
                          <>
                            <label class="container mcq_choose_text">
                              {item.option}
                              <input type="radio" name="radio" />{" "}
                              <span class="checkmark"></span>{" "}
                            </label>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {/* Content */}
                <div class="col-md-8 main-scroll">
                  {/* Headin Section */}
                  <div class="template_vidio_name">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="template_name image-template-name">
                          <div class="temp-bot">
                            <div class="template_sections">
                              <div class="template_text">
                                <p class="info-template">
                                  Heading
                                  {/* <img
                                    src={
                                      require("../../img/ionic-ios-information-circle-outline@2x.png")
                                        .default
                                    }
                                    alt="img_circle"
                                    class="img_line"
                                  /> */}
                                </p>
                              </div>
                              <div class="number-range">
                                <p>{this.state.heading.length}/50</p>
                              </div>
                            </div>
                            <div class="input_section">
                              <div class="input_text">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Heading"
                                  name="heading"
                                  value={this.state.heading}
                                  onChange={this.headingHandleChange}
                                  maxLength="50"
                                  id=""
                                />{" "}
                              </div>
                            </div>
                          </div>

                          <div class="temp-bot">
                            {/* <div class="switch-row">
                                <div class="col-md-12 switch_btn">
                                  <div class="switch-section-image">
                                    <label
                                      class="switch"
                                      onClick={() => this.ifimage()}
                                    >
                                      <input type="checkbox" />{" "}
                                      <span class="slider round"></span>{" "}
                                    </label>{" "}
                                    <span class="image_text">Image/Video</span>{" "}
                                  </div>
                                </div>
                              </div> */}

                            <div class="template_sections">
                              <div class="template_text">
                                <p class="info-template">Upload Image</p>
                              </div>
                              {/* <div class="switch-section-image">
                                  <label
                                    class="switch"
                                    onClick={() => this.ifimage()}
                                  >
                                    <input type="checkbox" />{" "}
                                    <span class="slider round"></span>{" "}
                                  </label>{" "}
                                  <span class="image_text">Image/Video</span>{" "}
                                </div> */}
                              <div class="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  onClick={() => this.ifimage()}
                                  class="custom-control-input"
                                  id="customSwitches1"
                                />
                                <label
                                  class="custom-control-label"
                                  for="customSwitches1"
                                >
                                  Image/Video
                                </label>
                              </div>
                            </div>
                            <div class="input_section">
                              <div class="input_text">
                                <input
                                  type="text"
                                  class="form-control heading_input"
                                  id=""
                                  name="image"
                                  value={this.state.image}
                                  onChange={this.imageHandleChange}
                                  placeholder="Paste your link here"
                                />{" "}
                              </div>
                            </div>
                          </div>

                          <div class="temp-bot">
                            <div class="template_sections">
                              <div class="template_text">
                                <p class="info-template">
                                  Contextual Text
                                  {/* <img
                                    src={
                                      require("../../img/ionic-ios-information-circle-outline@2x.png")
                                        .default
                                    }
                                    alt="img_circle"
                                    class="img_line"
                                  /> */}
                                </p>
                              </div>
                              <div class="number-range">
                                <p>{this.state.contextual.length}/300</p>
                              </div>
                            </div>
                            <div class="input_section">
                              <div class="input_text">
                                <textarea
                                  class="form-control summernote_from"
                                  id=""
                                  rows="3"
                                  name="contextual"
                                  value={this.state.contextual}
                                  onChange={this.contextualHandleChange}
                                  maxLength="300"
                                ></textarea>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* POLL */}
                  <div class="template_vidio_name">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="template_name image-template-name">
                          <div class="temp-bot">
                            <div class="template_sections">
                              <div class="template_text">
                                <p class="info-template">
                                  Question
                                  {/* <img
                                    src={
                                      require("../../img/ionic-ios-information-circle-outline@2x.png")
                                        .default
                                    }
                                    alt="img_circle"
                                    class="img_line"
                                  /> */}
                                </p>
                              </div>
                              <div class="number-range">
                                <p>{this.state.question.length}/100</p>
                              </div>
                            </div>
                            <div class="input_section">
                              <div class="input_text">
                                <textarea
                                  class="form-control summernote_from"
                                  id=""
                                  rows="3"
                                  name="question"
                                  placeholder="Question"
                                  value={this.state.question}
                                  onChange={this.questionHandleChange}
                                  maxLength="100"
                                ></textarea>{" "}
                              </div>
                              {this.validator.message(
                                "Question",
                                this.state.question,
                                "required|max:150"
                              )}
                            </div>
                          </div>

                          <div class="temp-bot">
                            <div class="template_sections">
                              <div class="template_text">
                                <p class="info-template">
                                  Instruction Text
                                  {/* <img
                                    src={
                                      require("../../img/ionic-ios-information-circle-outline@2x.png")
                                        .default
                                    }
                                    alt="img_circle"
                                    class="img_line"
                                  /> */}
                                </p>
                              </div>
                              <div class="number-range">
                                <p>{this.state.instructionTxt.length}/150</p>
                              </div>
                            </div>
                            <div class="input_section">
                              <div class="input_text">
                                <input
                                  type="text"
                                  class="form-control heading_input"
                                  id=""
                                  name="instructionTxt"
                                  value={this.state.instructionTxt}
                                  onChange={this.instructionTxtHandleChange}
                                  maxLength="150"
                                  placeholder="Instruction Text"
                                />{" "}
                              </div>
                            </div>
                          </div>

                          <div class="temp-bot">
                            <ReactDragListView {...dragProps}>
                              <ol class="presentation-list">
                                {this.state.options.map((element, index) => (
                                  <>
                                    <li key={index}>
                                      <div class="row add-option-container">
                                        <div class="col-md-9 p_l_26">
                                          <div class="faculty_sections Upload_info add_option">
                                            <div class="faculty_text">
                                              <p>Add Option {index + 1}*</p>
                                            </div>
                                            <div class="number summernote_number">
                                              <p>{element.option.length}/64</p>
                                            </div>
                                          </div>
                                          <div class="form-group">
                                            <textarea
                                              class="form-control"
                                              id=""
                                              rows="3"
                                              name="option"
                                              value={element.option || ""}
                                              onChange={(e) =>
                                                this.handleOptionChange(
                                                  index,
                                                  e
                                                )
                                              }
                                              maxLength="64"
                                            ></textarea>
                                            {this.validator.message(
                                              "Option",
                                              element.option,
                                              "required|maxlength:124"
                                            )}
                                          </div>
                                        </div>
                                        <div
                                          class="col-md-2"
                                          onClick={() =>
                                            this.removeFormFields(index)
                                          }
                                        >
                                          <p>Remove</p>
                                          <div class="btn btn_mcq">
                                            <i
                                              class="fa fa-times"
                                              aria-hidden="true"
                                            ></i>{" "}
                                          </div>
                                        </div>
                                        <div class="col-md-1 move-btn">
                                          {" "}
                                          <i
                                            class="fa fa-arrows-alt  mcq_arrow"
                                            aria-hidden="true"
                                          ></i>{" "}
                                        </div>
                                      </div>
                                    </li>
                                  </>
                                ))}
                              </ol>
                            </ReactDragListView>
                          </div>

                          <div>
                            <div class="template_sections">
                              <div class="template_text">
                                <div class="input_section">
                                  <div class="input_text">
                                    <button
                                      type="button"
                                      class="btn btn-primary btn_add_mcq "
                                      onClick={() => this.addFormFields()}
                                    >
                                      Add More Options
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Feedback */}
                  <div class="template_vidio_name">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="template_name image-template-name">
                          <div>
                            <div class="template_sections">
                              <div class="template_text">
                                <p class="info-template">
                                  Feedback
                                  {/* <img
                                    src={
                                      require("../../img/ionic-ios-information-circle-outline@2x.png")
                                        .default
                                    }
                                    alt="img_circle"
                                    class="img_line"
                                  /> */}
                                </p>
                              </div>
                              <div class="number-range">
                                <p>{this.state.feedbackText.length}/250</p>
                              </div>
                            </div>
                            <div class="input_section">
                              <div class="input_text">
                                <textarea
                                  class="form-control summernote_from"
                                  id=""
                                  rows="3"
                                  name="feedbackText"
                                  value={this.state.feedbackText}
                                  onChange={this.feedbackTextHandleChange}
                                  maxLength="250"
                                ></textarea>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Additional details */}
                  <div class="template_vidio_name">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="template_name image-template-name">
                          <div>
                            <div class="template_sections">
                              <div class="template_text">
                                <p class="info-template">
                                  Template Name*
                                  <img
                                    src={
                                      require("../../img/ionic-ios-information-circle-outline@2x.png")
                                        .default
                                    }
                                    alt="img_circle"
                                    class="img_line"
                                  />
                                </p>
                              </div>
                              <div class="number-range">
                                <p>{this.state.name.length}/100</p>
                              </div>
                            </div>
                            <div class="input_section">
                              <div class="input_text">
                                <input
                                  type="text"
                                  name="name"
                                  value={this.state.name}
                                  onChange={this.nameHandleChange}
                                  class="form-control"
                                  placeholder="Template Name"
                                  id=""
                                  maxLength="100"
                                />{" "}
                              </div>
                              {this.validator.message(
                                "Name",
                                this.state.name,
                                "required"
                              )}
                            </div>
                          </div>

                          <div>
                            <div class="template_sections">
                              <div class="template_text">
                                <p class="info-template">Title</p>
                              </div>
                              <div class="number-range">
                                <p>{this.state.title.length}/40</p>
                              </div>
                            </div>
                            <div class="input_section">
                              <div class="input_text">
                                <input
                                  type="text"
                                  class="form-control full-width"
                                  name="title"
                                  value={this.state.title}
                                  onChange={this.titleHandleChange}
                                  id="exampleInputEmail1"
                                  placeholder="Title"
                                  maxLength="100"
                                />{" "}
                              </div>
                            </div>
                          </div>

                          <div class="template_sections">
                            <div class="template_text">
                              <p class="info-template">Description</p>
                            </div>
                            <div class="number-range">
                              <p>{this.state.description.length}/280</p>
                            </div>
                          </div>
                          <div class="input_section">
                            <div class="input_text">
                              <div class="form-group">
                                <textarea
                                  class="form-control"
                                  id=""
                                  rows="3"
                                  name="description"
                                  value={this.state.description}
                                  onChange={this.descriptionHandleChange}
                                  placeholder="Description"
                                  maxLength="280"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="image-template-name image-template-name-2">
                          {/* Add Faculty */}
                          <div class="template_sections">
                            <div class="template_text">
                              <p>Enter Faculty Name</p>
                            </div>
                          </div>

                          <div class="input_section">
                            <div class="input_text">
                              <SearchInput
                                class="form-control collapse-search"
                                id="facultyInput"
                                onChange={this.facultyUpdated}
                                placeholder="Type to add Faculty"
                                value={this.state.facultySearchTerm}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                          {this.state.faculties.map((item) => {
                            return (
                              <>
                                <div class="dropdown_img">
                                  <a
                                    class="dropdown-item"
                                    onClick={() => this.addFaculty(item)}
                                    href="#"
                                  >
                                    <img src={item.img} />
                                    <span class="text_vidio_name">
                                      {item.name}
                                    </span>{" "}
                                    {/* <span aria-hidden="true">×</span> */}
                                  </a>
                                </div>
                              </>
                            );
                          })}
                          <div class="">
                            {this.state.addedFaculty.map((item, index) => (
                              <>
                                <a class="dropdown-item drop-img" href="#">
                                  <img src={item.img} width="10px" />
                                  <span class="text_vidio_name">
                                    {item.name}
                                  </span>{" "}
                                  <span
                                    aria-hidden="true"
                                    onClick={() => this.removeFaculty(item)}
                                  >
                                    <i
                                      class="fa fa-times"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </a>
                              </>
                            ))}
                          </div>

                          {/* Add Thrive Skills */}
                          <div class="template_sections">
                            <div class="template_text">
                              <p>Enter Thrive Skills Tag*</p>
                            </div>
                          </div>
                          <div class="input_section">
                            <div class="input_text">
                              <SearchInput
                                class="form-control collapse-search"
                                id="thriveskillInput"
                                onChange={this.thriveSkillUpdated}
                                placeholder="Type to add Thrive Skill"
                                value={this.state.thriveskillSearchTerm}
                                autoComplete="off"
                              />
                            </div>
                            {this.validator.message(
                              "ThriveSkill",
                              this.state.addedThriveSkill,
                              "required"
                            )}
                          </div>
                          {this.state.thriveSkills.map((item) => {
                            return (
                              <>
                                <div class="dropdown_img">
                                  <a
                                    class="dropdown-item"
                                    onClick={() => this.addThriveSkill(item)}
                                    href="#"
                                  >
                                    <img src={item.icon} />
                                    <span class="text_vidio_name">
                                      {item.name}
                                    </span>{" "}
                                    {/* <span aria-hidden="true">×</span> */}
                                  </a>
                                </div>
                              </>
                            );
                          })}
                          <div class="">
                            {this.state.addedThriveSkill.map((item, index) => (
                              <>
                                <a class="dropdown-item drop-img" href="#">
                                  <img src={item.icon} width="10px" />
                                  <span class="text_vidio_name">
                                    {item.name}
                                  </span>{" "}
                                  <span
                                    aria-hidden="true"
                                    onClick={() => this.removeThriveSkill(item)}
                                  >
                                    <i
                                      class="fa fa-times"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </a>
                              </>
                            ))}
                          </div>

                          <div class="template_sections">
                            <div class="row-xp">
                              <div class="col-md-6 switch_btn">
                                <div class="template_name enter_xp">
                                  <p>Enter XP points</p>
                                  <div class="input_section from_daily">
                                    <div class="input_text">
                                      <input
                                        class="form-control enter_xp_text"
                                        id=""
                                        name="xpPoints"
                                        value={this.state.xpPoints}
                                        onChange={this.xpPointsHandleChange}
                                        placeholder=""
                                      ></input>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6 switch_btn">
                                <div class="template_name enter_xp duration_xp">
                                  <p>Enter Duration</p>
                                  <div class="input_section from_daily">
                                    <div class="input_text">
                                      <input
                                        class="form-control enter_xp_text"
                                        id=""
                                        name="duration"
                                        value={this.state.duration}
                                        onChange={this.durationHandleChange}
                                        placeholder=""
                                      ></input>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="switch-row">
                            <div class="col-md-12 switch_btn">
                              {/* <div class="switch-section-image">
                                  <label class="switch">
                                    <input
                                      type="checkbox"
                                      checked={this.state.dailychallenge}
                                      onChange={this.dcChange}
                                    />{" "}
                                    <span class="slider round"></span>{" "}
                                  </label>
                                  <p>Daily Challenges</p>
                                </div> */}
                              <div class="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  checked={this.state.dailychallenge}
                                  onChange={this.dcChange}
                                  class="custom-control-input"
                                  id="customSwitches2"
                                />
                                <label
                                  class="custom-control-label"
                                  for="customSwitches2"
                                >
                                  Daily Challenges
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Content Ended */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  addFaculty(item) {
    if (this.state.addedFaculty.some((val) => item.name === val.name) === false)
      this.state.addedFaculty.push(item);
    this.state.faculties = [];
    this.state.facultySearchTerm = "";
  }
  removeFaculty(item) {
    this.state.addedFaculty.splice(this.state.addedFaculty.indexOf(item), 1);
  }

  facultyUpdated(term) {
    const token = localStorage.getItem("token");
    if (term.length > 0) {
      axios
        .get(
          Constants.api +
            "facultys?name=" +
            term +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=10&page=" +
            this.state.page,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            if (this.state.addedFaculty.length != 0) {
              this.state.addedFaculty.forEach((faculty) => {
                console.log(this.state.addedThriveSkill.length);
                let index = res.data.results.indexOf(faculty);
                const newFaculty = res.data.results;
                newFaculty.splice(index, 1);
                this.setState({ faculties: newFaculty });
              });
            } else {
              this.setState({ faculties: res.data.results });
            }
          }
        });
      this.setState({ facultySearchTerm: term });
    } else {
      this.setState({
        faculties: [],
      });
    }
  }

  addThriveSkill(item) {
    if (
      this.state.addedThriveSkill.some((val) => item.name === val.name) ===
      false
    )
      this.state.addedThriveSkill.push(item);
    this.state.thriveSkills = [];
    this.state.thriveskillSearchTerm = "";
  }
  removeThriveSkill(item) {
    this.state.addedThriveSkill.splice(
      this.state.addedThriveSkill.indexOf(item),
      1
    );
  }

  thriveSkillUpdated(term) {
    const token = localStorage.getItem("token");
    if (term.length > 0) {
      axios
        .get(
          Constants.api +
            "thriveSkills?name=" +
            term +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=10&page=" +
            this.state.page,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            if (this.state.addedThriveSkill.length != 0) {
              this.state.addedThriveSkill.forEach((thriveskill) => {
                console.log(this.state.addedThriveSkill.length);
                let index = res.data.results.indexOf(thriveskill);
                const newThriveskill = res.data.results;
                newThriveskill.splice(index, 1);
                this.setState({ thriveSkills: newThriveskill });
              });
            } else {
              this.setState({ thriveSkills: res.data.results });
            }
          }
        });
      this.setState({ thriveskillSearchTerm: term });
    } else {
      this.setState({
        contthriveSkillsents: [],
      });
    }
  }
}

export default CreatePoll;
