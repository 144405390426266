import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import ReactDragListView from "react-drag-listview";
import SearchInput from "react-search-input";

class CreateCategories extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      name: this.props.resp ? this.props.resp.data.name : "",
      description: this.props.resp ? this.props.resp.data.description : "",
      image: this.props.resp ? this.props.resp.data.icon : "",
      sortBy: "name:asc",
      limit: 1000,
      page: 1,
      searchTerm: "",
      faculty: [],
      addedContent: this.props.resp ? this.props.resp.data.pathways : [],
      contents: [],
      lesson: [],
      search: "",
      create: 1,
    };

    this.searchUpdated = this.searchUpdated.bind(this);
  }

  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };
  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  descriptionHandleChange = (event) => {
    this.setState({ description: event.target.value });
  };
  imageHandleChange = (event) => {
    this.setState({ image: event.target.value });
  };
  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");
      let pathways = [];
      let options = this.state.addedContent;
      options.map(function (item, i) {
        pathways.push(item.id);
        //Do something
      });
      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "categorys/" + this.props.resp.data.id,
            {
              name: this.state.name,
              description: this.state.description,
              icon: this.state.image,
              pathways: pathways,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Category Updated");
              this.setState({ create: 0 });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      } else {
        axios
          .post(
            Constants.api + "categorys",
            {
              name: this.state.name,
              description: this.state.description,
              icon: this.state.image,
              pathways: pathways,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Category Added");

              this.setState({
                name: "",
                image: "",
                description: "",
                addedContent: [],
              });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };

  render() {
    if (this.state.create == 0) {
      window.location = "/categories";
    }

    let search = this.state.search;
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const addedContent = [...that.state.addedContent];
        const item = addedContent.splice(fromIndex, 1)[0];
        addedContent.splice(toIndex, 0, item);
        that.setState({ addedContent });
      },
      nodeSelector: "li",
      handleSelector: ".mcq_arrow",
    };
    return (
      <>
        <div class="category_section">
          <div class="faculty_sections create-sec-padding">
            <div class="Faculty_text">
              <p onClick={() => this.setState({ create: 0 })}>
                Category /
                <span>{this.props.resp ? "Edit" : "Create"} Category</span>
              </p>
            </div>
          </div>
          <div class="faculty_section create-sec-padding">
            <div class="faculty_text">
              <h3>
                <i
                  class="fa fa-arrow-left"
                  aria-hidden="true"
                  onClick={() => this.setState({ create: 0 })}
                ></i>{" "}
                {this.props.resp ? "Edit" : "Create"} Category
              </h3>{" "}
            </div>
            <div class="btn_create">
              <button
                type="submit"
                onClick={this.handleFormSubmit}
                class="btn btn-primary  btn_pubish"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                {" "}
                Save{" "}
              </button>
              <button
                type="button"
                onClick={() => this.setState({ create: 0 })}
                class="btn btn-primary btn_category"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                Close
              </button>
            </div>
          </div>
          <div class="category_name category-box-contain">
            <div class="container">
              <div class="col-md-12">
                <div class="category_name_text">
                  <div class="template_name">
                    <div class="faculty_sections category_nsection">
                      <div class="faculty_text">
                        <p>Category Name*</p>
                      </div>
                      <div class="number">
                        <p>{this.state.name.length}/100</p>
                      </div>
                    </div>
                    <div class="input_section">
                      <div class="input_text">
                        <input
                          type="text"
                          class="form-control"
                          name="name"
                          onChange={this.nameHandleChange}
                          value={this.state.name}
                          id="exampleInputEmail1"
                          placeholder="Quick Learn"
                          maxLength="100"
                        />{" "}
                      </div>
                      {this.validator.message(
                        "Name",
                        this.state.name,
                        "required"
                      )}
                    </div>
                  </div>
                </div>

                <div class="add_icon">
                  <div class="add_icon_logo">
                    <div class="upload_icon">
                      <p>Add Icon</p>
                      <input
                        type="text"
                        class="form-control"
                        name="image"
                        onChange={this.imageHandleChange}
                        value={this.state.image}
                        id="exampleInputEmail1"
                        placeholder="Type here"
                      />{" "}
                    </div>
                    {this.state.image != "" ? (
                      <div class="smile_icon">
                        <p>Uploaded Icon</p>{" "}
                        <img src={this.state.image} height="30px" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div class="category_name_text">
                  <div class="template_name">
                    <div class="faculty_sections category_nsection">
                      <div class="faculty_text">
                        <p>Description</p>
                      </div>
                      <div class="number">
                        <p>{this.state.description.length}/150</p>
                      </div>
                    </div>
                    <div class="input_section">
                      <div class="input_text">
                        <input
                          type="text"
                          class="form-control"
                          name="description"
                          onChange={this.descriptionHandleChange}
                          value={this.state.description}
                          maxLength="150"
                          id="exampleInputEmail1"
                          placeholder="Type here"
                        />{" "}
                      </div>
                    </div>

                    <div class="inline-form-full">
                      <label for="exampleFormControlInput1">
                        Add Pathway to this Category*{" "}
                      </label>
                      <div>
                        <SearchInput
                          class="form-control collapse-search"
                          id="inputAddress"
                          onChange={this.searchUpdated}
                          placeholder="Type to add Content"
                          value={this.state.searchTerm}
                          autocomplete="off"
                        />
                        {this.validator.message(
                          "Pathway",
                          this.state.addedContent,
                          "required"
                        )}

                        {this.state.contents.map((content) => {
                          return (
                            <>
                              <div>
                                <div class="card card-body">
                                  <a href="#" class="search-body-view">
                                    <div class="search-body-view-part">
                                      <span class="search-body-view-part-name">
                                        {" "}
                                        {content.name}
                                      </span>
                                    </div>
                                    <div class="search-body-view-part">
                                      <div class="highlight-cell highlight-gray">
                                        {content.nuggetType}
                                      </div>
                                    </div>
                                    <div class="search-body-view-last">
                                      <div
                                        class="box-c box-blue"
                                        onClick={() => this.addContent(content)}
                                      >
                                        <i class="fa fa-plus"></i>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </>
                          );
                        })}

                        {/* Listing of Added Content */}
                        {this.state.addedContent.length > 0 ? (
                          <div class="added-content-container">
                            <label>Added Pathway</label>
                            <ReactDragListView {...dragProps}>
                              <ul style={{ listStyleType: "none" }}>
                                {this.state.addedContent.map(
                                  (content, index) => (
                                    <li key={index}>
                                      <div class="search-body-view search-body-view-box">
                                        <div class="search-body-view-part">
                                          <span class="search-body-view-part-name">
                                            {content.name}
                                          </span>
                                        </div>
                                        <div class="search-body-view-part">
                                          <div class="highlight-cell highlight-blue"></div>
                                        </div>
                                        <div class="search-body-view-last">
                                          <a
                                            href="#"
                                            onClick={() =>
                                              this.removeContent(content)
                                            }
                                          >
                                            Remove
                                          </a>
                                          {/* <ion-icon name="checkmark-outline">drag</ion-icon> */}
                                        </div>
                                        <div class="col-md-1">
                                          {" "}
                                          <i
                                            class="fa fa-arrows-alt  mcq_arrow"
                                            aria-hidden="true"
                                          ></i>{" "}
                                        </div>
                                      </div>
                                    </li>
                                  )
                                )}
                              </ul>
                            </ReactDragListView>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  addContent(content) {
    if (
      this.state.addedContent.some((val) => content.name === val.name) === false
    )
      this.state.addedContent.push(content);
    this.state.contents.splice(this.state.contents.indexOf(content), 1);

    this.state.contents = [];
    this.state.searchTerm = "";
  }
  removeContent(content) {
    this.state.addedContent.splice(this.state.addedContent.indexOf(content), 1);
  }

  searchUpdated(term) {
    const token = localStorage.getItem("token");
    if (term.length > 0) {
      axios
        .get(
          Constants.api +
            "pathways?name=" +
            term +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=60&page=" +
            this.state.page,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            if (this.state.addedContent.length != 0) {
              this.state.addedContent.forEach((faculty) => {
                let index = res.data.output.results.indexOf(faculty);
                const newFaculty = res.data.output.results;
                // newFaculty.splice(index, 1);
                this.setState({ contents: newFaculty });
              });
            } else {
              this.setState({ contents: res.data.output.results });
            }
          }
        });
      this.setState({ searchTerm: term });
    } else {
      this.setState({
        contents: [],
      });
    }
  }
}

export default CreateCategories;
