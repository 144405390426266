import React, { Component } from "react";
import * as Constants from "../../Constants/index";

import axios from "axios";
import moment from "moment";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import CreateAssessment from "./createassessment.component";
import Pagination from "react-js-pagination";

class Assessment extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    // this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
  }

  state = {
    name: "",
    sortBy: "question:asc",
    limit: 25,
    page: 1,
    totalResults: 0,
    assessment: [],
    quesAns: [],
    search: "",
    create: 0,
  };

  componentDidMount() {
    //alert("Dsds");

    const tsRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        //console.log(token);

        const resp = await axios
          .get(
            Constants.api +
              "assessments?sortBy=" +
              this.state.sortBy +
              "&limit=" +
              this.state.limit +
              "&page=" +
              this.state.page,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              console.log(resp.data.results);
              this.setState({
                assessment: resp.data.results,
                totalResults: resp.data.totalResults,
              });
              console.log(this.state.assessment);
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    tsRequest();
  }
  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ page: pageNumber }, this.componentDidMount);
  }

  render() {
    const deleteItem = (id) => {
      if (window.confirm("Are you sure?")) {
        try {
          const token = localStorage.getItem("token");
          const deletedata = new FormData();
          deletedata.append("id", id); //append the values with key, value pair

          axios
            .delete(
              Constants.api + "assessments/" + id,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
              deletedata
            )
            .then((resp) => {
              //  casetype array

              if (resp.status == 204) {
                this.componentDidMount();
              } else {
              }
              //movie array
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
    };
    const editItem = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "assessments/" + id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              this.setState({ create: 1, resp: resp });
            } else {
              swal("Something went wrong!");
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    let search = this.state.search;
    const tsRender = this.state.assessment.map(function (item, i) {
      return (
        <>
          <tr>
            <td>
              <p class="thrive_think">{item.question}</p>
            </td>
            <td>
              <p class="thrive_think">{item.quesKey}</p>
            </td>
            <td>{item.quesAns.join(".\n\r")}</td>
            <td>
              <p>{moment(item.createdAt).format("MM/DD/YY H:ma")}</p>
            </td>
            <td>
              <p>{moment(item.updatedAt).format("MM/DD/YY H:ma")}</p>
            </td>
            <td>
              {/* <img
                src={require("../../img/pencil.svg").default}
                onClick={() => editItem(item.id)}
              />{" "} */}
              <img
                src={require("../../img/delete.svg").default}
                onClick={() => deleteItem(item.id)}
              />
            </td>
          </tr>
        </>
      );
    });

    return (
      <>
        {this.state.create == 0 ? (
          <>
            <div class="faculty_section faculty_section">
              <div class="faculty_text">
                <h3>Assessment</h3>{" "}
              </div>
              <div class="btn_create">
                <button
                  type="button"
                  class="btn btn-create btn-new"
                  onClick={() => this.setState({ create: 1 })}
                >
                  Create Assessment
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-9">
                <div class="form-group serch_group">
                  <input
                    type="text"
                    class="form-control form-control_one"
                    id="formGroupExampleInput2"
                    placeholder="Search"
                    name="search"
                    value={this.state.value}
                    onChange={this.searchHandleChange}
                  />{" "}
                  <i class="fa fa-search" aria-hidden="true"></i>{" "}
                </div>
              </div>
              <div class="col-md-3">
                <div class="from_dropdown">
                  <label for="show">Show entries</label>
                  <select name="cars" id="show">
                    <option
                      value="25"
                      onClick={
                        (() => this.setState({ limit: 25 }),
                        () => this.componentDidMount())
                      }
                    >
                      25
                    </option>
                    <option
                      value="50"
                      onClick={
                        (() => this.setState({ limit: 50 }),
                        () => this.componentDidMount())
                      }
                    >
                      50
                    </option>
                    <option
                      value="75"
                      onClick={
                        (() => this.setState({ limit: 75 }),
                        () => this.componentDidMount())
                      }
                    >
                      75
                    </option>
                    <option
                      value="100"
                      onClick={
                        (() => this.setState({ limit: 100 }),
                        () => this.componentDidMount())
                      }
                    >
                      100
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="thrive_skill">
              <div class="category_table">
                <div class="category_container">
                  <div class="filter_section padside_25">
                    <div class="tab-content assessment-tab">
                      <div class="tab-pane active" id="home">
                        <div class="table_faculty main-scroll-table">
                          <table cellspacing="0" class="table">
                            <tbody>
                              <tr class="row_table">
                                <th class="create_tabe ">
                                  <p>Assessment Name</p>
                                </th>
                                <th class="faculty_name">
                                  <p>Question</p>
                                </th>
                                <th class="faculty_name">
                                  <p>Options</p>
                                </th>
                                <th class="designation_text">
                                  <p>Created On</p>
                                </th>
                                <th class="action_text">
                                  <p>Modified On</p>
                                </th>
                                <th class="action_text">Actions</th>
                              </tr>

                              {tsRender}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pagination-container">
              <Pagination
                activePage={this.state.page}
                itemsCountPerPage={this.state.limit}
                totalItemsCount={this.state.totalResults}
                pageRangeDisplayed={15}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
          </>
        ) : this.state.resp ? (
          <CreateAssessment resp={this.state.resp} />
        ) : (
          <CreateAssessment />
        )}
      </>
    );
  }
}

export default Assessment;
