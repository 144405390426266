import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import DatePicker from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
class CreateCoupon extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      name: this.props.resp ? this.props.resp.data.output.name : "",
      code: this.props.resp ? this.props.resp.data.output.code : "",
      renewalType: this.props.resp
        ? this.props.resp.data.output.renewalType
        : "",
      discountType: this.props.resp
        ? this.props.resp.data.output.discountType
        : "",
      discountValue: this.props.resp
        ? this.props.resp.data.output.discountValue
        : "",
      maxLimit: this.props.resp ? this.props.resp.data.output.maxLimit : 1,
      perlearnerLimit: this.props.resp
        ? this.props.resp.data.output.perlearnerLimit
        : 1,
      couponType: this.props.resp
        ? this.props.resp.data.output.couponType
        : "SINGLE",
      couponCount: this.props.resp
        ? this.props.resp.data.output.couponCount
        : "0",

      start: this.props.resp
        ? this.props.resp.data.output.startDate.split("T")[0]
        : "",
      end: this.props.resp
        ? this.props.resp.data.output.endDate.split("T")[0]
        : "",
      prefix: this.props.resp ? this.props.resp.data.output.prefix : "",
      subscription: this.props.resp
        ? this.props.resp.data.output.subscriptionType
        : "",

      sortBy: "name:asc",
      limit: "10",
      page: 1,
      status: "DRAFT",
      subscriptions: [],
    };
  }

  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  codeHandleChange = (event) => {
    if (isNaN(event.target.value)) this.setState({ code: event.target.value });
  };

  couponTypeHandleChange = (event) => {
    this.setState({ couponType: event.target.value });
  };
  subscriptionHandleChange = (event) => {
    this.setState({ subscription: event.target.value });
  };

  prefixHandleChange = (event) => {
    if (isNaN(event.target.value))
      this.setState({ prefix: event.target.value });
  };

  renewalTypeHandleChange = (event) => {
    this.setState({ renewalType: event.target.value });
  };
  discountTypeHandleChange = (event) => {
    this.setState({ discountType: event.target.value });
  };

  discountValueHandleChange = (event) => {
    this.setState({ discountValue: event.target.value });
  };
  maxValueHandleChange = (event) => {
    this.setState({ maxValue: event.target.value });
  };
  maxLimitHandleChange = (event) => {
    this.setState({ maxLimit: event.target.value });
  };
  couponCountHandleChange = (event) => {
    this.setState({ couponCount: event.target.value });
  };
  perlearnerLimitHandleChange = (event) => {
    this.setState({ perlearnerLimit: event.target.value });
  };
  starthandleChange = (date) => {
    this.setState({ start: date });
  };
  endhandleChange = (date) => {
    this.setState({ end: date });
  };

  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");

      let obj = {
        couponType: this.state.couponType,
        prefix: this.state.prefix,
        name: this.state.name,
        code: this.state.code,
        discountType: this.state.discountType,
        discountValue: this.state.discountValue,
        renewalType: this.state.renewalType,
        maxLimit: this.state.maxLimit,

        couponCount: this.state.couponCount,
        perlearnerLimit: this.state.perlearnerLimit,
        startDate: moment(this.state.start).format("YYYY-MM-DD"),
        endDate: moment(this.state.end).format("YYYY-MM-DD"),
        status: this.state.status,
        subscriptionType: this.state.subscription,
      };
      if (this.state.couponType == "MULTIPLE") delete obj.code;
      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "couponSchemes/" + this.props.resp.data.output.id,
            obj,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Coupons Updated");
              this.setState({ create: 0 });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      } else {
        axios
          .post(Constants.api + "couponSchemes", obj, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.status == 201) {
              swal("Coupon Added");

              window.location = "/coupon-code";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };

  render() {
    if (this.state.create == 0) {
      window.location = "/coupon-code";
    }

    return (
      <>
        <div class="faculty_section faculty_list">
          <div class="Faculty_text">
            <p>
              <span class="faculty_text">Coupons </span>/
              <span>{this.props.resp ? "Edit" : "New"} Coupon</span>
            </p>
          </div>
        </div>
        <div class="main-head pathway-head pad_30">
          <h3>
            <i
              class="fa fa-arrow-left"
              aria-hidden="true"
              onClick={() => this.setState({ create: 0 })}
            ></i>{" "}
            {this.props.resp ? "Edit" : "New"} Coupons
          </h3>
          <div class="btn-right">
            {/* <button type="button" class="btn btn-edit">
              <ion-icon name="pencil"></ion-icon>
            </button> */}
            <button
              type="submit"
              onClick={this.handleFormSubmit}
              class="btn  btn-create btn-save_cretate pathway-add-save-btn"
            >
              Save
            </button>
            <button
              type="button"
              onClick={() => this.setState({ create: 0 })}
              class="btn btn-primary btn_category"
              data-toggle="modal"
              data-target="#exampleModal"
            >
              Close
            </button>
          </div>
        </div>

        <div class="main-scroll main-content pad_30">
          <div class="pathway-builder-form">
            <div class="form-group">
              <label for="exampleFormControlInput1">Coupon Type</label>
              <div class="inline-form">
                <select
                  class="form-control"
                  name="couponType"
                  value={this.state.couponType}
                  onChange={this.couponTypeHandleChange}
                  id="exampleFormControlSelect1"
                >
                  <option value="SINGLE">Single</option>
                  <option value="MULTIPLE">Multiple</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label for="inputAddress">
                Enter name of the coupon*
                <ion-icon name="information-circle-outline"></ion-icon>
                <span class="text-limit"></span>
              </label>
              <input
                type="text"
                class="form-control"
                id="inputAddress"
                name="name"
                maxlength="100"
                value={this.state.name}
                onChange={this.nameHandleChange}
              />
            </div>
            {this.validator.message("Name", this.state.name, "required")}
            {this.state.couponType == "SINGLE" ? (
              <div class="form-group">
                <label for="inputAddress">
                  Enter Coupon Code*
                  <ion-icon name="information-circle-outline"></ion-icon>
                  <span class="text-limit"></span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="inputAddress"
                  name="code"
                  maxlength="10"
                  value={this.state.code}
                  onChange={this.codeHandleChange}
                />
              </div>
            ) : (
              ""
            )}
            {this.state.couponType == "MULTIPLE" ? (
              <>
                <div class="form-group">
                  <label for="inputAddress">
                    Enter Prefix*
                    <ion-icon name="information-circle-outline"></ion-icon>
                    <span class="text-limit"></span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="inputAddress"
                    name="prefix"
                    maxlength="4"
                    minlength="4"
                    value={this.state.prefix}
                    onChange={this.prefixHandleChange}
                  />
                </div>
                {/* <div class="form-group">
                  <label for="inputAddress">
                    Maximum usage of coupon*{" "}
                    <ion-icon name="information-circle-outline"></ion-icon>
                    <span class="text-limit"></span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="inputAddress"
                    name="maxLimit"
                    min="0"
                    value={this.state.maxLimit}
                    onChange={this.maxLimitHandleChange}
                  />
                </div> */}
                <div class="form-group">
                  <label for="inputAddress">
                    Coupon Count{" "}
                    <ion-icon name="information-circle-outline"></ion-icon>
                    <span class="text-limit"></span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="inputAddress"
                    name="couponCount"
                    min="0"
                    value={this.state.couponCount}
                    onChange={this.couponCountHandleChange}
                  />
                </div>
              </>
            ) : (
              ""
            )}
            <div class="row">
              <div class="col-md-8">
                <label for="exampleFormControlInput1">
                  Choose Subscription Type*
                </label>
                <div class="inline-form">
                  <select
                    class="form-control"
                    name="subscription"
                    value={this.state.subscription}
                    onChange={this.subscriptionHandleChange}
                    id="exampleFormControlSelect1"
                  >
                    <option value="">Select</option>
                    <option value="MONTH">Monthly</option>
                    <option value="YEAR">Yearly</option>
                  </select>
                </div>
              </div>
              <div class="col-md-8">
                <label for="exampleFormControlInput1">
                  Choose discount Type*
                </label>
                <div class="inline-form">
                  <select
                    class="form-control"
                    name="discountType"
                    value={this.state.discountType}
                    onChange={this.discountTypeHandleChange}
                    id="exampleFormControlSelect1"
                  >
                    <option value="">Select</option>

                    <option value="AMOUNT">Amount</option>
                    <option value="PERCENTAGE">Percentage</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="inputAddress">
                Coupon Discount*{" "}
                <ion-icon name="information-circle-outline"></ion-icon>
                <span class="text-limit"></span>
              </label>
              <input
                type="number"
                class="form-control"
                id="inputAddress"
                name="discountValue"
                min="0"
                maxlength="100"
                value={this.state.discountValue}
                onChange={this.discountValueHandleChange}
              />
            </div>

            {this.state.couponType == "SINGLE" ? (
              <>
                <div class="form-group">
                  <label for="inputAddress">
                    Maximum usage of coupon*{" "}
                    <ion-icon name="information-circle-outline"></ion-icon>
                    <span class="text-limit"></span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="inputAddress"
                    name="maxLimit"
                    min="0"
                    value={this.state.maxLimit}
                    onChange={this.maxLimitHandleChange}
                  />
                </div>
                {/* <div class="form-group">
                  <label for="inputAddress">
                    Redemption per Learner{" "}
                    <ion-icon name="information-circle-outline"></ion-icon>
                    <span class="text-limit"></span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="inputAddress"
                    name="perlearnerLimit"
                    min="0"
                    value={this.state.perlearnerLimit}
                    onChange={this.perlearnerLimitHandleChange}
                  />
                </div> */}
              </>
            ) : (
              ""
            )}
            <div class="col-md-8">
              <label for="exampleFormControlInput1">
                Choose Type of Renewal*
              </label>
              <div class="inline-form">
                <select
                  class="form-control"
                  name="renewalType"
                  value={this.state.renewalType}
                  onChange={this.renewalTypeHandleChange}
                  id="exampleFormControlSelect1"
                >
                  <option value="">Select</option>
                  <option value="FIRSTTIME">First Time</option>
                  <option value="RENEWAL">Renewal</option>
                </select>
              </div>
            </div>
            <div class="inline-form-full">
              <label for="exampleFormControlInput1">Choose Start Date* </label>
              {this.state.start == "" ? (
                <DatePicker
                  name="start"
                  // isValidDate={disablePastDt}
                  selected={this.state.start}
                  shouldCloseOnSelect={true}
                  onChange={this.starthandleChange}
                  dateFormat="YYYY-MM-DD"
                  placeholderText="Date"
                  class="form-control required"
                />
              ) : (
                moment(this.state.start).format("YYYY-MM-DD")
              )}

              <a
                class="btn btn-sm"
                onClick={() => this.setState({ start: "" })}
              >
                Change Date
              </a>
            </div>

            {this.validator.message("Start Date", this.state.start, "required")}
            <div class="inline-form-full">
              <label for="exampleFormControlInput1">Choose End Date* </label>
              {this.state.end == "" ? (
                <DatePicker
                  name="end"
                  // isValidDate={disablePastDt}
                  selected={this.state.end}
                  shouldCloseOnSelect={true}
                  onChange={this.endhandleChange}
                  dateFormat="YYYY-MM-DD"
                  placeholderText="Date"
                  class="form-control required"
                />
              ) : (
                moment(this.state.end).format("YYYY-MM-DD")
              )}

              <a class="btn btn-sm" onClick={() => this.setState({ end: "" })}>
                Change End Date
              </a>
            </div>

            {this.validator.message("End Date", this.state.end, "required")}
          </div>
        </div>
      </>
    );
  }
}

export default CreateCoupon;
