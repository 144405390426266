import React, { Component } from "react";
import * as Constants from "../../Constants/index";

import axios from "axios";
import * as QueryString from "query-string";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import CreateFaculty from "./createfaculty.component";
import Pagination from "react-js-pagination";

class Faculty extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    // this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
  }

  state = {
    name: "",
    sortBy: "name:asc",
    limit: 25,
    page: 1,
    faculty: [],
    search: "",
    create: 0,
    totalResults: 0,
  };

  componentDidMount() {
    const facultyRequest = async () => {
      try {
        const token = localStorage.getItem("token");

        const resp = await axios
          .get(
            Constants.api +
              "facultys?name=" +
              this.state.search +
              "&sortBy=" +
              this.state.sortBy +
              "&limit=" +
              this.state.limit +
              "&page=" +
              this.state.page,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              console.log(resp.data.results);
              this.setState({
                faculty: resp.data.results,
                totalResults: resp.data.totalResults,
              });
              console.log(this.state.faculty);
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    facultyRequest();
  }
  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount
    );
  };
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ page: pageNumber }, this.componentDidMount);
  }
  render() {
    const deleteItem = (id) => {
      if (window.confirm("Are you sure?")) {
        try {
          const token = localStorage.getItem("token");
          const deletedata = new FormData();
          deletedata.append("id", id); //append the values with key, value pair

          axios
            .delete(
              Constants.api + "facultys/" + id,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
              deletedata
            )
            .then((resp) => {
              if (resp.status == 204) {
                this.componentDidMount();
              } else {
                swal("", "Something went wrong!", "error");
              }
            });
        } catch (err) {
          console.error(err);
        }
      }
    };
    const editItem = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "facultys/" + id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
            deletedata
          )
          .then((resp) => {
            if (resp.status == 200) {
              this.setState({ create: 1, resp: resp });
            } else {
              swal("", "Something went wrong!", "error");
            }
          });
      } catch (err) {
        console.error(err);
      }
    };

    let search = this.state.search;
    const facultyRender = this.state.faculty.map(function (item, i) {
      return (
        <>
          <tr>
            <td>
              <img src={item.img} />
            </td>
            <td>
              <p>{item.name}</p>
            </td>
            <td>
              <p>{item.designation}</p>
            </td>
            <td>
              <i
                class="fa fa-pencil"
                onClick={() => editItem(item.id)}
                aria-hidden="true"
              ></i>{" "}
              <i
                class="fa fa-trash"
                onClick={() => deleteItem(item.id)}
                aria-hidden="true"
              ></i>
            </td>
          </tr>
        </>
      );
    });

    return (
      <>
        {this.state.create == 0 ? (
          <>
            <div
              class="tab-pane fade in active show"
              id="v-pills-six"
              role="tabpanel"
              aria-labelledby="v-pills-settings-tab"
            >
              <div class="faculty_section faculty_section">
                <div class="faculty_text">
                  <h3>Faculty</h3>{" "}
                </div>
                <div class="btn_create">
                  <button
                    type="button"
                    class="btn btn-create btn-new"
                    onClick={() => this.setState({ create: 1 })}
                  >
                    Create Faculty
                  </button>
                </div>
              </div>
              <div class="form-group serch_group fac-search">
                <input
                  type="text"
                  class="form-control form-control_one"
                  id="formGroupExampleInput2"
                  placeholder="Search"
                  onChange={this.searchHandleChange}
                />{" "}
                <i class="fa fa-search" aria-hidden="true"></i>{" "}
              </div>
              <div class="box-body table_faculty main-scroll-table main-scroll-table-long">
                <table class="table">
                  <tbody>
                    <tr class="row_table">
                      <th class="faculty_img">
                        <p>Faculty</p>
                      </th>
                      <th class="faculty_name">
                        <p>Faculty Name</p>
                      </th>
                      <th class="designation_text">
                        <p>Designation</p>
                      </th>
                      <th class="action_text">Actions</th>
                    </tr>
                    {facultyRender}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pagination-container">
              <Pagination
                activePage={this.state.page}
                itemsCountPerPage={this.state.limit}
                totalItemsCount={this.state.totalResults}
                pageRangeDisplayed={15}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
          </>
        ) : this.state.resp ? (
          <CreateFaculty resp={this.state.resp} />
        ) : (
          <CreateFaculty />
        )}
        <></>
      </>
    );
  }
}

export default Faculty;
