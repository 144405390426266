import React, { Component } from "react";
import * as Constants from "../../Constants/index";

import axios from "axios";
import * as QueryString from "query-string";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Modals from "./modals.component";
import moment from "moment";
import CreateVideo from "./createvideo.component";
import CreateMcq from "./createmcq.component";
import CreatePoll from "./createpoll.component";
import CreateImage from "./createimage.component";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Pagination from "react-js-pagination";
import ReactHlsPlayer from "react-hls-player/dist";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

class Content extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    // this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
  }

  state = {
    name: "",
    sortBy: "name:asc",
    limit: 25,
    page: 1,
    content: [],
    search: "",
    create: 0,
    createimage: 0,
    createmcq: 0,
    ceatepoll: 0,
    createvideo: 0,
    modal: false,
    totalResults: 0,
    editcontent: false,
    nuggetType: "",
  };

  componentDidMount() {
    //alert("Dsds");

    const tsRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        //console.log(token);

        const resp = await axios
          .get(
            Constants.api +
              "contentUnits?name=" +
              this.state.search +
              "&sortBy=" +
              this.state.sortBy +
              "&limit=" +
              this.state.limit +
              "&page=" +
              this.state.page +
              "&nuggetType=" +
              this.state.nuggetType,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              console.log(resp.data.results);
              this.setState({
                content: resp.data.results,
                totalResults: resp.data.totalResults,
              });
              console.log(this.state.content);
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    tsRequest();
  }
  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ page: pageNumber }, this.componentDidMount);
  }
  async changeType(type) {
    await this.setState({ nuggetType: type });
    this.componentDidMount();
  }
  render() {
    const editItem = (id, type) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "contentUnits/" + id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              if (type == "IMAGE")
                this.setState({
                  createimage: 1,
                });
              if (type == "VIDEO")
                this.setState({
                  createvideo: 1,
                });
              if (type == "MCQ")
                this.setState({
                  createmcq: 1,
                });
              if (type == "POLL")
                this.setState({
                  createpoll: 1,
                });
              this.setState({
                resp: resp,
                create: 1,
              });
              console.log(resp);
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const previewItem = (id, type) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "contentUnits/" + id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              this.setState({
                preview: 1,
                resp: resp,
              });
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const deleteItem = (id) => {
      if (window.confirm("Are you sure?")) {
        try {
          const token = localStorage.getItem("token");
          const deletedata = new FormData();
          // deletedata.append("id", id); //append the values with key, value pair

          axios
            .delete(Constants.api + "contentUnits/" + id + "?status=3", {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((resp) => {
              //  casetype array

              if (resp.status == 204) {
                this.componentDidMount();
              } else {
              }
              //movie array
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
    };

    let search = this.state.search;
    const contentRender = this.state.content.map(function (item, i) {
      let dc = "";
      if (item.dailyChallenge == true) {
        dc = <p class="highlight-cell highlight-blue">Daily Challenge</p>;
      }
      let classes = "";
      if (item.nuggetType == "IMAGE") classes = "highlight-cell highlight-red";
      else classes = "highlight-cell highlight-gray";
      return (
        <>
          <tr>
            <td>{moment(item.createdAt).format("MM/DD/YY H:ma")}</td>
            <td>{moment(item.updatedAt).format("MM/DD/YY H:ma")}</td>
            <td>{item.name}</td>
            <td>
              <p class={classes}>{item.nuggetType}</p> {dc}
            </td>
            <td class="edit-delete">
              <i
                class="fa fa-eye"
                onClick={() => previewItem(item.id, item.nuggetType)}
                aria-hidden="true"
              ></i>{" "}
              <i
                class="fa fa-pencil"
                onClick={() => editItem(item.id, item.nuggetType)}
                aria-hidden="true"
              ></i>{" "}
              <i
                class="fa fa-trash"
                onClick={() => deleteItem(item.id)}
                aria-hidden="true"
              ></i>
            </td>
          </tr>
        </>
      );
    });
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    return (
      <>
        {this.state.create == 0 ? (
          <>
            <div class="main-head pathway-head pad_30">
              <h3>Content Library</h3>
              <button
                type="button"
                class="btn  btn-create btn-save_cretate pathway-save-btn btn-new"
                onClick={() => this.setState({ modal: true })}
              >
                Add Content
              </button>
            </div>
            <Tabs className="nav-div">
              <div className="pad_30">
                <TabPanel className="tab-pane">
                  <div class="listing-info">
                    <div class="listing-left">
                      <DropdownButton
                        id="dropdown-basic-button"
                        title="Filter By"
                        className="filter-select"
                      >
                        <Dropdown.Item
                          href="#/action-1"
                          className="select-type"
                        >
                          Type <i class="fas fa-angle-right"></i>
                          <div className="select-dropdown-content">
                            <ul>
                              <li>
                                <input
                                  type="checkbox"
                                  name="image"
                                  onClick={() => this.changeType("IMAGE")}
                                />
                                <span class="checklabel">Image</span>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  name="VIDEO"
                                  onClick={() => this.changeType("VIDEO")}
                                />
                                <span class="checklabel">Video</span>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  name="poll"
                                  onClick={() => this.changeType("POLL")}
                                />
                                <span class="checklabel">Poll</span>
                              </li>
                              <li>
                                <input
                                  type="checkbox"
                                  name="mcq"
                                  onClick={() => this.changeType("MCQ")}
                                />
                                <span class="checklabel">MCQ</span>
                              </li>
                              {/* <li>
                                <input type="checkbox" name="palylist" />
                                <span class="checklabel">Playlist</span>
                              </li>
                              <li>
                                <input type="checkbox" name="graded" />
                                <span class="checklabel">
                                  Graded Assessment
                                </span>
                              </li>
                              <li>
                                <input type="checkbox" name="daily" />
                                <span class="checklabel">Daily challenge</span>
                              </li> */}
                            </ul>
                            <div className="gray-select">
                              <div className="gray-contain">
                                <a
                                  class="reset"
                                  onClick={() =>
                                    this.setState({ nuggetType: "" })
                                  }
                                >
                                  Clear all
                                </a>
                                <button className="btn btn-create">
                                  Apply
                                </button>
                              </div>
                            </div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#/action-2"
                          className="select-skill"
                        >
                          Skill Group <i class="fas fa-angle-right"></i>
                          <div className="select-dropdown-content">
                            <ul>
                              <li>
                                <input type="checkbox" name="think" />
                                <span class="checklabel">Think</span>
                              </li>
                              <li>
                                <input type="checkbox" name="solve" />
                                <span class="checklabel">Solve</span>
                              </li>
                              <li>
                                <input type="checkbox" name="poll" />
                                <span class="checklabel">Collaborate</span>
                              </li>
                              <li>
                                <input type="checkbox" name="mcq" />
                                <span class="checklabel">Communicate</span>
                              </li>
                              <li>
                                <input type="checkbox" name="palylist" />
                                <span class="checklabel">Lead</span>
                              </li>
                            </ul>
                            <div className="gray-select">
                              <div className="gray-contain">
                                <a class="reset">Clear all</a>
                                <button className="btn btn-create">
                                  Apply
                                </button>
                              </div>
                            </div>
                          </div>
                        </Dropdown.Item>
                      </DropdownButton>
                      {/* <select
                        name="filter-by"
                        id="filter"
                        class="filter-pathway"
                      >
                        <option value="" disable>
                          Filter By
                        </option>
                        <option value="type" className="type-select">
                          Type
                        </option>
                        <option value="skillgroup" className="type-select">
                          Skill Group
                        </option>
                      </select> */}
                      {/* <div className="select-dropdown-content">
                        <ul>
                          <li>
                            <input type="checkbox" name="image" />
                            <span class="checklabel">Image</span>
                          </li>
                          <li>
                            <input type="checkbox" name="vidoe" />
                            <span class="checklabel">Video</span>
                          </li>
                          <li>
                            <input type="checkbox" name="poll" />
                            <span class="checklabel">Poll</span>
                          </li>
                          <li>
                            <input type="checkbox" name="mcq" />
                            <span class="checklabel">MCQ</span>
                          </li>
                          <li>
                            <input type="checkbox" name="palylist" />
                            <span class="checklabel">Playlist</span>
                          </li>
                          <li>
                            <input type="checkbox" name="graded" />
                            <span class="checklabel">Graded Assessment</span>
                          </li>
                          <li>
                            <input type="checkbox" name="daily" />
                            <span class="checklabel">Daily challenge</span>
                          </li>
                        </ul>
                      </div> */}
                      <div class="search-bar">
                        <input
                          type="search"
                          name="search"
                          value={this.state.search}
                          onChange={this.searchHandleChange}
                          id="search"
                          class="search-pathway"
                          placeholder="Search"
                        />
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="listing-right">
                      Show entries
                      <select name="cars" id="show">
                        <option
                          value="25"
                          onClick={() =>
                            this.setState(
                              { limit: 35 },
                              this.componentDidMount()
                            )
                          }
                        >
                          25
                        </option>
                        <option
                          value="50"
                          onClick={() =>
                            this.setState(
                              { limit: 50 },
                              this.componentDidMount()
                            )
                          }
                        >
                          50
                        </option>
                        <option
                          value="75"
                          onClick={() =>
                            this.setState(
                              { limit: 75 },
                              this.componentDidMount()
                            )
                          }
                        >
                          75
                        </option>
                        <option
                          value="100"
                          onClick={() =>
                            this.setState(
                              { limit: 100 },
                              this.componentDidMount()
                            )
                          }
                        >
                          100
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="main-scroll-table">
                    <table cellspacing="0" class="pathway-table">
                      <tbody>
                        <tr>
                          <th width="15%">Created On</th>
                          <th width="15%">Modified On</th>
                          <th width="35%">Name</th>
                          <th width="25%">Type</th>
                          <th width="10%">Actions</th>
                        </tr>

                        {contentRender}
                      </tbody>
                    </table>
                  </div>
                </TabPanel>
              </div>
            </Tabs>
            <div className="pagination-container">
              <Pagination
                activePage={this.state.page}
                itemsCountPerPage={this.state.limit}
                totalItemsCount={this.state.totalResults}
                pageRangeDisplayed={15}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
          </>
        ) : (
          ""
        )}
        {this.state.modal ? <Modals show={true} /> : ""}
        {this.state.resp ? (
          <>
            {this.state.createvideo == 1 ? (
              <CreateVideo resp={this.state.resp} />
            ) : (
              ""
            )}
            {this.state.createimage == 1 ? (
              <CreateImage resp={this.state.resp} />
            ) : (
              ""
            )}
            {this.state.createmcq == 1 ? (
              <CreateMcq resp={this.state.resp} />
            ) : (
              ""
            )}
            {this.state.createpoll == 1 ? (
              <CreatePoll resp={this.state.resp} />
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}

        {this.state.preview == 1 ? (
          <>
            <div class="overlay-black"></div>
            <div class="modal-popup thrive_skills modal_Thrive">
              <div
                class="close_btn"
                onClick={() => this.setState({ preview: 0 })}
              >
                <i class="fa fa-close"></i>{" "}
              </div>
              <div class="modal_body_section">
                <div class="add_video_modal">
                  <div class="video_text">
                    <p>
                      <b>{this.state.resp.data.name}</b>
                    </p>
                    <button type="button" class="btn btn_video">
                      {this.state.resp.data.nuggetType}
                    </button>
                  </div>

                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <div class="video_section video-left">
                        <div class="duration_content">
                          <p>Duration of content:</p>
                          <p>
                            <b>{this.state.resp.data.duration} seconds</b>
                          </p>
                        </div>
                        <div class="used_text">
                          {/* <p>Used in (3 pathways):</p> */}
                          <p>
                            <b>{this.state.resp.data.description}</b>
                          </p>
                        </div>

                        <div class="add_videos">
                          <p>{this.state.resp.data.nuggetType}:</p>{" "}
                          {this.state.resp.data.nuggetType == "IMAGE" ? (
                            <img
                              src={this.state.resp.data.nuggetImage.url}
                              alt="video"
                            />
                          ) : (
                            ""
                          )}
                          {this.state.resp.data.nuggetType == "VIDEO" ? (
                            <ReactHlsPlayer
                              controls
                              src={this.state.resp.data.nuggetVideo.url}
                            />
                          ) : (
                            ""
                          )}
                          {this.state.resp.data.nuggetType == "MCQ" ? (
                            <div class="row">
                              {/* Preview */}
                              <div class="col-md-12 mcq-prev-box">
                                <div
                                  class="available_info text-center mcq_texts main-scroll"
                                  // style={bg}
                                >
                                  <h5>
                                    {
                                      this.state.resp.data.nuggetMCQ.mcq
                                        .question
                                    }
                                  </h5>
                                  <p>
                                    {
                                      this.state.resp.data.nuggetMCQ.mcq
                                        .instructionTxt
                                    }
                                  </p>
                                  <div class="info_mcq">
                                    {this.state.resp.data.nuggetMCQ.mcq.options.map(
                                      function (item, i) {
                                        return (
                                          <>
                                            <label class="container mcq_choose_text">
                                              {item.option}
                                              <input
                                                type="radio"
                                                name="radio"
                                              />{" "}
                                              <span class="checkmark"></span>{" "}
                                            </label>
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {this.state.resp.data.nuggetType == "POLL" ? (
                            <div class="row">
                              {/* Preview */}
                              <div class="col-md-12 mcq-prev-box">
                                <div
                                  class="available_info text-center mcq_texts main-scroll"
                                  // style={bg}
                                >
                                  <h5>
                                    {
                                      this.state.resp.data.nuggetPOLL.poll
                                        .question
                                    }
                                  </h5>
                                  <p>
                                    {
                                      this.state.resp.data.nuggetPOLL.poll
                                        .instructionTxt
                                    }
                                  </p>
                                  <div class="info_mcq">
                                    {this.state.resp.data.nuggetPOLL.poll.options.map(
                                      function (item, i) {
                                        return (
                                          <>
                                            <label class="container mcq_choose_text">
                                              {item.option}
                                              <input
                                                type="radio"
                                                name="radio"
                                              />{" "}
                                              <span class="checkmark"></span>{" "}
                                            </label>
                                          </>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12 video-right">
                      {/* <div class="no_of_slides">
                        <p>No. of Slides:</p>
                        <p>
                          <b>3</b>
                        </p>
                      </div> */}
                      <div class="faculties">
                        <p>Faculties:</p>
                        {this.state.resp.data.facultys.map(function (item, i) {
                          return (
                            <>
                              <img src={item.img} title={item.name} alt="img" />{" "}
                            </>
                          );
                        })}
                      </div>
                      <div class="thrive_skill">
                        <p>Thrive skills tags:</p>
                        {this.state.resp.data.thriveSkills.map(function (
                          item,
                          i
                        ) {
                          return (
                            <>
                              <button type="button" class="btn btn_skills">
                                {item.name}
                              </button>
                            </>
                          );
                        })}
                      </div>
                      <div class="thrive_skill custom_tag hidden_class">
                        <p>Custom tags:</p>
                        {this.state.resp.data.thriveSkills.map(function (
                          item,
                          i
                        ) {
                          return (
                            <>
                              <button type="button" class="btn btn_skills">
                                {item.name}
                              </button>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default Content;
