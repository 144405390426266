import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import ReactDragListView from "react-drag-listview";
import SearchInput from "react-search-input";

class CreateQuestion extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      options: this.props.resp
        ? this.props.resp.data.options
        : [{ option: "", correct: false, feedback: "", score: 0 }],
      tag: [],
      name: this.props.resp ? this.props.resp.data.name : "",

      instructionTxt: this.props.resp ? this.props.resp.data.instruction : "",

      question: this.props.resp ? this.props.resp.data.question : "",

      feedback: this.props.resp ? this.props.resp.data.feedbackType : "GENERIC",
      feedbackText: this.props.resp ? this.props.resp.data.feedback : "",
      feedbackCorrect: this.props.resp
        ? this.props.resp.data.feedbackCorrect
        : "",
      feedbackInCorrect: this.props.resp
        ? this.props.resp.data.feedbackInCorrect
        : "",
      search: "",
      create: 1,
      preview: 0,
      heading: this.props.resp ? this.props.resp.data.intro.heading : "",
      ifimage: this.props.resp
        ? this.props.resp.data.intro.urlType == "IMAGE"
          ? false
          : true
        : false,

      title: this.props.resp ? this.props.resp.data.title : "",
      contextual: this.props.resp ? this.props.resp.data.intro.contextual : "",
      image: this.props.resp ? this.props.resp.data.intro.url : "",
    };
  }

  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };

  instructionTxtHandleChange = (event) => {
    this.setState({ instructionTxt: event.target.value });
  };
  questionHandleChange = (event) => {
    this.setState({ question: event.target.value });
  };
  feedbackHandleChange = (event) => {
    this.setState({ feedback: event.target.value });
  };
  feedbackTextHandleChange = (event) => {
    this.setState({ feedbackText: event.target.value });
  };
  feedbackInCorrectHandleChange = (event) => {
    this.setState({ feedbackInCorrect: event.target.value });
  };
  feedbackCorrectHandleChange = (event) => {
    this.setState({ feedbackCorrect: event.target.value });
  };

  componentDidMount() {}

  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");
      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "gaQuestions/" + this.props.resp.data.id,
            {
              name: this.state.name,
              question: this.state.question,
              feedback: this.state.feedbackText,
              feedbackType: this.state.feedback,
              feedbackCorrect: this.state.feedbackCorrect,
              feedbackInCorrect: this.state.feedbackInCorrect,

              instruction: this.state.instructionTxt,
              options: this.state.options,
              intro: {
                heading: this.state.heading,
                url: this.state.image,
                urlType: this.state.ifimage ? "VIDEO" : "IMAGE",
                contextual: this.state.contextual,
              },
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Question Updated");
              this.setState({ create: 0 });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      } else {
        axios
          .post(
            Constants.api + "gaQuestions",
            {
              name: this.state.name,
              question: this.state.question,
              feedback: this.state.feedbackText,
              feedbackType: this.state.feedback,
              feedbackCorrect: this.state.feedbackCorrect,
              feedbackInCorrect: this.state.feedbackInCorrect,
              instruction: this.state.instructionTxt,
              options: this.state.options,
              intro: {
                heading: this.state.heading,
                url: this.state.image,
                urlType: this.state.ifimage ? "VIDEO" : "IMAGE",
                contextual: this.state.contextual,
              },
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Question Added");

              this.setState({
                name: "",
                question: "",
                instructionTxt: "",
                options: [
                  { option: "", correct: false, feedback: "", score: 0 },
                ],
                feedbackText: "",
              });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };
  headingHandleChange = (event) => {
    this.setState({ heading: event.target.value });
  };
  imageHandleChange = (event) => {
    this.setState({ image: event.target.value });
  };

  ifimage() {
    this.setState({ ifimage: !this.state.ifimage });
  }
  contextualHandleChange = (event) => {
    this.setState({ contextual: event.target.value });
  };
  handleOptionChange(i, e) {
    let options = this.state.options;
    options[i][e.target.name] = e.target.value;
    this.setState({ options });
  }
  handleCorrectChange(i) {
    let options = this.state.options;
    options[i]["correct"] = !options[i]["correct"];
    this.setState({ options });
  }
  addFormFields() {
    this.setState({
      options: [
        ...this.state.options,
        { option: "", correct: false, feedback: "", score: 0 },
      ],
    });
  }

  removeFormFields(i) {
    let options = this.state.options;
    options.splice(i, 1);
    this.setState({ options });
  }

  render() {
    if (this.state.create == 0) {
      window.location = "/question";
    }

    //Dragging
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const options = [...that.state.options];
        const item = options.splice(fromIndex, 1)[0];
        options.splice(toIndex, 0, item);

        that.setState({ options });
      },
      nodeSelector: "li",
      handleSelector: ".mcq_arrow",
    };

    // Thriskill Render
    // const thriveSkillRender = (() => {
    //   return (
    //     <>
    //       hello
    //     </>
    //   )
    // });

    return (
      <>
        <div class="add_video_section add_video">
          {/* Bread crumbs */}
          <div class="faculty_sections">
            <div class="Faculty_text">
              <p>
                <span>Create Questions</span>
              </p>
            </div>
          </div>
          {/* Second Section */}
          <div class="faculty_section">
            <div class="faculty_text">
              <h3>
                <i
                  class="fa fa-arrow-left"
                  aria-hidden="true"
                  onClick={() => this.setState({ create: 0 })}
                ></i>{" "}
                Create Questions
              </h3>{" "}
            </div>
            <div class="btn_create">
              <button
                type="submit"
                onClick={this.handleFormSubmit}
                class="btn btn-primary btn_category btn_vidios btn-save-1"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                Save
              </button>
              <button
                onClick={() => this.setState({ create: 0 })}
                type="button"
                class="btn btn-save btn-save_close"
              >
                Close
              </button>
            </div>
          </div>
          {/* Progress */}
          <ol class="track-progress">
            <li class="done">
              <div class="blue-dot"></div> <span>Create</span>{" "}
            </li>
            <li class="todo">
              <div class="blue-dot"></div> <span>Preview</span>{" "}
            </li>
          </ol>

          {/* Main Content */}
          <div class="mcq_section ">
            <div class="container video_sections">
              <div class="row">
                {/* Preview */}
                <div class="col-md-4 main-scroll">
                  <div
                    class="available_info text-center mcq_texts"
                    // style={bg}
                  >
                    <h5>{this.state.question}</h5>
                    <p>{this.state.instructionTxt}</p>
                    <div class="info_mcq">
                      {this.state.options.map(function (item, i) {
                        return (
                          <>
                            <label class="container mcq_choose_text">
                              {item.option}
                              <input type="radio" name="radio" />{" "}
                              <span class="checkmark"></span>{" "}
                            </label>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {/* Content */}
                <div class="col-md-8 main-scroll">
                  {/* Headin Section */}
                  <div class="template_vidio_name">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="template_name image-template-name">
                          <div>
                            <div class="template_sections">
                              <div class="template_text">
                                <p class="info-template">Heading</p>
                              </div>
                              <div class="number-range">
                                <p>{this.state.heading.length}/40</p>
                              </div>
                            </div>
                            <div class="input_section">
                              <div class="input_text">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Heading"
                                  name="heading"
                                  value={this.state.heading}
                                  onChange={this.headingHandleChange}
                                  maxLength="100"
                                  id=""
                                />{" "}
                              </div>
                            </div>
                          </div>

                          <div>
                            <div class="template_sections">
                              <div class="template_text">
                                <p class="info-template">
                                  {this.state.ifimage ? "Video" : "Image"} Url
                                </p>
                              </div>
                              <div class="custom-control custom-switch">
                                <input
                                  type="checkbox"
                                  onClick={() => this.ifimage()}
                                  checked={this.state.ifimage}
                                  class="custom-control-input"
                                  id="customSwitches1"
                                />
                                <label
                                  class="custom-control-label"
                                  for="customSwitches1"
                                >
                                  Image/Video
                                </label>
                              </div>
                            </div>
                            <div class="input_section">
                              <div class="input_text">
                                <input
                                  type="text"
                                  class="form-control heading_input"
                                  id=""
                                  name="image"
                                  value={this.state.image}
                                  onChange={this.imageHandleChange}
                                  placeholder="Paste your link here"
                                />{" "}
                              </div>
                            </div>
                          </div>

                          <div>
                            <div class="template_sections">
                              <div class="template_text">
                                <p class="info-template">Contextual Text</p>
                              </div>
                              <div class="number-range">
                                <p>{this.state.contextual.length}/300</p>
                              </div>
                            </div>
                            <div class="input_section">
                              <div class="input_text">
                                <textarea
                                  class="form-control summernote_from"
                                  id=""
                                  rows="3"
                                  name="contextual"
                                  value={this.state.contextual}
                                  onChange={this.contextualHandleChange}
                                  maxLength="300"
                                ></textarea>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Feedback */}
                  <div class="template_vidio_name">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="template_name image-template-name">
                          <p>Feedback Type*</p>
                          <div class="mcq_text">
                            <div class="select_from">
                              <select
                                class="js-example-basic-multipleones form-control "
                                name="feedback"
                                onChange={this.feedbackHandleChange}
                                value={this.state.feedback}
                                placeholder="Type here"
                              >
                                {" "}
                                <i
                                  class="fa fa-plus-square-o"
                                  aria-hidden="true"
                                ></i>{" "}
                                <option value="">Select</option>
                                <option value="GENERIC">GENERIC</option>
                                <option value="OPTIONWISE">
                                  Option Specific
                                </option>
                                <option value="CORRECT-INCORRECT">
                                  Correct
                                </option>
                              </select>
                              {this.validator.message(
                                "feedback",
                                this.state.feedback,
                                "required|string|max:250"
                              )}
                              <ion-icon name="caret-up-outline"></ion-icon>
                              <ion-icon name="caret-down-outline"></ion-icon>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.state.feedback == "GENERIC" ? (
                      <div class="row">
                        <div class="col-md-12">
                          <div class="template_name image-template-name">
                            <div>
                              <div class="template_sections">
                                <div class="template_text">
                                  <p class="info-template">Feedback</p>
                                </div>
                                <div class="number-range">
                                  <p>{this.state.feedbackText.length}/300</p>
                                </div>
                              </div>
                              <div class="input_section">
                                <div class="input_text">
                                  <textarea
                                    class="form-control summernote_from"
                                    id=""
                                    rows="3"
                                    maxLength="300"
                                    name="feedbackText"
                                    value={this.state.feedbackText}
                                    maxLength="300"
                                    onChange={this.feedbackTextHandleChange}
                                  ></textarea>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.feedback == "CORRECT-INCORRECT" ? (
                      <div class="row">
                        <div class="col-md-12">
                          <div class="template_name image-template-name">
                            <div>
                              <div class="template_sections">
                                <div class="template_text">
                                  <p class="info-template">
                                    Feedback of Correct
                                    {/* <img
                                    src={
                                      require("../../img/ionic-ios-information-circle-outline@2x.png")
                                        .default
                                    }
                                    alt="img_circle"
                                    class="img_line"
                                  /> */}
                                  </p>
                                </div>
                                <div class="number-range">
                                  <p>{this.state.feedbackCorrect.length}/230</p>
                                </div>
                              </div>
                              <div class="input_section">
                                <div class="input_text">
                                  <textarea
                                    class="form-control summernote_from"
                                    id=""
                                    rows="3"
                                    name="feedbackCorrect"
                                    value={this.state.feedbackCorrect}
                                    maxLength="230"
                                    onChange={this.feedbackCorrectHandleChange}
                                  ></textarea>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="template_name image-template-name">
                              <div>
                                <div class="template_sections">
                                  <div class="template_text">
                                    <p class="info-template">
                                      Feedback of InCorrect
                                      {/* <img
                                    src={
                                      require("../../img/ionic-ios-information-circle-outline@2x.png")
                                        .default
                                    }
                                    alt="img_circle"
                                    class="img_line"
                                  /> */}
                                    </p>
                                  </div>
                                  <div class="number-range">
                                    <p>
                                      {this.state.feedbackInCorrect.length}/230
                                    </p>
                                  </div>
                                </div>
                                <div class="input_section">
                                  <div class="input_text">
                                    <textarea
                                      class="form-control summernote_from"
                                      id=""
                                      rows="3"
                                      name="feedbackInCorrect"
                                      value={this.state.feedbackInCorrect}
                                      maxLength="230"
                                      onChange={
                                        this.feedbackInCorrectHandleChange
                                      }
                                    ></textarea>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* POLL */}
                  <div class="template_vidio_name">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="template_name image-template-name">
                          <div>
                            <div class="template_sections">
                              <div class="template_text">
                                <p class="info-template">
                                  Template Name
                                  {/* <img
                                    src={
                                      require("../../img/ionic-ios-information-circle-outline@2x.png")
                                        .default
                                    }
                                    alt="img_circle"
                                    class="img_line"
                                  /> */}
                                </p>
                              </div>
                              <div class="number-range">
                                <p>{this.state.name.length}/100</p>
                              </div>
                            </div>
                            <div class="input_section">
                              <div class="input_text">
                                <input
                                  type="text"
                                  class="form-control heading_input"
                                  id=""
                                  name="name"
                                  value={this.state.name}
                                  onChange={this.nameHandleChange}
                                  maxLength="100"
                                  placeholder="Name"
                                />{" "}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="template_sections">
                              <div class="template_text">
                                <p class="info-template">Question</p>
                              </div>
                              <div class="number-range">
                                <p>{this.state.question.length}/280</p>
                              </div>
                            </div>
                            <div class="input_section">
                              <div class="input_text">
                                <textarea
                                  class="form-control summernote_from"
                                  id=""
                                  rows="3"
                                  name="question"
                                  placeholder="Question"
                                  value={this.state.question}
                                  onChange={this.questionHandleChange}
                                  maxLength="280"
                                ></textarea>{" "}
                              </div>
                            </div>
                          </div>

                          <div>
                            <div class="template_sections">
                              <div class="template_text">
                                <p class="info-template">
                                  Instruction Text
                                  {/* <img
                                    src={
                                      require("../../img/ionic-ios-information-circle-outline@2x.png")
                                        .default
                                    }
                                    alt="img_circle"
                                    class="img_line"
                                  /> */}
                                </p>
                              </div>
                              <div class="number-range">
                                <p>{this.state.instructionTxt.length}/150</p>
                              </div>
                            </div>
                            <div class="input_section">
                              <div class="input_text">
                                <input
                                  type="text"
                                  class="form-control heading_input"
                                  id=""
                                  name="instructionTxt"
                                  value={this.state.instructionTxt}
                                  onChange={this.instructionTxtHandleChange}
                                  maxLength="150"
                                  placeholder="Instruction Text"
                                />{" "}
                              </div>
                            </div>
                          </div>

                          <div>
                            <ReactDragListView {...dragProps}>
                              <ul style={{ listStyleType: "none" }}>
                                {this.state.options.map((element, index) => (
                                  <>
                                    <li key={index}>
                                      <div class="row add-option-container">
                                        <div class="col-md-9 p_l_26">
                                          <div class="faculty_sections Upload_info add_option">
                                            <div class="faculty_text">
                                              <p>Add Option {index + 1}*</p>
                                            </div>
                                            <div class="number summernote_number">
                                              <p>{element.option.length}/124</p>
                                            </div>
                                          </div>
                                          <div class="form-group">
                                            <textarea
                                              class="form-control"
                                              id=""
                                              rows="3"
                                              name="option"
                                              placeholder="Option"
                                              value={element.option || ""}
                                              onChange={(e) =>
                                                this.handleOptionChange(
                                                  index,
                                                  e
                                                )
                                              }
                                              maxLength="124"
                                            ></textarea>
                                          </div>
                                          {this.state.feedback ==
                                          "OPTIONWISE" ? (
                                            <div class="form-group">
                                              <textarea
                                                class="form-control"
                                                id=""
                                                rows="3"
                                                name="feedback"
                                                placeholder="Feedback"
                                                value={element.feedback || ""}
                                                onChange={(e) =>
                                                  this.handleOptionChange(
                                                    index,
                                                    e
                                                  )
                                                }
                                                maxLength="100"
                                              ></textarea>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>

                                        <div class="col-md-2">
                                          {/* <div class="col-md-4"> */}
                                          <p>Correct</p>
                                          {element.correct ? (
                                            <div
                                              class="btn btn_mcq btn_mcq_active"
                                              style={{
                                                backgroundColor: "#5ac06c26",
                                              }}
                                              onClick={() =>
                                                this.handleCorrectChange(index)
                                              }
                                            >
                                              {" "}
                                              <i
                                                class="fa fa-check"
                                                aria-hidden="true"
                                              ></i>{" "}
                                            </div>
                                          ) : (
                                            <div
                                              class="btn btn_mcq move-btn"
                                              onClick={() =>
                                                this.handleCorrectChange(index)
                                              }
                                            >
                                              {" "}
                                              <i
                                                class="fa fa-check"
                                                aria-hidden="true"
                                              ></i>{" "}
                                            </div>
                                          )}
                                          {/* </div> */}
                                          {index > 0 ? (
                                            <div
                                              class="col-md-4"
                                              onClick={() =>
                                                this.removeFormFields(index)
                                              }
                                            >
                                              <p>Remove</p>
                                              <div class="btn btn_mcq">
                                                <i
                                                  class="fa fa-times"
                                                  aria-hidden="true"
                                                ></i>{" "}
                                              </div>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        <div class="col-md-1 move-btn">
                                          {" "}
                                          <i
                                            class="fa fa-arrows-alt  mcq_arrow"
                                            aria-hidden="true"
                                          ></i>{" "}
                                        </div>
                                        <div className="score-input">
                                          <div class="temp-bot">
                                            <div class="template_sections">
                                              <div class="template_text">
                                                <p class="info-template">
                                                  Score
                                                  <div class="form-group">
                                                    <input
                                                      type="number"
                                                      class="form-control"
                                                      name="score"
                                                      value={
                                                        element.score || ""
                                                      }
                                                      onChange={(e) =>
                                                        this.handleOptionChange(
                                                          index,
                                                          e
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* <div class="row-full">
                                            <p>Score</p>
                                            <div class="form-group">
                                              <input
                                                type="number"
                                                class="form-control"
                                                name="score"
                                                value={element.score || ""}
                                                onChange={(e) =>
                                                  this.handleOptionChange(
                                                    index,
                                                    e
                                                  )
                                                }
                                              />
                                            </div>
                                          </div> */}
                                      </div>
                                    </li>
                                  </>
                                ))}
                              </ul>
                            </ReactDragListView>
                          </div>

                          <div>
                            <div class="template_sections">
                              <div class="template_text">
                                <div class="input_section">
                                  <div class="input_text">
                                    <button
                                      type="button"
                                      class="btn btn-primary btn_add_mcq "
                                      onClick={() => this.addFormFields()}
                                    >
                                      Add More Options
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Content Ended */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  addFaculty(item) {
    if (this.state.addedFaculty.some((val) => item.name === val.name) === false)
      this.state.addedFaculty.push(item);
    this.state.faculties = [];
    this.state.facultySearchTerm = "";
  }
  removeFaculty(item) {
    this.state.addedFaculty.splice(this.state.addedFaculty.indexOf(item), 1);
  }

  facultyUpdated(term) {
    const token = localStorage.getItem("token");
    if (term.length > 0) {
      axios
        .get(
          Constants.api +
            "facultys?name=" +
            term +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=10&page=" +
            this.state.page,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            if (this.state.addedFaculty.length != 0) {
              this.state.addedFaculty.forEach((faculty) => {
                console.log(this.state.addedThriveSkill.length);
                let index = res.data.results.indexOf(faculty);
                const newFaculty = res.data.results;
                newFaculty.splice(index, 1);
                this.setState({ faculties: newFaculty });
              });
            } else {
              this.setState({ faculties: res.data.results });
            }
          }
        });
      this.setState({ facultySearchTerm: term });
    } else {
      this.setState({
        faculties: [],
      });
    }
  }

  addThriveSkill(item) {
    if (
      this.state.addedThriveSkill.some((val) => item.name === val.name) ===
      false
    )
      this.state.addedThriveSkill.push(item);
    this.state.thriveSkills = [];
    this.state.thriveskillSearchTerm = "";
  }
  removeThriveSkill(item) {
    this.state.addedThriveSkill.splice(
      this.state.addedThriveSkill.indexOf(item),
      1
    );
  }

  thriveSkillUpdated(term) {
    const token = localStorage.getItem("token");
    if (term.length > 0) {
      axios
        .get(
          Constants.api +
            "thriveSkills?name=" +
            term +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=10&page=" +
            this.state.page,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            if (this.state.addedThriveSkill.length != 0) {
              this.state.addedThriveSkill.forEach((thriveskill) => {
                console.log(this.state.addedThriveSkill.length);
                let index = res.data.results.indexOf(thriveskill);
                const newThriveskill = res.data.results;
                newThriveskill.splice(index, 1);
                this.setState({ thriveSkills: newThriveskill });
              });
            } else {
              this.setState({ thriveSkills: res.data.results });
            }
          }
        });
      this.setState({ thriveskillSearchTerm: term });
    } else {
      this.setState({
        contthriveSkillsents: [],
      });
    }
  }
}

export default CreateQuestion;
