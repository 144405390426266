import React, { Component, useEffect, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Modal } from "react-bootstrap";

class Modals extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();

    // this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
    // this.state = { editorState: EditorState.createEmpty() };
    // this.oneditorChange = (editorState) => this.setState({ editorState });
  }
  state = {
    show: this.props.show,
  };
  render() {
    return (
      <Modal
        show={this.state.show}
        animation={false}
        backdrop="static"
        class="col-md-12"
      >
        <Modal.Header>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            {" "}
            <span
              aria-hidden="true"
              onClick={() => this.setState({ show: false })}
            >
              ×
            </span>{" "}
          </button>
          <h5 class="text-center modal_heading">Choose Content Type</h5>
        </Modal.Header>
        <Modal.Body>
          <div class="multimedia_body">
            <div class="multimedia_section">
              <div class="row" style={{ marginTop: "10px" }}>
                <div class="col-md-6 modal-options">
                  <a href="/content/createvideo">
                    <div class="content_type">
                      <div class="content_img">
                        {" "}
                        <img
                          src={require("../../img/videocam_black.png").default}
                        />{" "}
                      </div>
                      <div class="content_text">
                        <h5>Multimedia</h5>
                      </div>
                    </div>
                  </a>
                </div>

                <div class="col-md-6 modal-options">
                  <a href="/content/createimage">
                    <div class="content_type">
                      <div class="content_img">
                        {" "}
                        <img
                          src={require("../../img/image_black.png").default}
                        />{" "}
                      </div>
                      <div class="content_text">
                        <h5>Image</h5>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div class="row" style={{ marginTop: "10px" }}>
                <div class="col-md-6 modal-options">
                  <a href="/content/createpoll">
                    <div class="content_type">
                      <div class="content_img">
                        {" "}
                        <img
                          src={require("../../img/bar_chart.png").default}
                        />{" "}
                      </div>
                      <div class="content_text">
                        <h5>Poll</h5>{" "}
                      </div>
                    </div>
                  </a>
                </div>
                <div class="col-md-6 modal-options">
                  <a href="/content/createmcq">
                    <div class="content_type">
                      <div class="content_img">
                        {" "}
                        <img
                          src={require("../../img/checklist.png").default}
                        />{" "}
                      </div>
                      <div class="content_text">
                        <h5>MCQ</h5>{" "}
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
export default Modals;
