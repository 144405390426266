import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import ReactDragListView from "react-drag-listview";
import SearchInput from "react-search-input";
import Switch from "react-switch";

class CreatePathway extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    // this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
    // this.state = { editorState: EditorState.createEmpty() };
    // this.oneditorChange = (editorState) => this.setState({ editorState });
    this.categoryHandleChange = this.categoryHandleChange.bind(this);
    this.state = {
      sortBy: "name:asc",
      name: this.props.resp ? this.props.resp.data.output.name : "",
      title: this.props.resp ? this.props.resp.data.output.title : "",
      image: this.props.resp ? this.props.resp.data.output.thumbnail : "",
      description: this.props.resp
        ? this.props.resp.data.output.description
        : "",
      video: this.props.resp ? this.props.resp.data.output.teaserVideo : "",
      certificate: this.props.resp
        ? this.props.resp.data.output.certificate
        : false,
      recommend: this.props.resp
        ? this.props.resp.data.output.recommend
        : false,
      homeWeb: this.props.resp ? this.props.resp.data.output.homeWeb : false,
      howHelp: this.props.resp
        ? this.props.resp.data.output.howHelp
        : [
            { heading: "", description: "" },
            { heading: "", description: "" },
            { heading: "", description: "" },
          ],
      faqs: this.props.resp
        ? this.props.resp.data.output.faqs
        : [{ question: "", answer: "" }],

      category: this.props.resp ? this.props.resp.data.output.category : "",
      limit: 1000,
      page: 1,
      categories: [],
      create: 1,
      lesson: this.props.resp ? this.props.resp.data.output.lesson : [],
      selectedLesson: this.props.resp ? this.props.resp.data.output.lesson : [],
      lessons: [],
      alllessons: [],
      searchTerm: "",

      ga: this.props.resp ? this.props.resp.data.output.gradedAssess : {},
      //this.props.resp ? this.props.resp.data.output.gradedAssess :
      addedGA: [],
      gas: [],

      gaSearchTerm: "",

      addedThriveSkill: [],
      thriveSkills: [],
      thriveskillSearchTerm: "",
      metatitle: this.props.resp ? this.props.resp.data.output.metatitle : "",
      metakeywords: this.props.resp
        ? this.props.resp.data.output.metakeywords
        : "",
      metadescription: this.props.resp
        ? this.props.resp.data.output.metadescription
        : "",
    };
    this.searchUpdated = this.searchUpdated.bind(this);
    this.gaUpdated = this.gaUpdated.bind(this);
    this.thriveSkillUpdated = this.thriveSkillUpdated.bind(this);
  }

  // state = {

  // };
  metadescriptionHandleChange = (event) => {
    this.setState({ metadescription: event.target.value });
  };
  metatitleHandleChange = (event) => {
    this.setState({ metatitle: event.target.value });
  };
  metakeywordsHandleChange = (event) => {
    this.setState({ metakeywords: event.target.value });
  };
  descriptionHandleChange = (event) => {
    this.setState({ description: event.target.value });
  };
  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  titleHandleChange = (event) => {
    this.setState({ title: event.target.value });
  };
  imageHandleChange = (event) => {
    this.setState({ image: event.target.value });
  };
  videoHandleChange = (event) => {
    this.setState({ video: event.target.value });
  };
  categoryHandleChange = (event) => {
    this.setState({ category: event.target.value });
  };
  certificateChange = () => {
    this.setState({ certificate: !this.state.certificate }, this.remGa);
  };
  recommendChange = () => {
    this.setState({ recommend: !this.state.recommend });
  };
  homeWebHandleChange = () => {
    this.setState({ homeWeb: !this.state.homeWeb });
  };
  remGa = () => {
    if (!this.state.certificate) {
      this.state.addedGA = [];
    }
  };

  componentDidMount() {
    if (this.props.resp) {
      let ts = [];
      ts.push(this.props.resp.data.output.thriveskill);
      if (this.props.resp.data.output.thriveskill)
        this.setState({ addedThriveSkill: ts });
    }
    if (this.props.resp) {
      if (this.props.resp.data.output.gradedAssess) {
        let ga = [];
        ga.push(this.props.resp.data.output.gradedAssess);

        this.setState({ addedGA: ga });
      }
    }

    const categoriesRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        const resp = await axios
          .get(
            Constants.api +
              "categorys?sortBy=" +
              this.state.sortBy +
              "&limit=" +
              this.state.limit +
              "&page=" +
              this.state.page,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              console.log(resp.data.results);
              this.setState({ categories: resp.data.results });
              console.log(this.state.categories);
            } else {
            }
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    categoriesRequest();
  }

  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      this.validator.hideMessages();
      let selectedLesson = [];
      let options = this.state.selectedLesson;
      options.map(function (item, i) {
        selectedLesson.push(item.id);
        //Do something
      });
      const token = localStorage.getItem("token");
      let obj = {};
      if (this.state.certificate) {
        obj = {
          name: this.state.name,
          category: this.state.category,
          title: this.state.title,
          certificate: this.state.certificate,
          recommend: this.state.recommend,
          gradedAssess:
            this.state.addedGA.length > 0 ? this.state.addedGA[0]["id"] : "",
          description: this.state.description,
          metadescription: this.state.metadescription,
          metatitle: this.state.metatitle,
          metakeywords: this.state.metakeywords,
          thumbnail: this.state.image,
          teaserVideo: this.state.video,
          howHelp: this.state.howHelp,
          homeWeb: this.state.homeWeb,
          faqs: this.state.faqs,
          status: "DRAFT",
          lesson: selectedLesson,
          thriveskill:
            this.state.addedThriveSkill.length > 0
              ? this.state.addedThriveSkill[0]["id"]
              : "",
        };
      } else {
        obj = {
          name: this.state.name,
          category: this.state.category,
          title: this.state.title,
          certificate: this.state.certificate,
          recommend: this.state.recommend,
          description: this.state.description,
          thumbnail: this.state.image,
          teaserVideo: this.state.video,
          howHelp: this.state.howHelp,
          homeWeb: this.state.homeWeb,
          metadescription: this.state.metadescription,
          metatitle: this.state.metatitle,
          metakeywords: this.state.metakeywords,
          faqs: this.state.faqs,
          status: "DRAFT",
          lesson: selectedLesson,
          thriveskill:
            this.state.addedThriveSkill.length > 0
              ? this.state.addedThriveSkill[0]["id"]
              : "",
        };
      }
      if (this.state.addedThriveSkill.length == 0) {
        delete obj.thriveskill;
      }
      if (this.props.resp) {
        console.log(this.props.resp);
        axios
          .patch(
            Constants.api + "pathways/" + this.props.resp.data.output.id,
            obj,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Pathway Updated");
              this.setState({ create: 0 });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      } else {
        axios
          .post(
            Constants.api + "pathways",

            obj,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Pathway Added");
              this.setState({
                name: "",
                title: "",
                image: "",
                video: "",
                description: "",
                howHelp: [
                  { heading: "", description: "" },
                  { heading: "", description: "" },
                  { heading: "", description: "" },
                ],
                faqs: [{ question: "", answer: "" }],
                selectedLesson: [],
                category: "",
                addedThriveSkill: [],
                addedGA: [],
              });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };

  // How Help
  handleHowHelpChange(i, e) {
    let howHelp = this.state.howHelp;
    howHelp[i][e.target.name] = e.target.value;
    this.setState({ howHelp });
  }
  addHowHelp() {
    this.setState({
      howHelp: [...this.state.howHelp, { heading: "", description: "" }],
    });
  }
  removeHowHelp(i) {
    let howHelp = this.state.howHelp;
    howHelp.splice(i, 1);
    this.setState({ howHelp });
  }

  // FAQ
  addFaq() {
    this.setState({
      faqs: [...this.state.faqs, { question: "", answer: "" }],
    });
  }
  removeFaq(i) {
    let faqs = this.state.faqs;
    faqs.splice(i, 1);
    this.setState({ faqs });
  }
  handleFaqChange(i, e) {
    let faqs = this.state.faqs;
    faqs[i][e.target.name] = e.target.value;
    this.setState({ faqs });
  }

  render() {
    //  console.log(this.props.resp);
    if (this.state.create == 0) {
      window.location = "/pathway";
    }
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const selectedLesson = [...that.state.selectedLesson];
        const item = selectedLesson.splice(fromIndex, 1)[0];
        selectedLesson.splice(toIndex, 0, item);
        that.setState({ selectedLesson });
      },
      nodeSelector: "li",
      handleSelector: "ion-icon",
    };
    return (
      <>
        <div class="faculty_section faculty_list">
          <div class="Faculty_text">
            <p>
              <span class="faculty_text">Pathway Listing </span>/
              <span>{this.props.resp ? "Edit" : "New"} Pathway Builder</span>
            </p>
          </div>
        </div>
        <div class="main-head pathway-head pad_30">
          <h3>
            <i
              class="fa fa-arrow-left"
              aria-hidden="true"
              onClick={() => this.setState({ create: 0 })}
            ></i>{" "}
            {this.props.resp ? "Edit" : "New"} Pathway builder
          </h3>
          <div class="btn-right">
            {/* <button type="button" class="btn btn-edit">
                <ion-icon name="pencil"></ion-icon>
              </button> */}
            <button
              type="submit"
              onClick={this.handleFormSubmit}
              class="btn  btn-create btn-save_cretate pathway-add-save-btn"
            >
              Save
            </button>
            <button
              type="button"
              onClick={() => this.setState({ create: 0 })}
              class="btn btn-primary btn_category"
              data-toggle="modal"
              data-target="#exampleModal"
            >
              Close
            </button>
          </div>
        </div>
        <ol class="track-progress">
          <li class="done">
            <div class="blue-dot"></div>
            <span>Create</span>
          </li>
          <li class="todo">
            <div class="blue-dot"></div>
            <span>Preview</span>
          </li>
        </ol>
        <div class="main-scroll main-content pad_30">
          <div class="pathway-builder-form">
            <div class="pathway-builder-form faqs-container">
              <div class="form-group ">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="switch-row">
                      <label>
                        <span>Recommended</span>

                        <Switch
                          onChange={this.recommendChange}
                          class="form-control"
                          checked={this.state.recommend}
                        />
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <label>
                      <span>Home Web</span>
                      <Switch
                        onChange={this.homeWebHandleChange}
                        class="form-control"
                        checked={this.state.homeWeb}
                      />
                    </label>
                  </div>
                  <div class="col-sm-4">
                    <label>
                      <span>Certificate</span>
                      <Switch
                        onChange={this.certificateChange}
                        class="custom-control-input"
                        checked={this.state.certificate}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <label for="exampleFormControlInput1">
              Choose a category for the pathway*
            </label>
            <div class="inline-form">
              <select
                class="form-control"
                name="category"
                value={this.state.category}
                onChange={this.categoryHandleChange}
                id="exampleFormControlSelect1"
              >
                <option>Category</option>
                <option value="Goal Based">Goal Based</option>
                <option value="Skill Based">Skill Based</option>
                <option value="Trigger Based (workplace trigger)">
                  Trigger Based (workplace trigger)
                </option>
              </select>
              {/* <a
                    href="/categories"
                    type="button"
                    class="btn  btn-create btn-save btn-save_close pathway-add-next-btn"
                  >
                    Add More Categories
                  </a> */}
            </div>

            <div class="form-group">
              <label for="inputAddress">
                Give the pathway a title*{" "}
                <ion-icon name="information-circle-outline"></ion-icon>
                <span class="text-limit">{this.state.title.length}/50</span>
              </label>
              <input
                type="text"
                class="form-control"
                id="inputAddress"
                name="title"
                maxlength="100"
                value={this.state.title}
                onChange={this.titleHandleChange}
              />
            </div>
            {this.validator.message("Title", this.state.title, "required")}
            <div class="form-group">
              <label for="inputAddress">
                Give the pathway a template name*{" "}
                <ion-icon name="information-circle-outline"></ion-icon>{" "}
                <span class="text-limit">{this.state.name.length}/100</span>
              </label>
              <input
                type="text"
                class="form-control"
                id="inputAddress"
                name="name"
                value={this.state.name}
                onChange={this.nameHandleChange}
              />
            </div>
            {this.validator.message("Name", this.state.name, "required")}
            <div class="form-group">
              <label for="exampleFormControlTextarea1">
                Write a short description to convey to learners what they will
                learn from the pathway*{" "}
                <span class="text-limit">
                  {this.state.description.length}/500
                </span>
              </label>
              <textarea
                class="form-control"
                name="description"
                value={this.state.description}
                onChange={this.descriptionHandleChange}
                rows="3"
                maxLength="500"
              ></textarea>
              {this.validator.message(
                "Description",
                this.state.description,
                "required"
              )}
            </div>
          </div>
          <div class="pathway-builder-form">
            <div class="form-group-container">
              <div class="form-group left-group">
                <label for="exampleFormControlInput1">
                  Thumbnail Image Link
                </label>
                <div class="inline-form">
                  <input
                    type="text"
                    class="form-control"
                    id="inputAddress"
                    placeholder="Paste your link here"
                    name="image"
                    value={this.state.image}
                    onChange={this.imageHandleChange}
                  />
                </div>

                <div class="preview-box">
                  {this.state.image != "" ? (
                    <img src={this.state.image} width="100px" />
                  ) : (
                    <span class="na">No preview</span>
                  )}
                </div>
              </div>
              <div class="form-group right-group">
                <label for="exampleFormControlInput1">Teaser Video Link</label>
                <div class="inline-form">
                  <input
                    type="text"
                    class="form-control"
                    id="inputAddress"
                    name="video"
                    value={this.state.video}
                    onChange={this.videoHandleChange}
                    placeholder="Paste your link here"
                  />
                </div>
                <div class="preview-box">
                  {this.state.video != "" ? (
                    <video
                      src={this.state.video}
                      width="250px"
                      class="na"
                      controls
                    ></video>
                  ) : (
                    <span class="na">No preview</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div class="pathway-builder-form heading-form">
            <div class="form-group">
              <label class="mar-20">
                Add minimum 3 points to be displayed on the pathway page in the
                "How will this pathway help you" section
              </label>
              {this.state.howHelp.map((element, index) => (
                <>
                  <div class="remove-head">
                    <div class="inline-form">
                      <label for="exampleFormControlInput1">
                        Heading {index + 1}*{" "}
                        <span class="text-limit">
                          {element.heading.length}/35
                        </span>
                      </label>
                      {index >= 3 ? (
                        <div class="remove-btn-container">
                          <label>Remove</label>
                          <button
                            class="btn btn-remove"
                            onClick={() => this.removeHowHelp(index)}
                          >
                            {" "}
                            <span>+</span>{" "}
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <input
                      type="text"
                      class="form-control"
                      id="inputAddress"
                      maxLength="35"
                      name="heading"
                      value={element.heading || ""}
                      onChange={(e) => this.handleHowHelpChange(index, e)}
                    />
                  </div>
                  {this.validator.message(
                    "Heading",
                    element.heading,
                    "required"
                  )}

                  <div class="inline-form-full">
                    <label for="exampleFormControlInput1">
                      Description*{" "}
                      <span class="text-limit">
                        {element.description.length}/85
                      </span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="inputAddress"
                      maxLength="85"
                      name="description"
                      value={element.description || ""}
                      onChange={(e) => this.handleHowHelpChange(index, e)}
                    />
                  </div>
                  {this.validator.message(
                    "Description",
                    element.description,
                    "required"
                  )}
                </>
              ))}
              <div>
                <button
                  type="button"
                  onClick={() => this.addHowHelp()}
                  class="btn btn-create upload-btn"
                >
                  Add More
                </button>
              </div>
            </div>
          </div>
          <div class="pathway-builder-form">
            <form>
              <div class="form-group">
                <label class="mar-20 bold-label">Lessons*</label>
                <div class="inline-form-full">
                  <SearchInput
                    class="form-control collapse-search"
                    id="inputAddress"
                    onChange={this.searchUpdated}
                    placeholder="Type to add Lesson"
                  />
                  {this.state.lessons.map((lesson) => {
                    return (
                      <>
                        <div>
                          <div class="card card-body">
                            <a href="#" class="search-body-view">
                              <div class="search-body-view-part">
                                <span class="search-body-view-part-name">
                                  {" "}
                                  {lesson.name}
                                </span>
                              </div>
                              <div class="search-body-view-part">
                                <div class="highlight-cell highlight-gray">
                                  {lesson.title}
                                </div>
                              </div>
                              <div class="search-body-view-last">
                                <div
                                  class="box-c box-blue"
                                  onClick={() => this.addLesson(lesson)}
                                >
                                  <i class="fa fa-plus" aria-hidden="true"></i>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </>
                    );
                  })}

                  {/* Listing of Added Content */}
                  {this.state.selectedLesson.length > 0 ? (
                    <div class="added-content-container">
                      <label>Added Content</label>
                      <ReactDragListView {...dragProps}>
                        <ul style={{ listStyleType: "none" }}>
                          {this.state.selectedLesson.map((lesson, index) => (
                            <li key={index}>
                              <div class="search-body-view search-body-view-box">
                                <div class="search-body-view-part">
                                  <span class="search-body-view-part-name">
                                    {lesson.name}
                                  </span>
                                </div>
                                <div class="search-body-view-part">
                                  <div class="highlight-cell highlight-blue">
                                    {lesson.title}
                                  </div>
                                </div>
                                <div class="search-body-view-last">
                                  <a
                                    href="#"
                                    onClick={() => this.removeLesson(lesson)}
                                  >
                                    Remove
                                  </a>
                                  <ion-icon name="checkmark-outline">
                                    <div class="col-md-1">
                                      {" "}
                                      <i
                                        class="fa fa-arrows-alt  mcq_arrow"
                                        aria-hidden="true"
                                      ></i>{" "}
                                    </div>
                                  </ion-icon>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </ReactDragListView>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </form>
          </div>
          {/* FAQ */}
          <div class="pathway-builder-form faqs-container">
            <div class="form-group">
              <label class="mar-20">Add FAQs</label>
              {this.state.faqs.map((element, index) => (
                <>
                  <div class="form-group-container mar-20">
                    <div class="inline-form-full">
                      <label for="exampleFormControlInput1">
                        Question{" "}
                        <span class="text-limit">
                          {element.question.length}/150
                        </span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputAddress"
                        name="question"
                        maxLength="150"
                        value={element.question || ""}
                        onChange={(e) => this.handleFaqChange(index, e)}
                      />
                    </div>
                    <div class="inline-form-full">
                      <label for="exampleFormControlInput1">
                        Answer{" "}
                        <span class="text-limit">
                          {element.answer.length}/500
                        </span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="inputAddress"
                        name="answer"
                        maxLength="500"
                        value={element.answer || ""}
                        onChange={(e) => this.handleFaqChange(index, e)}
                      />
                    </div>
                    <div
                      class="remove-btn-container"
                      onClick={() => this.removeFaq(index)}
                    >
                      <label>Remove</label>
                      <button class="btn btn-remove">
                        {" "}
                        <span>+</span>{" "}
                      </button>
                    </div>
                  </div>
                </>
              ))}

              <div class="bottom-btn-container">
                <button
                  type="button"
                  onClick={() => this.addFaq()}
                  class="btn btn-create upload-btn"
                >
                  Add More
                </button>
              </div>
            </div>
          </div>
          {/* Graded Assessment */}
          <div class="pathway-builder-form faqs-container">
            <div class="form-group">
              {this.state.certificate ? (
                <div class="certification-div">
                  <div class="template_sections">
                    <div class="template_text">
                      <p>Enter Graded Assesment</p>
                    </div>
                  </div>
                  {this.state.addedGA.length == 0 ? (
                    <>
                      <div class="input_section">
                        <div class="input_text">
                          <SearchInput
                            class="form-control collapse-search"
                            id="facultyInput"
                            onChange={this.gaUpdated}
                            placeholder="Type to add Graded Assessment"
                            value={this.state.gaSearchTerm}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      {this.state.gas.map((item) => {
                        return (
                          <>
                            <div class="dropdown_img">
                              <a
                                class="dropdown-item"
                                onClick={() => this.addGA(item)}
                                href="#"
                              >
                                <span class="text_vidio_name">{item.name}</span>{" "}
                                {/* <span aria-hidden="true">×</span> */}
                              </a>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    ""
                  )}
                  <div class="">
                    {this.state.addedGA.map((item, index) => (
                      <>
                        <a class="dropdown-item drop-img" href="#">
                          <span class="text_vidio_name">{item.name}</span>{" "}
                          <span
                            aria-hidden="true"
                            onClick={() => this.removeGA()}
                          >
                            <i class="fa fa-times" aria-hidden="true"></i>
                          </span>
                        </a>
                      </>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {/* Add Thrive Skills */}
            <div class="certification-div">
              <div class="template_text">
                <p>Enter Thrive Skills Tag</p>
              </div>
            </div>
            {this.state.addedThriveSkill.length == 0 ? (
              <div class="input_section">
                <div class="input_text">
                  <SearchInput
                    class="form-control collapse-search"
                    id="thriveskillInput"
                    onChange={this.thriveSkillUpdated}
                    placeholder="Type to add Thrive Skill"
                    value={this.state.thriveskillSearchTerm}
                    autoComplete="off"
                  />
                </div>
              </div>
            ) : (
              ""
            )}

            {this.state.thriveSkills.map((item) => {
              return (
                <>
                  <div class="dropdown_img">
                    <a
                      class="dropdown-item"
                      onClick={() => this.addThriveSkill(item)}
                      href="#"
                    >
                      <span class="text_vidio_name">{item.name}</span>{" "}
                      {/* <span aria-hidden="true">×</span> */}
                    </a>
                  </div>
                </>
              );
            })}
            <div class="">
              {this.state.addedThriveSkill.map((item, index) => (
                <>
                  <a
                    class="dropdown-item drop-img"
                    href="#"
                    onClick={() => this.removeThriveSkill(item)}
                  >
                    <span class="text_vidio_name">{item.name}</span>{" "}
                    <span aria-hidden="true">
                      {" "}
                      <i class="fa fa-times" aria-hidden="true"></i>{" "}
                    </span>
                  </a>
                </>
              ))}
            </div>
          </div>
          {/*meta*/}
          <div class="inline-form-full">
            <label for="exampleFormControlInput1">
              Meta Title*{" "}
              <span class="text-limit"> {this.state.metatitle.length}/100</span>
            </label>
            <input
              type="text"
              class="form-control"
              id="inputAddress"
              maxLength="100"
              name="metatitle"
              value={this.state.metatitle}
              onChange={this.metatitleHandleChange}
            />
          </div>
          <div class="inline-form-full">
            <label for="exampleFormControlInput1">
              Meta Keywords*{" "}
              <span class="text-limit">
                {this.state.metakeywords.length}/500
              </span>
            </label>
            <input
              type="text"
              class="form-control"
              id="inputAddress"
              maxLength="500"
              name="metakeywords"
              value={this.state.metakeywords}
              onChange={this.metakeywordsHandleChange}
            />
          </div>
          <div class="inline-form-full">
            <label for="exampleFormControlInput1">
              Meta Description*{" "}
              <span class="text-limit">
                {" "}
                {this.state.metadescription.length}/500
              </span>
            </label>
            <input
              type="text"
              class="form-control"
              id="inputAddress"
              maxLength="500"
              name="metadescription"
              value={this.state.metadescription}
              onChange={this.metadescriptionHandleChange}
            />
          </div>
        </div>
        <div class="main-preview hidden_class">
          <div class="preview-page">
            <span class="na">Preview of front end final design</span>
          </div>
        </div>
      </>
    );
  }

  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };

  addLesson(lesson) {
    if (
      this.state.selectedLesson.some((val) => lesson.name === val.name) ===
      false
    ) {
      this.state.selectedLesson.push(lesson);
      this.state.lessons.splice(this.state.lessons.indexOf(lesson), 1);
    }
    console.log(this.state.lessons);
  }
  removeLesson(lesson) {
    this.state.selectedLesson.splice(
      this.state.selectedLesson.indexOf(lesson),
      1
    );
  }

  searchUpdated(term) {
    const token = localStorage.getItem("token");
    if (term.length > 0) {
      axios
        .get(
          Constants.api +
            "lessons?title=" +
            term +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=10&page=" +
            this.state.page,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.setState({ lessons: res.data.results });
            console.log(this.state.lessons);
          }
        });
      this.setState({ searchTerm: term });
    } else {
      this.setState({
        lessons: [],
      });
    }
  }

  addGA(item) {
    if (this.state.addedGA.some((val) => item.name === val.name) === false)
      this.state.addedGA.push(item);
  }
  removeGA() {
    this.state.addedGA = [];
  }

  gaUpdated(term) {
    const token = localStorage.getItem("token");
    if (term.length > 0) {
      axios
        .get(
          Constants.api +
            "gradedAssesss?name=" +
            term +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=10&page=" +
            this.state.page,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.setState({ gas: res.data.results });
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          }
        });
      this.setState({ gaSearchTerm: term });
    } else {
      this.setState({
        faculties: [],
      });
    }
  }

  addThriveSkill(item) {
    if (
      this.state.addedThriveSkill.some((val) => item.name === val.name) ===
      false
    )
      this.state.addedThriveSkill.push(item);
    this.state.thriveSkills = [];
    this.state.thriveskillSearchTerm = "";
  }
  removeThriveSkill(item) {
    this.state.addedThriveSkill.splice(
      this.state.addedThriveSkill.indexOf(item),
      1
    );
  }

  thriveSkillUpdated(term) {
    const token = localStorage.getItem("token");
    if (term.length > 0) {
      axios
        .get(
          Constants.api +
            "thriveSkills?name=" +
            term +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=10&page=" +
            this.state.page,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            if (this.state.addedThriveSkill.length != 0) {
              this.state.addedThriveSkill.forEach((thriveskill) => {
                console.log(this.state.addedThriveSkill.length);
                let index = res.data.results.indexOf(thriveskill);
                const newThriveskill = res.data.results;
                // newThriveskill.splice(index, 1);
                this.setState({ thriveSkills: newThriveskill });
              });
            } else {
              this.setState({ thriveSkills: res.data.results });
            }
          }
        });
      this.setState({ thriveskillSearchTerm: term });
    } else {
      this.setState({
        contthriveSkillsents: [],
      });
    }
  }
}

export default CreatePathway;
