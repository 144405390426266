import React, { Component } from "react";
import * as Constants from "../../Constants/index";

import axios from "axios";
import * as QueryString from "query-string";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import CreateTag from "./createTag.component";
import moment from "moment";

class Tags extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    // this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
  }

  state = {
    name: "",
    sortBy: "name:asc",
    limit: 25,
    page: 1,
    template: [],
    search: "",
    create: 0,
  };

  componentDidMount() {
    const tsRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        //console.log(token);
        let url = "";
        this.state.search != ""
          ? (url =
              "tags?" +
              "category=" +
              this.state.search +
              "&sortBy=" +
              this.state.sortBy +
              "&limit=" +
              this.state.limit +
              "&page=" +
              this.state.page)
          : (url =
              "tags?sortBy=" +
              this.state.sortBy +
              "&limit=" +
              this.state.limit +
              "&page=" +
              this.state.page);
        const resp = await axios
          .get(Constants.api + url, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              console.log(resp.data.results);
              this.setState({ template: resp.data.results });
              console.log(this.state.template);
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    tsRequest();
  }
  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount
    );
  };
  render() {
    let search = this.state.search;

    const editItem = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "tags/" + id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              console.log(resp);
              this.setState({ create: 1, resp: resp });
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const deleteItem = (id) => {
      if (window.confirm("Are you sure?")) {
        try {
          const token = localStorage.getItem("token");
          const deletedata = new FormData();
          deletedata.append("id", id); //append the values with key, value pair

          axios
            .delete(
              Constants.api + "tags/" + id,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
              deletedata
            )
            .then((resp) => {
              //  casetype array

              if (resp.status == 204) {
                this.componentDidMount();
              } else {
              }
              //movie array
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
    };
    const templateRender = this.state.template.map(function (item, i) {
      return (
        <>
          <tr>
            <td>{moment(item.createdAt).format("MM/DD/YY H:ma")}</td>
            <td>{moment(item.updatedAt).format("MM/DD/YY H:ma")}</td>
            <td>{item.superCategory}</td>
            <td class="edit-delete">
              <i
                class="fa fa-pencil"
                onClick={() => editItem(item.id)}
                aria-hidden="true"
              ></i>{" "}
              <i
                class="fa fa-trash"
                onClick={() => deleteItem(item.id)}
                aria-hidden="true"
              ></i>
            </td>
          </tr>
        </>
      );
    });
    return this.state.create == 0 ? (
      <>
        <div class="main-head pathway-head pad_30">
          <h3>Today Tag</h3>
          <button
            type="button"
            class="btn  btn-create btn-save_cretate pathway-save-btn"
            onClick={() => this.setState({ create: 1 })}
          >
            Create New
          </button>
        </div>
        <Tabs className="nav-div">
          <div className="pad_30">
            <TabPanel className="tab-pane">
              <div class="listing-info">
                <div class="listing-left">
                  <div class="search-bar">
                    <input
                      type="search"
                      name="search"
                      value={this.state.search}
                      onChange={this.searchHandleChange}
                      id="search"
                      class="search-pathway"
                      placeholder="Search"
                    />
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="listing-right">
                  <div class="from_dropdown">
                    <label for="show">Show entries</label>
                    <select name="cars" id="show">
                      <option
                        value="25"
                        onClick={
                          (() => this.setState({ limit: 25 }),
                          () => this.componentDidMount())
                        }
                      >
                        25
                      </option>
                      <option
                        value="50"
                        onClick={
                          (() => this.setState({ limit: 50 }),
                          () => this.componentDidMount())
                        }
                      >
                        50
                      </option>
                      <option
                        value="75"
                        onClick={
                          (() => this.setState({ limit: 75 }),
                          () => this.componentDidMount())
                        }
                      >
                        75
                      </option>
                      <option
                        value="100"
                        onClick={
                          (() => this.setState({ limit: 100 }),
                          () => this.componentDidMount())
                        }
                      >
                        100
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="main-scroll-table">
                <table cellspacing="0" class="pathway-table">
                  <tbody>
                    <tr>
                      {/* <th width="10%">
                        <input
                          type="checkbox"
                          name="select-all"
                          id="select-all"
                        />
                      </th> */}
                      <th width="15%">Created On</th>
                      <th width="15%">Modified On</th>
                      <th width="25%">Super Category</th>

                      <th width="25%">Action</th>
                    </tr>

                    {templateRender}
                  </tbody>
                </table>
              </div>
            </TabPanel>
          </div>
        </Tabs>
      </>
    ) : this.state.resp ? (
      <CreateTag resp={this.state.resp} />
    ) : (
      <CreateTag />
    );
  }
}
export default Tags;
