import React, { Component } from "react";
import * as Constants from "../../Constants/index";

import axios from "axios";
import * as QueryString from "query-string";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import CreateTodaySection from "./createtodaysection.component";
import moment from "moment";

class TodaySection extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    // this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
  }

  state = {
    name: "",
    sortBy: "name:asc",
    limit: 10,
    page: 1,
    pathway: [],
    search: "",
    create: 0,
  };

  componentDidMount() {
    const tsRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        //console.log(token);
        let url = "";
        this.state.search != ""
          ? (url =
              "todayMerchandises?" +
              "name=" +
              this.state.search +
              "&sortBy=" +
              this.state.sortBy +
              "&limit=" +
              this.state.limit +
              "&page=" +
              this.state.page)
          : (url =
              "todayMerchandises?sortBy=" +
              this.state.sortBy +
              "&limit=" +
              this.state.limit +
              "&page=" +
              this.state.page);
        const resp = await axios
          .get(Constants.api + url, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              console.log(resp.data.results);
              this.setState({ pathway: resp.data.results });
              console.log(this.state.pathway);
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    tsRequest();
  }

  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount
    );
  };
  render() {
    const activateItem = (id, status, active) => {
      try {
        const token = localStorage.getItem("token");

        axios
          .put(
            Constants.api +
              "todayMerchandises/" +
              id +
              "?status=" +
              status +
              "&active=" +
              active,
            "",

            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((resp) => {
            //  casetype array
            console.log(resp);
            if (resp.status == 200) {
              swal(status + " Succesfully!");
              window.location = "/today-section";
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const editItem = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "todayMerchandises/" + id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              console.log(resp);
              this.setState({ create: 1, resp: resp });
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const deleteItem = (id) => {
      if (window.confirm("Are you sure?")) {
        try {
          const token = localStorage.getItem("token");
          const deletedata = new FormData();
          deletedata.append("id", id); //append the values with key, value pair

          axios
            .delete(
              Constants.api + "todayMerchandises/" + id,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
              deletedata
            )
            .then((resp) => {
              //  casetype array

              if (resp.status == 204) {
                this.componentDidMount();
              } else {
              }
              //movie array
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
    };

    let search = this.state.search;
    let pathwayRender = this.state.pathway.map(function (item, i) {
      return (
        <>
          <tr>
            <td>{moment(item.createdAt).format("MM/DD/YY H:ma")}</td>
            <td>{moment(item.updatedAt).format("MM/DD/YY H:ma")}</td>
            <td>{item.title}</td>
            <td class="edit-delete">
              <i
                class="fa fa-pencil"
                onClick={() => editItem(item.id)}
                aria-hidden="true"
              ></i>{" "}
              <i
                class="fa fa-trash"
                onClick={() => deleteItem(item.id)}
                aria-hidden="true"
              ></i>
            </td>
            <td class="resend_link">
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="form-control"
                  id="customSwitchesfive"
                  checked={item.active}
                  onClick={() =>
                    activateItem(item.id, "PUBLISHED", !item.active)
                  }
                />
                <label class="custom-control-label" for="customSwitchesfive">
                  Activate
                </label>
              </div>
            </td>{" "}
          </tr>
        </>
      );
    });

    return this.state.create == 0 ? (
      <>
        <div class="main-head pathway-head pad_30">
          <h3>Today Section</h3>
          <button
            type="button"
            class="btn  btn-create btn-save_cretate pathway-save-btn"
            onClick={() => this.setState({ create: 1 })}
          >
            Create New
          </button>
        </div>
        <Tabs className="nav-div">
          <div className="pad_30">
            <TabPanel className="tab-pane">
              <div class="listing-info">
                <div class="listing-left">
                  <div class="search-bar">
                    <input
                      type="search"
                      name="search"
                      value={this.state.search}
                      onChange={this.searchHandleChange}
                      id="search"
                      class="search-pathway"
                      placeholder="Search"
                    />
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="listing-right">
                  Show entries
                  <button
                    class="btn-entries dropdown-toggle show-entries"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    25
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a class="dropdown-item" href="#">
                      Action
                    </a>
                    <a class="dropdown-item" href="#">
                      Another action
                    </a>
                    <a class="dropdown-item" href="#">
                      Something else here
                    </a>
                  </div>
                </div>
              </div>
              <div className="main-scroll-table">
                <table cellspacing="0" class="pathway-table">
                  <tbody>
                    <tr>
                      {/* <th width="10%">
                        <input
                          type="checkbox"
                          name="select-all"
                          id="select-all"
                        />
                      </th> */}
                      <th width="15%">Created On</th>
                      <th width="15%">Modified On</th>
                      <th width="35%">Title</th>
                      <th width="25%">Type</th>
                      <th width="10%">Actions</th>
                    </tr>

                    {pathwayRender}
                  </tbody>
                </table>
              </div>
            </TabPanel>
            <TabPanel className="tab-pane">
              <div class="listing-info">
                <div class="listing-left">
                  <select name="filter-by" id="filter" class="filter-pathway">
                    <option value="" disable>
                      Filter By
                    </option>
                  </select>
                  <div class="search-bar">
                    <input
                      type="search"
                      name="search"
                      id="search"
                      class="search-pathway"
                      placeholder="Search"
                    />
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="listing-right">
                  Show entries
                  <button
                    class="btn-entries dropdown-toggle show-entries"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    25
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a class="dropdown-item" href="#">
                      Action
                    </a>
                    <a class="dropdown-item" href="#">
                      Another action
                    </a>
                    <a class="dropdown-item" href="#">
                      Something else here
                    </a>
                  </div>
                </div>
              </div>
              <table cellspacing="0" class="main-scroll-table pathway-table">
                <tbody>
                  <tr>
                    <th width="80%">Title</th>
                    <th width="20%">Actions</th>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        name="select-all"
                        id="select-all"
                      />
                    </td>

                    <td>Being a better public speaker</td>
                    <td>
                      <p class="highlight-cell highlight-gray">Draft</p>
                    </td>
                    <td class="edit-delete">
                      <i class="fa fa-pencil" aria-hidden="true"></i>{" "}
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        name="select-all"
                        id="select-all"
                      />
                    </td>

                    <td>Being a better public speaker</td>
                    <td>
                      <p class="highlight-cell highlight-blue">Draft</p>
                    </td>
                    <td class="edit-delete">
                      <i class="fa fa-pencil" aria-hidden="true"></i>{" "}
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        name="select-all"
                        id="select-all"
                      />
                    </td>
                    <td>Being a better public speaker</td>
                    <td>
                      <p class="highlight-cell highlight-blue">Draft</p>
                    </td>
                    <td class="edit-delete">
                      <i class="fa fa-pencil" aria-hidden="true"></i>{" "}
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        name="select-all"
                        id="select-all"
                      />
                    </td>
                    <td>Being a better public speaker</td>
                    <td>
                      <p class="highlight-cell highlight-blue">Draft</p>
                    </td>
                    <td class="edit-delete">
                      <i class="fa fa-pencil" aria-hidden="true"></i>{" "}
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TabPanel>
          </div>
        </Tabs>
      </>
    ) : this.state.resp ? (
      <CreateTodaySection resp={this.state.resp} />
    ) : (
      <CreateTodaySection />
    );
  }
}

export default TodaySection;
