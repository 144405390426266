import React, { Component } from "react";
import axios from "axios";
import swal from "sweetalert";
// import ShowTime from '../show-time/ShowTime.component';
import * as Constants from "../../Constants/index";
import SimpleReactValidator from "simple-react-validator";

class Login extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
  }

  state = {
    email: "",
    password: "",
  };
  componentDidMount() {
    if (localStorage.getItem("userid") != null) {
      window.location.href = "/dashboard";
    }
  }
  handleLoginSubmit = async (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      try {
        const response = await axios
          .post(Constants.api + "auth/login", {
            email: this.state.email,
            password: this.state.password,
          })
          .then((res) => {
            if (res.status == 200) {
              console.log(res.data.tokens);
              swal("", "You Have Succesfully Logged In.", "success");
              localStorage.setItem("useremail", res.data.user.email);
              localStorage.setItem("userid", res.data.user.id);
              localStorage.setItem("token", res.data.tokens.access.token);
              localStorage.setItem("username", res.data.user.name);
              localStorage.setItem(
                "tokenexpiry",
                res.data.tokens.access.expires
              );
              localStorage.setItem("navbarMenu", res.data.sections);
              window.location = "/dashboard";
            } else {
              // this.setState({alert:res.data.msg});
              swal("User not found");
            }
          });
      } catch (e) {
        swal("Something went wrong");
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };

  emailhandleChange = (event) => {
    this.setState({ email: event.target.value });
  };

  passwordhandleChange = (event) => {
    this.setState({ password: event.target.value });
  };

  render() {
    return (
      <div class="login_from">
        <div class="container-fluid pl-0 ">
          <div class="row ">
            <div class="col-md-6">
              <div class="login_img">
                {" "}
                <img src={require("../../img/001@2x.png").default} />{" "}
              </div>
            </div>
            <div class="col-md-4">
              <form onSubmit={this.handleLoginSubmit}>
                <div class="login_text">
                  <h1>Welcome Back</h1>

                  <div class="form-group">
                    <label for="exampleInputEmail1">Email address</label>
                    <input
                      type="email"
                      class="form-control"
                      id=""
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                      name="email"
                      value={this.state.value}
                      onChange={this.emailhandleChange}
                    />{" "}
                    <small
                      id="emailHelp"
                      class="form-text text-muted hidden_text_login"
                    >
                      This email Id is not registered, enter again.
                    </small>{" "}
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1">Password</label>
                    <input
                      type="password"
                      class="form-control"
                      id=""
                      placeholder="Password"
                      name="password"
                      value={this.state.value}
                      onChange={this.passwordhandleChange}
                    />{" "}
                    <small
                      id="emailHelp"
                      class="form-text text-muted hidden_text_login"
                    >
                      Password didn't matched, enter again.
                    </small>{" "}
                  </div>
                  <div class="forgot_password">
                    <p>Forgot Password?</p>
                  </div>
                  <button
                    type="submit"
                    class="btn btn-primary btn_submit_login"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
