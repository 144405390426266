import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import ReactDragListView from "react-drag-listview";
import ReactDragListView2 from "react-drag-listview";

import SearchInput from "react-search-input";

class CreatePathwayMerch extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.categoryHandleChange = this.categoryHandleChange.bind(this);
    this.pathwayfoldsHandleChange = this.pathwayfoldsHandleChange.bind(this);
    this.categorySearchUpdated = this.categorySearchUpdated.bind(this);
    this.lessonSearchUpdated = this.lessonSearchUpdated.bind(this);

    this.state = {
      count: 0,
      template: [],
      listid: "",
      categories: [],
      pathcategories: [],
      ctaimage: "",
      category: "",
      limit: 100,
      create: 1,
      page: 1,
      sortBy: "name:asc",
      title: this.props.resp ? this.props.resp.data.title : "",
      status: "DRAFT",
      pathwayList: false,
      categoryList: false,
      pathwaytitle: "",
      pathwaycategory: "",
      pathwaydescription: "",
      pathwayText: "",
      pathwayfolds: 0,
      pathwayhighlight: false,
      pathwayListSaved: false,
      categorytitle: "",
      categorydescription: "",
      addedcategories: [],
      lessons: [],
      categoryhighlight: false,
      categoryListSaved: false,
      lessonListSaved: false,
      ctaList: false,
      lessonList: false,
      ctaListSaved: false,
      ctatitle: "",
      ctadescription: "",
      lessontitle: "",
      lessondescription: "",
      selectedLesson: [],
      ctatext: "",
      ctasubflow: "",
      searchcatTerm: "",
      pathwayseeall: 0,
      lessonseeall: 0,
      categoryseeall: 0,
      ctaseeall: 0,
    };
  }

  componentDidMount() {
    let template = this.state.template;
    if (this.props.resp) {
      console.log(this.props.resp.data.templates);

      for (
        let i = 1;
        i <= Object.keys(this.props.resp.data.templates).length;
        i++
      ) {
        template.push(this.props.resp.data.templates[i]);
      }
    }
    this.setState({ template });

    console.log(this.state.template);
    const categoriesRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        const resp = await axios
          .get(
            Constants.api +
              "categorys?sortBy=" +
              this.state.sortBy +
              "&limit=" +
              this.state.limit +
              "&page=" +
              this.state.page,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              console.log(resp.data.results);
              this.setState({ pathcategories: resp.data.results });
              console.log(this.state.pathcategories);
            } else {
            }
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    categoriesRequest();
  }
  //pathwaylisting
  categoryHandleChange = (event) => {
    this.setState({ pathwaycategory: event.target.value });
  };
  titleHandleChange = (event) => {
    this.setState({ title: event.target.value });
  };
  pathwaydescriptionHandleChange = (event) => {
    this.setState({ pathwaydescription: event.target.value });
  };
  pathwayfoldsHandleChange = (event) => {
    this.setState({ pathwayfolds: event.target.value });
  };
  pathwaytitleHandleChange = (event) => {
    this.setState({ pathwaytitle: event.target.value });
  };

  pathwayhighlightHandleChange = (event) => {
    this.setState({ pathwayhighlight: !this.state.pathwayhighlight });
  };
  pathwayseeallHandleChange = (event) => {
    this.setState({ pathwayseeall: event.target.value });
  };
  lessonseeallHandleChange = (event) => {
    this.setState({ lessonseeall: event.target.value });
  };
  categoryseeallHandleChange = (event) => {
    this.setState({ categoryseeall: event.target.value });
  };
  ctaseeallHandleChange = (event) => {
    this.setState({ ctaseeall: event.target.value });
  };

  pathwayListSaved = () => {
    this.setState({
      pathwayListSaved: true,
      pathwayList: false,
    });
    let pathwayObject = {
      type: "PathwayList",
      category: this.state.pathwaycategory,
      folds: this.state.pathwayfolds,
      title: this.state.pathwaytitle,
      highlight: this.state.pathwayhighlight,
    };

    this.pushtotemplate(pathwayObject);
  };
  categoryListSaved = () => {
    let addedcategories = [];
    let options = this.state.addedcategories;
    options.forEach((item) => {
      addedcategories.push(item.id);
      //Do something
    });
    this.setState({
      categoryListSaved: true,
      categoryList: false,
    });
    let categoryObject = {
      type: "CategoryList",
      title: this.state.categorytitle,
      description: this.state.categorydescription,
      categories: addedcategories,
      addedcategories: options,
    };

    this.pushtotemplate(categoryObject);
  };
  ctaListSaved = () => {
    this.setState({
      ctaListSaved: true,
      ctaList: false,
    });
    let ctaObject = {
      type: "CTA",
      image: this.state.ctaimage,
      title: this.state.ctatitle,
      description: this.state.ctadescription,
      ctaText: this.state.ctatext,
      subflow: this.state.ctasubflow,
    };

    this.pushtotemplate(ctaObject);
  };
  lessonListSaved = () => {
    let selectedlesson = [];
    let lessons = this.state.selectedLesson;
    lessons.forEach((item) => {
      selectedlesson.push(item.id);
      //Do something
    });
    this.setState({
      lessonListSaved: true,
      lessonList: false,
    });
    let lessonObject = {
      type: "LessonList",
      title: this.state.lessontitle,
      description: this.state.lessondescription,
      lesson: selectedlesson,
      selectedLesson: lessons,
    };

    this.pushtotemplate(lessonObject);
  };
  pushtotemplate = (obj) => {
    // let newNum = this.state.count + 1;
    // this.setState({ count: newNum });
    console.log(this.state.listid);
    if (this.state.listid >= 0 && this.state.listid != "") {
      let template = this.state.template;
      template[this.state.listid] = obj;
      this.setState({ template });
    } else {
      if (this.state.listid != "0") {
        let template = this.state.template;
        template.push(obj);
        this.setState({ template });
      } else {
        let template = this.state.template;
        template[this.state.listid] = obj;
        this.setState({ template });
      }
    }
    console.log(this.state.template);
    this.setState({
      pathwaytitle: "",
      pathwaycategory: "",
      pathwaydescription: "",
      pathwayText: "",
      pathwayfolds: 0,
      pathwayhighlight: false,
      pathwayListSaved: false,
      categorytitle: "",
      categorydescription: "",
      addedcategories: [],
      selectedlesson: [],
      ctaList: false,
      lessonList: false,
      ctaListSaved: false,
      ctatitle: "",
      ctadescription: "",
      lessontitle: "",
      lessondescription: "",
      selectedLesson: [],
      lessons: [],
      ctatext: "",
      ctasubflow: "",
      searchcatTerm: "",
      listid: "",
    });
    swal("Template Saved");
  };

  //categorylisitng
  addCategory(category) {
    if (
      this.state.addedcategories.some((val) => category.name === val.name) ===
      false
    ) {
      this.state.addedcategories.push(category);
      this.state.categories.splice(this.state.categories.indexOf(category), 1);
    }
    this.state.searchcatTerm = "";

    console.log(this.state.categories);
  }
  categorydescriptionHandleChange = (event) => {
    this.setState({ categorydescription: event.target.value });
  };
  categorytitleHandleChange = (event) => {
    this.setState({ categorytitle: event.target.value });
  };

  //ctalisting
  ctatitleHandleChange = (event) => {
    this.setState({ ctatitle: event.target.value });
  };
  ctadescriptionHandleChange = (event) => {
    this.setState({ ctadescription: event.target.value });
  };
  ctatextHandleChange = (event) => {
    this.setState({ ctatext: event.target.value });
  };
  ctasubflowHandleChange = (event) => {
    this.setState({ ctasubflow: event.target.value });
  };
  ctaimageHandleChange = (event) => {
    this.setState({ ctaimage: event.target.value });
  };

  //lessonlisting
  lessontitleHandleChange = (event) => {
    this.setState({ lessontitle: event.target.value });
  };
  lessondescriptionHandleChange = (event) => {
    this.setState({ lessondescription: event.target.value });
  };

  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      this.validator.hideMessages();

      const token = localStorage.getItem("token");
      var templatejson = {};
      for (var i = 0; i < this.state.template.length; ++i) {
        templatejson[i + 1] = this.state.template[i];
        // return templatejson;
      }
      let obj = {};
      obj = {
        title: this.state.title,
        version: 1,
        active: false,
        status: this.state.status,
        templates: templatejson,
      };

      if (this.props.resp) {
        console.log(this.props.resp);
        axios
          .patch(
            Constants.api + "pathMerchandises/" + this.props.resp.data.id,
            obj,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Pathway Merchandising Updated");
              this.setState({ create: 0 });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      } else {
        axios
          .post(
            Constants.api + "pathMerchandises",

            obj,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Pathway  Merchandise Added");
              window.location = "/pathway-merchandising";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };
  render() {
    if (this.state.create == 0) {
      window.location = "/pathway-merchandising";
    }
    const deletefromtemplate = (i) => {
      this.state.template.splice(i, 1);
    };
    const editLessonList = (listid, title, description, lesson, seeall) => {
      this.setState({
        lessontitle: title,
        lessondescription: description,
        selectedLesson: lesson,
        lessonList: true,
        listid: listid,
        lessonseeall: seeall,
      });
    };
    const editCategoryList = (
      listid,
      title,
      description,
      categories,
      seeall
    ) => {
      this.setState({
        categorytitle: title,
        categorydescription: description,
        addedcategories: categories,
        categoryList: true,
        listid: listid,
        categoryseeall: seeall,
      });
    };
    const editCtaList = (
      listid,
      title,
      image,
      description,
      ctaText,
      subflow,
      seeall
    ) => {
      this.setState({
        ctatitle: title,
        ctadescription: description,
        ctaimage: image,
        ctatext: ctaText,
        ctasubflow: subflow,
        ctaList: true,
        listid: listid,
        ctaseeall: seeall,
      });
    };
    const editPathwayList = (
      listid,
      title,
      category,
      description,
      highlight,
      folds,
      seeall
    ) => {
      this.setState({
        pathwaytitle: title,
        pathwaydescription: description,
        pathwaycategory: category,
        pathwayhighlight: highlight,
        pathwayfolds: folds,
        pathwayList: true,
        listid: listid,
        pathwayseeall: seeall,
      });
    };

    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const selectedLesson = [...that.state.selectedLesson];
        const item = selectedLesson.splice(fromIndex, 1)[0];
        selectedLesson.splice(toIndex, 0, item);
        that.setState({ selectedLesson });
      },
      nodeSelector: "li",
      handleSelector: "ion-icon",
    };
    const sectionDragProps = {
      onDragEnd(fromIndex, toIndex) {
        const template = [...that.state.template];
        const item = template.splice(fromIndex, 1)[0];
        template.splice(toIndex, 0, item);
        that.setState({ template });
      },
      nodeSelector: "li",
      handleSelector: ".fa-arrows-alt",
    };
    return (
      <>
        {this.state.pathwayList ? (
          <div class="overlay-container">
            <div class="overlay-black "></div>
            <div class="modal-popup ">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Pathway Listing{" "}
                  <ion-icon name="alert-circle-outline"></ion-icon>
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ pathwayList: false })}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="pathway-builder-form modal-form">
                  <div class="form-group">
                    <label>Title*</label>
                    <div class="listing-left">
                      <div class="input-group">
                        <input
                          type="text"
                          name="pathwaytitle"
                          onChange={this.pathwaytitleHandleChange}
                          value={this.state.pathwaytitle}
                          class="form-control"
                          placeholder="Type here"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Choose Category*</label>
                    <div class="listing-left">
                      <div class="input-group">
                        <select
                          class="custom-select filter-pathway"
                          name="category"
                          value={this.state.pathwaycategory}
                          onChange={this.categoryHandleChange}
                          id="exampleFormControlSelect1"
                        >
                          <option>Category</option>

                          {this.state.pathcategories.map(function (item, i) {
                            let sel = "selected";
                            return (
                              <>
                                <option value={item.id} sel>
                                  {item.name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </div>
                      {/* <div class="selected-box">
                        <div class="highlight-cell selected-item-modal border-selected">
                          <span>Graded Assessment</span>{" "}
                          <i class="fa fa-times" aria-hidden="true"></i>
                        </div>
                        <div class="highlight-cell selected-item-modal border-selected">
                          <span>Graded Assessment</span>{" "}
                          <i class="fa fa-times" aria-hidden="true"></i>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* <div class="form-group">
                    <label>
                      Category Description{" "}
                      <span class="text-limit">
                        {this.state.pathwaydescription.length}/150
                      </span>
                    </label>
                    <input
                      type="text"
                      name="pathwaydescription"
                      onChange={this.pathwaydescriptionHandleChange}
                      value={this.state.pathwaydescription}
                      class="form-control"
                      placeholder="Type here"
                    />
                  </div> */}
                  <div class="form-group">
                    <label>Select Number to Display on Fold*</label>
                    <div class="listing-left">
                      <div class="input-group">
                        <select
                          class="custom-select filter-pathway"
                          name="pathwayfolds"
                          onChange={this.pathwayfoldsHandleChange}
                          value={this.state.pathwayfolds}
                        >
                          <option selected="" disabled="">
                            Select Number
                          </option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                          <option value="4">Four</option>
                          <option value="5">Five</option>
                          <option value="6">Six</option>
                          <option value="7">Seven</option>
                          <option value="8">Eight</option>
                          <option value="9">Nine</option>
                          <option value="10">Ten</option>
                          <option value="11">Eleven</option>
                          <option value="12">Twelve</option>
                          <option value="13">Thirteen</option>
                          <option value="14">Fourteen</option>
                          <option value="15">Fifteen</option>
                          <option value="16">Sixteen</option>
                          <option value="17">Seventeen</option>
                          <option value="18">Eighteen</option>
                          <option value="19">Nineteen</option>
                          <option value="20">Twenty</option>
                        </select>
                      </div>

                      {/* <div class="selected-box">
                        <div class="highlight-cell selected-item-modal border-selected">
                          <span>Graded Assessment</span>{" "}
                          <i class="fa fa-times" aria-hidden="true"></i>
                        </div>
                        <div class="highlight-cell selected-item-modal border-selected">
                          <span>Graded Assessment</span>{" "}
                          <i class="fa fa-times" aria-hidden="true"></i>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* <!-- Default switch --> */}
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="customSwitches1"
                      checked={this.state.pathwayhighlight}
                      onChange={this.pathwayhighlightHandleChange}
                    />
                    <label class="custom-control-label" for="customSwitches1">
                      Highlight Fold
                    </label>
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  onClick={this.pathwayListSaved}
                  class="btn btn-create btn-save-modal"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.categoryList ? (
          <div class="overlay-container">
            <div class="overlay-black "></div>
            <div class="modal-popup ">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Category Listing
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => this.setState({ categoryList: false })}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="pathway-builder-form modal-form">
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1">
                      Title{" "}
                      <span class="text-limit">
                        {this.state.categorytitle.length}/30
                      </span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      name="categorytitle"
                      value={this.state.categorytitle}
                      onChange={this.categorytitleHandleChange}
                      placeholder="Type here"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1">
                      Description{" "}
                      <span class="text-limit">
                        {this.state.categorydescription.length}/150
                      </span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Type here"
                      name="categorydescription"
                      value={this.state.categorydescription}
                      onChange={this.categorydescriptionHandleChange}
                    />
                  </div>

                  <div class="form-group">
                    <label>Select Categories</label>
                    <div class="listing-left">
                      <div class="input-group">
                        <SearchInput
                          class="form-control collapse-search"
                          id="inputAddress"
                          autoComplete="off"
                          onChange={this.categorySearchUpdated}
                          placeholder="Type to add Category"
                        />
                        {this.state.categories.map((category) => {
                          return (
                            <>
                              <div class="input-group">
                                <div class="card card-body">
                                  <a href="#" class="search-body-view">
                                    <div class="search-body-view-part">
                                      <span class="search-body-view-part-name">
                                        {" "}
                                        {category.name}
                                      </span>
                                    </div>
                                    <div class="search-body-view-part"></div>
                                    <div class="search-body-view-last">
                                      <div
                                        class="box-c box-blue"
                                        onClick={() =>
                                          this.addCategory(category)
                                        }
                                      >
                                        <i class="fa fa-plus"></i>
                                      </div>
                                    </div>{" "}
                                  </a>
                                </div>
                              </div>
                            </>
                          );
                        })}
                        {this.state.addedcategories.length > 0 ? (
                          <div className="input-group">
                            <div class="added-content-container">
                              <label>Added Categories</label>
                              <ul style={{ listStyleType: "none" }}>
                                {this.state.addedcategories.map(
                                  (cat, index) => (
                                    <li key={index}>
                                      <div class="search-body-view-box">
                                        <div class="search-body-view-part">
                                          <span class="search-body-view-part-name">
                                            {cat.name}
                                          </span>
                                        </div>
                                        <a
                                          href="#"
                                          onClick={() =>
                                            this.removeCategory(cat)
                                          }
                                        >
                                          <i class="fa fa-close" />{" "}
                                        </a>
                                      </div>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      {/* <div class="selected-box">
                   <div class="highlight-cell selected-item-modal border-selected">
                     <span>Graded Assessment</span>{" "}
                     <i class="fa fa-times" aria-hidden="true"></i>
                   </div>
                   <div class="highlight-cell selected-item-modal border-selected">
                     <span>Graded Assessment</span>{" "}
                     <i class="fa fa-times" aria-hidden="true"></i>
                   </div>
                 </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-create btn-save-modal"
                  onClick={this.categoryListSaved}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.ctaList ? (
          <div class="overlay-container">
            <div class="overlay-black "></div>
            <div class="modal-popup">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  CTA Template
                </h5>
                <button
                  type="button"
                  class="close"
                  onClick={() => this.setState({ ctaList: false })}
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body cta-modal">
                <div class="pathway-builder-form modal-form">
                  <div class="form-group">
                    <div class="inline-form-nugget">
                      <div class="inline-form-nugget-part">
                        <label>Upload Thumbnail*</label>
                        <input
                          name="ctaimage"
                          value={this.state.ctaimage}
                          onChange={this.ctaimageHandleChange}
                        />
                      </div>
                      <div class="inline-form-nugget-part">
                        <div class="na-container">
                          {this.state.ctaimage != "" ? (
                            <img
                              src={this.state.ctaimage}
                              height="90px"
                              width="20px"
                              class="na"
                            />
                          ) : (
                            <span class="na">Preview not available</span>
                          )}
                        </div>
                        {/* <!-- <img src="https://images.pexels.com/photos/301926/pexels-photo-301926.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" alt=""> --> */}
                      </div>
                      <div class="inline-form-nugget-part">
                        {/* <!-- <label>Duration (in sec)</label>
                                  <input type="text" name=""> --> */}
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1">
                      Title*{" "}
                      <span class="text-limit">
                        {this.state.ctatitle.length}/30
                      </span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Type here"
                      name="ctatitle"
                      value={this.state.ctatitle}
                      onChange={this.ctatitleHandleChange}
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1">
                      Description{" "}
                      <span class="text-limit">
                        {this.state.ctadescription.length}/30
                      </span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Type here"
                      name="ctadescription"
                      value={this.state.ctadescription}
                      onChange={this.ctadescriptionHandleChange}
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleFormControlTextarea1">
                      CTA Text*{" "}
                      <span class="text-limit">
                        {this.state.ctatext.length}/30
                      </span>
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Type here"
                      name="ctatext"
                      value={this.state.ctatext}
                      onChange={this.ctatextHandleChange}
                    />
                  </div>
                  <div class="form-group">
                    <label>Subflows*</label>
                    <div class="listing-left">
                      <div class="input-group">
                        <select
                          class="custom-select filter-pathway"
                          onChange={this.ctasubflowHandleChange}
                          value={this.state.ctasubflow}
                        >
                          <option selected="" disabled="">
                            Select here
                          </option>
                          <option value="PA">Personal Assessment</option>
                          <option value="EP">Explore Premium</option>
                          <option value="AP">Explore Catalogue</option>
                          <option value="RP">Recommended Pathways</option>

                        </select>
                      </div>

                      {/* <div class="highlight-cell highlight-blue selected-item-modal">
                        <span>Graded Assessment</span>{" "}
                        <i class="fa fa-times" aria-hidden="true"></i>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  onClick={this.ctaListSaved}
                  class="btn btn-create btn-save-modal"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.lessonList ? (
          <>
            <div class="overlay-container">
              <div class="overlay-black "></div>
              <div class="modal-popup ">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Lesson
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.setState({ lessonList: false })}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="pathway-builder-form modal-form">
                    <div class="form-group">
                      <label for="exampleFormControlTextarea1">
                        Title{" "}
                        <span class="text-limit">
                          {this.state.lessontitle.length}/30
                        </span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="lessontitle"
                        value={this.state.lessontitle}
                        onChange={this.lessontitleHandleChange}
                        placeholder="Type here"
                      />
                    </div>
                    <div class="form-group">
                      <label for="exampleFormControlTextarea1">
                        Description{" "}
                        <span class="text-limit">
                          {this.state.lessondescription.length}/30
                        </span>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="lessondescription"
                        value={this.state.lessondescription}
                        onChange={this.lessondescriptionHandleChange}
                        placeholder="Type here"
                      />
                    </div>

                    <div class="form-group">
                      <label class="mar-20 bold-label">Choose Lessons*</label>
                      <div class="inline-form-full">
                        <SearchInput
                          class="form-control collapse-search"
                          id="inputAddress"
                          onChange={this.lessonSearchUpdated}
                          placeholder="Type to add Lesson"
                        />
                        {this.state.lessons.map((lesson) => {
                          return (
                            <>
                              <div>
                                <div class="card card-body">
                                  <a href="#" class="search-body-view">
                                    <div class="search-body-view-part">
                                      <span class="search-body-view-part-name">
                                        {" "}
                                        {lesson.title}
                                      </span>
                                    </div>
                                    <div class="search-body-view-part">
                                      <div class="highlight-cell highlight-gray">
                                        {lesson.contentUnits.length} Nuggets
                                      </div>
                                    </div>
                                    <div class="search-body-view-last">
                                      <div
                                        class="box-c box-blue"
                                        onClick={() => this.addLesson(lesson)}
                                      >
                                        <i
                                          class="fa fa-plus"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </>
                          );
                        })}

                        {/* Listing of Added Content */}
                        {this.state.selectedLesson.length > 0 ? (
                          <div class="added-content-container">
                            <label>Added Lessons</label>
                            <ReactDragListView2 {...dragProps}>
                              <ul style={{ listStyleType: "none" }}>
                                {this.state.selectedLesson.map(
                                  (lesson, index) => (
                                    <li key={index}>
                                      <div class="search-body-view search-body-view-box">
                                        <div class="search-body-view-part">
                                          <span class="search-body-view-part-name">
                                            {lesson.name}
                                          </span>
                                        </div>
                                        <div class="search-body-view-part">
                                          <div class="highlight-cell highlight-blue">
                                            {lesson.title}
                                          </div>
                                        </div>
                                        <div class="search-body-view-last">
                                          <a
                                            href="#"
                                            onClick={() =>
                                              this.removeLesson(lesson)
                                            }
                                          >
                                            Remove
                                          </a>
                                          <ion-icon name="checkmark-outline">
                                            <div class="col-md-1">
                                              {" "}
                                              <i
                                                class="fa fa-arrows-alt  mcq_arrow"
                                                aria-hidden="true"
                                              ></i>{" "}
                                            </div>
                                          </ion-icon>
                                        </div>
                                      </div>
                                    </li>
                                  )
                                )}
                              </ul>
                            </ReactDragListView2>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    onClick={this.lessonListSaved}
                    class="btn btn-create btn-save-modal"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        <div class="overlay-container">
          <div class="overlay-black hidden_class"></div>

          <div class="faculty_section faculty_list">
            <div class="Faculty_text">
              <p>
                <span class="faculty_text">Pathway Merchandising </span>/
                <span>Create New</span>
              </p>
            </div>
          </div>
          <div class="main-head pathway-head pad_30">
            <h3>
              <i
                class="fa fa-arrow-left"
                onClick={() => this.setState({ create: 0 })}
                aria-hidden="true"
              ></i>{" "}
              Create Page
            </h3>
            <div class="btn-right">
              {/* <button type="button" class="btn btn-edit">
                <i class="fa fa-eye" aria-hidden="true"></i>
              </button> */}
              <button
                type="button"
                onClick={this.handleFormSubmit}
                class="btn  btn-create btn-save_cretate pathway-add-save-btn"
              >
                Save
              </button>
              <button
                type="button"
                onClick={() => this.setState({ create: 0 })}
                class="btn  btn-create btn-save btn-save_close pathway-add-next-btn"
              >
                Close
              </button>
            </div>
          </div>
          <div class="module-container padside_30">
            <div class="module-container-preview padside_30">
              {/* <!-- Button trigger modal --> */}
              <button
                class="btn btn-modal"
                onClick={() => this.setState({ pathwayList: true })}
              >
                <img src={require("../../assets/smart_button.png").default} />
                <span>Pathway Listing</span>
              </button>
              <button
                class="btn btn-modal"
                onClick={() => this.setState({ categoryList: true })}
              >
                <img src={require("../../assets/view_agenda.png").default} />
                <span>Category Listing</span>
              </button>
              <button
                class="btn btn-modal"
                onClick={() => this.setState({ ctaList: true })}
              >
                <img
                  src={require("../../assets/horizontal_distrib.png").default}
                />
                <span>CTA Template</span>
              </button>
              <button
                class="btn btn-modal"
                onClick={() => this.setState({ lessonList: true })}
              >
                <img src={require("../../assets/smart_button.png").default} />
                <span>Lesson Listing</span>
              </button>
            </div>
          </div>
          <div class="pad_30">
            <div class="title-container pathway-builder-form">
              <label>Title </label>
              <input
                type="text"
                name="title"
                value={this.state.title}
                onChange={this.titleHandleChange}
                class="wd-40"
              />
              {this.validator.message("Title", this.state.title, "required")}
            </div>
          </div>
          <div class="main-scroll main-content daily-content padside_30 pages-section-container">
            <div class="pages-section-list">
              {this.state.template.length === 0 ? (
                <div class="na-container ">
                  <span class="na">
                    To add section, please click on the above given option.
                  </span>
                </div>
              ) : (
                ""
              )}
              <ReactDragListView {...sectionDragProps}>
                <ul style={{ listStyleType: "none" }}>
                  {this.state.template.map(function (item, i) {
                    if (item.type == "PathwayList") {
                      return (
                        <>
                          <li class="card-module pathway-builder-form" key={i}>
                            <div class="card-module-head">
                              <h6>{item.type}</h6>
                              <div class="nugget-preview-right">
                                <a
                                  href="#"
                                  class="nugget-img-delete"
                                  onClick={() =>
                                    editPathwayList(
                                      i,
                                      item.title,
                                      item.category,
                                      item.description,
                                      item.highlight,
                                      item.folds,
                                      item.seeall
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      require("../../assets/Icon material-mode-edit@2x.png")
                                        .default
                                    }
                                    alt=""
                                  />
                                </a>
                                <a
                                  href="#"
                                  class="nugget-img-delete"
                                  onClick={() => deletefromtemplate(i)}
                                >
                                  <img
                                    src={
                                      require("../../assets/Icon material-delete-forever@2x.png")
                                        .default
                                    }
                                    alt=""
                                  />
                                </a>
                                <a href="#">
                                  <i
                                    class="fa fa-arrows-alt"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                            </div>
                            <div class="white-box-container">
                              <div class="nugget-preview-info">
                                <p>{item.title}</p>
                                <div class="nugget-preview-right">
                                  {/* <a href="#">
                                <i
                                  class="fa fa-arrows-alt"
                                  aria-hidden="true"
                                ></i>
                              </a> */}
                                </div>
                              </div>
                              {item.highlight ? (
                                <div class="highlight-cell highlight-blue">
                                  Highlight
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </li>
                        </>
                      );
                    } else if (item.type == "CategoryList") {
                      return (
                        <>
                          <li class="card-module pathway-builder-form" key={i}>
                            <div class="card-module-head">
                              <h6>{item.type}</h6>
                              <div class="nugget-preview-right">
                                <a
                                  href="#"
                                  class="nugget-img-delete"
                                  onClick={() =>
                                    editCategoryList(
                                      i,
                                      item.title,
                                      item.description,
                                      item.addedcategories,
                                      item.seeall
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      require("../../assets/Icon material-mode-edit@2x.png")
                                        .default
                                    }
                                    alt=""
                                  />
                                </a>
                                <a
                                  href="#"
                                  class="nugget-img-delete"
                                  onClick={() => deletefromtemplate(i)}
                                >
                                  <img
                                    src={
                                      require("../../assets/Icon material-delete-forever@2x.png")
                                        .default
                                    }
                                    alt=""
                                  />
                                </a>
                                <a href="#">
                                  <i
                                    class="fa fa-arrows-alt"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                            </div>
                            <div class="white-box-container">
                              <div class="nugget-preview-info">
                                <p>{item.title}</p>
                                <div class="nugget-preview-right">
                                  {/* <a href="#">
                                <i
                                  class="fa fa-arrows-alt"
                                  aria-hidden="true"
                                ></i>
                              </a> */}
                                </div>
                              </div>
                            </div>
                          </li>
                        </>
                      );
                    } else if (item.type == "LessonList") {
                      return (
                        <>
                          <li class="card-module pathway-builder-form" key={i}>
                            <div class="card-module-head">
                              <h6>{item.type}</h6>
                              <div class="nugget-preview-right">
                                <a
                                  href="#"
                                  class="nugget-img-delete"
                                  onClick={() =>
                                    editLessonList(
                                      i,
                                      item.title,
                                      item.description,
                                      item.selectedLesson,
                                      item.seeall
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      require("../../assets/Icon material-mode-edit@2x.png")
                                        .default
                                    }
                                    alt=""
                                  />
                                </a>
                                <a
                                  href="#"
                                  class="nugget-img-delete"
                                  onClick={() => deletefromtemplate(i)}
                                >
                                  <img
                                    src={
                                      require("../../assets/Icon material-delete-forever@2x.png")
                                        .default
                                    }
                                    alt=""
                                  />
                                </a>
                                <a href="#">
                                  <i
                                    class="fa fa-arrows-alt"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                            </div>
                            <div class="white-box-container">
                              <div class="nugget-preview-info">
                                <p>{item.title}</p>
                                <div class="nugget-preview-right">
                                  {/* <a href="#">
                                <i
                                  class="fa fa-arrows-alt -alt"
                                  aria-hidden="true"
                                ></i>
                              </a> */}
                                </div>
                              </div>
                            </div>
                          </li>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <li class="card-module pathway-builder-form" key={i}>
                            <div class="card-module-head">
                              <h6>CTA Module</h6>
                              <div class="nugget-preview-right">
                                <a
                                  href="#"
                                  onClick={() =>
                                    editCtaList(
                                      i,
                                      item.title,
                                      item.image,
                                      item.description,
                                      item.ctaText,
                                      item.subflow,
                                      item.seeall
                                    )
                                  }
                                  class="nugget-img-delete"
                                >
                                  <img
                                    src={
                                      require("../../assets/Icon material-mode-edit@2x.png")
                                        .default
                                    }
                                    alt=""
                                  />
                                </a>
                                {/* <!-- <a href="#" class="nugget-img-delete">
                                <img src="./img/Icon material-delete-forever@2x.png" alt="">
                            </a> --> */}
                                <a href="#">
                                  <i
                                    class="fa fa-arrows-alt -alt"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              </div>
                            </div>

                            <div class="cta-module-preview-container">
                              <div class="cta-module-preview">
                                <div class="cta-module-preview-box">
                                  <div class="cta-module-preview-img">
                                    <img src={item.image} alt="" />
                                  </div>
                                  <div class="cta-module-preview-content">
                                    <h6>{item.title}</h6>
                                    <p>{item.description}</p>
                                  </div>
                                  <a href="#">
                                    <button class="btn btn-seemore">
                                      {item.ctaText}
                                    </button>
                                  </a>
                                </div>
                                <div class="delete-container">
                                  <a
                                    href="#"
                                    class="nugget-img-delete"
                                    onClick={() => deletefromtemplate(i)}
                                  >
                                    <img
                                      src={
                                        require("../../assets/Icon material-delete-forever@2x.png")
                                          .default
                                      }
                                      alt=""
                                    />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </li>
                        </>
                      );
                    }
                  })}
                </ul>
              </ReactDragListView>
            </div>
          </div>
        </div>
      </>
    );
  }
  removeCategory(cat) {
    this.state.addedcategories.splice(
      this.state.addedcategories.indexOf(cat),
      1
    );
  }
  categorySearchUpdated(term) {
    const token = localStorage.getItem("token");
    if (term.length > 0) {
      axios
        .get(
          Constants.api +
            "categorys?name=" +
            term +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=10&page=" +
            this.state.page,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.setState({ categories: res.data.results });
            console.log(this.state.categories);
          }
        });
      this.setState({ searchcatTerm: term });
    } else {
      this.setState({
        categories: [],
      });
    }
  }
  addLesson(lesson) {
    if (
      this.state.selectedLesson.some((val) => lesson.name === val.name) ===
      false
    ) {
      this.state.selectedLesson.push(lesson);
      //  this.state.lessons.splice(this.state.lessons.indexOf(lesson), 1);
    }
    this.state.lessons = [];
    this.state.searchTerm = "";
    console.log(this.state.lessons);
  }
  removeLesson(lesson) {
    this.state.selectedLesson.splice(
      this.state.selectedLesson.indexOf(lesson),
      1
    );
  }

  lessonSearchUpdated(term) {
    const token = localStorage.getItem("token");
    if (term.length > 0) {
      axios
        .get(
          Constants.api +
            "lessons?title=" +
            term +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=10&page=" +
            this.state.page,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.setState({ lessons: res.data.results });
            console.log(this.state.lessons);
          }
        });
      this.setState({ searchTerm: term });
    } else {
      this.setState({
        lessons: [],
      });
    }
  }
}

export default CreatePathwayMerch;
