import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";

class CreateAssessment extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      options: [{ option: "" }],
      quesKey: this.props.resp ? this.props.resp.data.quesKey : "",
      question: this.props.resp ? this.props.resp.data.question : "",
      quesAns: this.props.resp ? this.props.resp.data.quesAns : [],
      selectedThriveSkill: this.props.resp
        ? this.props.resp.data.thriveskill
        : [],
      sortBy: "name:asc",
      limit: 1000,
      page: 1,
      faculty: [],
      thriveskill: [],
      searchThriveskill: "",
      ThriveSkillsearchshow: false,
      search: "",
      create: 1,
    };
    this.questionHandleChange = this.questionHandleChange.bind(this);
  }

  quesKeyHandleChange = (event) => {
    this.setState({ quesKey: event.target.value });
  };
  optionChange(i, item) {
    console.log(this.state.options);
  }
  componentDidMount() {
    if (this.props.resp) {
      let quesAns = this.state.quesAns;
      console.log(quesAns);
      let options = this.state.options;
      quesAns.forEach(function (item, index) {
        options[index]["option"] = item;
      });
      this.setState({ options: options });
    }

    const tsRequest = async () => {
      try {
        const token = localStorage.getItem("token");

        const resp = await axios
          .get(
            Constants.api +
              "thriveSkills?sortBy=" +
              this.state.sortBy +
              "&limit=" +
              this.state.limit +
              "&page=" +
              this.state.page,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              this.setState({ ts: resp.data.results });
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    tsRequest();
  }
  handleOptionChange(i, e) {
    let options = this.state.options;
    options[i][e.target.name] = e.target.value;
    this.setState({ options });
    console.log(this.state.options);
  }

  addFormFields() {
    this.setState({
      options: [...this.state.options, { option: "" }],
    });
  }

  removeFormFields(i) {
    let options = this.state.options;
    options.splice(i, 1);
    this.setState({ options });
  }
  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      let quesAns = [];
      let options = this.state.options;
      options.foreach((opt) => {
        quesAns.push(opt["option"]);
        //Do something
        //return true;
      });
      this.setState({ quesAns: quesAns });
      const token = localStorage.getItem("token");
      if (this.props.resp) {
        console.log(this.props.resp);
        axios
          .patch(
            Constants.api + "assessments/" + this.props.resp.data.id,
            {
              question: this.state.question,
              quesKey: this.state.quesKey,
              quesAns: quesAns,
              thriveSkills: this.state.selectedThriveSkill,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Assessment Updated");
              this.setState({ create: 0 });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      } else {
        axios
          .post(
            Constants.api + "assessments",
            {
              question: this.state.question,
              quesKey: this.state.quesKey,
              quesAns: quesAns,
              thriveSkills: this.state.selectedThriveSkill,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Assessment Added");

              this.setState({
                question: "",
                quesKey: "",
                thriveskill: [],
                selectedThriveSkill: [],
                quesAns: [],
                options: [{ option: "" }],
              });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };

  questionHandleChange = (e) => {
    this.setState({ question: e.target.value });
  };
  tsHandleChange = (event) => {
    this.setState({
      searchThriveskill: event.target.value,
      ThriveSkillsearchshow: !this.state.ThriveSkillsearchshow,
    });
  };
  searchThriveSkill = () => {
    const token = localStorage.getItem("token");
    if (this.state.searchThriveskill.length > 2) {
      axios
        .get(
          Constants.api +
            "thriveSkills?name=" +
            this.state.searchThriveskill +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=10&page=" +
            this.state.page,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.setState({ thriveskill: res.data.results });
            console.log(this.state.thriveskill);
          }
        });
    }
  };

  render() {
    const updateTS = (memb) => {
      let jjarray = this.state.selectedThriveSkill;
      if (jjarray.includes(memb) === false) {
        jjarray.push(memb);
        console.log(jjarray);

        let newarray = jjarray.filter(function (el) {
          return el != null;
        });

        this.setState({
          //this push function throws error on 2nd go round
          selectedThriveSkill: newarray,
        });
      }
    };
    const addthriveskill = (e) => {
      this.setState({
        searchThriveskill: "",
        ThriveSkillsearchshow: !this.state.ThriveSkillsearchshow,
      });

      updateTS(e.id);
    };
    const removethriveskill = (fid) => {
      let index = this.state.selectedThriveSkill.indexOf(fid);
      if (index != -1) {
        this.state.selectedThriveSkill.splice(index, 1); // remove 1 element from index
      }

      console.log(this.state.selectedThriveSkill);
    };

    if (this.state.create == 0) {
      window.location = "/assessment";
    }

    let search = this.state.search;
    let selectedThriveSkill = this.state.selectedThriveSkill;

    return (
      <>
        <div class="create_three_skills">
          <div class="category_section">
            <div class="faculty_sections">
              <div class="Faculty_text">
                <p>
                  Assessments /
                  <span>{this.props.resp ? "Edit" : "Create"} Assesment</span>
                </p>
              </div>
            </div>
            <div class="faculty_section">
              <div class="faculty_text">
                <h3>
                  <i
                    class="fa fa-arrow-left"
                    onClick={() => this.setState({ create: 0 })}
                    aria-hidden="true"
                  ></i>
                  {this.props.resp ? "Edit" : "Create"} Assessment
                </h3>{" "}
              </div>
              <div class="btn_create">
                <button
                  type="submit"
                  onClick={this.handleFormSubmit}
                  class="btn btn-primary  btn_pubish btn_three_skill"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  {" "}
                  Save{" "}
                </button>
                <button
                  type="button"
                  class="btn btn-primary btn_category btn_three_skill"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  onClick={() => this.setState({ create: 0 })}
                >
                  Close
                </button>
              </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="category_name create-assessment-form">
                    <div class="container">
                      <div class="col-md-12">
                        <div class="create_skill_box">
                          <div class="category_name_text">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="mcq_input_section select-ques">
                                  <p>Question Number</p>
                                  <div class="mcq_text">
                                    <div class="select_from">
                                      <select
                                        class="js-example-basic-multipleones form-control "
                                        name="question"
                                        onChange={this.questionHandleChange}
                                        value={this.state.question}
                                        placeholder="Type here"
                                      >
                                        {" "}
                                        <i
                                          class="fa fa-plus-square-o"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        <option value="">Select</option>
                                        <option value="ques0">Start</option>
                                        <option value="ques1">
                                          Question 1
                                        </option>
                                        <option value="ques2">
                                          Question 2
                                        </option>
                                        <option value="ques3">
                                          Question 3
                                        </option>
                                        <option value="ques4">
                                          Question 4
                                        </option>
                                        <option value="ques5">
                                          Question 5
                                        </option>
                                      </select>

                                      <ion-icon name="caret-up-outline"></ion-icon>
                                      <ion-icon name="caret-down-outline"></ion-icon>
                                    </div>
                                  </div>
                                </div>
                                {this.validator.message(
                                  "question",
                                  this.state.question,
                                  "required"
                                )}
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-md-12">
                                <div class="template_name">
                                  <div class="faculty_sections category_nsection">
                                    <div class="faculty_text">
                                      <p>Question*</p>
                                    </div>
                                    <div class="number">
                                      <p>0/50</p>
                                    </div>
                                  </div>
                                  <div class="input_section">
                                    <div class="input_text">
                                      <input
                                        type="text"
                                        class="form-control"
                                        name="quesKey"
                                        onChange={this.quesKeyHandleChange}
                                        value={this.state.quesKey}
                                        id="exampleInputEmail1"
                                        placeholder="Type here"
                                      />{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {this.state.question != "ques5" ? (
                            <>
                              {" "}
                              {Array.isArray(this.state.options)
                                ? this.state.options.map((element, index) => (
                                    <>
                                      <div class="row">
                                        <div class="col-md-12">
                                          <div class="faculty_sections category_nsection">
                                            <div class="faculty_text">
                                              <p>
                                                Option {index + 1}*{" "}
                                                {index != 0 ? (
                                                  <i
                                                    onClick={() =>
                                                      this.removeFormFields(
                                                        index
                                                      )
                                                    }
                                                    class="fa fa-times"
                                                    aria-hidden="true"
                                                  ></i>
                                                ) : (
                                                  ""
                                                )}{" "}
                                              </p>
                                            </div>
                                            <div class="number">
                                              <p>0/50</p>
                                            </div>
                                          </div>
                                          <div class="template_name">
                                            <div class="input_section">
                                              <div class="input_text">
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  name="option"
                                                  value={element.option || ""}
                                                  onChange={(e) =>
                                                    this.handleOptionChange(
                                                      index,
                                                      e
                                                    )
                                                  }
                                                  id="exampleInputEmail1"
                                                  placeholder="Type here"
                                                />{" "}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ))
                                : ""}
                              <div class="add_more_mcq p_l_26 mt-4">
                                <button
                                  type="button"
                                  class="btn btn-primary btn_add_mcq "
                                  onClick={() => this.addFormFields()}
                                >
                                  Add More Options
                                </button>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.question == "ques5" ? (
                            <div class="row">
                              <div class="col-md-10">
                                <div class="template_name">
                                  <div class="faculty_sections category_nsection">
                                    <div class="faculty_text">
                                      <p>Thrive Skill</p>
                                    </div>
                                    <div class="number">
                                      <p>0/50</p>
                                    </div>
                                  </div>
                                  <div class="input_section">
                                    <div class="input_text">
                                      <input
                                        type="text"
                                        class="form-control"
                                        autoComplete="off"
                                        name="searchThriveskill"
                                        style={{ color: "#000" }}
                                        value={this.state.searchThriveskill}
                                        onKeyUp={this.searchThriveSkill}
                                        onChange={this.tsHandleChange}
                                        id="exampleInputEmail1"
                                        placeholder="Type here"
                                      />{" "}
                                    </div>
                                    <div class="dropdown">
                                      {this.state.selectedThriveSkill.length > 0
                                        ? this.state.ts.map(function (item, i) {
                                            if (
                                              selectedThriveSkill.includes(
                                                item.id
                                              )
                                            ) {
                                              return (
                                                <>
                                                  <a
                                                    class="dropdown-item"
                                                    href="#"
                                                  >
                                                    <button
                                                      type="button"
                                                      class="btn btn_skill"
                                                    >
                                                      {item.name}
                                                      <span
                                                        aria-hidden="true"
                                                        onClick={() =>
                                                          removethriveskill(
                                                            item.id
                                                          )
                                                        }
                                                      >
                                                        ×
                                                      </span>
                                                    </button>
                                                  </a>
                                                </>
                                              );
                                            }
                                          })
                                        : ""}
                                      {/* <div class="plus alt"></div>
                              </button> */}
                                      {this.state.ThriveSkillsearchshow ? (
                                        <div
                                          class=" dropdown_img"
                                          aria-labelledby="dropdownMenuButton"
                                        >
                                          {this.state.thriveskill.map(function (
                                            item,
                                            i
                                          ) {
                                            return (
                                              <a
                                                class="dropdown-item"
                                                href="#"
                                                onClick={() =>
                                                  addthriveskill(item)
                                                }
                                              >
                                                <button
                                                  type="button"
                                                  class="btn btn_skill"
                                                >
                                                  {item.name}
                                                  {/* <span aria-hidden="true">×</span> */}
                                                </button>
                                              </a>
                                            );
                                          })}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CreateAssessment;
