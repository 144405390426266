import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";

class CreateThriveSkill extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    // this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
    // this.state = { editorState: EditorState.createEmpty() };
    // this.oneditorChange = (editorState) => this.setState({ editorState });
    this.skillgroupHandleChange = this.skillgroupHandleChange.bind(this);
  }

  state = {
    name: this.props.resp ? this.props.resp.data.name : "",
    skillgroup: this.props.resp ? this.props.resp.data.skillGroup : "",
    image: this.props.resp ? this.props.resp.data.icon : "",
    description: this.props.resp ? this.props.resp.data.description : "",
    quesTxt1:
      this.props.resp && this.props.resp.data.assessQ1 !== undefined
        ? this.props.resp.data.assessQ1.quesTxt
        : "",
    quesTxt2:
      this.props.resp && this.props.resp.data.assessQ1 !== undefined
        ? this.props.resp.data.assessQ2.quesTxt
        : "",
    quesType1:
      this.props.resp && this.props.resp.data.assessQ1 !== undefined
        ? this.props.resp.data.assessQ1.quesType
        : false,
    quesType2:
      this.props.resp && this.props.resp.data.assessQ2 !== undefined
        ? this.props.resp.data.assessQ2.quesType
        : false,

    sortBy: "name:asc",
    limit: 1000,
    page: 1,
    faculty: [],
    search: "",
    create: 1,
  };
  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };
  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };

  imageHandleChange = (event) => {
    this.setState({ image: event.target.value });
  };
  descriptionHandleChange = (event) => {
    this.setState({ description: event.target.value });
  };
  quesTxt1HandleChange = (event) => {
    this.setState({ quesTxt1: event.target.value });
  };
  quesTxt2HandleChange = (event) => {
    this.setState({ quesTxt2: event.target.value });
  };
  quesType1HandleChange = (event) => {
    this.setState({ quesType1: !this.state.quesType1 });
  };
  quesType2HandleChange = (event) => {
    this.setState({ quesType2: !this.state.quesType2 });
  };
  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");
      if (this.props.resp) {
        console.log(this.props.resp);
        axios
          .patch(
            Constants.api + "thriveSkills/" + this.props.resp.data.id,
            {
              name: this.state.name,
              skillGroup: this.state.skillgroup,
              icon: this.state.image,
              description: this.state.description,
              assessQ1: {
                quesTxt: this.state.quesTxt1,
                quesType: this.state.quesType1,
              },
              assessQ2: {
                quesTxt: this.state.quesTxt2,
                quesType: this.state.quesType2,
              },
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Thrive Skill Updated");
              this.setState({ create: 0 });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      } else {
        axios
          .post(
            Constants.api + "thriveSkills",
            {
              name: this.state.name,
              skillGroup: this.state.skillgroup,
              icon: this.state.image,
              description: this.state.description,
              assessQ1: {
                quesTxt: this.state.quesTxt1,
                quesType: this.state.quesType1,
              },
              assessQ2: {
                quesTxt: this.state.quesTxt2,
                quesType: this.state.quesType2,
              },
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Thrive Skill Added");

              this.setState({
                name: "",
                skillgroup: "",
                image: "",
                description: "",
              });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };

  updatemember(memb) {
    let jjarray = this.state.skillgroup;
    if (jjarray.includes(memb) === false) {
      jjarray.push(memb);
      console.log(jjarray);

      let newarray = jjarray.filter(function (el) {
        return el != null;
      });

      this.setState({
        //this push function throws error on 2nd go round
        skillgroup: newarray,
      });
    }
  }
  skillgroupHandleChange = (e) => {
    this.setState({ skillgroup: e.target.value });
  };

  render() {
    //console.log(this.props.resp);
    const options = [
      { label: "Think ", value: "Think" },
      { label: "Solve ", value: "Solve" },
      { label: "Communicate ", value: "Communicate" },
      { label: "Collaborate ", value: "Collaborate" },
      { label: "Lead ", value: "Lead" },
    ];
    if (this.state.create == 0) {
      window.location = "/thrive-skill";
    }

    let search = this.state.search;

    return (
      <>
        <div class="create_three_skills">
          <div class="category_section">
            <div class="faculty_sections">
              <div class="Faculty_text">
                <p>
                  Thrive Skills /
                  <span>
                    {" "}
                    {this.props.resp ? "Edit" : "Create"} Thrive Skill
                  </span>
                </p>
              </div>
            </div>
            <div class="faculty_section">
              <div class="faculty_text">
                <h3>
                  <i
                    class="fa fa-arrow-left"
                    onClick={() => this.setState({ create: 0 })}
                    aria-hidden="true"
                  ></i>
                  {this.props.resp ? "Edit" : "Create"} Thrive Skill
                </h3>{" "}
              </div>
              <div class="btn_create">
                <button
                  type="submit"
                  onClick={this.handleFormSubmit}
                  class="btn btn-primary  btn_pubish btn_three_skill"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  {" "}
                  Save{" "}
                </button>
                <button
                  type="button"
                  class="btn btn-primary btn_category btn_three_skill"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  onClick={() => this.setState({ create: 0 })}
                >
                  Close
                </button>
              </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="category_name">
                    <div class="container">
                      <div class="col-md-12">
                        <div class="create_skill_box">
                          <div class="category_name_text">
                            <div class="row-full">
                              <div class="col-md-12">
                                <div class="template_name">
                                  <div class="faculty_sections category_nsection">
                                    <div class="faculty_text">
                                      <p>Thrive Skill Name*</p>
                                    </div>
                                    <div class="number">
                                      <p>{this.state.name.length}/100</p>
                                    </div>
                                  </div>
                                  <div class="input_section">
                                    <div class="input_text">
                                      <input
                                        type="text"
                                        class="form-control"
                                        name="name"
                                        onChange={this.nameHandleChange}
                                        value={this.state.name}
                                        id="exampleInputEmail1"
                                        placeholder="Type here"
                                        maxLength="100"
                                      />{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {this.validator.message(
                              "name",
                              this.state.name,
                              "required|string"
                            )}
                          </div>
                          <div class="row">
                            <div class="col-md-8">
                              <div class="add_icon">
                                <div class="add_icon_logo">
                                  <div class="upload_icon">
                                    <p>Add Icon</p>
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="image"
                                      onChange={this.imageHandleChange}
                                      value={this.state.image}
                                      id="exampleInputEmail1"
                                      placeholder="Type here"
                                    />{" "}
                                  </div>
                                  {this.state.image != "" ? (
                                    <div class="smile_icon">
                                      <p>Uploaded Icon</p>{" "}
                                      <div className="form-control">
                                        <img
                                          src={this.state.image}
                                          height="30px"
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div class="mcq_input_section">
                                <p>Skill Group*</p>
                                <div class="mcq_text">
                                  <div class="select_from">
                                    <select
                                      class="js-example-basic-multipleones form-control "
                                      name="skillgroup"
                                      onChange={this.skillgroupHandleChange}
                                      value={this.state.skillgroup}
                                      placeholder="Type here"
                                    >
                                      {" "}
                                      <i
                                        class="fa fa-plus-square-o"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      <option value="">Select</option>
                                      <option value="Think">Think</option>
                                      <option value="Solve">Solve</option>
                                      <option value="Communicate">
                                        Communicate
                                      </option>
                                      <option value="Collaborate">
                                        Collaborate
                                      </option>
                                      <option value="Lead">Lead</option>
                                    </select>
                                    {this.validator.message(
                                      "skillgroup",
                                      this.state.name,
                                      "required|string|max:250"
                                    )}
                                    <ion-icon name="caret-up-outline"></ion-icon>
                                    <ion-icon name="caret-down-outline"></ion-icon>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div class="row">
                            <div class="col-md-12">
                              <div className="faculty-flex">
                                <div class="faculty_text">
                                  <p>Description</p>
                                </div>
                                <div class="number">
                                  <p>{this.state.description.length}/250</p>
                                </div>
                              </div>
                              <textarea
                                row="3"
                                type="text"
                                class="form-control"
                                name="description"
                                onChange={this.descriptionHandleChange}
                                value={this.state.description}
                                id="exampleInputEmail1"
                                placeholder="Type here"
                                maxLength="250"
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Questions */}
                  <div class="category_name">
                    <div className="container ques-container">
                      <h5 className="question-head">Assessment</h5>
                      <div class="temp-bot">
                        <div class="template_sections">
                          <div class="template_text">
                            <p class="info-template">Question 1</p>
                          </div>
                          <div class="custom-control custom-switch">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customSwitches1"
                              checked={this.state.quesType1}
                              onClick={this.quesType1HandleChange}
                            />
                            <label
                              class="custom-control-label"
                              for="customSwitches1"
                            >
                              Negative/ Positive
                            </label>
                          </div>
                        </div>
                        <div class="input_section">
                          <div class="input_text">
                            <input
                              type="text"
                              class="form-control heading_input"
                              name="quesText1"
                              value={this.state.quesTxt1}
                              onChange={this.quesTxt1HandleChange}
                              placeholder="Add Question"
                            />{" "}
                          </div>
                          {this.validator.message(
                            "Question 1",
                            this.state.quesTxt1,
                            "required"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="container ques-container">
                      <div class="temp-bot">
                        <div class="template_sections">
                          <div class="template_text">
                            <p class="info-template">Question 2</p>
                          </div>
                          <div class="custom-control custom-switch">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customSwitches2"
                              checked={this.state.quesType2}
                              onClick={this.quesType2HandleChange}
                            />
                            <label
                              class="custom-control-label"
                              for="customSwitches2"
                            >
                              Negative/ Positive
                            </label>
                          </div>
                        </div>
                        <div class="input_section">
                          <div class="input_text">
                            <input
                              type="text"
                              class="form-control heading_input"
                              id=""
                              name="quesTxt2"
                              value={this.state.quesTxt2}
                              onChange={this.quesTxt2HandleChange}
                              placeholder="Add question"
                            />{" "}
                          </div>
                          {this.validator.message(
                            "Question 2",
                            this.state.quesTxt2,
                            "required"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CreateThriveSkill;
