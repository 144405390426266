import React, { Component } from "react";
import * as Constants from "../../Constants/index";

import axios from "axios";
import * as QueryString from "query-string";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useHistory } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import DailyChallengeCreate from "./dailyChallengeCreate.component";
import moment from "moment";
import Pagination from "react-js-pagination";
import Switch from "react-switch";
import Carousel from "react-multi-carousel";
import swal from "sweetalert";

class DailyChallenge extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    // this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
  }

  state = {
    name: "",
    sortBy: "name:asc",
    limit: 25,
    totalResults: 0,
    page: 1,
    lesson: [],
    search: "",
    create: 0,
  };

  componentDidMount() {
    const lessonRequest = async () => {
      try {
        const token = localStorage.getItem("token");

        const resp = await axios
          .get(
            Constants.api +
              "dailys?title=" +
              this.state.search +
              "&sortBy=" +
              this.state.sortBy +
              "&limit=" +
              this.state.limit +
              "&page=" +
              this.state.page,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              console.log(resp.data.results);
              this.setState({
                lesson: resp.data.results,
                totalResults: resp.data.totalResults,
              });
              console.log(this.state.lesson);
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    lessonRequest();
  }

  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ page: pageNumber }, this.componentDidMount);
  }
  render() {
    const activateItem = (id, active) => {
      try {
        const token = localStorage.getItem("token");

        axios
          .put(
            Constants.api + "dailys/" + id + "?active=" + active,
            "",

            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((resp) => {
            let status = "";
            //  casetype array
            if (active) status = "Activated";
            else status = "Deactivated";
            console.log(resp);
            if (resp.status == 200) {
              swal(status + " Succesfully!");
              window.location = "/daily-challenge";
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const editItem = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "dailys/" + id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              this.setState({ create: 1, resp: resp });
              console.log(this.state.resp);
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const previewItem = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "dailys/" + id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              this.setState({ preview: 1, resp: resp });
              console.log(this.state.resp);
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const deleteItem = (id) => {
      if (window.confirm("Are you sure?")) {
        try {
          const token = localStorage.getItem("token");
          const deletedata = new FormData();
          deletedata.append("id", id); //append the values with key, value pair

          axios
            .delete(
              Constants.api + "dailys/" + id,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
              deletedata
            )
            .then((resp) => {
              //  casetype array

              if (resp.status == 204) {
                this.componentDidMount();
              } else {
              }
              //movie array
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
    };

    let search = this.state.search;
    const lessonRender = this.state.lesson.map(function (item, i) {
      return (
        <>
          <tr>
            {/* <td>{moment(item.createdAt).format("MM/DD/YY H:ma")}</td>
            <td class="date_two">
              {moment(item.updatedAt).format("MM/DD/YY H:ma")}
            </td> */}
            <td>
              <p>{item.id}</p>
            </td>
            <td>
              <p>{item.title}</p>
            </td>
            <td>
              <p>{item.dated.split("T")[0]}</p>
            </td>

            <td class="img_log">
              <i
                class="fa fa-eye"
                onClick={() => previewItem(item.id)}
                aria-hidden="true"
              ></i>{" "}
              <i
                class="fa fa-pencil"
                onClick={() => editItem(item.id)}
                aria-hidden="true"
              ></i>{" "}
              <i
                class="fa fa-trash"
                onClick={() => deleteItem(item.id)}
                aria-hidden="true"
              ></i>
            </td>
            <td class="img_log">
              <label>
                <span>Activate</span>

                <Switch
                  onChange={() => activateItem(item.id, !item.active)}
                  class="form-control"
                  checked={item.active}
                />
              </label>
            </td>
          </tr>
        </>
      );
    });
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    return (
      <>
        {this.state.create == 0 ? (
          <>
            <div class="main-head pathway-head pad_30">
              <h3>Daily Challenge</h3>
              <button
                type="button"
                class="btn  btn-create btn-save_cretate pathway-save-btn btn-new"
                onClick={() => this.setState({ create: 1 })}
              >
                Create New
              </button>
            </div>

            <Tabs className="nav-div">
              {/* <TabList className="nav-tabs">
                <Tab className="pathway-tabs nav-link">Published</Tab>
                <Tab className="pathway-tabs nav-link">Draft</Tab>
              </TabList> */}
              <div class="category_table">
                <div class="category_container">
                  <div className="pad_30">
                    <TabPanel className="tab-pane">
                      <div class="listing-info">
                        <div class="listing-left">
                          <div class="search-bar">
                            <input
                              type="text"
                              class="form-control form-control_one"
                              id="formGroupExampleInput2"
                              placeholder="Search"
                              name="search"
                              value={this.state.value}
                              onChange={this.searchHandleChange}
                            />{" "}
                            <i class="fa fa-search" aria-hidden="true"></i>
                          </div>
                        </div>
                        <div class="listing-right">
                          Show entries
                          <select name="cars" id="show">
                            <option
                              value="25"
                              onClick={() =>
                                this.setState(
                                  { limit: 35 },
                                  this.componentDidMount()
                                )
                              }
                            >
                              25
                            </option>
                            <option
                              value="50"
                              onClick={() =>
                                this.setState(
                                  { limit: 50 },
                                  this.componentDidMount()
                                )
                              }
                            >
                              50
                            </option>
                            <option
                              value="75"
                              onClick={() =>
                                this.setState(
                                  { limit: 75 },
                                  this.componentDidMount()
                                )
                              }
                            >
                              75
                            </option>
                            <option
                              value="100"
                              onClick={() =>
                                this.setState(
                                  { limit: 100 },
                                  this.componentDidMount()
                                )
                              }
                            >
                              100
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class=" table_faculty main-scroll-table lesson-table">
                        <table cellspacing="0" class="table">
                          <tbody>
                            <tr class="row_table">
                              <th class="action_text ">
                                <p>ID</p>
                              </th>
                              <th class="designation_text ">
                                <p>Title</p>
                              </th>
                              <th class="action_text">
                                <p>For Date</p>
                              </th>
                              <th class="action_text">
                                <p>Actions</p>
                              </th>
                              <th class="action_text">Activate</th>
                            </tr>
                            {lessonRender}
                          </tbody>
                        </table>
                      </div>
                    </TabPanel>
                    <TabPanel className="tab-pane">
                      <div class=" table_faculty main-scroll-table">
                        <table class="table">
                          <tbody>
                            <tr class="row_table">
                              <th class="create_tabe">
                                <p>ID</p>
                              </th>
                              <th class="create_tabe">
                                <p>Title</p>
                              </th>
                              <th class="faculty_name">
                                <p>For Date</p>
                              </th>
                              <th class="designation_text">
                                <p>Category</p>
                              </th>
                              <th class="action_text">Actions</th>
                            </tr>
                            {lessonRender}
                          </tbody>
                        </table>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </Tabs>
            <div className="pagination-container">
              <Pagination
                activePage={this.state.page}
                itemsCountPerPage={this.state.limit}
                totalItemsCount={this.state.totalResults}
                pageRangeDisplayed={15}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
          </>
        ) : this.state.resp ? (
          <DailyChallengeCreate resp={this.state.resp} />
        ) : (
          <DailyChallengeCreate />
        )}
        {this.state.preview == 1 ? (
          <>
            <div class="overlay-black"></div>
            <div class="modal-popup thrive_skills modal_Thrive">
              <div
                class="close_btn"
                onClick={() => this.setState({ preview: 0 })}
              >
                <i class="fa fa-close"></i>{" "}
              </div>
              <div class="modal_body_section">
                <div class="add_video_modal">
                  <div class="video_text">
                    <p>
                      <b>{this.state.resp.data.name}</b>
                    </p>
                    <button type="button" class="btn btn_video">
                      {this.state.resp.data.nuggetType}
                    </button>
                  </div>

                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <div class="video_section video-left">
                        <div class="duration_content">
                          <p>Duration of content:</p>
                          <p>
                            <b>{this.state.resp.data.duration} seconds</b>
                          </p>
                        </div>
                        <div class="used_text">
                          {/* <p>Used in (3 pathways):</p> */}
                          <p>
                            <b>{this.state.resp.data.description}</b>
                          </p>
                        </div>
                        <div class="add_videos">
                          <p>{this.state.resp.data.nuggetType}:</p>{" "}
                          <Carousel
                            responsive={responsive}
                            showDots={true}
                            autoPlay={false}
                            arrows={true}
                            centerMode={false}
                          >
                            <div className="rows">
                              <img
                                // src={this.state.resp.data.nuggetImage.url}
                                src="https://images.pexels.com/photos/301926/pexels-photo-301926.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                                alt="video"
                              />
                            </div>
                            <div className="rows">
                              <img
                                src="https://images.pexels.com/photos/301926/pexels-photo-301926.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                                // src={this.state.resp.data.nuggetImage.url}
                                alt="video"
                              />
                            </div>
                            <div class="rows">
                              {/* Preview */}
                              <div class="col-md-12 mcq-prev-box">
                                <div
                                  class="available_info text-center mcq_texts main-scroll-mcq"
                                  // style={bg}
                                >
                                  <h5>
                                    {/* {
                                      this.state.resp.data.nuggetMCQ.mcq
                                        .question
                                    } */}
                                    asjdl;askd
                                  </h5>
                                  <p>
                                    {/* {
                                      this.state.resp.data.nuggetMCQ.mcq
                                        .instructionTxt
                                    } */}
                                    askdlksada
                                  </p>
                                  <div class="info_mcq">
                                    {/* {this.state.resp.data.nuggetMCQ.mcq.options.map(
                                      function (item, i) {
                                        return (
                                          <> */}
                                    <label class="container mcq_choose_text">
                                      {/* {item.option} */}
                                      <input type="radio" name="radio" />{" "}
                                      <span class="checkmark"></span>{" "}
                                    </label>
                                    {/* </>
                                        );
                                      }
                                    )} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="rows">
                              {/* Preview */}
                              <div class="col-md-12 mcq-prev-box">
                                <div
                                  class="available_info text-center mcq_texts main-scroll-mcq"
                                  // style={bg}
                                >
                                  <h5>
                                    {/* {
                                      this.state.resp.data.nuggetPOLL.poll
                                        .question
                                    } */}
                                    asdlkasld
                                  </h5>
                                  <p>
                                    {/* {
                                      this.state.resp.data.nuggetPOLL.poll
                                        .instructionTxt
                                    } */}
                                    asdml;aslmdasd
                                  </p>
                                  <div class="info_mcq">
                                    {/* {this.state.resp.data.nuggetPOLL.poll.options.map(
                                      function (item, i) {
                                        return (
                                          <> */}
                                    <label class="container mcq_choose_text">
                                      {/* {item.option} */}
                                      <input type="radio" name="radio" />{" "}
                                      <span class="checkmark"></span>{" "}
                                    </label>
                                    {/* </>
                                        );
                                      }
                                    )} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Carousel>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12 video-right">
                      {/* <div class="no_of_slides">
                        <p>No. of Slides:</p>
                        <p>
                          <b>3</b>
                        </p>
                      </div> */}
                      <div class="faculties">
                        <p>Faculties:</p>
                        {/* {this.state.resp.data.facultys.map(function (item, i) {
                          return (
                            <> */}
                        <img src="" alt="img" />{" "}
                        {/* </>
                          );
                        })} */}
                      </div>
                      <div class="thrive_skill">
                        <p>Thrive skills tags:</p>
                        {/* {this.state.resp.data.thriveSkills.map(function (
                          item,
                          i
                        ) {
                          return (
                            <> */}
                        <button type="button" class="btn btn_skills">
                          nn
                        </button>
                        {/* </>
                          );
                        })} */}
                      </div>
                      <div class="thrive_skill custom_tag hidden_class">
                        <p>Custom tags:</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default DailyChallenge;
