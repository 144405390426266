import React, { Component } from "react";
import * as Constants from "../../Constants/index";

import axios from "axios";
import * as QueryString from "query-string";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import CreateGraded from "./creategraded.component";
import moment from "moment";
import CreateQuestion from "./createQuestion.component";
import Pagination from "react-js-pagination";

class Question extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    // this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
  }

  state = {
    name: "",
    sortBy: "name:asc",
    limit: 25,
    totalResults: 0,
    page: 1,
    graded: [],
    search: "",
    create: 0,
  };

  componentDidMount() {
    //alert("Dsds");

    const categoriesRequest = async () => {
      try {
        const token = localStorage.getItem("token");

        const resp = await axios
          .get(
            Constants.api +
              "gAQuestions?sortBy=" +
              this.state.sortBy +
              "&limit=" +
              this.state.limit +
              "&page=" +
              this.state.page,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              console.log(resp.data.results);
              this.setState({
                graded: resp.data.results,
                totalResults: resp.data.totalResults,
              });
              console.log(this.state.garded);
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    categoriesRequest();
  }

  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ page: pageNumber }, this.componentDidMount);
  }
  render() {
    const editItem = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "gAQuestions/" + id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              this.setState({ create: 1, resp: resp });
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const deleteItem = (id) => {
      if (window.confirm("Are you sure?")) {
        try {
          const token = localStorage.getItem("token");
          const deletedata = new FormData();
          deletedata.append("id", id); //append the values with key, value pair

          axios
            .delete(
              Constants.api + "gAQuestions/" + id,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
              deletedata
            )
            .then((resp) => {
              //  casetype array

              if (resp.status == 204) {
                this.componentDidMount();
              } else {
              }
              //movie array
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
    };

    let search = this.state.search;
    const gradedRender = this.state.graded.map(function (item, i) {
      return (
        <>
          <tr>
            <td>{moment(item.createdAt).format("MM/DD/YY H:ma")}</td>
            <td class="date_two">
              {moment(item.updatedAt).format("MM/DD/YY H:ma")}
            </td>
            <td>
              <p>{item.name}</p>
            </td>
            <td class="img_log">
              <i
                class="fa fa-pencil"
                onClick={() => editItem(item.id)}
                aria-hidden="true"
              ></i>{" "}
              <i
                class="fa fa-trash"
                onClick={() => deleteItem(item.id)}
                aria-hidden="true"
              ></i>
            </td>
          </tr>
        </>
      );
    });

    return (
      <>
        {this.state.create == 0 ? (
          <>
            <div class="main-head pathway-head pad_30">
              <h3>Questions</h3>
              <button
                type="button"
                class="btn  btn-create btn-save_cretate pathway-save-btn btn-new"
                onClick={() => this.setState({ create: 1 })}
              >
                Create New
              </button>
            </div>
            <Tabs className="nav-div">
              <div class="category_table">
                <div class="category_container">
                  <div className="pad_30">
                    <TabPanel className="tab-pane">
                      <div class=" table_faculty main-scroll-table question-table">
                        <div class="listing-info">
                          <div class="listing-left">
                            <div class="search-bar">
                              <input
                                type="text"
                                class="form-control form-control_one"
                                id="formGroupExampleInput2"
                                placeholder="Search"
                                name="search"
                                value={this.state.value}
                                onChange={this.searchHandleChange}
                              />{" "}
                              <i class="fa fa-search" aria-hidden="true"></i>
                            </div>
                          </div>
                          <div class="listing-right">
                            Show entries
                            <select name="cars" id="show">
                              <option
                                value="25"
                                onClick={
                                  (() => this.setState({ limit: 25 }),
                                  () => this.componentDidMount())
                                }
                              >
                                25
                              </option>
                              <option
                                value="50"
                                onClick={
                                  (() => this.setState({ limit: 50 }),
                                  () => this.componentDidMount())
                                }
                              >
                                50
                              </option>
                              <option
                                value="75"
                                onClick={
                                  (() => this.setState({ limit: 75 }),
                                  () => this.componentDidMount())
                                }
                              >
                                75
                              </option>
                              <option
                                value="100"
                                onClick={
                                  (() => this.setState({ limit: 100 }),
                                  () => this.componentDidMount())
                                }
                              >
                                100
                              </option>
                            </select>
                          </div>
                        </div>

                        <table cellspacing="0" class="table">
                          <tbody>
                            <tr class="row_table">
                              <th class="create_tabe ">
                                <p>Created On</p>
                              </th>
                              <th class="faculty_name">
                                <p>Modified On</p>
                              </th>
                              <th class="designation_text">
                                <p>Name</p>
                              </th>
                              <th class="action_text">Actions</th>
                            </tr>
                            {gradedRender}
                          </tbody>
                        </table>
                      </div>
                    </TabPanel>
                    <TabPanel className="tab-pane">
                      <div class="table_faculty">
                        <table class="table">
                          <tbody>
                            <tr class="row_table">
                              <th class="create_tabe">
                                <p>Created On</p>
                              </th>
                              <th class="faculty_name">
                                <p>Modified On</p>
                              </th>
                              <th class="designation_text">
                                <p>Category</p>
                              </th>
                              <th class="action_text">Actions</th>
                            </tr>
                            {gradedRender}
                          </tbody>
                        </table>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </Tabs>
            <div className="pagination-container">
              <Pagination
                activePage={this.state.page}
                itemsCountPerPage={this.state.limit}
                totalItemsCount={this.state.totalResults}
                pageRangeDisplayed={15}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
            <div class="category_table">
              <div class="category_container">
                <div class="tab-content pad_30">
                  <div class="tab-pane active" id="home"></div>
                  <div class="tab-pane container fade" id="menu1"></div>
                </div>
              </div>
            </div>
          </>
        ) : this.state.resp ? (
          <CreateQuestion resp={this.state.resp} />
        ) : (
          <CreateQuestion />
        )}
      </>
    );
  }
}

export default Question;
