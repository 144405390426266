import React, { Component } from "react";
import SearchInput from "react-search-input";
import axios from "axios";
import swal from "sweetalert";
import * as Constants from "../../Constants/index";
import SimpleReactValidator from "simple-react-validator";
import ReactDragListView from "react-drag-listview";

// import emails from './mails'

// const KEYS_TO_FILTERS = ['user.name', 'subject', 'dest.name']

class LessonCreate extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      name: this.props.resp ? this.props.resp.data.output.name : "",
      title: this.props.resp ? this.props.resp.data.output.title : "",
      searchTerm: "",
      sortBy: "name:asc",
      merchandise: this.props.resp
        ? this.props.resp.data.output.mechandise
        : false,
      limit: 1000,
      page: 1,
      contents: [],
      addedContent: this.props.resp
        ? this.props.resp.data.output.contentUnits
        : [],
      create: 1,
      image: this.props.resp ? this.props.resp.data.output.image : "",
    };
    this.searchUpdated = this.searchUpdated.bind(this);
  }

  handleFormSubmit = (event) => {
    console.log(this.state.merchandise + " " + this.state.image);
    if (this.state.merchandise === true && this.state.image == "") {
      swal("Please add image url");
      return;
    }
    event.preventDefault();
    let contentUnits = [];
    let options = this.state.addedContent;
    options.map(function (item, i) {
      contentUnits.push(item.id);
      //Do something
    });

    this.setState({ contentUnits: contentUnits });
    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");
      let obj = {};
      if (this.state.merchandise) {
        obj = {
          title: this.state.title,
          name: this.state.name,
          contentUnits: contentUnits,
          image: this.state.image,
          mechandise: this.state.merchandise,

          // gradedAssess: {},
        };
      } else {
        obj = {
          title: this.state.title,
          name: this.state.name,
          contentUnits: contentUnits,
          mechandise: this.state.merchandise,
        };
      }
      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "lessons/" + this.props.resp.data.output.id,
            obj,

            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Lesson Updated");
              this.setState({ create: 0 });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      } else {
        axios
          .post(Constants.api + "lessons", obj, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.status == 201) {
              swal("Lesson Added");

              this.setState({
                name: "",
                addedContent: [],
                term: "",
                searchTerm: "",
                title: "",
                image: "",
              });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      }
    } else this.validator.showMessages();

    this.forceUpdate();
  };

  render() {
    // const filteredEmails = emails.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
    if (this.state.create == 0) {
      window.location = "/lesson";
    }
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const addedContent = [...that.state.addedContent];
        const item = addedContent.splice(fromIndex, 1)[0];
        addedContent.splice(toIndex, 0, item);
        that.setState({ addedContent });
      },
      nodeSelector: "li",
      handleSelector: ".mcq_arrow",
    };
    return (
      <>
        <div class="category_section">
          <div class="faculty_sections">
            <div class="Faculty_text">
              <p onClick={() => this.setState({ create: 0 })}>
                Lesson /
                <span> {this.props.resp ? "Edit" : "Create"} Lesson</span>
              </p>
            </div>
          </div>
          <div class="faculty_section">
            <div class="faculty_text">
              <h3>
                <i
                  class="fa fa-arrow-left"
                  onClick={() => this.setState({ create: 0 })}
                  aria-hidden="true"
                ></i>{" "}
                {this.props.resp ? "Edit" : "Create"} Lesson
              </h3>{" "}
            </div>
            <div class="btn_create">
              <button
                type="submit"
                onClick={this.handleFormSubmit}
                class="btn btn-primary  btn_pubish"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                {" "}
                Save{" "}
              </button>
              <button
                type="button"
                onClick={() => this.setState({ create: 0 })}
                class="btn btn-primary btn_category"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                Close
              </button>
            </div>
          </div>
          <div class="padside_25" style={{ marginTop: "30px" }}>
            <div class="pathway-builder-form">
              <div class="form-group">
                {/* <label class="mar-20 bold-label">Lesson 1</label> */}
                <div class="inline-form-full mar-20">
                  <label for="exampleFormControlInput1">
                    Template Name*{" "}
                    <span class="text-limit">{this.state.name.length}/100</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={this.state.name}
                    onChange={this.nameHandleChange}
                    class="form-control"
                    id="inputAddress"
                    maxlength="100"
                  />
                </div>
                {this.validator.message(
                  "Name",
                  this.state.name,
                  "required|max:100"
                )}
                <div class="inline-form-full mar-20">
                  <label for="exampleFormControlInput1">
                    Title*{" "}
                    <span class="text-limit">{this.state.title.length}/50</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={this.state.title}
                    onChange={this.titleHandleChange}
                    class="form-control"
                    id="inputAddress"
                    maxlength="100"
                  />
                </div>
                {this.validator.message("Title", this.state.title, "required")}
                <div class="custom-control custom-switch form-group">
                  <input
                    type="checkbox"
                    checked={this.state.merchandise}
                    onClick={this.merchandiseHandleChange}
                    class="custom-control-input"
                    id="customSwitches2"
                  />
                  <label class="custom-control-label" for="customSwitches2">
                    Merchandise
                  </label>
                </div>
                {this.state.merchandise ? (
                  <>
                    {" "}
                    <div class="inline-form-full mar-20">
                      <label for="exampleFormControlInput1">Image* </label>
                      <input
                        type="text"
                        name="image"
                        value={this.state.image}
                        onChange={this.imageHandleChange}
                        class="form-control"
                        id="inputAddress"
                      />
                    </div>
                    {this.state.image != "" ? (
                      <img src={this.state.image} class="col-md-4" />
                    ) : (
                      ""
                    )}{" "}
                  </>
                ) : (
                  ""
                )}

                <div class="inline-form-full">
                  <label for="exampleFormControlInput1">
                    Add Content to this lesson*{" "}
                  </label>
                  <div>
                    <SearchInput
                      class="form-control collapse-search"
                      id="inputAddress"
                      onChange={this.searchUpdated}
                      placeholder="Type to add Content"
                      value={this.state.searchTerm}
                      autocomplete="off"
                    />
                    {this.validator.message(
                      "Content",
                      this.state.addedContent,
                      "required"
                    )}

                    {this.state.contents.map((content) => {
                      return (
                        <>
                          <div>
                            <div class="card card-body">
                              <a href="#" class="search-body-view">
                                <div class="search-body-view-part">
                                  <span class="search-body-view-part-name">
                                    {" "}
                                    {content.name}
                                  </span>
                                </div>
                                <div class="search-body-view-part">
                                  <div class="highlight-cell highlight-gray">
                                    {content.nuggetType}
                                  </div>
                                </div>
                                <div class="search-body-view-last">
                                  <div
                                    class="box-c box-blue"
                                    onClick={() => this.addContent(content)}
                                  >
                                    <i class="fa fa-plus"></i>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </>
                      );
                    })}

                    {/* Listing of Added Content */}
                    {this.state.addedContent.length > 0 ? (
                      <div class="added-content-container">
                        <label>Added Content</label>
                        <ReactDragListView {...dragProps}>
                          <ul style={{ listStyleType: "none" }}>
                            {this.state.addedContent.map((content, index) => (
                              <li key={index}>
                                <div class="search-body-view search-body-view-box">
                                  <div class="search-body-view-part">
                                    <span class="search-body-view-part-name">
                                      {content.name}
                                    </span>
                                  </div>
                                  <div class="search-body-view-part">
                                    <div class="highlight-cell highlight-blue">
                                      {content.nuggetType}
                                    </div>
                                  </div>
                                  <div class="search-body-view-last">
                                    <a
                                      href="#"
                                      onClick={() =>
                                        this.removeContent(content)
                                      }
                                    >
                                      Remove
                                    </a>
                                    {/* <ion-icon name="checkmark-outline">drag</ion-icon> */}
                                  </div>
                                  <div class="col-md-1">
                                    {" "}
                                    <i
                                      class="fa fa-arrows-alt  mcq_arrow"
                                      aria-hidden="true"
                                    ></i>{" "}
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </ReactDragListView>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div>
                  {/* <button type="button" class="btn btn-create upload-btn">
                      Add More Lesson
                    </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  titleHandleChange = (event) => {
    this.setState({ title: event.target.value });
  };
  imageHandleChange = (event) => {
    this.setState({ image: event.target.value });
  };
  merchandiseHandleChange = (event) => {
    this.setState({ merchandise: !this.state.merchandise });
  };
  addContent(content) {
    if (
      this.state.addedContent.some((val) => content.name === val.name) === false
    )
      this.state.addedContent.push(content);
    this.state.contents.splice(this.state.contents.indexOf(content), 1);

    this.state.contents = [];
    this.state.searchTerm = "";
  }
  removeContent(content) {
    this.state.addedContent.splice(this.state.addedContent.indexOf(content), 1);
  }

  searchUpdated(term) {
    const token = localStorage.getItem("token");
    if (term.length > 0) {
      axios
        .get(
          Constants.api +
            "contentUnits?name=" +
            term +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=60&page=" +
            this.state.page,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            if (this.state.addedContent.length != 0) {
              this.state.addedContent.forEach((faculty) => {
                let index = res.data.results.indexOf(faculty);
                const newFaculty = res.data.results;
                // newFaculty.splice(index, 1);
                this.setState({ contents: newFaculty });
              });
            } else {
              this.setState({ contents: res.data.results });
            }
          }
        });
      this.setState({ searchTerm: term });
    } else {
      this.setState({
        contents: [],
      });
    }
  }
}

export default LessonCreate;
