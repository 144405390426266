import React from "react";
import ReactDragListView from "react-drag-listview";
// const ReactDragListView = require('react-drag-listview');

class LessonSort extends React.Component {
  constructor(props) {
    super(props);

    const data = [];
    for (let i = 1, len = 7; i < len; i++) {
      data.push({
        title: `rows${i}`
      });
    }

    this.state = {
      data
    };
  }

  render() {
    const that = this;
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        console.log(fromIndex);
        const data = [...that.state.data];
        const item = data.splice(fromIndex, 1)[0];
        data.splice(toIndex, 0, item);
       
        that.setState({ data });
      },
      nodeSelector: 'li',
      handleSelector: 'a'
    };

    return (
      <ReactDragListView {...dragProps}>
        <ol>
          {this.state.data.map((item, index) => (
            <li key={index}>
              {item.title}
              <a href="#">Drag</a>
            </li>
          ))}
        </ol>
      </ReactDragListView>
    );
  }
}
export default LessonSort;