import React, { Component } from "react";
import axios from "axios";
import swal from "sweetalert";
import Modal from "react-modal";
import * as Constants from "../../Constants/index";
import SimpleReactValidator from "simple-react-validator";
import moment from "moment";
class Dashbaord extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
  }

  state = {
    email: "",
    password: "",
    setIsOpen: false,
    search: "",
    parameter: "",
    subscription: {},
    learning: [],
    profile: {},
    showProfile: false,
    showPathway: false,
    showLesson: false,
    showtab: 1,
    // modalIsOpen: false,
  };

  getUserData = () => {
    if (this.validator.allValid()) {
      if (this.state.parameter != "") {
        let searchRequest = "";
        if (this.state.parameter == "email")
          searchRequest = "?email=" + this.state.search;
        if (this.state.parameter == "mobile")
          searchRequest = "?mobile=" + this.state.search;
        if (this.state.parameter == "appleId")
          searchRequest = "?appleId=" + this.state.search;
        if (this.state.parameter == "googleId")
          searchRequest = "?googleId=" + this.state.search;
        const token = localStorage.getItem("token");

        axios
          .get(Constants.api + "customer/dashboard/user" + searchRequest, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.setState({ subscription: res.data.subscription });
              this.setState({
                profile: res.data,
                showProfile: true,
                showPathway: true,
              });
              let learningkeys = Object.keys(this.state.learning);

              let learning = [];

              learningkeys.forEach((key, index) => {
                learning.push(this.state.learning[key]);
              });
              this.setState({
                learning: learning,
              });
            } else {
              // this.setState({alert:res.data.msg});
              swal("User not found");
            }
          });
      } else swal("Please select what to search by");
    } else this.validator.showMessages();
  };

  handleChange = (event) => {
    this.setState({ search: event.target.value });
  };

  handleParameterChange = (event) => {
    this.setState({ parameter: event.target.value });
  };

  // openModal() {
  //   this.setState({
  //     setIsOpen: true,
  //   });
  // }

  render() {
    return (
      <>
        <div class="col-md-12">
          <div class="dashboard_section">
            <div class="dashboard_heading">
              <h1>Dashboard</h1>{" "}
            </div>
            <ul class="nav nav-tabs nav_pricing" id="myTab" role="tablist">
              <li class="nav-item">
                {" "}
                <a
                  class="nav-link active"
                  id="home-tab"
                  data-toggle="tab"
                  href="#home"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  onClick={() => this.setState({ showtab: 1 })}
                >
                  Reports
                </a>{" "}
              </li>
              <li class="nav-item">
                {" "}
                <a
                  class="nav-link"
                  id="profile-tab"
                  data-toggle="tab"
                  href="#profile"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  onClick={() => this.setState({ showtab: 2 })}
                >
                  Learners
                </a>{" "}
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              {/* <div
                class="tab-pane fade active show"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              > */}
              {this.state.showtab == 1 ? (
                <div class="feedback_section">
                  <div
                    class="feedback_info"
                    onClick={() => this.setState({ setIsOpen: true })}
                  >
                    <p>Pathway Feedback</p>
                  </div>
                  <div class="feedback_info">
                    <p>Learner Assessment</p>
                  </div>
                  <div class="feedback_info">
                    <p>Error Feedback</p>
                  </div>
                  <div class="feedback_info">
                    <p>Content Performance</p>
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* </div> */}
              {this.state.showtab == 2 ? (
                <div class="main-scroll main-content pad_30">
                  {/* <div
                  class="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                  > */}
                  <div class="main-content">
                    <div class="learner_info">
                      <p>Learner’s Details*</p>
                      <div class="row">
                        <div class="col-md-2">
                          <div class="form-group from-group-search">
                            <select
                              class="form-control from_search"
                              id="exampleFormControlSelect1"
                              onChange={this.handleParameterChange}
                            >
                              <option value="">Search By</option>
                              <option value="email">Email Address</option>
                              <option value="mobile">Mobile Number</option>
                              <option value="appleId">Apple ID</option>
                              <option value="googleId">Google ID</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6 p-l-0">
                          <div class="input-group m-t-6">
                            <input
                              id="email"
                              type="text"
                              class="form-control"
                              name="search"
                              onChange={this.handleChange}
                              value={this.state.search}
                              placeholder="Enter details here"
                            />{" "}
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="get_data m-t-6">
                            <button
                              type="submit"
                              onClick={this.getUserData}
                              class="btn get_btn"
                            >
                              Get Data
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.state.showProfile ? (
                    <div class="roles_table table_dashboard">
                      <div class="row">
                        <div class="col-sm-12">
                          <table
                            id=""
                            class="table table-bordered table-hover dataTable table_content"
                            role="grid"
                            aria-describedby="example2_info"
                          >
                            <thead>
                              <tr role="row">
                                <th
                                  class="sorting padding-table-columns"
                                  style={{ width: "12%" }}
                                  tabindex="0"
                                  aria-controls="example2"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Created On"
                                >
                                  Full Name
                                </th>
                                <th
                                  class="sorting padding-table-columns"
                                  style={{ width: "13%" }}
                                  tabindex="0"
                                  aria-controls="example2"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Created On"
                                >
                                  Sign Up Date
                                </th>
                                <th
                                  class="sorting"
                                  tabindex="0"
                                  style={{ width: "15%" }}
                                  aria-controls="example2"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Modified On"
                                >
                                  Last Login
                                </th>
                                <th
                                  class="sorting"
                                  style={{ width: "15%" }}
                                  tabindex="0"
                                  aria-controls="example2"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="Title"
                                >
                                  Subscription Type
                                </th>
                                <th
                                  class="sorting_asc"
                                  style={{ width: "16%" }}
                                  tabindex="0"
                                  aria-controls="example2"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="CSS grade: activate to sort column descending"
                                  aria-sort="Actions"
                                >
                                  Subs. Start Date
                                </th>
                                <th
                                  class="sorting_asc"
                                  style={{ width: "16%" }}
                                  tabindex="0"
                                  aria-controls="example2"
                                  rowspan="1"
                                  colspan="1"
                                  aria-label="CSS grade: activate to sort column descending"
                                  aria-sort="Actions"
                                >
                                  Subs. End Date
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr role="row row_space" class="border">
                                <td class="name">
                                  {this.state.profile && this.state.profile.name
                                    ? this.state.profile.name
                                    : ""}
                                </td>
                                <td class="date">
                                  {" "}
                                  {this.state.profile &&
                                  this.state.profile.createdAt
                                    ? moment(
                                        this.state.profile.createdAt
                                      ).format("YYYY-MM-DD")
                                    : ""}
                                </td>
                                <td class="date_time">
                                  {" "}
                                  {this.state.profile &&
                                  this.state.profile.updatedAt
                                    ? moment(
                                        this.state.profile.updatedAt
                                      ).format("YYYY-MM-DD H:i:s")
                                    : ""}
                                </td>
                                <td class="yearly">
                                  {" "}
                                  {this.state.subscription &&
                                  this.state.subscription.stype
                                    ? this.state.subscription.stype
                                    : ""}{" "}
                                </td>
                                <td class="date">
                                  {" "}
                                  {this.state.subscription &&
                                  this.state.subscription.startDate
                                    ? moment(
                                        this.state.subscription.startDate
                                      ).format("YYYY-MM-DD")
                                    : ""}{" "}
                                </td>
                                <td class="date">
                                  {" "}
                                  {this.state.subscription &&
                                  this.state.subscription.nextDate
                                    ? moment(
                                        this.state.subscription.nextDate
                                      ).format("YYYY-MM-DD")
                                    : ""}{" "}
                                </td>
                                <td>
                                  <div class="form-group from-group-search">
                                    <select
                                      class="form-control from_search from_action"
                                      id="exampleFormControlSelect1"
                                    >
                                      <option>Action</option>
                                      <option>Delete User</option>
                                      <option>Cancel Subs.</option>
                                      <option>Regenerate Cert.</option>
                                    </select>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div class="learner_progress m-t-30">
                    <center>
                      <p class="text-center text_details">
                        Learner Progress Details
                      </p>
                    </center>
                    <div class="learner_info learner_details">
                      <div class="container roles_table table_dashboard">
                        <div class="row">
                          <div class="col-sm-12">
                            <table
                              id=""
                              class="table table-bordered table-hover dataTable table_content"
                              role="grid"
                              aria-describedby="example2_info"
                            >
                              <thead>
                                <tr role="row">
                                  <th
                                    class="sorting padding-table-columns"
                                    style={{ width: "18%" }}
                                    tabindex="0"
                                    aria-controls="example2"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Created On"
                                  >
                                    Pathway Title
                                  </th>
                                  <th
                                    class="sorting padding-table-columns"
                                    style={{ width: "22%" }}
                                    tabindex="0"
                                    aria-controls="example2"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Created On"
                                  >
                                    Progress
                                  </th>
                                  <th
                                    class="sorting"
                                    tabindex="0"
                                    style={{ width: "20%" }}
                                    aria-controls="example2"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Modified On"
                                  >
                                    Started On
                                  </th>
                                  <th
                                    class="sorting"
                                    style={{ width: "20%" }}
                                    tabindex="0"
                                    aria-controls="example2"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Title"
                                  >
                                    Completed On
                                  </th>
                                  <th
                                    class="sorting_asc"
                                    style={{ width: "20%" }}
                                    tabindex="0"
                                    aria-controls="example2"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="CSS grade: activate to sort column descending"
                                    aria-sort="Actions"
                                  >
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.learning.map(function (item, i) {
                                  return (
                                    <tr role="row row_space">
                                      <td class="name">{item.name}</td>
                                      <td class="date">100%</td>
                                      <td class="date_time">08/06/20</td>
                                      <td class="yearly">08/06/20</td>
                                      <td class="date">
                                        <button
                                          type="button"
                                          class="btn btn-details"
                                        >
                                          Details
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="learner_progress m-t-30">
                    <center>
                      <p class="text-center text_details">
                        Set Clear Goals For Your Team
                      </p>
                    </center>
                    <div class="learner_info learner_details">
                      <div class="container roles_table table_dashboard">
                        <div class="row">
                          <div class="col-sm-12">
                            <table
                              id=""
                              class="table table-bordered table-hover dataTable table_content"
                              role="grid"
                              aria-describedby="example2_info"
                            >
                              <thead>
                                <tr role="row">
                                  <th
                                    class="sorting padding-table-columns"
                                    style={{ width: "14%" }}
                                    tabindex="0"
                                    aria-controls="example2"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Created On"
                                  >
                                    Lesson Title
                                  </th>
                                  <th
                                    class="sorting padding-table-columns"
                                    style={{ width: "14%" }}
                                    tabindex="0"
                                    aria-controls="example2"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Created On"
                                  >
                                    Type
                                  </th>
                                  <th
                                    class="sorting"
                                    tabindex="0"
                                    style={{ width: "14%" }}
                                    aria-controls="example2"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Modified On"
                                  >
                                    Thrive Skills
                                  </th>
                                  <th
                                    class="sorting"
                                    style={{ width: "14%" }}
                                    tabindex="0"
                                    aria-controls="example2"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="Title"
                                  >
                                    Status
                                  </th>
                                  <th
                                    class="sorting_asc"
                                    style={{ width: "14%" }}
                                    tabindex="0"
                                    aria-controls="example2"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="CSS grade: activate to sort column descending"
                                    aria-sort="Actions"
                                  >
                                    Points Earned
                                  </th>
                                  <th
                                    class="sorting_asc"
                                    style={{ width: "14%" }}
                                    tabindex="0"
                                    aria-controls="example2"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="CSS grade: activate to sort column descending"
                                    aria-sort="Actions"
                                  >
                                    Total Points
                                  </th>
                                  <th
                                    class="sorting_asc"
                                    style={{ width: "14%" }}
                                    tabindex="0"
                                    aria-controls="example2"
                                    rowspan="1"
                                    colspan="1"
                                    aria-label="CSS grade: activate to sort column descending"
                                    aria-sort="Actions"
                                  >
                                    Certificate
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr role="row row_space">
                                  <td class="introduction">Introduction</td>
                                  <td class="content">Content</td>
                                  <td class="public">
                                    Confident Public Speaking
                                  </td>
                                  <td class="complete">Complete</td>
                                  <td class="na_text">N/A</td>
                                  <td class="na_text">N/A</td>
                                  <td class="yearly">Downloaded</td>
                                </tr>
                                <tr role="row row_space">
                                  <td class="introduction">Introduction</td>
                                  <td class="content">Content</td>
                                  <td class="public">
                                    Confident Public Speaking
                                  </td>
                                  <td class="complete">Complete</td>
                                  <td class="na_text">N/A</td>
                                  <td class="na_text">N/A</td>
                                  <td class="yearly">Downloaded</td>
                                </tr>
                                <tr role="row row_space">
                                  <td class="introduction">Introduction</td>
                                  <td class="content">Content</td>
                                  <td class="public">
                                    Confident Public Speaking
                                  </td>
                                  <td class="complete">Complete</td>
                                  <td class="na_text">N/A</td>
                                  <td class="na_text">N/A</td>
                                  <td class="yearly">Shared</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.setIsOpen} portalClassName="dash-modal">
          <div
            className="close-modal"
            onClick={() =>
              this.setState({
                setIsOpen: false,
              })
            }
          >
            <i class="fas fa-times"></i>
          </div>
          <div className="path-modal-container">
            <h3>Pathway Feedback Report</h3>
            <div className="path-modal-contain">
              <div className="form-inline">
                <div className="form-part">
                  <label>Start Date*</label>
                  <input type="date" name="" id="" />
                </div>
                <div className="form-part">
                  <label>End Date*</label>
                  <input type="date" name="" id="" />
                </div>
              </div>
            </div>
            <div className="path-modal-buttons">
              <button className="btn btn-cancel">Cancel</button>
              <button className="btn btn-download">Download</button>
            </div>
          </div>
        </Modal>
        {/* regenerate certificate */}
        <Modal isOpen={false} portalClassName="dash-modal">
          <div
            className="close-modal"
            onClick={() =>
              this.setState({
                setIsOpen: false,
              })
            }
          >
            <i class="fas fa-times"></i>
          </div>
          <div className="path-modal-container">
            <h3>Regenerate Certificate</h3>
            <div className="path-modal-contain">
              <div className="form-inline">
                <div className="form-part">
                  <label>First Name*</label>
                  <input type="text" name="" id="" placeholder="Enter here" />
                </div>
                <div className="form-part">
                  <label>Last Name*</label>
                  <input type="text" name="" id="" placeholder="Enter here" />
                </div>
              </div>
            </div>
            <div className="path-modal-buttons">
              <button className="btn btn-cancel">Cancel</button>
              <button className="btn btn-download">Regenerate</button>
            </div>
          </div>
        </Modal>
        {/* /regenerate certificate */}
      </>
    );
  }
}

export default Dashbaord;
