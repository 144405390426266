import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import CreateCoupon from "./createCoupon.component";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import Switch from "react-switch";
import Table from "react-bootstrap/Table";

class Coupon extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    // this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
  }

  state = {
    name: "",
    sortBy: "name:asc",
    limit: 25,
    totalResults: 0,
    page: 1,
    coupons: [],
    search: "",
    create: 0,
  };

  componentDidMount() {
    const couponsRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        let url = "";
        this.state.search != ""
          ? (url =
              "couponSchemes?name=" +
              this.state.search +
              "&sortBy=" +
              this.state.sortBy +
              "&limit=" +
              this.state.limit +
              "&page=" +
              this.state.page)
          : (url =
              "couponSchemes?sortBy=" +
              this.state.sortBy +
              "&limit=" +
              this.state.limit +
              "&page=" +
              this.state.page);
        const resp = await axios
          .get(Constants.api + url, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              console.log(resp);
              this.setState({
                coupons: resp.data.output.results,
                totalResults: resp.data.totalResults,
              });
              console.log(this.state.users);
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    couponsRequest();
  }
  generateCoupons = () => {
    const token = localStorage.getItem("token");

    axios
      .get(Constants.api + "/coupons/generate", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        //  casetype array

        if (resp.status == 201) {
          swal(resp.data);
          this.componentDidMount();
        } else {
        }
        //movie array
      });
  };

  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount
    );
  };
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ page: pageNumber }, this.componentDidMount);
  }

  render() {
    const generateCSV = (id) => {
      const token = localStorage.getItem("token");

      axios
        .get(Constants.api + "/coupons/createcsv/" + id, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((resp) => {
          //  casetype array
          swal(resp.data);
          this.componentDidMount();

          //movie array
        });
    };
    const publishItem = (id, status) => {
      if (status == "ACTIVATED") status = "DEACTIVATED";
      else status = "ACTIVATED";
      try {
        const token = localStorage.getItem("token");

        axios
          .put(
            Constants.api + "couponSchemes/" + id + "?status=" + status,
            "",

            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((resp) => {
            //  casetype array
            console.log(resp);
            if (resp.status == 200) {
              swal(status + " Succesfully!");
              this.componentDidMount();
              //window.location = "/pathway";
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const editItem = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "couponSchemes/" + id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              this.setState({ create: 1, resp: resp });
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const deleteItem = (id) => {
      if (window.confirm("Are you sure?")) {
        try {
          const token = localStorage.getItem("token");
          const deletedata = new FormData();
          deletedata.append("id", id); //append the values with key, value pair

          axios
            .delete(
              Constants.api + "couponSchemes/" + id,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
              deletedata
            )
            .then((resp) => {
              //  casetype array

              if (resp.status == 204) {
                this.componentDidMount();
              } else {
              }
              //movie array
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
    };

    const couponRender = this.state.coupons.map(function (item, i) {
      return (
        <>
          <tr>
            <td>
              <p>{item.couponType}</p>
            </td>
            <td>
              <p>{item.name}</p>
            </td>
            <td>
              <p>{item.code}</p>
            </td>
            <td>
              <p>{item.startDate.split("T")[0]}</p>
            </td>
            <td>
              <p>{item.endDate.split("T")[0]}</p>
            </td>
            <td>
              <p></p>
            </td>
            <td>
              <p>{item.maxLimit}</p>
            </td>
            <td>
              ({item.generate.count}) {item.generate.status}
            </td>
            <td>
              {item.status == "ACTIVATED" ? (
                item.couponType == "SINGLE" ? (
                  <>
                    {" "}
                    <Switch
                      type="checkbox"
                      class="custom-control-input"
                      id="customSwitchesfive"
                      onChange={() => publishItem(item.id, item.status)}
                      checked={item.status == "ACTIVATED" ? true : false}
                    />
                    <span>Deactivate</span>
                  </>
                ) : (
                  <span style={{ color: "green" }}>Active</span>
                )
              ) : (
                <label>
                  <Switch
                    type="checkbox"
                    class="custom-control-input"
                    id="customSwitchesfive"
                    onChange={() => publishItem(item.id, item.status)}
                    checked={item.status == "ACTIVATED" ? true : false}
                  />
                  <span>Activate</span>
                </label>
              )}
            </td>

            <td>
              {item.generate.status == "COMPLETED" && item.fileUrl != "" ? (
                <a href={item.fileUrl}>
                  <i class="fa fa-download" title="Download File"></i>
                </a>
              ) : (
                <i
                  class="fa fa-refresh"
                  title="Generate CSV"
                  onClick={() => generateCSV(item.id)}
                  aria-hidden="true"
                ></i>
              )}
              &nbsp;
              {item.status == "NEW" ? (
                <i
                  class="fa fa-sm fa-pencil"
                  onClick={() => editItem(item.id)}
                  aria-hidden="true"
                ></i>
              ) : (
                ""
              )}
            </td>
          </tr>
        </>
      );
    });

    return (
      <>
        {this.state.create == 0 ? (
          <>
            <div
              class="tab-pane fade in active show"
              id="v-pills-six"
              role="tabpanel"
              aria-labelledby="v-pills-settings-tab"
            >
              <div class="faculty_section faculty_section">
                <div class="faculty_text">
                  <h3>Coupon Code</h3>{" "}
                </div>
                <div class="btn_create">
                  <button
                    type="button"
                    class="btn btn-create btn-new"
                    onClick={() => this.setState({ create: 1 })}
                  >
                    Add New Coupon
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    class="btn btn-create btn-new"
                    onClick={() => this.generateCoupons()}
                  >
                    Generate Coupons
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-9">
                  <div class="form-group serch_group">
                    <input
                      type="text"
                      class="form-control form-control_one"
                      id="formGroupExampleInput2"
                      placeholder="Search"
                      onChange={this.searchHandleChange}
                    />{" "}
                    <i class="fa fa-search" aria-hidden="true"></i>{" "}
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="from_dropdown">
                    <label for="show">Show entries</label>
                    <select name="cars" id="show">
                      <option
                        value="25"
                        onClick={
                          (() => this.setState({ limit: 25 }),
                          () => this.componentDidMount())
                        }
                      >
                        25
                      </option>
                      <option
                        value="50"
                        onClick={
                          (() => this.setState({ limit: 50 }),
                          () => this.componentDidMount())
                        }
                      >
                        50
                      </option>
                      <option
                        value="75"
                        onClick={
                          (() => this.setState({ limit: 75 }),
                          () => this.componentDidMount())
                        }
                      >
                        75
                      </option>
                      <option
                        value="100"
                        onClick={
                          (() => this.setState({ limit: 100 }),
                          () => this.componentDidMount())
                        }
                      >
                        100
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="box-body table_faculty main-scroll-table main-scroll-table-long">
                <Table responsive="sm" striped={true} hover={true}>
                  <tbody>
                    <tr class="row_table">
                      <th class="designation_text">
                        <p> Type of coupon</p>
                      </th>
                      <th class="designation_text">
                        <p> Name of coupon</p>
                      </th>
                      <th class="designation_text">
                        <p> Coupon Code</p>
                      </th>
                      <th class="create_tabe">
                        <p>Start Date</p>
                      </th>
                      <th class="create_tabe ">
                        <p>End Date</p>
                      </th>
                      <th class="create_tabe ">
                        <p>Total Redemption</p>
                      </th>

                      <th class="create_tabe ">
                        <p>Redemption</p>
                      </th>
                      <th class="create_tabe ">
                        <p>Status</p>
                      </th>
                      <th class="action_text">Activate</th>

                      <th class="action_text">Actions</th>
                    </tr>
                    {couponRender}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="pagination-container">
              <Pagination
                activePage={this.state.page}
                itemsCountPerPage={this.state.limit}
                totalItemsCount={this.state.totalResults}
                pageRangeDisplayed={15}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
          </>
        ) : this.state.resp ? (
          <CreateCoupon resp={this.state.resp} />
        ) : (
          <CreateCoupon />
        )}
      </>
    );
  }
}

export default Coupon;
