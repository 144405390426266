import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import PricingCreate from "./pricingCreate.component";
import moment from "moment";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import swal from "sweetalert";
import Switch from "react-switch";

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    // this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
  }

  state = {
    name: "",
    sortBy: "name:asc",
    limit: 10,
    page: 1,
    type: "ACTIVATED",
    subscriptions: [],
    search: "",
    create: 0,
  };

  componentDidMount() {
    const subRequest = async () => {
      try {
        const token = localStorage.getItem("token");

        const resp = await axios
          .get(
            Constants.api +
              "subscriptions?status=" +
              this.state.type +
              "&sortBy=" +
              this.state.sortBy +
              "&limit=" +
              this.state.limit +
              "&page=" +
              this.state.page,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((resp) => {
            //  casetype array
            if (resp.status == 200) {
              console.log(resp.data.output.results);
              this.setState({ subscriptions: resp.data.output.results });
              console.log(this.state.subscriptions);
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    subRequest();
  }

  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };

  render() {
    const changeStatus = (id, status) => {
      if (status == "ACTIVATED") status = "DEACTIVATED";
      else status = "ACTIVATED";
      try {
        const token = localStorage.getItem("token");

        axios
          .put(
            Constants.api + "subscriptions/" + id + "?status=" + status,
            "",

            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((resp) => {
            //  casetype array
            console.log(resp);
            if (resp.status == 200) {
              swal(status + " Succesfully!");
              this.componentDidMount();
              //window.location = "/pathway";
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    const editItem = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "subscriptions/" + id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              this.setState({ create: 1, resp: resp });
              console.log(this.state.resp);
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const deleteItem = (id) => {
      if (window.confirm("Are you sure?")) {
        try {
          const token = localStorage.getItem("token");
          const deletedata = new FormData();
          deletedata.append("id", id); //append the values with key, value pair

          axios
            .delete(
              Constants.api + "subscriptions/" + id,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
              deletedata
            )
            .then((resp) => {
              //  casetype array

              if (resp.status == 204) {
                this.componentDidMount();
              } else {
              }
              //movie array
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
    };

    let search = this.state.search;
    const subscriptionRender = this.state.subscriptions.map(function (item, i) {
      return (
        <>
          <tr role="row row_space">
            <td class="name">{item.stype}</td>
            <td class="email_id">
              {moment(item.createdAt).format("MM/DD/YY H:ma")}
            </td>
            <td class="admion">
              {moment(item.updatedAt).format("MM/DD/YY H:ma")}
            </td>
            <td class="sorting_1">
              {" "}
              <img
                onClick={() => editItem(item.id)}
                src={require("../../img/pencil.svg").default}
                alt="edit"
              />{" "}
              <img
                onClick={() => deleteItem(item.id)}
                src={require("../../img/delete.png").default}
                alt="img_icon"
              />{" "}
            </td>

            <td class="resend_link">
              <div class="custom-control custom-switch">
                <Switch
                  type="checkbox"
                  class="custom-control-input"
                  id="customSwitchesfive"
                  onChange={() => changeStatus(item.id, item.status)}
                  checked={item.status == "ACTIVATED" ? true : false}
                />
                <label class="custom-control-label" for="customSwitchesfive">
                  Activate
                </label>
              </div>
            </td>
          </tr>
        </>
      );
    });

    return (
      <>
        {this.state.create == 0 ? (
          <>
            <div class="main-head pathway-head pad_30">
              <h3>Pricing</h3>
              <button
                type="button"
                class="btn  btn-create btn-save_cretate pathway-save-btn"
                onClick={() => this.setState({ create: 1 })}
              >
                Create New Subscription
              </button>
            </div>
            <Tabs className="nav-div">
              <TabList className="nav-tabs">
                <Tab
                  className="pathway-tabs nav-link"
                  onClick={() =>
                    this.setState({ type: "ACTIVATED" }, this.componentDidMount)
                  }
                >
                  Activated
                </Tab>
                <Tab
                  className="pathway-tabs nav-link"
                  onClick={() =>
                    this.setState({ type: "DRAFT" }, this.componentDidMount)
                  }
                >
                  Draft
                </Tab>
                <Tab
                  className="pathway-tabs nav-link"
                  onClick={() =>
                    this.setState(
                      { type: "DEACTIVATED" },
                      this.componentDidMount
                    )
                  }
                >
                  Deactivated
                </Tab>
              </TabList>
              <div className="pad_30">
                {/* {*Activated*} */}

                <TabPanel className="tab-pane">
                  <div class="listing-info">
                    <div class="listing-left">
                      <div class="search-bar">
                        <input
                          type="search"
                          name="search"
                          value={this.state.search}
                          onChange={this.searchHandleChange}
                          id="search"
                          class="search-pathway"
                          placeholder="Search"
                        />
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="listing-right">
                      Show entries
                      <select name="cars" id="show">
                        <option
                          value="25"
                          onClick={
                            (() => this.setState({ limit: 25 }),
                            () => this.componentDidMount())
                          }
                        >
                          25
                        </option>
                        <option
                          value="50"
                          onClick={
                            (() => this.setState({ limit: 50 }),
                            () => this.componentDidMount())
                          }
                        >
                          50
                        </option>
                        <option
                          value="75"
                          onClick={
                            (() => this.setState({ limit: 75 }),
                            () => this.componentDidMount())
                          }
                        >
                          75
                        </option>
                        <option
                          value="100"
                          onClick={
                            (() => this.setState({ limit: 100 }),
                            () => this.componentDidMount())
                          }
                        >
                          100
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="roles_table">
                    <div class="row">
                      <div class="col-sm-12">
                        <table
                          id=""
                          class="table table-hover dataTable table_content main-scroll-table pricing-list"
                          role="grid"
                          aria-describedby="example2_info"
                        >
                          <thead>
                            <tr role="row">
                              <th
                                class="sorting padding-table-columns"
                                style={{ width: "30%" }}
                                tabindex="0"
                                aria-controls="example2"
                                rowspan="1"
                                colspan="1"
                                aria-label="Created On"
                              >
                                Subscription Name
                              </th>
                              <th
                                class="sorting padding-table-columns"
                                style={{ width: "20%" }}
                                tabindex="0"
                                aria-controls="example2"
                                rowspan="1"
                                colspan="1"
                                aria-label="Created On"
                              >
                                Start Date
                              </th>
                              <th
                                class="sorting"
                                tabindex="0"
                                style={{ width: "30%" }}
                                aria-controls="example2"
                                rowspan="1"
                                colspan="1"
                                aria-label="Modified On"
                              >
                                End Date
                              </th>
                              <th
                                class="sorting"
                                style={{ width: "10%" }}
                                tabindex="0"
                                aria-controls="example2"
                                rowspan="1"
                                colspan="1"
                                aria-label="Title"
                              >
                                Actions
                              </th>
                              <th
                                class="sorting_asc"
                                style={{ width: "10%" }}
                                tabindex="0"
                                aria-controls="example2"
                                rowspan="1"
                                colspan="1"
                                aria-label="CSS grade: activate to sort column descending"
                                aria-sort="Actions"
                              ></th>
                            </tr>
                          </thead>
                          <tbody>{subscriptionRender}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                {/* {*DRAFT*} */}
                <TabPanel className="tab-pane">
                  <div class="listing-info">
                    <div class="listing-left">
                      <div class="search-bar">
                        <input
                          type="search"
                          name="search"
                          value={this.state.search}
                          onChange={this.searchHandleChange}
                          id="search"
                          class="search-pathway"
                          placeholder="Search"
                        />
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="listing-right">
                      Show entries
                      <select name="cars" id="show">
                        <option
                          value="25"
                          onClick={
                            (() => this.setState({ limit: 25 }),
                            () => this.componentDidMount())
                          }
                        >
                          25
                        </option>
                        <option
                          value="50"
                          onClick={
                            (() => this.setState({ limit: 50 }),
                            () => this.componentDidMount())
                          }
                        >
                          50
                        </option>
                        <option
                          value="75"
                          onClick={
                            (() => this.setState({ limit: 75 }),
                            () => this.componentDidMount())
                          }
                        >
                          75
                        </option>
                        <option
                          value="100"
                          onClick={
                            (() => this.setState({ limit: 100 }),
                            () => this.componentDidMount())
                          }
                        >
                          100
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="roles_table">
                    <div class="row">
                      <div class="col-sm-12">
                        <table
                          id=""
                          class="table table-hover dataTable table_content main-scroll-table pricing-list"
                          role="grid"
                          aria-describedby="example2_info"
                        >
                          <thead>
                            <tr role="row">
                              <th
                                class="sorting padding-table-columns"
                                style={{ width: "30%" }}
                                tabindex="0"
                                aria-controls="example2"
                                rowspan="1"
                                colspan="1"
                                aria-label="Created On"
                              >
                                Subscription Name
                              </th>
                              <th
                                class="sorting padding-table-columns"
                                style={{ width: "20%" }}
                                tabindex="0"
                                aria-controls="example2"
                                rowspan="1"
                                colspan="1"
                                aria-label="Created On"
                              >
                                Start Date
                              </th>
                              <th
                                class="sorting"
                                tabindex="0"
                                style={{ width: "30%" }}
                                aria-controls="example2"
                                rowspan="1"
                                colspan="1"
                                aria-label="Modified On"
                              >
                                End Date
                              </th>
                              <th
                                class="sorting"
                                style={{ width: "10%" }}
                                tabindex="0"
                                aria-controls="example2"
                                rowspan="1"
                                colspan="1"
                                aria-label="Title"
                              >
                                Actions
                              </th>
                              <th
                                class="sorting_asc"
                                style={{ width: "10%" }}
                                tabindex="0"
                                aria-controls="example2"
                                rowspan="1"
                                colspan="1"
                                aria-label="CSS grade: activate to sort column descending"
                                aria-sort="Actions"
                              ></th>
                            </tr>
                          </thead>
                          <tbody>{subscriptionRender}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </TabPanel>
                {/* {*Deactivated*} */}

                <TabPanel className="tab-pane">
                  <div class="listing-info">
                    <div class="listing-left">
                      <div class="search-bar">
                        <input
                          type="search"
                          name="search"
                          value={this.state.search}
                          onChange={this.searchHandleChange}
                          id="search"
                          class="search-pathway"
                          placeholder="Search"
                        />
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </div>
                    </div>
                    <div class="listing-right">
                      Show entries
                      <select name="cars" id="show">
                        <option
                          value="25"
                          onClick={
                            (() => this.setState({ limit: 25 }),
                            () => this.componentDidMount())
                          }
                        >
                          25
                        </option>
                        <option
                          value="50"
                          onClick={
                            (() => this.setState({ limit: 50 }),
                            () => this.componentDidMount())
                          }
                        >
                          50
                        </option>
                        <option
                          value="75"
                          onClick={
                            (() => this.setState({ limit: 75 }),
                            () => this.componentDidMount())
                          }
                        >
                          75
                        </option>
                        <option
                          value="100"
                          onClick={
                            (() => this.setState({ limit: 100 }),
                            () => this.componentDidMount())
                          }
                        >
                          100
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="roles_table">
                    <div class="row">
                      <div class="col-sm-12">
                        <table
                          id=""
                          class="table table-hover dataTable table_content main-scroll-table pricing-list"
                          role="grid"
                          aria-describedby="example2_info"
                        >
                          <thead>
                            <tr role="row">
                              <th
                                class="sorting padding-table-columns"
                                style={{ width: "30%" }}
                                tabindex="0"
                                aria-controls="example2"
                                rowspan="1"
                                colspan="1"
                                aria-label="Created On"
                              >
                                Subscription Name
                              </th>
                              <th
                                class="sorting padding-table-columns"
                                style={{ width: "20%" }}
                                tabindex="0"
                                aria-controls="example2"
                                rowspan="1"
                                colspan="1"
                                aria-label="Created On"
                              >
                                Start Date
                              </th>
                              <th
                                class="sorting"
                                tabindex="0"
                                style={{ width: "30%" }}
                                aria-controls="example2"
                                rowspan="1"
                                colspan="1"
                                aria-label="Modified On"
                              >
                                End Date
                              </th>
                              <th
                                class="sorting"
                                style={{ width: "10%" }}
                                tabindex="0"
                                aria-controls="example2"
                                rowspan="1"
                                colspan="1"
                                aria-label="Title"
                              >
                                Actions
                              </th>
                              <th
                                class="sorting_asc"
                                style={{ width: "10%" }}
                                tabindex="0"
                                aria-controls="example2"
                                rowspan="1"
                                colspan="1"
                                aria-label="CSS grade: activate to sort column descending"
                                aria-sort="Actions"
                              ></th>
                            </tr>
                          </thead>
                          <tbody>{subscriptionRender}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </div>
            </Tabs>
          </>
        ) : this.state.resp ? (
          <PricingCreate resp={this.state.resp} />
        ) : (
          <PricingCreate />
        )}
      </>
    );
  }
}

export default Pricing;
