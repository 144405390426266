import React, { Component } from "react";
import SearchInput from "react-search-input";
import axios from "axios";
import swal from "sweetalert";
import * as Constants from "../../Constants/index";
import SimpleReactValidator from "simple-react-validator";
import DatePicker from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";

// import emails from './mails'

// const KEYS_TO_FILTERS = ['user.name', 'subject', 'dest.name']

class pricingCreate extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      stype: this.props.resp ? this.props.resp.data.output.stype : "Yearly",
      basePrice: this.props.resp ? this.props.resp.data.output.basePrice : "",
      actualPrice: this.props.resp
        ? this.props.resp.data.output.actualPrice
        : "",
      discount: this.props.resp ? this.props.resp.data.output.discount : "",

      status: "DRAFT",
      start: this.props.resp
        ? moment(this.props.resp.data.output.start).format("YYYY-MM-DD")
        : "",
      end: this.props.resp
        ? moment(this.props.resp.data.output.end).format("YYYY-MM-DD")
        : "",
      create: 1,
    };
  }

  handleFormSubmit = (event) => {
    event.preventDefault();

    if (this.validator.allValid()) {
      const token = localStorage.getItem("token");
      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "subscriptions/" + this.props.resp.data.output.id,
            {
              stype: this.state.stype,
              basePrice: this.state.basePrice,
              actualPrice: this.state.actualPrice,
              start: moment(this.state.start).format("YYYY-MM-DD"),
              end: moment(this.state.end).format("YYYY-MM-DD"),
              status: this.state.status,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Subscription Updated");
              this.setState({ create: 0 });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      } else {
        axios
          .post(
            Constants.api + "subscriptions",
            {
              stype: this.state.stype,
              basePrice: this.state.basePrice,
              actualPrice: this.state.actualPrice,
              start: this.state.start,
              end: this.state.end,
              status: this.state.status,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Subscriptions Added");

              window.location = "/pricing";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };
  starthandleChange = (date) => {
    this.setState({
      start: date,
    });
  };
  endhandleChange = (date) => {
    this.setState({
      end: date,
    });
  };
  render() {
    // const filteredEmails = emails.filter(createFilter(this.state.searchTerm, KEYS_TO_FILTERS))
    if (this.state.create == 0) {
      window.location = "/pricing";
    }

    return (
      <>
        <div class="category_section">
          <div class="faculty_sections">
            <div class="Faculty_text">
              <p onClick={() => this.setState({ create: 0 })}>
                Subscriptions /
                <span>
                  {" "}
                  {this.props.resp ? "Edit" : "Create"} Subscriptions
                </span>
              </p>
            </div>
          </div>
          <div class="faculty_section">
            <div class="faculty_text">
              <h3>
                <i
                  class="fa fa-arrow-left"
                  onClick={() => this.setState({ create: 0 })}
                  aria-hidden="true"
                ></i>{" "}
                {this.props.resp ? "Edit" : "Create"} Subscriptions
              </h3>{" "}
            </div>
            <div class="btn_create">
              <button
                type="submit"
                onClick={this.handleFormSubmit}
                class="btn btn-primary  btn_pubish"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                {" "}
                Save{" "}
              </button>
              <button
                type="button"
                onClick={() => this.setState({ create: 0 })}
                class="btn btn-primary btn_category"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                Close
              </button>
            </div>
          </div>
          <div class="pad_35">
            <div class="pathway-builder-form">
              <div class="form-group">
                {/* <label class="mar-20 bold-label">Lesson 1</label> */}
                <div class="inline-form-full">
                  <label for="exampleFormControlInput1">
                    Subscription Type*
                  </label>
                  <select
                    name="stype"
                    value={this.state.stype}
                    onChange={this.stypeHandleChange}
                  >
                    <option value="Yearly">Yearly</option>
                    <option value="Monthly">Monthly</option>
                  </select>
                </div>
                <div class="inline-form-full">
                  <label for="exampleFormControlInput1">Base Price</label>
                  <div>
                    <input
                      class="form-control collapse-search"
                      id="inputAddress"
                      type="number"
                      name="basePrice"
                      value={this.state.basePrice}
                      onChange={this.basePriceHandleChange}
                      placeholder="Type Base Price"
                    />
                  </div>
                  {this.validator.message(
                    "Base Price",
                    this.state.basePrice,
                    "required"
                  )}
                </div>
                <div class="inline-form-full">
                  <label for="exampleFormControlInput1">Actual Price</label>
                  <div>
                    <input
                      class="form-control collapse-search"
                      id="inputAddress"
                      type="number"
                      name="actualPrice"
                      value={this.state.actualPrice}
                      onChange={this.actualPriceHandleChange}
                      placeholder="Type Actual Price"
                    />
                  </div>
                  {this.validator.message(
                    "Actual Price",
                    this.state.actualPrice,
                    "required"
                  )}
                </div>
                <div class="inline-form-full">
                  <label for="exampleFormControlInput1">Discount</label>
                  <div>
                    <input
                      class="form-control collapse-search"
                      id="inputAddress"
                      type="text"
                      name="disocunt"
                      disabled
                      value={this.state.discount}
                      onChange={this.discountHandleChange}
                      placeholder="Discount"
                    />
                  </div>
                </div>
                <div class="inline-form-full">
                  <label for="exampleFormControlInput1">
                    Choose Start Date*{" "}
                  </label>
                  {this.state.start == "" ? (
                    <DatePicker
                      name="start"
                      // isValidDate={disablePastDt}
                      selected={this.state.start}
                      shouldCloseOnSelect={true}
                      onChange={this.starthandleChange}
                      dateFormat="YYYY-MM-DD"
                      placeholderText="Date"
                      class="form-control required"
                    />
                  ) : (
                    moment(this.state.start).format("YYYY-MM-DD")
                  )}

                  <a
                    class="btn btn-sm"
                    onClick={() => this.setState({ start: "" })}
                  >
                    Change Date
                  </a>
                </div>

                {this.validator.message(
                  "Start Date",
                  this.state.start,
                  "required"
                )}
                <div class="inline-form-full">
                  <label for="exampleFormControlInput1">
                    Choose End Date*{" "}
                  </label>
                  {this.state.end == "" ? (
                    <DatePicker
                      name="end"
                      // isValidDate={disablePastDt}
                      selected={this.state.end}
                      shouldCloseOnSelect={true}
                      onChange={this.endhandleChange}
                      dateFormat="YYYY-MM-DD"
                      placeholderText="Date"
                      class="form-control required"
                    />
                  ) : (
                    moment(this.state.end).format("YYYY-MM-DD")
                  )}

                  <a
                    class="btn btn-sm"
                    onClick={() => this.setState({ end: "" })}
                  >
                    Change End Date
                  </a>
                </div>

                {this.validator.message("End Date", this.state.end, "required")}

                <div>
                  {/* <button type="button" class="btn btn-create upload-btn">
                      Add More Lesson
                    </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  stypeHandleChange = (event) => {
    this.setState({ stype: event.target.value });
  };
  basePriceHandleChange = (event) => {
    this.setState({ basePrice: event.target.value });
  };
  actualPriceHandleChange = (event) => {
    this.setState({ actualPrice: event.target.value });
  };
  discountHandleChange = (event) => {
    this.setState({ discount: event.target.value });
  };
}

export default pricingCreate;
