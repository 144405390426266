import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import SearchInput from "react-search-input";

class CreatePersonal extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.roleTypeHandleChange = this.roleTypeHandleChange.bind(this);
    this.skillInterestHandleChange = this.skillInterestHandleChange.bind(this);

    this.thriveSkillUpdated = this.thriveSkillUpdated.bind(this);

    this.state = {
      userType: this.props.resp ? this.props.resp.data.userType : "",
      roleType: this.props.resp ? this.props.resp.data.roleType : "",
      skillInterest: this.props.resp ? this.props.resp.data.skillInterest : "",
      addedThriveSkill: this.props.resp ? this.props.resp.data.pathways : [],
      thriveSkills: [],
      thriveskillSearchTerm: "",
      sortBy: "name:asc",
      limit: 1000,
      page: 1,
      faculty: [],
      search: "",
      create: 1,
    };
  }

  userTypeHandleChange = (event) => {
    this.setState({ userType: event.target.value });
  };

  roleTypeHandleChange = (event) => {
    this.setState({ roleType: event.target.value });
  };
  skillInterestHandleChange = (event) => {
    this.setState({ skillInterest: event.target.value });
  };
  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      let thriveskill = [];
      let tsk = this.state.addedThriveSkill;
      tsk.map(function (item, i) {
        thriveskill.push(item.id);
        //Do something
      });
      const token = localStorage.getItem("token");
      if (this.state.userType == "student") this.state.roleType = "";
      if (this.props.resp) {
        console.log(this.props.resp);
        axios
          .patch(
            Constants.api + "personalAccesss/" + this.props.resp.data.id,
            {
              userType: this.state.userType,
              roleType: this.state.roleType,
              skillInterest: thriveskill,
              pathways: this.state.thriveSkills,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Personal assesssment Updated");
              this.setState({ create: 0 });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      } else {
        axios
          .post(
            Constants.api + "personalAccesss",
            {
              userType: this.state.userType,
              roleType: this.state.roleType,
              skillInterest: this.state.skillInterest,
              pathways: thriveskill,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("personal Access Added");
              document.getElementById("form").reset();
              this.setState({
                userType: "",
                roleType: "",
                skillInterest: "",
                thriveSkills: [],
                addedThriveSkill: [],
              });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };

  render() {
    console.log(this.props.resp);

    if (this.state.create == 0) {
      window.location = "/personal-assessment";
    }

    let search = this.state.search;

    return (
      <>
        <>
          <form onSubmit={this.handleFormSubmit} id="form">
            <div class="add_video_section add_video">
              <div class="faculty_sections">
                <div class="Faculty_text">
                  <p>
                    Personal Assessment/<span>Create</span>
                  </p>
                </div>
              </div>
              <div class="faculty_section">
                <div class="faculty_text">
                  <h3>
                    <i
                      class="fa fa-arrow-left"
                      onClick={() => this.setState({ create: 0 })}
                      aria-hidden="true"
                    ></i>
                    Personal Assessment{" "}
                  </h3>{" "}
                </div>
                <div class="btn_create">
                  <button
                    type="submit"
                    class="btn btn-primary btn_category btn_vidios"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    Save
                  </button>
                </div>
              </div>

              <div class="mcq_section main-scroll-table">
                <div class="container video_sections personal-assess-contain">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="template_vidio_name">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="template_name image-template-name">
                              <div class="template_sections">
                                <div class="template_text">
                                  <p class="info-template">
                                    <label>Usertype</label>
                                    <select
                                      class="js-example-basic-multipleones form-control "
                                      name="userType"
                                      onChange={this.userTypeHandleChange}
                                      value={this.state.userType}
                                      placeholder="Type here"
                                    >
                                      {" "}
                                      <i
                                        class="fa fa-plus-square-o"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      <option value="">Select</option>
                                      <option value="student">Student</option>
                                      <option value="working">Working</option>
                                    </select>
                                  </p>
                                  {this.validator.message(
                                    "userType",
                                    this.state.userType,
                                    "required"
                                  )}
                                </div>
                                {/* <div class="number-range">
                                  <p>{this.state.userType.length}/40</p>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="template_vidio_name">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="template_name image-template-name">
                              <div class="template_sections">
                                <div class="template_text">
                                  <p class="info-template">
                                    <label>Role Type</label>
                                    <select
                                      class="js-example-basic-multipleones form-control "
                                      name="roleType"
                                      onChange={this.roleTypeHandleChange}
                                      value={this.state.roleType}
                                      placeholder="Type here"
                                    >
                                      {" "}
                                      <i
                                        class="fa fa-plus-square-o"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      <option value="">Role Type</option>
                                      <option value="Individual Contributor">
                                        Individual Contributor
                                      </option>
                                      <option value="Managing small teams">
                                        Managing small teams
                                      </option>
                                      <option value="Managing large teams">
                                        Managing large teams
                                      </option>
                                    </select>
                                  </p>
                                </div>
                                {/* <div class="number-range">
                                  <p>{this.state.userType.length}/40</p>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="template_vidio_name">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="template_name image-template-name">
                              <div class="template_sections">
                                <div class="template_text">
                                  <p class="info-template">
                                    <label>Skill Interest</label>
                                    <select
                                      class="js-example-basic-multipleones form-control "
                                      name="skillInterest"
                                      onChange={this.skillInterestHandleChange}
                                      value={this.state.skillInterest}
                                      placeholder="Type here"
                                    >
                                      {" "}
                                      <i
                                        class="fa fa-plus-square-o"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      <option value="">Select</option>
                                      <option value="Productivity">
                                        Productivity
                                      </option>
                                      <option value="Career Planning">
                                        Career Planning
                                      </option>
                                      <option value="Workplace Effectiveness">
                                        Workplace Effectiveness
                                      </option>
                                      <option value="Personal Brand-Building">
                                        Personal Brand-Building
                                      </option>
                                      <option value="Productivity">
                                        Productivity
                                      </option>
                                      <option value="Leadership">
                                        Leadership
                                      </option>
                                      <option value="Effective Communication">
                                        Effective Communication
                                      </option>
                                      <option value="Interview Skills">
                                        Interview Skills
                                      </option>
                                      <option value="Collaboration">
                                        Collaboration
                                      </option>
                                    </select>
                                  </p>
                                  {this.validator.message(
                                    "skillInterest",
                                    this.state.skillInterest,
                                    "required"
                                  )}
                                </div>
                                {/* <div class="number-range">
                                  <p>{this.state.userType.length}/40</p>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="template_vidio_name pathways-personal-box">
                        <div class="template_sections">
                          <div class="template_text">
                            <p>Enter Pathways*</p>
                          </div>
                        </div>
                        <div class="input_section">
                          <div class="input_text">
                            <SearchInput
                              class="form-control collapse-search"
                              id="thriveskillInput"
                              onChange={this.thriveSkillUpdated}
                              placeholder="Type to add Pathways"
                              value={this.state.thriveskillSearchTerm}
                              autoComplete="off"
                            />
                          </div>
                          {this.validator.message(
                            "Pathways",
                            this.state.addedThriveSkill,
                            "required"
                          )}
                        </div>
                        {this.state.thriveSkills.map((item) => {
                          return (
                            <>
                              <div class="dropdown_img">
                                <a
                                  class="dropdown-item"
                                  onClick={() => this.addThriveSkill(item)}
                                  href="#"
                                >
                                  <img src={item.icon} />
                                  <span class="text_vidio_name">
                                    {item.name}
                                  </span>{" "}
                                  {/* <span aria-hidden="true">×</span> */}
                                </a>
                              </div>
                            </>
                          );
                        })}

                        <div class="">
                          {this.state.addedThriveSkill.map((item, index) => (
                            <>
                              <a class="dropdown-item drop-img" href="#">
                                <img src={item.icon} width="10px" />
                                <span class="text_vidio_name">
                                  {item.name}
                                </span>{" "}
                                <span
                                  aria-hidden="true"
                                  onClick={() => this.removeThriveSkill(item)}
                                >
                                  <i class="fa fa-times" aria-hidden="true"></i>
                                </span>
                              </a>
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      </>
    );
  }
  addThriveSkill(item) {
    console.log(item);
    if (
      this.state.addedThriveSkill.some((val) => item.name === val.name) ===
      false
    )
      this.state.addedThriveSkill.push(item);
    this.state.thriveSkills = [];
    this.state.thriveskillSearchTerm = "";
  }
  removeThriveSkill(item) {
    this.state.addedThriveSkill.splice(
      this.state.addedThriveSkill.indexOf(item),
      1
    );
  }
  thriveSkillUpdated(term) {
    const token = localStorage.getItem("token");
    if (term.length > 0) {
      axios
        .get(
          Constants.api +
            "pathways?name=" +
            term +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=10&page=" +
            this.state.page,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            if (this.state.addedThriveSkill.length != 0) {
              this.state.addedThriveSkill.forEach((thriveskill) => {
                console.log(this.state.addedThriveSkill.length);
                let index = res.data.output.results.indexOf(thriveskill);
                const newThriveskill = res.data.output.results;
                //newThriveskill.splice(index, 1);
                this.setState({ thriveSkills: newThriveskill });
              });
            } else {
              this.setState({ thriveSkills: res.data.output.results });
            }
          }
        });
      this.setState({ thriveskillSearchTerm: term });
    } else {
      this.setState({
        contthriveSkillsents: [],
      });
    }
  }
}

export default CreatePersonal;
