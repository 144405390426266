import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import SearchInput from "react-search-input";

class CreateImage extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      name: this.props.resp ? this.props.resp.data.name : "",
      description: this.props.resp ? this.props.resp.data.description : "",
      video: this.props.resp ? this.props.resp.data.nuggetImage.url : "",
      title: this.props.resp ? this.props.resp.data.title : "",
      type: "IMAGE",
      sortBy: "name:asc",
      limit: 1000,
      page: 1,

      create: 1,
      xpPoints: this.props.resp ? this.props.resp.data.xpPoints : 0,
      duration: this.props.resp ? this.props.resp.data.duration : 30,

      addedFaculty: this.props.resp ? this.props.resp.data.facultys : [],
      faculties: [],
      facultySearchTerm: "",

      addedThriveSkill: this.props.resp
        ? this.props.resp.data.thriveSkills
        : [],
      thriveSkills: [],
      thriveskillSearchTerm: "",

      dailychallenge: this.props.resp
        ? this.props.resp.data.dailyChallenge
        : false,
      preview: 0,
    };
    this.facultyUpdated = this.facultyUpdated.bind(this);
    this.thriveSkillUpdated = this.thriveSkillUpdated.bind(this);
  }

  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };
  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  titleHandleChange = (event) => {
    this.setState({ title: event.target.value });
  };
  descriptionHandleChange = (event) => {
    this.setState({ description: event.target.value });
  };
  videoHandleChange = (event) => {
    this.setState({ video: event.target.value });
  };
  xpPointsHandleChange = (event) => {
    this.setState({ xpPoints: event.target.value });
  };
  durationHandleChange = (event) => {
    this.setState({ duration: event.target.value });
  };
  dcChange = () => {
    this.setState({ dailychallenge: !this.state.dailychallenge });
  };

  // Form submit start
  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      let thriveskill = [];
      let tsk = this.state.addedThriveSkill;
      tsk.map(function (item, i) {
        thriveskill.push(item.id);
        //Do something
      });

      let facultys = [];
      let options = this.state.addedFaculty;
      options.map(function (item, i) {
        facultys.push(item.id);
        //Do something
      });

      const token = localStorage.getItem("token");
      if (this.props.resp) {
        axios
          .patch(
            Constants.api + "contentUnits/" + this.props.resp.data.id,
            {
              name: this.state.name,
              title: this.state.title,
              description: this.state.description,
              nuggetType: this.state.type,
              xpPoints: this.state.xpPoints,
              duration: this.state.duration,
              dailyChallenge: this.state.dailychallenge,
              facultys: facultys,
              thriveSkills: thriveskill,
              nuggetImage: {
                url: this.state.video,
              },
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Image Added");
              this.setState({ create: 0 });
            }
          })
          .catch((err) => {
            if (err.response) {
              swal(err.response.data.message);
            }
          });
      } else {
        axios
          .post(
            Constants.api + "contentUnits",
            {
              name: this.state.name,
              title: this.state.title,
              description: this.state.description,
              nuggetType: this.state.type,
              xpPoints: this.state.xpPoints,
              duration: this.state.duration,
              dailyChallenge: this.state.dailychallenge,
              facultys: facultys,
              thriveSkills: thriveskill,
              nuggetImage: {
                url: this.state.video,
              },
            },

            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Image Added");

              this.setState({
                name: "",
                video: "",
                description: "",
                title: "",
                xpPoints: 0,
                duration: 0,
                addedFaculty: [],
                addedThriveSkill: [],
                preview: 0,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              swal(err.response.data.message);
            }
          });
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };
  // Form submit ends

  render() {
    if (this.state.create == 0) {
      window.location = "/content";
    }
    //   const selectedtRender=
    return (
      <>
        {this.state.preview == 0 ? (
          <div class="add_video_section">
            <div class="faculty_sections">
              <div class="Faculty_text">
                <p>
                  Content/<span>Add Image</span>
                </p>
              </div>
            </div>
            <div class="faculty_section">
              <div class="faculty_text">
                <h3>
                  <i
                    class="fa fa-arrow-left"
                    onClick={() => this.setState({ create: 0 })}
                    aria-hidden="true"
                  ></i>
                  Add Image
                </h3>{" "}
              </div>
            </div>
            <ol class="track-progress">
              <li class="done">
                <div class="blue-dot"></div> <span>Create</span>{" "}
              </li>
              <li class="todo" onClick={() => this.setState({ preview: 1 })}>
                <div class="blue-dot"></div> <span>Preview</span>{" "}
              </li>
            </ol>
            <div class="drop_vidio text-center">
              <input
                type="text"
                class="btn copy_btn form-control"
                name="video"
                value={this.state.video}
                onChange={this.videoHandleChange}
                placeholder="Copy &amp; Paste HLS Link Here"
                id="txtImage"
                autoComplete="off"
              />
            </div>
            {this.validator.message("Image", this.state.video, "required")}
            <button
              type="button"
              class="btn next_btn float-right"
              onClick={() => this.addImageNext()}
            >
              Next
            </button>
          </div>
        ) : (
          <div class="add_video_section add_video">
            <div class="faculty_sections">
              <div class="Faculty_text">
                <p>
                  Content Listing/<span>Image</span>
                </p>
              </div>
            </div>
            <div class="faculty_section">
              <div class="faculty_text">
                <h3>
                  <i
                    class="fa fa-arrow-left"
                    onClick={() => this.setState({ create: 0 })}
                    aria-hidden="true"
                  ></i>
                  Add Image
                </h3>{" "}
              </div>
              <div class="btn_create">
                <button
                  type="submit"
                  onClick={this.handleFormSubmit}
                  class="btn btn-primary btn_category btn_vidios"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  Save
                </button>
              </div>
            </div>
            <ol class="track-progress">
              <li class="done">
                <div class="blue-dot"></div> <span>Create</span>{" "}
              </li>
              <li class="todo">
                <div class="blue-dot"></div> <span>Preview</span>{" "}
              </li>
            </ol>
            <div class="mcq_section main-scroll-table">
              <div class="container video_sections">
                <div class="row">
                  <div class="col-md-3">
                    <div class="img-container">
                      <img src={this.state.video} />
                    </div>
                    <button
                      type="button"
                      class="btn btn-light btn_repload"
                      onClick={() => this.setState({ preview: 0 })}
                    >
                      <ion-icon name="refresh-outline"></ion-icon> Reupload
                    </button>
                    <p class="upload_icon_text">
                      {" "}
                      <i class="fa fa-check-circle" aria-hidden="true"></i>1
                      image has been uploaded.
                    </p>
                  </div>
                  <div class="col-md-9">
                    <div class="template_vidio_name">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="template_name image-template-name">
                            <div class="template_sections">
                              <div class="template_text">
                                <p class="info-template">
                                  Template Name*
                                  <img
                                    src={
                                      require("../../img/ionic-ios-information-circle-outline@2x.png")
                                        .default
                                    }
                                    alt="img_circle"
                                    class="img_line"
                                  />
                                </p>
                              </div>
                              <div class="number-range">
                                <p>{this.state.name.length}/100</p>
                              </div>
                            </div>
                            <div class="input_section">
                              <div class="input_text">
                                <input
                                  type="text"
                                  name="name"
                                  value={this.state.name}
                                  onChange={this.nameHandleChange}
                                  class="form-control"
                                  placeholder="Template Name"
                                  maxLength="100"
                                  id=""
                                />{" "}
                              </div>
                              {this.validator.message(
                                "Name",
                                this.state.name,
                                "required"
                              )}
                            </div>
                            <div class="template_sections">
                              <div class="template_text">
                                <p class="info-template">Title</p>
                              </div>
                              <div class="number-range">
                                <p>{this.state.title.length}/40</p>
                              </div>
                            </div>
                            <div class="input_section">
                              <div class="input_text">
                                <input
                                  type="text"
                                  class="form-control full-width"
                                  name="title"
                                  value={this.state.title}
                                  onChange={this.titleHandleChange}
                                  id="exampleInputEmail1"
                                  placeholder="Title"
                                  maxLength="100"
                                />{" "}
                              </div>
                            </div>
                            <div class="template_sections">
                              <div class="template_text">
                                <p class="info-template">Description</p>
                              </div>
                              <div class="number-range">
                                <p>{this.state.description.length}/150</p>
                              </div>
                            </div>
                            <div class="input_section">
                              <div class="input_text">
                                <div class="form-group">
                                  <textarea
                                    class="form-control"
                                    id=""
                                    rows="3"
                                    name="description"
                                    value={this.state.description}
                                    onChange={this.descriptionHandleChange}
                                    placeholder="Description"
                                    maxLength="150"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="image-template-name image-template-name-2">
                            {/* Add Faculty */}
                            <div class="template_sections">
                              <div class="template_text">
                                <p>Enter Faculty Name</p>
                              </div>
                            </div>

                            <div class="input_section">
                              <div class="input_text">
                                <SearchInput
                                  class="form-control collapse-search"
                                  id="facultyInput"
                                  onChange={this.facultyUpdated}
                                  placeholder="Type to add Faculty"
                                  value={this.state.facultySearchTerm}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {this.state.faculties.map((item) => {
                              return (
                                <>
                                  <div class="dropdown_img">
                                    <a
                                      class="dropdown-item"
                                      onClick={() => this.addFaculty(item)}
                                      href="#"
                                    >
                                      <img src={item.img} />
                                      <span class="text_vidio_name">
                                        {item.name}
                                      </span>{" "}
                                      {/* <span aria-hidden="true">×</span> */}
                                    </a>
                                  </div>
                                </>
                              );
                            })}
                            <div class="">
                              {this.state.addedFaculty.map((item, index) => (
                                <>
                                  <a class="dropdown-item drop-img" href="#">
                                    <img src={item.img} />
                                    <span class="text_vidio_name">
                                      {item.name}
                                    </span>{" "}
                                    <span
                                      aria-hidden="true"
                                      onClick={() => this.removeFaculty(item)}
                                    >
                                      <i
                                        class="fa fa-times"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </a>
                                </>
                              ))}
                            </div>

                            {/* Add Thrive Skills */}
                            <div class="template_sections">
                              <div class="template_text">
                                <p>Enter Thrive Skills Tag*</p>
                              </div>
                            </div>
                            <div class="input_section">
                              <div class="input_text">
                                <SearchInput
                                  class="form-control collapse-search"
                                  id="thriveskillInput"
                                  onChange={this.thriveSkillUpdated}
                                  placeholder="Type to add Thrive Skill"
                                  value={this.state.thriveskillSearchTerm}
                                  autoComplete="off"
                                />
                              </div>
                              {this.validator.message(
                                "ThriveSkill",
                                this.state.addedThriveSkill,
                                "required"
                              )}
                            </div>
                            {this.state.thriveSkills.map((item) => {
                              return (
                                <>
                                  <div class="dropdown_img">
                                    <a
                                      class="dropdown-item"
                                      onClick={() => this.addThriveSkill(item)}
                                      href="#"
                                    >
                                      <img src={item.icon} />
                                      <span class="text_vidio_name">
                                        {item.name}
                                      </span>{" "}
                                      {/* <span aria-hidden="true">×</span> */}
                                    </a>
                                  </div>
                                </>
                              );
                            })}
                            <div class="">
                              {this.state.addedThriveSkill.map(
                                (item, index) => (
                                  <>
                                    <a class="dropdown-item drop-img" href="#">
                                      <img src={item.icon} width="10px" />
                                      <span class="text_vidio_name">
                                        {item.name}
                                      </span>{" "}
                                      <span
                                        aria-hidden="true"
                                        onClick={() =>
                                          this.removeThriveSkill(item)
                                        }
                                      >
                                        <i
                                          class="fa fa-times"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </a>
                                  </>
                                )
                              )}
                            </div>

                            <div class="template_sections">
                              <div class="row-xp">
                                <div class="col-md-6 switch_btn">
                                  <div class="template_name enter_xp">
                                    <p>Enter XP points</p>
                                    <div class="input_section from_daily">
                                      <div class="input_text">
                                        <input
                                          class="form-control enter_xp_text"
                                          id=""
                                          name="xpPoints"
                                          value={this.state.xpPoints}
                                          onChange={this.xpPointsHandleChange}
                                          placeholder=""
                                        ></input>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6 switch_btn">
                                  <div class="template_name enter_xp duration_xp">
                                    <p>Enter Duration</p>
                                    <div class="input_section from_daily">
                                      <div class="input_text">
                                        <input
                                          class="form-control enter_xp_text"
                                          id=""
                                          name="duration"
                                          value={this.state.duration}
                                          onChange={this.durationHandleChange}
                                          placeholder=""
                                        ></input>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="switch-row">
                              <div class="col-md-12 switch_btn">
                                <div class="custom-control custom-switch">
                                  <input
                                    type="checkbox"
                                    checked={this.state.dailychallenge}
                                    onChange={this.dcChange}
                                    class="custom-control-input"
                                    id="customSwitches1"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="customSwitches1"
                                  >
                                    Daily Challenges
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  addImageNext() {
    if (this.state.video == "") {
      this.validator.showMessageFor();
    } else {
      this.setState({ preview: 1 });
      this.validator.hideMessages();
    }
  }

  addFaculty(item) {
    if (this.state.addedFaculty.some((val) => item.name === val.name) === false)
      this.state.addedFaculty.push(item);
    this.state.faculties = [];
    this.state.facultySearchTerm = "";
  }
  removeFaculty(item) {
    this.state.addedFaculty.splice(this.state.addedFaculty.indexOf(item), 1);
  }

  facultyUpdated(term) {
    const token = localStorage.getItem("token");
    if (term.length > 0) {
      axios
        .get(
          Constants.api +
            "facultys?name=" +
            term +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=60&page=" +
            this.state.page,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            if (this.state.addedFaculty.length != 0) {
              this.state.addedFaculty.forEach((faculty) => {
                let index = res.data.results.indexOf(faculty);
                const newFaculty = res.data.results;
                newFaculty.splice(index, 1);
                this.setState({ faculties: newFaculty });
              });
            } else {
              this.setState({ faculties: res.data.results });
            }
          } else {
            console.log("hello");
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          }
        });
      this.setState({ facultySearchTerm: term });
    } else {
      this.setState({
        faculties: [],
      });
    }
  }

  addThriveSkill(item) {
    if (
      this.state.addedThriveSkill.some((val) => item.name === val.name) ===
      false
    )
      this.state.addedThriveSkill.push(item);
    this.state.thriveSkills = [];
    this.state.thriveskillSearchTerm = "";
  }
  removeThriveSkill(item) {
    this.state.addedThriveSkill.splice(
      this.state.addedThriveSkill.indexOf(item),
      1
    );
  }

  thriveSkillUpdated(term) {
    const token = localStorage.getItem("token");
    if (term.length > 0) {
      axios
        .get(
          Constants.api +
            "thriveSkills?name=" +
            term +
            "&sortBy=" +
            this.state.sortBy +
            "&limit=10&page=" +
            this.state.page,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            if (this.state.addedThriveSkill.length != 0) {
              this.state.addedThriveSkill.forEach((thriveskill) => {
                console.log(this.state.addedThriveSkill.length);
                let index = res.data.results.indexOf(thriveskill);
                const newThriveskill = res.data.results;
                newThriveskill.splice(index, 1);
                this.setState({ thriveSkills: newThriveskill });
              });
            } else {
              this.setState({ thriveSkills: res.data.results });
            }
          }
        });
      this.setState({ thriveskillSearchTerm: term });
    } else {
      this.setState({
        contthriveSkillsents: [],
      });
    }
  }
}

export default CreateImage;
