import React, { Component } from "react";
import * as Constants from "../../Constants/index";

import axios from "axios";
import * as QueryString from "query-string";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import CreatePathway from "./createpathway.component";
import moment from "moment";
import Pagination from "react-js-pagination";
import { Modal } from "react-bootstrap";
import ViewPathway from "./viewpathway.component";

class Pathway extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    // this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
  }

  state = {
    name: "",
    sortBy: "name:asc",
    limit: 25,
    page: 1,
    pathway: [],
    search: "",
    create: 0,
    totalResults: 0,
    preview: 0,
    status: this.props.resp ? this.props.resp.data.output.status : "PUBLISHED",
  };

  componentDidMount() {
    const tsRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        //console.log(token);

        const resp = await axios
          .get(
            Constants.api +
              "pathways?sortBy=" +
              this.state.sortBy +
              "&limit=" +
              this.state.limit +
              "&page=" +
              this.state.page +
              "&status=" +
              this.state.status +
              "&name=" +
              this.state.search,
            // +
            // "&status=" +
            // this.state.status,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              console.log(resp.data.results);
              this.setState({
                pathway: resp.data.output.results,
                totalResults: resp.data.output.totalResults,
              });
              console.log(this.state.totalResults);
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    tsRequest();
  }
  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount
    );
  };
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ page: pageNumber }, this.componentDidMount);
  }
  render() {
    const editItem = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "pathways/" + id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              console.log(resp);
              this.setState({ create: 1, resp: resp });
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const previewItem = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "pathways/" + id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              console.log(resp);
              this.setState({ preview: 1, resp: resp });
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const deleteItem = (id) => {
      if (window.confirm("Are you sure?")) {
        try {
          const token = localStorage.getItem("token");
          const deletedata = new FormData();
          deletedata.append("id", id); //append the values with key, value pair

          axios
            .delete(
              Constants.api + "pathways/" + id,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
              deletedata
            )
            .then((resp) => {
              //  casetype array

              if (resp.status == 204) {
                this.componentDidMount();
              } else {
              }
              //movie array
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
    };

    let search = this.state.search;
    const pathwayRender = this.state.pathway.map(function (item, i) {
      return (
        <>
          <tr>
            <td>{moment(item.createdAt).format("MM/DD/YY H:ma")}</td>
            <td>{moment(item.updatedAt).format("MM/DD/YY H:ma")}</td>
            <td>{item.title}</td>
            <td>
              <p class="highlight-cell highlight-gray">{item.status}</p>
            </td>
            <td>
              <p class="highlight-cell highlight-gray">
                {item.duration != "" || item.duration != 0
                  ? Math.floor(item.duration / 60) + ":" + (item.duration % 60)
                  : 0}{" "}
              </p>
            </td>
            <td class="edit-delete">
              <i
                class="fa fa-eye"
                onClick={() => previewItem(item.id)}
                aria-hidden="true"
              ></i>{" "}
              <i
                class="fa fa-pencil"
                onClick={() => editItem(item.id)}
                aria-hidden="true"
              ></i>{" "}
              <i
                class="fa fa-trash"
                onClick={() => deleteItem(item.id)}
                aria-hidden="true"
              ></i>
            </td>
          </tr>
        </>
      );
    });

    return this.state.create == 0 ? (
      <>
        {this.state.resp ? <ViewPathway resp={this.state.resp} /> : ""}
        <div class="main-head pathway-head pad_30">
          <h3>Pathway Listing</h3>
          <button
            type="button"
            class="btn  btn-create btn-save_cretate pathway-save-btn"
            onClick={() => this.setState({ create: 1 })}
          >
            Create New
          </button>
        </div>
        <Tabs className="nav-div">
          <TabList className="nav-tabs">
            <Tab
              className="pathway-tabs nav-link"
              onClick={() =>
                this.setState({ status: "PUBLISHED" }, this.componentDidMount)
              }
            >
              Published
            </Tab>
            <Tab
              className="pathway-tabs nav-link"
              onClick={() =>
                this.setState({ status: "DRAFT" }, this.componentDidMount)
              }
            >
              Draft
            </Tab>
          </TabList>
          <div className="pad_30">
            <TabPanel className="tab-pane">
              <div class="listing-info">
                <div class="listing-left">
                  <select name="filter-by" id="filter" class="filter-pathway">
                    <option value="" disable>
                      Filter By
                    </option>
                  </select>
                  <div class="search-bar">
                    <input
                      type="search"
                      name="search"
                      value={this.state.search}
                      onChange={this.searchHandleChange}
                      id="search"
                      class="search-pathway"
                      placeholder="Search"
                    />
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="listing-right">
                  Show entries
                  <button
                    class="btn-entries dropdown-toggle show-entries"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    25
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a class="dropdown-item" href="#">
                      Action
                    </a>
                    <a class="dropdown-item" href="#">
                      Another action
                    </a>
                    <a class="dropdown-item" href="#">
                      Something else here
                    </a>
                  </div>
                </div>
              </div>
              <div className="main-scroll-table">
                <table cellspacing="0" class="pathway-table">
                  <tbody>
                    <tr>
                      <th width="15%">Created On</th>
                      <th width="15%">Modified On</th>
                      <th width="35%">Title</th>
                      <th width="25%">Type</th>
                      <th width="10%">Duration</th>
                      <th width="10%">Actions</th>
                    </tr>

                    {pathwayRender}
                  </tbody>
                </table>
              </div>
              <div className="pagination-container">
                <Pagination
                  activePage={this.state.page}
                  itemsCountPerPage={this.state.limit}
                  totalItemsCount={this.state.totalResults}
                  pageRangeDisplayed={15}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </TabPanel>
            <TabPanel className="tab-pane">
              <div class="listing-info">
                <div class="listing-left">
                  <select name="filter-by" id="filter" class="filter-pathway">
                    <option value="" disable>
                      Filter By
                    </option>
                  </select>
                  <div class="search-bar">
                    <input
                      type="search"
                      name="search"
                      id="search"
                      value={this.state.search}
                      onChange={this.searchHandleChange}
                      class="search-pathway"
                      placeholder="Search"
                    />
                    <i class="fa fa-search" aria-hidden="true"></i>
                  </div>
                </div>
                <div class="listing-right">
                  Show entries
                  <button
                    class="btn-entries dropdown-toggle show-entries"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    25
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a class="dropdown-item" href="#">
                      Action
                    </a>
                    <a class="dropdown-item" href="#">
                      Another action
                    </a>
                    <a class="dropdown-item" href="#">
                      Something else here
                    </a>
                  </div>
                </div>
              </div>
              <table cellspacing="0" class="main-scroll-table pathway-table">
                <tbody>{pathwayRender}</tbody>
              </table>
              <div className="pagination-container">
                <Pagination
                  activePage={this.state.page}
                  itemsCountPerPage={this.state.limit}
                  totalItemsCount={this.state.totalResults}
                  pageRangeDisplayed={15}
                  onChange={this.handlePageChange.bind(this)}
                />
              </div>
            </TabPanel>
          </div>
        </Tabs>
      </>
    ) : this.state.resp ? (
      <CreatePathway resp={this.state.resp} />
    ) : (
      <CreatePathway />
    );
  }
}

export default Pathway;
