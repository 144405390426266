import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import SimpleReactValidator from "simple-react-validator";
import CreateAccessRoles from "./createAccessRoles.component";
import moment from "moment";
import Pagination from "react-js-pagination";

class AccessRoles extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    // this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
  }

  state = {
    name: "",
    sortBy: "name:asc",
    limit: 25,
    totalResults: 0,
    page: 1,
    users: [],
    search: "",
    create: 0,
  };

  componentDidMount() {
    const usersRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        let url = "";
        this.state.search != ""
          ? (url =
              "users?usertype=CMS" +
              "&name=" +
              this.state.search +
              "&sortBy=" +
              this.state.sortBy +
              "&limit=" +
              this.state.limit +
              "&page=" +
              this.state.page)
          : (url =
              "users?usertype=CMS&sortBy=" +
              this.state.sortBy +
              "&limit=" +
              this.state.limit +
              "&page=" +
              this.state.page);
        const resp = await axios
          .get(Constants.api + url, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              console.log(resp.data.results);
              this.setState({
                users: resp.data.results,
                totalResults: resp.data.totalResults,
              });
              console.log(this.state.users);
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    usersRequest();
  }

  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount
    );
  };
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ page: pageNumber }, this.componentDidMount);
  }

  render() {
    const editItem = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "users/" + id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              this.setState({ create: 1, resp: resp });
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    const deleteItem = (id) => {
      if (window.confirm("Are you sure?")) {
        try {
          const token = localStorage.getItem("token");
          const deletedata = new FormData();
          deletedata.append("id", id); //append the values with key, value pair

          axios
            .delete(
              Constants.api + "users/" + id,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
              deletedata
            )
            .then((resp) => {
              //  casetype array

              if (resp.status == 204) {
                this.componentDidMount();
              } else {
              }
              //movie array
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
    };

    const userRender = this.state.users.map(function (item, i) {
      return (
        <>
          <tr>
            <td>
              <p>{item.name}</p>
            </td>
            <td>
              <p>{item.email}</p>
            </td>
            <td>
              <p>{item.role}</p>
            </td>

            <td>
              <i
                class="fa fa-pencil"
                onClick={() => editItem(item.id)}
                aria-hidden="true"
              ></i>{" "}
              <i
                class="fa fa-trash"
                onClick={() => deleteItem(item.id)}
                aria-hidden="true"
              ></i>
            </td>
          </tr>
        </>
      );
    });

    return (
      <>
        {this.state.create == 0 ? (
          <>
            <div
              class="tab-pane fade in active show"
              id="v-pills-six"
              role="tabpanel"
              aria-labelledby="v-pills-settings-tab"
            >
              <div class="faculty_section faculty_section">
                <div class="faculty_text">
                  <h3>Access Code</h3>{" "}
                </div>
                <div class="btn_create">
                  <button
                    type="button"
                    class="btn btn-create btn-new"
                    onClick={() => this.setState({ create: 1 })}
                  >
                    Add New User
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-9">
                  <div class="form-group serch_group">
                    <input
                      type="text"
                      class="form-control form-control_one"
                      id="formGroupExampleInput2"
                      placeholder="Search"
                      onChange={this.searchHandleChange}
                    />{" "}
                    <i class="fa fa-search" aria-hidden="true"></i>{" "}
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="from_dropdown">
                    <label for="show">Show entries</label>
                    <select name="cars" id="show">
                      <option
                        value="25"
                        onClick={
                          (() => this.setState({ limit: 25 }),
                          () => this.componentDidMount())
                        }
                      >
                        25
                      </option>
                      <option
                        value="50"
                        onClick={
                          (() => this.setState({ limit: 50 }),
                          () => this.componentDidMount())
                        }
                      >
                        50
                      </option>
                      <option
                        value="75"
                        onClick={
                          (() => this.setState({ limit: 75 }),
                          () => this.componentDidMount())
                        }
                      >
                        75
                      </option>
                      <option
                        value="100"
                        onClick={
                          (() => this.setState({ limit: 100 }),
                          () => this.componentDidMount())
                        }
                      >
                        100
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="box-body table_faculty main-scroll-table main-scroll-table-long">
                <table class="table">
                  <tbody>
                    <tr class="row_table">
                      <th class="action_text">
                        <p>User Name</p>
                      </th>
                      <th class="designation_text">
                        <p>Email Id </p>
                      </th>
                      <th class="create_tabe ">
                        <p>Role</p>
                      </th>

                      <th class="action_text">Actions</th>
                    </tr>
                    {userRender}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="pagination-container">
              <Pagination
                activePage={this.state.page}
                itemsCountPerPage={this.state.limit}
                totalItemsCount={this.state.totalResults}
                pageRangeDisplayed={15}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
          </>
        ) : this.state.resp ? (
          <CreateAccessRoles resp={this.state.resp} />
        ) : (
          <CreateAccessRoles />
        )}
      </>
    );
  }
}

export default AccessRoles;
