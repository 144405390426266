import React, { Component, useEffect, useState } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import Switch from "react-switch";

class CreateTodayTemplate extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();

    this.state = {
      create: 1,
      name: this.props.resp ? this.props.resp.data.name : "",
      type: this.props.resp ? this.props.resp.data.templateType : null,
      descriptionPresent: this.props.resp
        ? this.props.resp.data.ttDescriptor.present
        : false,
      imageAlign: this.props.resp
        ? this.props.resp.data.ttImage.alignTop
        : false,
      videoAlign: this.props.resp
        ? this.props.resp.data.ttVideo.alignTop
        : false,
      headingAlign: this.props.resp
        ? this.props.resp.data.ttHeading.alignTop
        : false,
      imageHeight: this.props.resp
        ? this.props.resp.data.ttImage.imageHeight
        : 0,
      imageWidth: this.props.resp ? this.props.resp.data.ttImage.imageWidth : 0,
      videoHeight: this.props.resp
        ? this.props.resp.data.ttVideo.videoHeight
        : 0,
      videoWidth: this.props.resp ? this.props.resp.data.ttVideo.videoWidth : 0,
      headingLimit: this.props.resp
        ? this.props.resp.data.ttHeading.headingLimit
        : 0,
      descriptionLimit: this.props.resp
        ? this.props.resp.data.ttDescriptor.descriptorLimit
        : 30,
      descriptionFontSize: this.props.resp
        ? this.props.resp.data.ttDescriptor.fontSize
        : 14,
      minFont: this.props.resp
        ? this.props.resp.data.ttHeading.fontSizeMin
        : 20,
      maxFont: this.props.resp
        ? this.props.resp.data.ttHeading.fontSizeMax
        : 30,
      selectedType: this.props.resp
        ? {
          value: this.props.resp.data.templateType,
          label: this.props.resp.data.templateType,
        }
        : null,
    };
  }
  nameHandleChange = (event) => {
    this.setState({ name: event.target.value });
  };
  typeHandleChange = (type) => {
    // this.setState({ type });
    this.setState({ selectedType: type, type: type.value });
    console.log(type);
  };
  imageAlignChange = (event) => {
    this.setState({ imageAlign: !this.state.imageAlign });
  };
  videoAlignChange = (event) => {
    this.setState({ videoAlign: !this.state.videoAlign });
  };
  headingAlignChange = (event) => {
    this.setState({ headingAlign: !this.state.headingAlign });
  };
  descriptionPresentChange = (event) => {
    this.setState({ descriptionPresent: !this.state.descriptionPresent });
  };
  headingLimitHandleChange = (event) => {
    this.setState({ headingLimit: event.target.value });
  };
  descriptionLimitHandleChange = (event) => {
    this.setState({ descriptionLimit: event.target.value });
  };
  descriptionFontSizeHandleChange = (event) => {
    this.setState({ descriptionFontSize: event.target.value });
  };
  imageWidthHandleChange = (event) => {
    this.setState({ imageWidth: event.target.value });
  };
  imageHeightHandleChange = (event) => {
    this.setState({ imageHeight: event.target.value });
  };
  minFontHandleChange = (event) => {
    this.setState({ minFont: event.target.value });
  };
  maxFontHandleChange = (event) => {
    this.setState({ maxFont: event.target.value });
  };
  videoWidthHandleChange = (event) => {
    this.setState({ videoWidth: event.target.value });
  };
  videoHeightHandleChange = (event) => {
    this.setState({ videoHeight: event.target.value });
  };
  handleFormSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      this.validator.hideMessages();

      const token = localStorage.getItem("token");

      let obj = {};
      obj = {
        name: this.state.name,
        templateType: this.state.type,
        ttImage: {
          alignTop: this.state.imageAlign,
          imageWidth: this.state.imageWidth,
          imageHeight: this.state.imageHeight,
        },
        ttVideo: {
          alignTop: this.state.videoAlign,
          videoWidth: this.state.videoWidth,
          videoHeight: this.state.videoHeight,
        },
        ttHeading: {
          alignTop: this.state.headingAlign,
          font: "JOST_BOLD",
          fontSizeMin: this.state.minFont,
          fontSizeMax: this.state.maxFont,
          headingLimit: this.state.headingLimit,
        },
        ttDescriptor: {
          present: this.state.descriptionPresent,
          font: "Poppins_Light",
          fontSize: this.state.descriptionFontSize,
          descriptorLimit: this.state.descriptionLimit,
        },
      };

      if (this.props.resp) {
        console.log(this.props.resp);
        axios
          .patch(
            Constants.api + "todayTemplates/" + this.props.resp.data.id,
            obj,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              swal("Today Template Updated");
              this.setState({ create: 0 });
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      } else {
        axios
          .post(
            Constants.api + "todayTemplates",

            obj,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((res) => {
            if (res.status == 201) {
              swal("Today  Template Added");
              window.location = "/today-template";
            } else {
              // this.setState({alert:res.data.msg});
              swal("Something went wrong!");
            }
          });
      }
    } else this.validator.showMessages();
    this.forceUpdate();
  };
  render() {
    if (this.state.create == 0) {
      window.location = "/today-template";
    }
    let type = this.state.type;
    let options = [
      { value: "IMAGE_TEXT_SEPERATED", label: "Image & Text Seperate" },
      { value: "VIDEO_TEXT_SEPERATED", label: "Video & Text Seperate" },
      { value: "IMAGE_TEXT_OVERLAY", label: "Image with text overlay" },
      { value: "TEXT_ONLY", label: " Text Only" },
    ];

    return (
      <>
        {" "}
        <div class="add_video_section add_video">
          {/* Bread crumbs */}
          {/* <div class="faculty_sections">
            <div class="Faculty_text">
              <p>
                <span>Create Template</span>
              </p>
            </div>
          </div> */}
          {/* Second Section */}
          <div class="faculty_section">
            <div class="faculty_text">
              <h3>
                <i
                  class="fa fa-arrow-left"
                  aria-hidden="true"
                  onClick={() => this.setState({ create: 0 })}
                ></i>{" "}
                {this.props.resp ? "Edit" : "Create"} Today's Template
              </h3>{" "}
            </div>
            <div class="btn_create">
              <button
                type="submit"
                onClick={this.handleFormSubmit}
                class="btn btn-primary btn_category btn_vidios btn-save-1"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                {this.props.resp ? "Update" : "Save"}
              </button>
              <button
                onClick={() => this.setState({ create: 0 })}
                type="button"
                class="btn btn-save btn-save_close"
              >
                Close
              </button>
            </div>
          </div>
          {/* Progress */}
          {/* <ol class="track-progress">
            <li class="done">
              <div class="blue-dot"></div> <span>Create</span>{" "}
            </li>
            <li class="todo">
              <div class="blue-dot"></div> <span>Preview</span>{" "}
            </li> 
        </ol> */}

          {/* Main Content */}
          <div class="mcq_section ">
            <div class="container video_sections">
              <div class="row">
                {/* Preview */}
                {this.state.type && this.state.type.includes("TEXT_ONLY") ? (
                  <>
                    <div
                      align={this.state.imageAlign ? "top" : "bottom"}
                      class=" text-center  main-scroll"
                      style={{
                        background: "black",
                        height: "550px",
                        width: "300px",
                        display: "flex",
                        color: "white",
                        flexDirection: "column",
                      }}
                    >
                      <span
                        style={{
                          fontSize: this.state.maxFont,
                          textAlign: this.state.headingAlign ? "top" : "bottom",
                        }}
                      >
                        Heading
                      </span>
                      {this.state.descriptionPresent ? (
                        <p style={{ fontSize: "14px" }}> Description</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}

                {this.state.type && this.state.type.includes("IMAGE") ? (
                  <div class="rows col-md-3  text-center  main-scroll">
                    <div
                      align={this.state.imageAlign ? "top" : "bottom"}
                      class=" text-center  main-scroll"
                      style={{
                        background: "black",
                        width: "250px",
                        height: this.state.type.includes("OVERLAY")
                          ? "100%"
                          : "300px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: this.state.imageAlign
                          ? "flex-start"
                          : "flex-end",
                      }}
                    >
                      {this.state.type.includes("IMAGE") ? "Image" : "Video"}
                      {this.state.type &&
                        this.state.type.includes("OVERLAY") ? (
                        <>
                          <span
                            style={{
                              fontSize: this.state.maxFont,
                              textAlign: this.state.headingAlign
                                ? "top"
                                : "bottom",
                            }}
                          >
                            Heading
                          </span>
                          {this.state.descriptionPresent ? (
                            <p style={{ fontSize: "14px" }}> Description</p>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {this.state.type && this.state.type.includes("SEPERATE") ? (
                      <>
                        <span
                          style={{
                            fontSize: this.state.maxFont,
                            textAlign: this.state.headingAlign
                              ? "top"
                              : "bottom",
                          }}
                        >
                          Heading
                        </span>
                        {this.state.descriptionPresent ? (
                          <p style={{ fontSize: "14px" }}> Description</p>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}

                {this.state.type && this.state.type.includes("VIDEO") ? (
                  <div
                    class="rows col-md-3  text-center 
                     main-scroll"
                  >
                    <div
                      align={this.state.videoAlign ? "top" : "bottom"}
                      class=" text-center  main-scroll"
                      style={{
                        background: "black",
                        height: this.state.type.includes("OVERLAY")
                          ? "100%"
                          : "300px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: this.state.videoAlign
                          ? "flex-start"
                          : "flex-end",
                      }}
                    >
                      {this.state.type.includes("IMAGE") ? "Image" : "Video"}
                      {this.state.type &&
                        this.state.type.includes("OVERLAY") ? (
                        <>
                          <span
                            style={{
                              fontSize: this.state.maxFont,
                              textAlign: this.state.headingAlign
                                ? "top"
                                : "bottom",
                            }}
                          >
                            Heading
                          </span>
                          {this.state.descriptionPresent ? (
                            <p style={{ fontSize: "14px" }}> Description</p>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {this.state.type && this.state.type.includes("SEPERATE") ? (
                      <>
                        <span
                          style={{
                            fontSize: this.state.maxFont,
                            textAlign: this.state.headingAlign
                              ? "top"
                              : "bottom",
                          }}
                        >
                          Heading
                        </span>
                        {this.state.descriptionPresent ? (
                          <p style={{ fontSize: "14px" }}> Description</p>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}

                {/* Content */}
                <div class="col-md-8 main-scroll">
                  {/* Headin Section */}
                  <div class="template_vidio_name">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="template_name image-template-name">
                          <label for="exampleFormControlInput1">
                            Choose the Style
                          </label>
                          <Select
                            value={this.state.selectedType}
                            onChange={this.typeHandleChange}
                            options={options}
                          />

                          <div>
                            <div class="template_sections">
                              <div class="template_text">
                                <p class="info-template">Template Name</p>
                              </div>
                              <div class="number-range">
                                <p>{this.state.name.length}/50</p>
                              </div>
                            </div>
                            <div class="input_section">
                              <div class="input_text">
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Template Name"
                                  name="name"
                                  value={this.state.name}
                                  onChange={this.nameHandleChange}
                                  maxLength="50"
                                  id=""
                                />{" "}
                                {this.validator.message(
                                  "Name",
                                  this.state.name,
                                  "required"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Content Ended */}
                  {this.state.type &&
                    this.state.type.includes("IMAGE") == true &&
                    this.state.type.includes("OVERLAY") != true ? (
                    <>
                      {" "}
                      {/* Image start */}
                      <div class="template_vidio_name">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="template_name image-template-name">
                              <label for="exampleFormControlInput1">
                                Image Alignment
                              </label>
                              <div class="inline-form">
                                <label
                                  class="custom-control-label"
                                  for="customSwitches1"
                                >
                                  Bottom{" "}
                                </label>
                                <Switch
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="customSwitchesimage"
                                  onChange={this.imageAlignChange}
                                  checked={this.state.imageAlign}
                                />
                                <label
                                  class="custom-control-label"
                                  for="customSwitches1"
                                >
                                  Top{" "}
                                </label>
                              </div>
                              <div>
                                <div class="template_sections">
                                  <div class="template_text">
                                    <p class="info-template">
                                      Image Width
                                      {/* <img
                              src={
                                require("../../img/ionic-ios-information-circle-outline@2x.png")
                                  .default
                              }
                              alt="img_circle"
                              class="img_line"
                            /> */}
                                    </p>
                                  </div>
                                  <div class="number-range"></div>
                                </div>
                                <div class="input_section">
                                  <div class="input_text">
                                    <input
                                      type="number"
                                      class="form-control"
                                      placeholder="Image Width"
                                      name="imageWidth"
                                      value={this.state.imageWidth}
                                      onChange={this.imageWidthHandleChange}
                                      maxLength="50"
                                      id=""
                                    />{" "}
                                    {this.validator.message(
                                      "Image Width",
                                      this.state.imageWidth,
                                      "required"
                                    )}
                                  </div>
                                </div>
                                <div class="template_sections">
                                  <div class="template_text">
                                    <p class="info-template">Image Height</p>
                                  </div>
                                  <div class="number-range"></div>
                                </div>
                                <div class="input_section">
                                  <div class="input_text">
                                    <input
                                      type="number"
                                      class="form-control"
                                      placeholder="Image Height"
                                      name="imageHeight"
                                      value={this.state.imageHeight}
                                      onChange={this.imageHeightHandleChange}
                                      maxLength="50"
                                      id=""
                                    />{" "}
                                    {this.validator.message(
                                      "Image Height",
                                      this.state.imageHeight,
                                      "required"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* image Ended */}
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.type &&
                    this.state.type.includes("VIDEO") == true ? (
                    <>
                      {" "}
                      {/* Image start */}
                      <div class="template_vidio_name">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="template_name image-template-name">
                              <label for="exampleFormControlInput1">
                                Video Alignment
                              </label>
                              <div class="inline-form">
                                <label
                                  class="custom-control-label"
                                  for="customSwitches1"
                                >
                                  Bottom{" "}
                                </label>
                                <Switch
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="customSwitchesfive"
                                  onChange={this.videoAlignChange}
                                  checked={this.state.videoAlign}
                                />
                                <label
                                  class="custom-control-label"
                                  for="customSwitches1"
                                >
                                  Top{" "}
                                </label>
                              </div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* image Ended */}
                    </>
                  ) : (
                    ""
                  )}
                  {this.state.type &&
                    this.state.type.includes("TEXT") == true ? (
                    <>
                      {" "}
                      {/* Image start */}
                      <div class="template_vidio_name">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="template_name image-template-name">
                              <label for="exampleFormControlInput1">
                                Heading Alignment
                              </label>
                              <div class="inline-form">
                                <label
                                  class="custom-control-label"
                                  for="customSwitches1"
                                >
                                  Bottom{" "}
                                </label>

                                <Switch
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="customSwitchesheadinf"
                                  onChange={this.headingAlignChange}
                                  checked={this.state.headingAlign}
                                />
                                <label
                                  class="custom-control-label"
                                  for="customSwitches1"
                                >
                                  Top{" "}
                                </label>
                              </div>
                              <div>
                                <div class="template_sections">
                                  <div class="template_text">
                                    <p class="info-template">
                                      Mininum Font Size{" "}
                                    </p>
                                  </div>
                                  <div class="number-range"></div>
                                </div>
                                <div class="input_section">
                                  <div class="input_text">
                                    <input
                                      type="number"
                                      minimum="20"
                                      class="form-control"
                                      placeholder="Video Width"
                                      name="minFont"
                                      value={this.state.minFont}
                                      onChange={this.minFontHandleChange}
                                      maxLength="50"
                                      id=""
                                    />{" "}
                                    {this.validator.message(
                                      "min font",
                                      this.state.minFont,
                                      "required"
                                    )}
                                  </div>
                                </div>
                                <div class="template_sections">
                                  <div class="template_text">
                                    <p class="info-template">
                                      Maximum Font Size
                                    </p>
                                  </div>
                                  <div class="number-range"></div>
                                </div>
                                <div class="input_section">
                                  <div class="input_text">
                                    <input
                                      type="number"
                                      class="form-control"
                                      placeholder="Maximum Font"
                                      name="maxFont"
                                      value={this.state.maxFont}
                                      onChange={this.maxFontHandleChange}
                                      maxLength="50"
                                      minimum="30"
                                      id=""
                                    />{" "}
                                    {this.validator.message(
                                      "max font",
                                      this.state.maxFont,
                                      "required"
                                    )}
                                  </div>
                                </div>
                                <div class="template_sections">
                                  <div class="template_text">
                                    <p class="info-template">
                                      Max Length of Heading
                                    </p>
                                  </div>
                                  <div class="number-range"></div>
                                </div>
                                <div class="input_section">
                                  <div class="input_text">
                                    <input
                                      type="number"
                                      class="form-control"
                                      placeholder="Heading Limit"
                                      name="headingLimit"
                                      value={this.state.headingLimit}
                                      onChange={this.headingLimitHandleChange}
                                      maximum="50"
                                      id=""
                                    />{" "}
                                    {this.validator.message(
                                      "Heading Limit",
                                      this.state.headingLimit,
                                      "required|numeric|min:15,num"
                                    )}
                                  </div>
                                </div>

                                <Switch
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="customSwitchesfive"
                                  onChange={this.descriptionPresentChange}
                                  checked={this.state.descriptionPresent}
                                />
                                <label>Description Present</label>

                                {this.state.descriptionPresent ? (
                                  <>
                                    <div class="template_sections">
                                      <div class="template_text">
                                        <p class="info-template">
                                          Max Length of Description
                                        </p>
                                      </div>
                                      <div class="number-range"></div>
                                    </div>
                                    <div class="input_section">
                                      <div class="input_text">
                                        <input
                                          type="number"
                                          class="form-control"
                                          placeholder="Description Limit"
                                          name="descriptionLimit"
                                          value={this.state.descriptionLimit}
                                          onChange={
                                            this.descriptionLimitHandleChange
                                          }
                                          id=""
                                        />{" "}
                                        {this.validator.message(
                                          "description Limit",
                                          this.state.descriptionLimit,
                                          "required|numeric|min:30,num"
                                        )}
                                      </div>
                                    </div>
                                    <div class="template_sections">
                                      <div class="template_text">
                                        <p class="info-template">
                                          Font Size of Description
                                        </p>
                                      </div>
                                      <div class="number-range"></div>
                                    </div>
                                    <div class="input_section">
                                      <div class="input_text">
                                        <input
                                          type="number"
                                          class="form-control"
                                          placeholder="Font Size"
                                          name="descriptionFontSize"
                                          value={this.state.descriptionFontSize}
                                          maximum="50"
                                          id=""
                                        />{" "}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* image Ended */}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CreateTodayTemplate;