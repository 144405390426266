import { React, Component } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { UncontrolledCollapse, Button, CardBody, Card } from "reactstrap";
import axios from "axios";
import * as Constants from "../../Constants/index";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

class Navbar extends Component {
  constructor(props) {
    if (localStorage.getItem("userid") == null)
      localStorage.setItem("userid", 0);
    super(props);
    this.state = {
      cities: [],
      lat: 0.0,
      lng: 0.0,
    };
  }
  componentDidMount() {
    const profileRequest = async () => {
      const token = localStorage.getItem("customer_token");
      try {
        const response = await axios
          .get(Constants.api + "customer/profile", {
            headers: {
              appversion: Constants.av,
              platform: Constants.pt,
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {});
      } catch (err) {
        try {
          const response = await axios
            .post(
              Constants.api + "auth/refresh-tokens",
              {
                refreshToken: localStorage.getItem("customer_refreshtoken"),
              },
              ""
            )
            .then((res) => {
              if (res.status == 200) {
                console.log(res);
                localStorage.setItem(
                  "customer_token",
                  res.data.output.access.token
                );
                localStorage.setItem(
                  "customer_refreshtoken",
                  res.data.output.refresh.token
                );
              }
            });
        } catch (err) {
          localStorage.clear();
          window.location.href = "/login";
        }
      }
    };
    if (localStorage.getItem("customer_id")) profileRequest();
  }

  // handleChange(event) {
  //     this.setState({value: event.target.value});
  // }

  handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  getMyLocation = (e) => {
    let location = null;
    let latitude = null;
    let longitude = null;
    if (window.navigator && window.navigator.geolocation) {
      location = window.navigator.geolocation;
    }
    if (location) {
      location.getCurrentPosition(function (position) {
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
      });
    }
    this.setState({ lat: latitude, lng: longitude });
  };

  render() {
    if (localStorage.getItem("navbarMenu") === null) {
      localStorage.setItem("navbarMenu", "");
    }
    let today = new Date();
    console.log(JSON.stringify(today));
    console.log(localStorage.getItem("tokenexpiry"));
    if (JSON.stringify(today) >= localStorage.getItem("tokenexpiry"))
      this.handleLogout();
    //assigning location variable
    //destructuring pathname from location

    //Javascript split method to get the name of the path in array
    const splitLocation = window.location.pathname.split("/")[1];
    console.log(splitLocation);
    return (
      <>
        <div
          class="nav flex-column nav-pills nav_bar scroll-navbar"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <a
            className={splitLocation === "" ? "nav-link active" : "nav-link"}
            id="v-pills-home-tab"
            href="/dashboard"
            role="tab"
            aria-controls="v-pills-home"
            aria-selected="true"
          >
            <img
              src={require("../../img/home.svg").default}
              className={
                splitLocation === "" ? "navbar-icons active" : "navbar-icons"
              }
            />
            Dashboard
          </a>
          {localStorage.getItem("navbarMenu").includes("FACULTY") ? (
            <a
              className={
                splitLocation === "faculty" ? "nav-link active" : "nav-link"
              }
              id="v-pills-home-tab"
              href="/faculty"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              <img
                src={require("../../img/user.svg").default}
                className={
                  splitLocation === "faculty"
                    ? "navbar-icons active"
                    : "navbar-icons"
                }
              />{" "}
              Faculty
            </a>
          ) : (
            ""
          )}
          {localStorage.getItem("navbarMenu").includes("CATEGORY") ? (
            <a
              className={
                splitLocation === "categories" ? "nav-link active" : "nav-link"
              }
              id="v-pills-home-tab"
              href="/categories"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              <img
                src={require("../../img/merchandising.svg").default}
                className={
                  splitLocation === "categories"
                    ? "navbar-icons active"
                    : "navbar-icons"
                }
              />{" "}
              Category
            </a>
          ) : (
            ""
          )}
          {localStorage.getItem("navbarMenu").includes("THRIVESKILL") ? (
            <a
              className={
                splitLocation === "thrive-skill"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="v-pills-home-tab"
              href="/thrive-skill"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              <img
                src={require("../../img/shapes.svg").default}
                className={
                  splitLocation === "thrive-skill"
                    ? "navbar-icons active"
                    : "navbar-icons"
                }
              />{" "}
              Thrive Skills
            </a>
          ) : (
            ""
          )}

          {localStorage.getItem("navbarMenu").includes("CONTENT") ? (
            <a
              className={
                splitLocation === "content" ? "nav-link active" : "nav-link"
              }
              id="v-pills-home-tab"
              href="/content"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              <img
                src={require("../../img/book.svg").default}
                className={
                  splitLocation === "content"
                    ? "navbar-icons active"
                    : "navbar-icons"
                }
              />{" "}
              Content
            </a>
          ) : (
            ""
          )}
          {localStorage.getItem("navbarMenu").includes("GRADED_ASSESS") ||
          localStorage.getItem("navbarMenu").includes("GRADED_QA") ? (
            <>
              <Button
                className={
                  splitLocation === "graded-assesment"
                    ? "nav-link graded-menu active"
                    : "nav-link graded-menu"
                }
                id="toggler"
              >
                <img
                  src={require("../../img/merchandising.svg").default}
                  className={
                    splitLocation === "graded-assesment"
                      ? "navbar-icons active"
                      : "navbar-icons"
                  }
                />{" "}
                Graded Assessment
              </Button>
              <UncontrolledCollapse toggler="#toggler">
                <Card className="graded-card">
                  <CardBody className="graded-menu-content">
                    <div className="graded-menu-items">
                      {localStorage
                        .getItem("navbarMenu")
                        .includes("GRADED_QA") ? (
                        <div className="graded-menu-item">
                          <a href="/question">Question</a>
                        </div>
                      ) : (
                        ""
                      )}
                      {localStorage
                        .getItem("navbarMenu")
                        .includes("GRADED_ASSESS") ? (
                        <div className="graded-menu-item">
                          <a href="/graded-assesment">Assessment</a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </CardBody>
                </Card>
              </UncontrolledCollapse>
            </>
          ) : (
            ""
          )}
          {localStorage.getItem("navbarMenu").includes("LESSON") ? (
            <a
              className={
                splitLocation === "lesson" ? "nav-link active" : "nav-link"
              }
              id="v-pills-home-tab"
              href="/lesson"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              <img
                src={require("../../img/merchandising.svg").default}
                className={
                  splitLocation === "lesson"
                    ? "navbar-icons active"
                    : "navbar-icons"
                }
              />{" "}
              Lesson
            </a>
          ) : (
            ""
          )}
          {localStorage.getItem("navbarMenu").includes("PATHWAY") ? (
            <a
              className={
                splitLocation === "pathway" ? "nav-link active" : "nav-link"
              }
              id="v-pills-home-tab"
              href="/pathway"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              <img
                src={require("../../img/planet.svg").default}
                className={
                  splitLocation === "pathway"
                    ? "navbar-icons active"
                    : "navbar-icons"
                }
              />{" "}
              Pathway
            </a>
          ) : (
            ""
          )}
          {localStorage.getItem("navbarMenu").includes("DAILY") ? (
            <a
              className={
                splitLocation === "daily-challenge"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="v-pills-home-tab"
              href="/daily-challenge"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              <img
                src={require("../../img/feather.svg").default}
                className={
                  splitLocation === "daily-challenge"
                    ? "navbar-icons active"
                    : "navbar-icons"
                }
              />{" "}
              Daily Challenge
            </a>
          ) : (
            ""
          )}
          {localStorage.getItem("navbarMenu").includes("TODAY_MERCH") ? (
            <a
              className={
                splitLocation === "today-section"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="v-pills-home-tab"
              href="/today-section"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              <img
                src={require("../../img/calender.svg").default}
                className={
                  splitLocation === "today-section"
                    ? "navbar-icons active"
                    : "navbar-icons"
                }
              />{" "}
              Today Section
            </a>
          ) : (
            ""
          )}
          {localStorage.getItem("navbarMenu").includes("PATH_MERCH") ? (
            <a
              className={
                splitLocation === "pathway-merchandising"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="v-pills-home-tab"
              href="/pathway-merchandising"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              <img
                src={require("../../img/merchandising.svg").default}
                className={
                  splitLocation === "pathway-merchandising"
                    ? "navbar-icons active"
                    : "navbar-icons"
                }
              />{" "}
              Pathway Merchandising
            </a>
          ) : (
            ""
          )}
            {localStorage.getItem("navbarMenu").includes("TAG") ? (
            <a
              className={
                splitLocation === "tags" ? "nav-link active" : "nav-link"
              }
              id="v-pills-home-tab"
              href="/tags"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              <img
                src={require("../../img/playlist.png").default}
                className={
                  splitLocation === "tags"
                    ? "navbar-icons active"
                    : "navbar-icons"
                }
              />{" "}
              Tag
            </a>
          ) : (
            ""
          )}
          {localStorage.getItem("navbarMenu").includes("TODAY_TEMPLATE") ? (
            <a
              className={
                splitLocation === "today-template"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="v-pills-home-tab"
              href="/today-template"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              <img
                src={require("../../img/view_agenda.png").default}
                className={
                  splitLocation === "today-template"
                    ? "navbar-icons active"
                    : "navbar-icons"
                }
              />{" "}
              Today Template
            </a>
          ) : (
            ""
          )}
          {localStorage.getItem("navbarMenu").includes("TODAY_CONTENT") ? (
            <a
              className={
                splitLocation === "today-content"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="v-pills-home-tab"
              href="/today-content"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              <img
                src={require("../../img/bar_chart.png").default}
                className={
                  splitLocation === "today-content"
                    ? "navbar-icons active"
                    : "navbar-icons"
                }
              />{" "}
              Today Content
            </a>
          ) : (
            ""
          )}
          {localStorage.getItem("navbarMenu").includes("COUPON") ? (
            <a
              className={
                splitLocation === "coupon-code" ? "nav-link active" : "nav-link"
              }
              id="v-pills-home-tab"
              href="/coupon-code"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              <img
                src={require("../../img/discount.svg").default}
                className={
                  splitLocation === "coupon-code"
                    ? "navbar-icons active"
                    : "navbar-icons"
                }
              />{" "}
              Coupon Code
            </a>
          ) : (
            ""
          )}
          {localStorage.getItem("navbarMenu").includes("SUBSCRIPTION") ? (
            <a
              className={
                splitLocation === "pricing" ? "nav-link active" : "nav-link"
              }
              id="v-pills-home-tab"
              href="/pricing"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              <img
                src={require("../../img/rupees.svg").default}
                className={
                  splitLocation === "pricing"
                    ? "navbar-icons active"
                    : "navbar-icons"
                }
              />{" "}
              Pricing
            </a>
          ) : (
            ""
          )}
          {localStorage.getItem("navbarMenu").includes("USER") ? (
            <a
              className={
                splitLocation === "access-code" ? "nav-link active" : "nav-link"
              }
              id="v-pills-home-tab"
              href="/access-code"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              <img
                src={require("../../img/unlock.svg").default}
                className={
                  splitLocation === "access-code"
                    ? "navbar-icons active"
                    : "navbar-icons"
                }
              />{" "}
              Access Codes
            </a>
          ) : (
            ""
          )}
          {localStorage.getItem("navbarMenu").includes("TESTIMONIAL") ? (
            <a
              className={
                splitLocation === "testimonial" ? "nav-link active" : "nav-link"
              }
              id="v-pills-home-tab"
              href="/testimonial"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              <i
                className={
                  splitLocation === "testimonial"
                    ? "fa fa-users navbar-icons active"
                    : "fa fa-users navbar-icons"
                }
              ></i>{" "}
              Testimonial
            </a>
          ) : (
            ""
          )}
          {localStorage.getItem("navbarMenu").includes("ASSESSMENT") ? (
            <a
              className={
                splitLocation === "assessment" ? "nav-link active" : "nav-link"
              }
              id="v-pills-home-tab"
              href="/assessment"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              <img
                src={require("../../img/unlock.svg").default}
                className={
                  splitLocation === "assessment"
                    ? "navbar-icons active"
                    : "navbar-icons"
                }
              />{" "}
              Assessment
            </a>
          ) : (
            ""
          )}
          {localStorage.getItem("navbarMenu").includes("PERSONAL_ASSESS") ? (
            <a
              className={
                splitLocation === "personal-assessment"
                  ? "nav-link active"
                  : "nav-link"
              }
              id="v-pills-home-tab"
              href="/personal-assessment"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
            >
              <img
                src={require("../../img/unlock.svg").default}
                className={
                  splitLocation === "personal-assessment"
                    ? "navbar-icons active"
                    : "navbar-icons"
                }
              />{" "}
              Personal Assessment
            </a>
          ) : (
            ""
          )}
        
        </div>
        <div className="logout-container nav_bar">
          <a
            className="nav-link"
            id="v-pills-home-tab nav_logout"
            href="#"
            onClick={this.handleLogout}
            role="tab"
            aria-controls="v-pills-home"
            aria-selected="true"
          >
            LOGOUT
          </a>
        </div>
      </>
    );
  }
}

export default Navbar;
