import React, { Component } from "react";
import * as Constants from "../../Constants/index";

import axios from "axios";
import * as QueryString from "query-string";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import moment from "moment";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import CreateThriveSkill from "./createthriveskill.component";
import Pagination from "react-js-pagination";
class ThriveSkill extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.changelimit = this.changelimit.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
  }

  state = {
    name: "",
    sortBy: "name:asc",
    limit: 25,
    totalResults: 0,
    page: 1,
    thriveskill: [],
    search: "",
    create: 0,
  };
  changelimit = (event) => {
    alert(event.target.value);
    this.setState({ limit: event.target.value });
    this.componentDidMount();
  };
  componentDidMount() {
    //alert("Dsds");

    const tsRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        //console.log(token);

        const resp = await axios
          .get(
            Constants.api +
              "thriveSkills?sortBy=" +
              this.state.sortBy +
              "&limit=" +
              this.state.limit +
              "&page=" +
              this.state.page +
              "&name=" +
              this.state.search,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              console.log(resp.data.results);
              this.setState({
                thriveskill: resp.data.results,
                totalResults: resp.data.totalResults,
              });
              console.log(this.state.thriveskill);
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    tsRequest();
  }
  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ page: pageNumber }, this.componentDidMount);
  }
  render() {
    const deleteItem = (id) => {
      if (window.confirm("Are you sure?")) {
        try {
          const token = localStorage.getItem("token");
          const deletedata = new FormData();
          deletedata.append("id", id); //append the values with key, value pair

          axios
            .delete(
              Constants.api + "thriveSkills/" + id,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
              deletedata
            )
            .then((resp) => {
              //  casetype array

              if (resp.status == 204) {
                this.componentDidMount();
              } else {
              }
              //movie array
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
    };
    const editItem = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "thriveSkills/" + id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              this.setState({ create: 1, resp: resp });
            } else {
              swal("Something went wrong!");
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    let search = this.state.search;
    const tsRender = this.state.thriveskill.map(function (item, i) {
      return (
        <>
          <tr>
            <td>
              <img src={item.icon} />
            </td>
            <td>
              <p class="thrive_text">{item.name}</p>
            </td>
            <td>
              <p class="thrive_think">{item.skillGroup}</p>
            </td>
            <td>
              <p class="date_thrive">
                {" "}
                {moment(item.createdAt).format("MM/DD/YY H:ma")}
              </p>
            </td>
            <td>
              <p class="date_thrive">
                {moment(item.updatedAt).format("MM/DD/YY H:ma")}
              </p>
            </td>
            <td class="img_log">
              <i
                class="fa fa-pencil"
                onClick={() => editItem(item.id)}
                aria-hidden="true"
              ></i>{" "}
              <i
                class="fa fa-trash"
                onClick={() => deleteItem(item.id)}
                aria-hidden="true"
              ></i>
            </td>
          </tr>
        </>
      );
    });

    return (
      <>
        {this.state.create == 0 ? (
          <>
            <div class="thrive_skill">
              <div class="category_table">
                <div class="faculty_section faculty_section create-sec-padding">
                  <div class="faculty_text thrive_text">
                    <h3>Thrive Skills</h3>{" "}
                  </div>
                  <div class="btn_create btn-new">
                    <button
                      type="button"
                      class="btn  btn-create create_new_skill"
                      onClick={() => this.setState({ create: 1 })}
                    >
                      Create Thrive Skill
                    </button>
                  </div>
                </div>
                <div class="category_container create-sec-padding">
                  <div class="filter_section">
                    <div class="row-full">
                      <div class="col-md-9">
                        {/* <div class="row"> */}
                        <div class="form-group serch_group thrive-search">
                          <input
                            type="text"
                            class="form-control form-control_one"
                            id="formGroupExampleInput2"
                            placeholder="Search"
                            name="search"
                            value={this.state.value}
                            onChange={this.searchHandleChange}
                          />{" "}
                          <i class="fa fa-search" aria-hidden="true"></i>{" "}
                        </div>
                        {/* </div> */}
                      </div>
                      <div class="col-md-3">
                        <div class="from_dropdown">
                          <label for="show">Show entries</label>
                          <select
                            name="cars"
                            id="show"
                            onChange={this.changeLimit}
                          >
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="tab-content">
                      <div class="tab-pane active" id="home">
                        <div class="table_faculty main-scroll-table main-scroll-table-long">
                          <table cellspacing="0" class="table">
                            <tbody>
                              <tr class="row_table">
                                <th class="create_icon">
                                  <p>Icon</p>
                                </th>
                                <th class="create_tabe ">
                                  <p>Thrive Skills Name</p>
                                </th>
                                <th class="faculty_name">
                                  <p>Skill Group</p>
                                </th>
                                <th class="faculty_name">
                                  <p>Created On</p>
                                </th>
                                <th class="faculty_name">
                                  <p>Modified On</p>
                                </th>
                                <th class="action_text">Actions</th>
                              </tr>

                              {tsRender}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pagination-container">
              <Pagination
                activePage={this.state.page}
                itemsCountPerPage={this.state.limit}
                totalItemsCount={this.state.totalResults}
                pageRangeDisplayed={15}
                onChange={this.handlePageChange.bind(this)}
              />
            </div>
          </>
        ) : this.state.resp ? (
          <CreateThriveSkill resp={this.state.resp} />
        ) : (
          <CreateThriveSkill />
        )}
      </>
    );
  }
}

export default ThriveSkill;
