import React, { Component } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";
import CreatePersonal from "./createpersonal.component";

class Personal extends Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    // this.getFoodRequest = this.getFoodRequest.bind(this);
    // this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
  }

  state = {
    name: "",
    sortBy: "name:asc",
    limit: 1000,
    page: 1,
    personal: [],
    search: "",
    create: 0,
  };

  componentDidMount() {
    //alert("Dsds");

    const tsRequest = async () => {
      try {
        const token = localStorage.getItem("token");
        //console.log(token);

        const resp = await axios
          .get(
            Constants.api +
              "personalAccesss?sortBy=" +
              this.state.sortBy +
              "&limit=" +
              this.state.limit +
              "&page=" +
              this.state.page,

            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              console.log(resp.data.results);
              this.setState({ personal: resp.data.results });
              console.log(this.state.personal);
            } else {
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    tsRequest();
  }
  searchHandleChange = (event) => {
    this.setState(
      { search: event.target.value, page: 1 },
      this.componentDidMount()
    );
  };
  render() {
    const deleteItem = (id) => {
      if (window.confirm("Are you sure?")) {
        try {
          const token = localStorage.getItem("token");
          const deletedata = new FormData();
          deletedata.append("id", id); //append the values with key, value pair

          axios
            .delete(
              Constants.api + "personalAccesss/" + id,
              {
                headers: { Authorization: `Bearer ${token}` },
              },
              deletedata
            )
            .then((resp) => {
              //  casetype array

              if (resp.status == 204) {
                this.componentDidMount();
              } else {
              }
              //movie array
            });
        } catch (err) {
          // Handle Error Here
          console.error(err);
        }
      }
    };
    const editItem = (id) => {
      try {
        const token = localStorage.getItem("token");
        const deletedata = new FormData();
        deletedata.append("id", id); //append the values with key, value pair

        axios
          .get(
            Constants.api + "personalAccesss/" + id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
            deletedata
          )
          .then((resp) => {
            //  casetype array

            if (resp.status == 200) {
              this.setState({ create: 1, resp: resp });
            } else {
              swal("Something went wrong!");
            }
            //movie array
          });
      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    let search = this.state.search;
    const tsRender = this.state.personal.map(function (item, i) {
      if (
        item.userType.toLowerCase().includes(search.toLowerCase()) ||
        item.roleType.toLowerCase().includes(search.toLowerCase())
      ) {
        return (
          <>
            <tr>
              <td>
                <p class="thrive_text">{item.userType}</p>
              </td>
              <td>
                <p class="thrive_think">{item.roleType}</p>
              </td>
              <td>
                <p>{item.skillInterest}</p>
              </td>
              <td>
                <p>{moment(item.createdAt).format("MM/DD/YY H:ma")}</p>
              </td>
              <td>
                <p class="date_thrive">
                  {moment(item.updatedAt).format("MM/DD/YY H:ma")}
                </p>
              </td>
              <td class="img_log">
                {/* <img
                  src={require("../../img/pencil.svg").default}
                  onClick={() => editItem(item.id)}
                />{" "} */}
                <img
                  src={require("../../img/delete.svg").default}
                  onClick={() => deleteItem(item.id)}
                />
              </td>
            </tr>
          </>
        );
      }
    });

    return (
      <>
        {this.state.create == 0 ? (
          <>
            <div class="main-head pathway-head pad_30">
              <h3>Personal Access</h3>
              <button
                type="button"
                class="btn  btn-create btn-save_cretate pathway-save-btn"
                onClick={() => this.setState({ create: 1 })}
              >
                Create Personal Access
              </button>
            </div>
            <div class="main-head pathway-head personal-head padside_30">
              <div class="form-group serch_group">
                <input
                  type="text"
                  class="form-control form-control_one"
                  id="formGroupExampleInput2"
                  placeholder="Search"
                  value={this.state.value}
                  onChange={this.searchHandleChange}
                />{" "}
                <i class="fa fa-search" aria-hidden="true"></i>{" "}
              </div>
              <div class="from_dropdown">
                <label for="show">Show entries</label>
                <select name="cars" id="show">
                  <option
                    value="25"
                    onClick={
                      (() => this.setState({ limit: 25 }),
                      () => this.componentDidMount())
                    }
                  >
                    25
                  </option>
                  <option
                    value="50"
                    onClick={
                      (() => this.setState({ limit: 50 }),
                      () => this.componentDidMount())
                    }
                  >
                    50
                  </option>
                  <option
                    value="75"
                    onClick={
                      (() => this.setState({ limit: 75 }),
                      () => this.componentDidMount())
                    }
                  >
                    75
                  </option>
                  <option
                    value="100"
                    onClick={
                      (() => this.setState({ limit: 100 }),
                      () => this.componentDidMount())
                    }
                  >
                    100
                  </option>
                </select>
              </div>
            </div>
            <div class="thrive_skill">
              <div class="category_table">
                <div class="category_container">
                  <div class="filter_section">
                    <div class="tab-content padside_25">
                      <div class="tab-pane container active" id="home">
                        <div class=" table_faculty main-scroll-table">
                          <table cellspacing="0" class="table">
                            <tbody>
                              <tr class="row_table">
                                <th class="designation_text ">
                                  <p>Personal Access User</p>
                                </th>

                                <th class="designation_text">
                                  <p>Role Type</p>
                                </th>
                                <th class="designation_text">
                                  <p>Skill Interest</p>
                                </th>
                                <th class="designation_text">
                                  <p>Created On</p>
                                </th>
                                <th class="action_text">
                                  <p>Modified On</p>
                                </th>
                                <th class="action_text">Actions</th>
                              </tr>

                              {tsRender}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : this.state.resp ? (
          <CreatePersonal resp={this.state.resp} />
        ) : (
          <CreatePersonal />
        )}
      </>
    );
  }
}

export default Personal;
